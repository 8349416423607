define('totem-application/mixins/default_property_settings', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    reset_properties_to_default: function() {
      var default_property_settings, key, results, value;
      default_property_settings = this.get('default_property_settings');
      if (!default_property_settings) {
        return;
      }
      results = [];
      for (key in default_property_settings) {
        value = default_property_settings[key];
        if ((value != null) && value.type) {
          switch (false) {
            case !'array':
              results.push(this.set("" + key, []));
              break;
            default:
              results.push(void 0);
          }
        } else {
          results.push(this.set("" + key, value));
        }
      }
      return results;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});