define('totem-messages/mixins/models/message', ['exports', 'ember', 'ember-data', 'totem/ds/associations'], function (exports, ember, ds, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    date: ta['default'].attr('date'),
    state: ta['default'].attr('string'),
    time: ta['default'].attr('string'),
    to: ta['default'].attr('string'),
    from: ta['default'].attr('string'),
    body: ta['default'].attr('string'),
    source: ta['default'].attr('string'),
    rooms: ta['default'].attr(),
    to_users: ta['default'].attr(),
    to_teams: ta['default'].attr(),
    from_users: ta['default'].attr(),
    from_teams: ta['default'].attr(),
    is_new: ember['default'].computed.equal('state', 'new'),
    is_previous: ember['default'].computed.not('is_new'),
    messages: null,
    save_message: null,
    pre_message: ember['default'].computed(function() {
      return this.get('messages').format_pre(this);
    }),
    set_new: function() {
      return this.set('state', 'new');
    },
    set_previous: function() {
      return this.set('state', 'previous');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});