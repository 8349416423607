define('thinkspace-casespace/mixins/services/server_events/helpers', ['exports', 'ember', 'totem/scope'], function (exports, ember, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    get_store: function() {
      return totem_scope['default'].get_store();
    },
    get_current_user: function() {
      return totem_scope['default'].get_current_user();
    },
    load_records_into_store: function(value) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var records;
          records = value.records;
          if (ember['default'].isBlank(records)) {
            return resolve();
          }
          _this.store.pushPayload(records);
          return resolve();
        };
      })(this));
    },
    find_record: function(type, id) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (ember['default'].isBlank(type) || ember['default'].isBlank(id)) {
            return resolve(null);
          }
          return _this.store.find(type, id).then(function(record) {
            return resolve(record);
          });
        };
      })(this));
    },
    get_assignment: function() {
      var assignment;
      assignment = this.casespace.get_current_assignment();
      if (ember['default'].isBlank(assignment)) {
        totem_error["throw"](this, "Cannot join assignment server events.  Assignment is blank.");
      }
      return assignment;
    },
    get_phase: function() {
      var phase;
      phase = this.casespace.get_current_phase();
      if (ember['default'].isBlank(phase)) {
        totem_error["throw"](this, "Cannot join phase server events.  Phase is blank.");
      }
      return phase;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});