define('thinkspace-readiness-assurance/base/ra_component', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    ra: ember['default'].inject.service(ns['default'].to_p('ra')),
    init: function() {
      this._super();
      return this.ra = this.get('ra');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});