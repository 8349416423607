define('totem-application/pagination/object', ['exports', 'ember'], function (exports, ember) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].ObjectProxy.extend();

	exports['default'] = ___DefaultExportObject___;;

});