define('totem/ajax', ['exports', 'ember', 'ember-data', 'totem/config', 'totem/ns'], function (exports, ember, ds, config, ns) {

  'use strict';

  var Ajax, ___DefaultExportObject___;

  Ajax = (function() {
    function Ajax() {}

    Ajax.prototype.setup_complete = false;

    Ajax.prototype.get_container = function() {
      return this.container;
    };

    Ajax.prototype.set_container = function(container) {
      return this.container = container;
    };

    Ajax.prototype.setup = function() {
      this.adapter = this.container.lookup('adapter:application');
      this.store = this.container.lookup('store:main');
      this.totem_error = this.container.lookup('totem:error');
      this.totem_scope = this.container.lookup('totem:scope');
      this.totem_messages = this.container.lookup('totem:messages');
      this.json_transform = this.container.lookup('transform:json-api-models');
      if (!this.setup_comlete) {
        this.add_jquery_binary_tranport();
      }
      return this.setup_comlete = true;
    };

    Ajax.prototype.get_auth_header = function() {
      var email, session, token;
      session = this.get_container().lookup('simple-auth-session:main');
      token = session.get('secure.token');
      email = session.get('secure.email');
      if (ember['default'].isBlank(token)) {
        this.error("Session token is blank.");
      }
      if (ember['default'].isBlank(email)) {
        this.error("Session email is blank.");
      }
      return 'Token token' + '="' + token + '", ' + 'email' + '="' + email + '"';
    };

    Ajax.prototype.add_jquery_binary_tranport = function() {
      return ember['default'].$.ajaxTransport('+binary', function(options, original_options, jqXHR) {
        if (options.dataType && options.dataType === 'binary') {
          return {
            abort: function() {
              return jqXHR.abort();
            },
            send: function(headers, callback) {
              var data, dataType, is_async, type, url, xhr;
              xhr = new XMLHttpRequest();
              url = options.url;
              type = options.type;
              is_async = true;
              dataType = 'blob';
              data = options.data || null;
              xhr.addEventListener('load', function() {
                data = {};
                data[options.dataType] = xhr.response;
                return callback(xhr.status, xhr.statusText, data, xhr.getAllResponseHeaders());
              });
              xhr.open(type, url, is_async);
              xhr.setRequestHeader('Authorization', original_options.auth_header);
              xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
              xhr.responseType = dataType;
              xhr.processData = false;
              return xhr.send(data);
            }
          };
        }
      });
    };

    Ajax.prototype.binary = function(options) {
      var email, promise, session, token;
      if (!this.setup_complete) {
        this.setup();
      }
      session = this.get_container().lookup('simple-auth-session:main');
      token = session.get('secure.token');
      email = session.get('secure.email');
      if (ember['default'].isBlank(token)) {
        this.error("Session token is blank.");
      }
      if (ember['default'].isBlank(email)) {
        this.error("Session email is blank.");
      }
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = _this.build_query(options);
          query.auth_header = _this.get_auth_header();
          query.dataType = 'binary';
          query.success = function(result) {
            return resolve(result);
          };
          query.error = function(error) {
            return reject(error);
          };
          return ember['default'].$.ajax(query);
        };
      })(this));
      return ds['default'].PromiseObject.create({
        promise: promise
      });
    };

    Ajax.prototype.array = function(options) {
      var promise;
      if (!this.setup_complete) {
        this.setup();
      }
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = _this.build_query(options);
          query.success = function(result) {
            if (!options.skip_message) {
              _this.totem_messages.api_success({
                source: 'ajax.array',
                model: options.model || options.url,
                action: options.action
              });
            }
            return resolve(result);
          };
          query.error = function(error) {
            if (!options.skip_message) {
              _this.totem_messages.api_failure(error, {
                source: 'ajax.array',
                model: options.model || options.url,
                action: options.action
              });
            }
            return reject(error);
          };
          return ember['default'].$.ajax(query);
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    };

    Ajax.prototype.object = function(options) {
      var promise;
      if (!this.setup_complete) {
        this.setup();
      }
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = _this.build_query(options);
          query.success = function(result) {
            if (!options.skip_message) {
              _this.totem_messages.api_success({
                source: 'ajax.object',
                model: options.model || options.url,
                action: options.action
              });
            }
            return resolve(result);
          };
          query.error = function(error) {
            if (!options.skip_message) {
              _this.totem_messages.api_failure(error, {
                source: 'ajax.object',
                model: options.model || options.url,
                action: options.action
              });
            }
            return reject(error);
          };
          return ember['default'].$.ajax(query);
        };
      })(this));
      return ds['default'].PromiseObject.create({
        promise: promise
      });
    };

    Ajax.prototype.find_many = function(options) {
      var promise;
      if (!this.setup_complete) {
        this.setup();
      }
      return promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var results;
          results = {};
          return _this.object(options).then(function(payload) {
            var ids, model, models, type, values;
            for (type in payload) {
              model = ember['default'].Inflector.inflector.singularize(type);
              values = ember['default'].makeArray(payload[type]);
              ids = values.mapBy('id');
              _this.store.pushPayload(model, payload);
              models = _this.store.all(model).filter(function(model) {
                return ids.contains(parseInt(model.get('id')));
              });
              results[type] = models;
            }
            return resolve(results);
          });
        };
      })(this));
    };

    Ajax.prototype.adapter_model_url = function(options) {
      if (!this.setup_complete) {
        this.setup();
      }
      if (options.action == null) {
        options.action = '';
      }
      return this.build_query(options).url;
    };

    Ajax.prototype.adapter_host = function() {
      if (!this.setup_complete) {
        this.setup();
      }
      return this.adapter.get('host');
    };

    Ajax.prototype.build_query = function(options) {
      var action, data, id, model, model_class, query, type_key, url, verb;
      verb = options.verb || 'GET';
      action = options.action;
      model = options.model;
      id = options.id;
      data = options.data || {};
      url = options.url;
      if (!(model || url)) {
        this.error("Either [model] or [url] options must be passed.", options);
      }
      if (url) {
        if (model || action || id) {
          this.error("[model], [action] and [id] are ignored when the url is passed; remove them.", options);
        }
      } else {
        if (!model) {
          this.error("Model is blank.", options);
        }
        if (action == null) {
          this.error("Action is blank.", options);
        }
      }
      query = {};
      query.type = verb;
      query.dataType = 'json';
      query.contentType = 'application/json; charset=utf-8';
      if (config['default'].ajax_timeout) {
        query.timeout = config['default'].ajax_timeout;
      }
      type_key = null;
      if (url) {
        url = this.adapter.urlPrefix(url, this.adapter.urlPrefix());
      } else {
        switch (typeof model) {
          case 'string':
            model_class = this.totem_scope.model_class_from_string(model);
            if (!model_class) {
              this.error("Model class for [" + model + "] not found.", options);
            }
            type_key = this.totem_scope.model_class_type_key(model_class);
            this.totem_scope.add_auth_to_query(model_class, data);
            break;
          case 'object':
            type_key = this.totem_scope.record_type_key(model);
            this.totem_scope.add_auth_to_query(model, data);
            break;
          default:
            this.error("Unknown model object (not a string or object).", options);
        }
        if (!type_key) {
          this.error("Model typeKey is blank.", options);
        }
        url = this.adapter.buildURL(type_key, id, verb);
        if (action) {
          url += '/' + action;
        }
      }
      query.data = data;
      if (!this.query_is_get(query)) {
        query.data = this.stringify(query.data);
      }
      query.url = url;
      return query;
    };

    Ajax.prototype.stringify = function(obj) {
      return JSON.stringify(obj);
    };

    Ajax.prototype.query_is_get = function(query) {
      return query.type === 'GET' || query.type === 'get';
    };

    Ajax.prototype.error = function(message, options) {
      if (options == null) {
        options = null;
      }
      if (message == null) {
        message = '';
      }
      if (options) {
        message += " [options: " + (this.stringify(options)) + "]";
      }
      return this.totem_error["throw"](this, "totem.ajax error: " + message);
    };

    Ajax.prototype.normalize_and_push_payload = function(type, payload, options) {
      var normalized, payload_type, records;
      if (options == null) {
        options = {};
      }
      if (!this.setup_complete) {
        this.setup();
      }
      if (options.skip_ns) {
        payload_type = ember['default'].Inflector.inflector.pluralize(type);
      } else {
        payload_type = ns['default'].to_p(ember['default'].Inflector.inflector.pluralize(type));
        type = ns['default'].to_p(type);
      }
      if (options.single) {
        records = ember['default'].makeArray(payload[type]);
        delete payload[type];
      } else {
        records = payload[payload_type];
        delete payload[payload_type];
      }
      if (!ember['default'].isPresent(records)) {
        return [];
      }
      normalized = records.map((function(_this) {
        return function(record) {
          return _this.store.normalize(type, record);
        };
      })(this));
      records = this.store.pushMany(type, normalized);
      if (!(ember['default'].isEmpty(ember['default'].keys(payload)) || options.skip_extra_records)) {
        this.store.pushPayload(payload);
      }
      if (options.single) {
        records = records.get('firstObject');
      }
      return records;
    };

    Ajax.prototype.extract_included_records = function(payload, options) {
      var key, models;
      if (options == null) {
        options = {};
      }
      key = options.key || 'included';
      models = payload[key];
      delete payload[key];
      return this.json_transform.deserialize(models);
    };

    return Ajax;

  })();

  ___DefaultExportObject___ = new Ajax;

  exports['default'] = ___DefaultExportObject___;;

});