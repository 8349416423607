define('totem/ns', ['exports', 'ember', 'totem/util', 'totem/config/require_modules'], function (exports, ember, util, reqm) {

  'use strict';

  var TotemNamespaceMap, ___DefaultExportObject___;

  TotemNamespaceMap = (function() {
    function TotemNamespaceMap() {
      this.ns_map = {
        namespaces: {},
        type_to_namespace: {}
      };
      this.populate_ns_map();
    }

    TotemNamespaceMap.prototype.to_p = function() {
      return this.type_to(arguments, '/');
    };

    TotemNamespaceMap.prototype.to_r = function() {
      return this.type_to(arguments, '.', '/');
    };

    TotemNamespaceMap.prototype.to_prop = function() {
      return this.type_to(arguments, '.', '/', false);
    };

    TotemNamespaceMap.prototype.to_t = function() {
      return 'components/' + this.to_p(arguments);
    };

    TotemNamespaceMap.prototype.to_c = function() {
      return 'components/' + this.to_p(arguments);
    };

    TotemNamespaceMap.prototype.to_m = function() {
      return 'mixins/' + this.to_p(arguments);
    };

    TotemNamespaceMap.prototype.to_o = function() {
      return this.to_p(arguments).replace(/\//g, '.');
    };

    TotemNamespaceMap.prototype.type_to = function(args, sep, type_sep, underscore_parts) {
      var last, ns, parts, path, stype, type;
      if (type_sep == null) {
        type_sep = null;
      }
      if (underscore_parts == null) {
        underscore_parts = true;
      }
      parts = util['default'].flatten_array(args);
      type = parts.shift();
      if (type == null) {
        this.error("type was not passed as an argument.");
      }
      type = type.underscore();
      if (underscore_parts) {
        parts = parts.map(function(part) {
          return part.underscore();
        });
      }
      stype = type.singularize();
      ns = this.ns_map.type_to_namespace[stype];
      if (ns != null) {
        path = this.ns_map.namespaces[ns];
        if (path == null) {
          this.error("namespace for [" + ns + "] not defined for type [" + stype + "].");
        }
      } else {
        path = this.ns_map.namespaces[stype] || this.ns_map.namespaces[type];
        if (path == null) {
          this.error("type_to_namespace [" + stype + "] not defined.");
        }
        type = null;
      }
      if (type != null) {
        if (type.match(':')) {
          type = type.split(':', 2).pop();
        }
        (type_sep && (path += type_sep + type)) || (path += sep + type);
      }
      if (parts.length > 0) {
        if (type_sep) {
          last = parts.pop();
          if (parts.length > 0) {
            path += type_sep + parts.join(type_sep);
          }
          path += sep + last;
        } else {
          path += sep + parts.join(sep);
        }
      }
      return path;
    };

    TotemNamespaceMap.prototype.populate_ns_map = function() {
      var i, len, map, mod, mods, namespaces, ns, regex, type, type_to_namespace, val;
      regex = reqm['default'].config_regex('ns');
      mods = reqm['default'].filter_by(regex);
      if (ember['default'].isBlank(mods)) {
        return;
      }
      for (i = 0, len = mods.length; i < len; i++) {
        mod = mods[i];
        map = reqm['default'].require_module(mod);
        if (!reqm['default'].is_hash(map)) {
          this.error("Module '" + mod + "' is not a hash.");
        }
        namespaces = map.namespaces || {};
        if (!reqm['default'].is_hash(namespaces)) {
          this.error("Module '" + mod + "' key 'namespaces' is not a hash.");
        }
        for (ns in namespaces) {
          val = namespaces[ns];
          if (!reqm['default'].is_string(ns)) {
            this.error("Module '" + mod + "' namespace is not a string.");
          }
          if (!reqm['default'].is_string(val)) {
            this.error("Module '" + mod + "' namespace value is not a string.");
          }
          if (ember['default'].isPresent(this.ns_map.namespaces[ns])) {
            this.error("Module '" + mod + "' namespaces '" + ns + "' is a duplicate.");
          }
          this.ns_map.namespaces[ns] = val;
        }
        type_to_namespace = map.type_to_namespace || {};
        if (!reqm['default'].is_hash(type_to_namespace)) {
          this.error("Module '" + mod + "' key 'type_to_namespace' is not a hash.");
        }
        for (type in type_to_namespace) {
          ns = type_to_namespace[type];
          if (!reqm['default'].is_string(type)) {
            this.error("Module '" + mod + "' type_to_namespace type is not a string.");
          }
          if (!reqm['default'].is_string(ns)) {
            this.error("Module '" + mod + "' type_to_namespace namespace is not a string.");
          }
          if (ember['default'].isPresent(this.ns_map.type_to_namespace[type])) {
            this.error("Module '" + mod + "' type_to_namespace '" + type + "' is a duplicate.");
          }
          this.ns_map.type_to_namespace[type] = ns;
        }
      }
      return this.validate_integrity();
    };

    TotemNamespaceMap.prototype.validate_integrity = function() {
      var ns, ref, results, type;
      ref = this.ns_map.type_to_namespace;
      results = [];
      for (type in ref) {
        ns = ref[type];
        if (ember['default'].isBlank(this.ns_map.namespaces[ns])) {
          results.push(this.error("Namespace '" + ns + "' for type '" + type + "' does not exist."));
        } else {
          results.push(void 0);
        }
      }
      return results;
    };

    TotemNamespaceMap.prototype.error = function(message) {
      if (message == null) {
        message = '';
      }
      return reqm['default'].error(this, message);
    };

    TotemNamespaceMap.prototype.toString = function() {
      return 'TotemNamespaceMap';
    };

    return TotemNamespaceMap;

  })();

  ___DefaultExportObject___ = new TotemNamespaceMap;

  exports['default'] = ___DefaultExportObject___;;

});