define('thinkspace-readiness-assurance/chat_manager', ['exports', 'ember', 'thinkspace-readiness-assurance/mixins/cm/helpers', 'thinkspace-readiness-assurance/mixins/cm/initialize', 'thinkspace-readiness-assurance/mixins/cm/values'], function (exports, ember, m_helpers, m_initialize, m_values) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Object.extend(m_initialize['default'], m_values['default'], m_helpers['default']);

	exports['default'] = ___DefaultExportObject___;;

});