define('thinkspace-readiness-assurance/mixins/am/menus', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    c_menu_dashbaord: ns['default'].to_p('ra:admin', 'menu', 'dashboard'),
    c_menu_irat: ns['default'].to_p('ra:admin', 'menu', 'irat'),
    c_menu_irat_to_trat: ns['default'].to_p('ra:admin', 'menu', 'irat_to_trat'),
    c_menu_messages: ns['default'].to_p('ra:admin', 'menu', 'messages'),
    c_menu_timers: ns['default'].to_p('ra:admin', 'menu', 'timers'),
    c_menu_trat: ns['default'].to_p('ra:admin', 'menu', 'trat'),
    c_menu_trat_summary: ns['default'].to_p('ra:admin', 'menu', 'trat_summary'),
    c_messages_send: ns['default'].to_p('ra:admin', 'messages', 'send'),
    c_messages_view: ns['default'].to_p('ra:admin', 'messages', 'view'),
    c_timers_active: ns['default'].to_p('ra:admin', 'timers', 'active'),
    c_tracker_show: ns['default'].to_p('ra:admin', 'tracker', 'show'),
    c_irat_phase_states: ns['default'].to_p('ra:admin', 'irat', 'phase_states'),
    c_irat_to_trat_after: ns['default'].to_p('ra:admin', 'irat', 'to_trat_after'),
    c_irat_to_trat_due_at: ns['default'].to_p('ra:admin', 'irat', 'to_trat_due_at'),
    c_irat_to_trat_now: ns['default'].to_p('ra:admin', 'irat', 'to_trat_now'),
    c_trat_phase_states: ns['default'].to_p('ra:admin', 'trat', 'phase_states'),
    c_trat_summary_answers: ns['default'].to_p('ra:admin', 'trat', 'summary', 'answers'),
    c_trat_summary_teams: ns['default'].to_p('ra:admin', 'trat', 'summary', 'teams'),
    c_trat_teams: ns['default'].to_p('ra:admin', 'trat', 'teams', 'show'),
    c_trat_summary_answers_answer: ns['default'].to_p('ra:admin', 'trat', 'summary', 'answers', 'answer'),
    c_trat_summary_teams_team: ns['default'].to_p('ra:admin', 'trat', 'summary', 'teams', 'team'),
    c_trat_summary_teams_team_qm: ns['default'].to_p('ra:admin', 'trat', 'summary', 'teams', 'team_qm'),
    c_trat_teams_users: ns['default'].to_p('ra:admin', 'trat', 'teams', 'users', 'show'),
    c_admin_shared_error: ns['default'].to_p('ra:admin', 'shared', 'error'),
    c_admin_shared_menu: ns['default'].to_p('ra:admin', 'shared', 'menu'),
    c_admin_shared_message: ns['default'].to_p('ra:admin', 'shared', 'message'),
    c_admin_shared_phase_states: ns['default'].to_p('ra:admin', 'shared', 'phase_states'),
    c_admin_shared_time_at: ns['default'].to_p('ra:admin', 'shared', 'time_at'),
    c_admin_shared_time_after: ns['default'].to_p('ra:admin', 'shared', 'time_after'),
    c_admin_shared_toggle_select: ns['default'].to_p('ra:admin', 'shared', 'toggle_select'),
    c_admin_shared_radio_buttons: ns['default'].to_p('ra:admin', 'shared', 'radio', 'buttons'),
    c_admin_shared_radio_button: ns['default'].to_p('ra:admin', 'shared', 'radio', 'button'),
    c_admin_shared_team_users_select: ns['default'].to_p('ra:admin', 'shared', 'team_users', 'select'),
    c_admin_shared_team_users_team: ns['default'].to_p('ra:admin', 'shared', 'team_users', 'team'),
    c_admin_shared_team_users_user: ns['default'].to_p('ra:admin', 'shared', 'team_users', 'user'),
    c_admin_shared_teams_select: ns['default'].to_p('ra:admin', 'shared', 'teams', 'select'),
    c_admin_shared_teams_team: ns['default'].to_p('ra:admin', 'shared', 'teams', 'team'),
    c_admin_shared_timer_show: ns['default'].to_p('ra:admin', 'shared', 'timer', 'show'),
    c_admin_shared_timer_interval: ns['default'].to_p('ra:admin', 'shared', 'timer', 'interval'),
    c_admin_shared_timer_reminders: ns['default'].to_p('ra:admin', 'shared', 'timer', 'reminders'),
    c_admin_shared_users_select: ns['default'].to_p('ra:admin', 'shared', 'users', 'select'),
    c_admin_shared_users_user: ns['default'].to_p('ra:admin', 'shared', 'users', 'user'),
    c_shared_messages_view: ns['default'].to_p('readiness_assurance', 'shared', 'messages', 'view'),
    c_date_picker: ns['default'].to_p('common', 'date_picker'),
    c_time_picker: ns['default'].to_p('common', 'time_picker'),
    dashboard_menu: ember['default'].computed(function() {
      return [
        {
          component: this.c_menu_irat,
          title: 'IRAT',
          clear: true
        }, {
          component: this.c_menu_trat,
          title: 'TRAT',
          clear: true
        }, {
          component: this.c_menu_messages,
          title: 'Messages',
          clear: true
        }, {
          component: this.c_menu_timers,
          title: 'Timers',
          clear: true
        }, {
          component: this.c_timers_active,
          title: 'Active Timers',
          clear: true
        }, {
          component: this.c_tracker_show,
          title: 'Tracker',
          clear: false,
          clearable: false
        }
      ];
    }),
    messages_menu: ember['default'].computed(function() {
      return [
        {
          component: this.c_messages_send,
          title: 'Send Message',
          "default": true
        }, {
          component: this.c_messages_view,
          title: 'View Message',
          top: true
        }
      ];
    }),
    timers_menu: ember['default'].computed(function() {
      return [
        {
          component: this.c_timers_active,
          title: 'Active'
        }
      ];
    }),
    irat_menu: ember['default'].computed(function() {
      return [
        {
          component: this.c_menu_irat_to_trat,
          title: 'Transition Teams to TRAT',
          clear: true
        }, {
          component: this.c_irat_phase_states,
          title: 'Phase States',
          clear: true
        }, {
          component: this.c_messages_view,
          title: 'View Messages',
          top: true,
          first: true,
          clearable: false
        }, {
          component: this.c_timers_active,
          title: 'Active Timers',
          top: true
        }
      ];
    }),
    irat_to_trat_menu: ember['default'].computed(function() {
      return [
        {
          component: this.c_irat_to_trat_after,
          title: 'After',
          clear: true,
          "default": true
        }, {
          component: this.c_irat_to_trat_due_at,
          title: 'Due At',
          clear: true
        }, {
          component: this.c_irat_to_trat_now,
          title: 'Now',
          clear: true
        }
      ];
    }),
    trat_menu: ember['default'].computed(function() {
      return [
        {
          component: this.c_trat_teams,
          title: 'Teams'
        }, {
          component: this.c_menu_trat_summary,
          title: 'Summary'
        }, {
          component: this.c_trat_phase_states,
          title: 'Phase States',
          clear: true
        }, {
          component: this.c_messages_view,
          title: 'View Messages',
          top: true,
          clearable: false
        }
      ];
    }),
    trat_summary_menu: ember['default'].computed(function() {
      return [
        {
          component: this.c_trat_summary_answers,
          title: 'By Answer Counts',
          "default": true
        }, {
          component: this.c_trat_summary_teams,
          title: 'By Teams'
        }
      ];
    }),
    select_component: function(config) {
      var val;
      val = config.select || 'team_users';
      return this["c_admin_shared_" + val + "_select"];
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});