define('thinkspace-readiness-assurance/mixins/qm/helpers', ['exports', 'ember', 'totem/error'], function (exports, ember, totem_error) {

  'use strict';

  var ___DefaultExportObject___,
    slice = [].slice;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    current_user_full_name: function() {
      return this.rm.current_user_full_name();
    },
    is_true_or_false: function(val) {
      return val === true || val === false;
    },
    is_function: function(fn) {
      return typeof fn === 'function';
    },
    is_object: function(obj) {
      return obj && typeof obj === 'object';
    },
    is_hash: function(obj) {
      return this.is_object(obj) && !ember['default'].isArray(obj);
    },
    is_active: function(obj) {
      return !this.is_inactive(obj);
    },
    is_inactive: function(obj) {
      if (!obj) {
        return true;
      }
      return obj.isDestroyed || obj.isDestroying;
    },
    debug: function() {
      console.warn(this);
      console.info('answer_id    :', this.get('answer_id'));
      console.info('justification:', this.get('justification'));
      console.info('choices      :', this.choices);
      console.info('qid          :', this.qid);
      return console.info('question     :', this.question);
    },
    error: function() {
      var args, message;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      message = args.shift() || '';
      if (ember['default'].isPresent(args)) {
        console.error(message, args);
      }
      return totem_error['default']["throw"](this, message);
    },
    toString: function() {
      return 'ReadinessAssuranceQuestionManager:' + ember['default'].guidFor(this);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});