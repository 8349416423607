define('totem-template-manager/tvo/status', ['exports', 'ember', 'totem-template-manager/tvo/status_base'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      this._super();
      this.init_status_values();
      return this.set('collection_keys', []);
    },
    get_path: function(key) {
      return this.tvo_property + "." + key;
    },
    get_value: function(key) {
      return this.tvo.get_path_value(this.get_path(key));
    },
    get_collection_keys: function() {
      return this.get('collection_keys');
    },
    set_value: function(key) {
      var path, status;
      path = this.get_path(key);
      status = this.get_value(key);
      if (!status) {
        this.tvo.set_path_value(path, this.new_status_collection());
        this.get_collection_keys().push(key);
      }
      return path;
    },
    register_validation: function(key, source, fn) {
      this.set_value(key);
      return this.get_value(key).register_validation(source, fn);
    },
    all_valid: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var status_instances;
          status_instances = _this.get_collection_keys().map(function(key) {
            return _this.get_value(key);
          });
          return _this.set_status_values(status_instances).then(function(status) {
            if (status.is_valid) {
              return resolve(status);
            } else {
              return reject(status);
            }
          });
        };
      })(this));
    },
    edit_on: function() {
      return this.set('is_edit', true);
    },
    edit_off: function() {
      return this.set('is_edit', false);
    },
    new_status_collection: function() {
      return this.get('status_collection').create();
    },
    toString: function() {
      return 'TvoStatus';
    },
    status_collection: base['default'].extend({
      init: function() {
        this._super();
        this.init_status_values();
        this.set('status_map', ember['default'].Map.create());
        return this.set('count', {});
      },
      get_status_map: function() {
        return this.get('status_map');
      },
      register_validation: function(source, fn) {
        var source_status;
        source_status = this.new_source_status();
        source_status.set_callback(source, fn);
        return this.set_source_status(source, source_status);
      },
      validate: function(source, group_guid) {
        if (group_guid == null) {
          group_guid = null;
        }
        return new ember['default'].RSVP.Promise((function(_this) {
          return function(resolve, reject) {
            var source_status;
            if (source.get('is_validation_mixin_included') !== true) {
              return resolve();
            }
            source_status = _this.new_source_status();
            return source.validate().then(function() {
              source_status.set_is_valid(true);
              _this.set_validate_status(source, source_status, group_guid);
              return resolve();
            }, function(error) {
              source_status.set_is_valid(false);
              _this.set_validate_status(source, source_status, group_guid);
              return reject();
            });
          };
        })(this));
      },
      set_validate_status: function(source, source_status, group_guid) {
        source_status.set_by_validate();
        this.set_source_status(source, source_status, group_guid);
        return this.update_overall_validate_status_counts();
      },
      update_overall_validate_status_counts: function() {
        var invalid, status, valid;
        status = this.get_status_map();
        valid = 0;
        invalid = 0;
        this.get_status_map().forEach((function(_this) {
          return function(source_status) {
            if (source_status.is_by_validate()) {
              if (source_status.get_is_valid()) {
                return valid += 1;
              } else {
                return invalid += 1;
              }
            }
          };
        })(this));
        this.set('count.valid', valid);
        this.set('count.invalid', invalid);
        return this.set_is_valid(invalid < 1);
      },
      set_source_status: function(source, source_status, group_guid) {
        var guid;
        if (group_guid == null) {
          group_guid = null;
        }
        guid = group_guid || this.get_source_guid(source);
        return this.get_status_map().set(guid, source_status);
      },
      set_status_values: function() {
        var status_instances;
        status_instances = [];
        this.get_status_map().forEach((function(_this) {
          return function(status) {
            return status_instances.push(status);
          };
        })(this));
        return this._super(status_instances);
      },
      get_source_guid: function(source) {
        return ember['default'].guidFor(source) || 'bad_status_guid';
      },
      new_source_status: function() {
        return this.get('source_status').create();
      },
      toString: function() {
        return 'TvoStatusCollection';
      },
      source_status: base['default'].extend({
        init: function() {
          this._super();
          this.init_status_values();
          this.set('by_validate', false);
          this.set('by_callback', false);
          this.set('callback_source', null);
          return this.set('callback_fn', null);
        },
        get_callback_source: function() {
          return this.get('callback_source');
        },
        get_callback_fn: function() {
          return this.get('callback_fn');
        },
        is_by_callback: function() {
          return this.get('by_callback') === true;
        },
        is_by_validate: function() {
          return this.get('by_validate') === true;
        },
        set_by_validate: function() {
          return this.set('by_validate', true);
        },
        set_callback: function(source, fn) {
          this.set('by_callback', true);
          this.set('callback_source', source);
          return this.set('callback_fn', fn);
        },
        set_status_values: function() {
          return new ember['default'].RSVP.Promise((function(_this) {
            return function(resolve, reject) {
              var callback_status, fn, source;
              if (_this.is_by_validate()) {
                return resolve();
              }
              source = _this.get_callback_source();
              fn = _this.get_callback_fn();
              if (!(source && fn)) {
                _this.set_is_valid(false);
                _this.set_status_messages('missing source or fn');
                return resolve();
              }
              if (typeof source[fn] !== 'function') {
                _this.set_is_valid(false);
                _this.set_status_messages("[" + fn + "] is not a function for [" + (source.toString()) + "]");
                return resolve();
              }
              callback_status = _this.get('callback_status').create();
              return source[fn](callback_status).then(function() {
                _this.set_callback_status_values(callback_status);
                return resolve();
              }, function() {
                _this.set_is_valid(false);
                _this.set_status_messages("[" + fn + "] function for [" + (source.toString()) + "] had an error.");
                return resolve();
              });
            };
          })(this));
        },
        set_callback_status_values: function(status) {
          this.set_is_valid(status.get_is_valid());
          this.set_valid_count(status.get_valid_count());
          this.set_invalid_count(status.get_invalid_count());
          return this.set_status_messages(status.get_status_messages());
        },
        callback_status: base['default'].extend({
          init: function() {
            this._super();
            return this.init_status_values();
          },
          increment_valid_count: function() {
            return this.incrementProperty('valid_count');
          },
          increment_invalid_count: function() {
            return this.incrementProperty('invalid_count');
          }
        }),
        toString: function() {
          return 'TvoSourceStatus';
        }
      })
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});