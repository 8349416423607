define('totem-template-manager/tvo/template', ['exports', 'ember', 'totem/config', 'totem/ns'], function (exports, ember, config, ns) {

  'use strict';

  var ___DefaultExportObject___,
    hasProp = {}.hasOwnProperty;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    parse: function(template) {
      return this._parse(template);
    },
    add_components: function(components) {
      return this._add_components(components);
    },
    get_template: function() {
      return this.get('$template');
    },
    to_html: function() {
      return this.get_template().html();
    },
    compile: function() {
      return ember['default'].Handlebars.compile(this.to_html());
    },
    toString: function() {
      return 'TvoTemplate';
    },
    _parse: function(template) {
      var $template;
      $template = $('<div/>').html(template);
      this._set_component_sections($template);
      this._replace_rows($template);
      this._replace_columns($template);
      this.set('$template', $template);
      return this.get_template();
    },
    _set_component_sections: function($template) {
      var $comp, $components, component, j, len, results;
      $components = $template.find('component');
      results = [];
      for (j = 0, len = $components.length; j < len; j++) {
        component = $components[j];
        $comp = $(component);
        if (!$comp.attr('section')) {
          results.push($comp.attr('section', this.tvo.tag_title($comp)));
        } else {
          results.push(void 0);
        }
      }
      return results;
    },
    _replace_rows: function($template) {
      var $children, $new_row, $row, $rows, j, len, results, row;
      $rows = $template.find('row');
      results = [];
      for (j = 0, len = $rows.length; j < len; j++) {
        row = $rows[j];
        $row = $(row);
        $children = $row.children();
        $new_row = $(this._row_html($row));
        $row.replaceWith($new_row);
        results.push($new_row.append($children));
      }
      return results;
    },
    _replace_columns: function($template) {
      var $children, $col, $cols, $new_col, col, j, len, results;
      $cols = $template.find('column');
      results = [];
      for (j = 0, len = $cols.length; j < len; j++) {
        col = $cols[j];
        $col = $(col);
        $children = $col.children();
        $new_col = $(this._col_html($col));
        $col.replaceWith($new_col);
        results.push($new_col.append($children));
      }
      return results;
    },
    _row_html: function($row) {
      var hash;
      hash = this.tvo.tag_attribute_hash($row);
      hash["class"] = this._get_tag_classes($row, 'row');
      return this._tag_with_attributes('div', hash);
    },
    _col_html: function($col) {
      var cols, columns_class, hash;
      hash = this.tvo.tag_attribute_hash($col);
      cols = hash.width || 12;
      delete hash.width;
      columns_class = config['default'].grid.classes.columns;
      hash["class"] = this._get_tag_classes($col, columns_class + " small-" + cols);
      return this._tag_with_attributes('div', hash);
    },
    _tag_with_attributes: function(tag, hash) {
      var attr_name, attr_value, new_tag;
      new_tag = "<" + tag;
      for (attr_name in hash) {
        if (!hasProp.call(hash, attr_name)) continue;
        attr_value = hash[attr_name];
        new_tag += " " + attr_name + "='" + attr_value + "'";
      }
      new_tag += '>';
      return new_tag;
    },
    _get_tag_classes: function($tag, classes) {
      if (classes == null) {
        classes = '';
      }
      return (this.tvo.tag_class($tag) + ' ' + classes).trim();
    },
    _add_components: function(components) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var common_component_promises, componentable_promises;
          common_component_promises = components.getEach(ns['default'].to_p('component'));
          componentable_promises = components.getEach('componentable');
          return ember['default'].RSVP.Promise.all(common_component_promises).then(function(common_components) {
            return ember['default'].RSVP.Promise.all(componentable_promises).then(function(componentables) {
              var component_promises;
              component_promises = [];
              components.forEach(function(component, index) {
                var common_component, componentable;
                common_component = common_components.objectAt(index);
                componentable = componentables.objectAt(index);
                return component_promises.push(_this._add_component(common_component, component, componentable));
              });
              return ember['default'].RSVP.Promise.all(component_promises).then(function() {
                return resolve();
              }, function(error) {
                return console.error(error);
              });
            });
          });
        };
      })(this));
    },
    _add_component: function(common_component, component, componentable) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var $comp, attribute, bind_attributes, hash, j, len, paths, preprocessor, preprocessor_promises, preprocessors, section, value;
          section = component.get('section');
          $comp = _this._get_component_section_tag(section);
          preprocessors = _this._get_section_preprocessors(common_component, $comp);
          if (ember['default'].isPresent(preprocessors)) {
            preprocessor_promises = [];
            bind_attributes = [];
            hash = {};
            for (j = 0, len = preprocessors.length; j < len; j++) {
              preprocessor = preprocessors[j];
              attribute = preprocessor.attribute;
              bind_attributes.push(preprocessor.bind || attribute);
              paths = preprocessor.paths;
              paths = paths.map(function(p) {
                return ns['default'].to_p(p);
              });
              value = componentable.get(attribute);
              preprocessor_promises.push(_this._preprocess(paths, componentable, value));
            }
            return ember['default'].RSVP.Promise.all(preprocessor_promises).then(function(values) {
              var attr, k, len1;
              for (k = 0, len1 = bind_attributes.length; k < len1; k++) {
                attr = bind_attributes[k];
                hash[attr] = values.shift();
              }
              _this._replace_template_component_html(common_component, component, componentable, section, hash);
              return resolve();
            }, function(error) {
              return reject(error);
            });
          } else {
            _this._replace_template_component_html(common_component, component, componentable, section, hash);
            return resolve();
          }
        };
      })(this));
    },
    _get_component_section_tag: function(section) {
      var $comp, length;
      $comp = this.get_template().find("component[section=" + section + "]");
      length = $comp.length;
      switch (false) {
        case !(length > 1):
          console.warn("Section [" + section + "] is duplicated " + length + " times.");
          return null;
        case !(length < 1):
          console.warn("Section [" + section + "] is not found.");
          return null;
        default:
          return $comp;
      }
    },
    _get_section_preprocessors: function(common_component, $comp) {
      return $comp.data('preprocessors') || common_component.get('preprocessors');
    },
    _get_component_path: function(common_component, $comp) {
      return $comp.data('path') || common_component.get('value.path');
    },
    _replace_template_component_html: function(common_component, component, componentable, section, hash) {
      var $comp, bind_actions, bind_properties, component_path, html, path;
      if (hash == null) {
        hash = {};
      }
      $comp = this._get_component_section_tag(section);
      component_path = ns['default'].to_p(this._get_component_path(common_component, $comp));
      hash.attributes = this.tvo.tag_attribute_hash($comp);
      hash.model = componentable;
      path = this.tvo.value.set_value_for(component, hash);
      bind_properties = this._get_bind_properties(path, hash);
      bind_actions = this._get_bind_actions($comp);
      this.tvo.set_path_value(path + ".component_path", component_path);
      html = '{{ component';
      html += " tvo." + path + ".component_path";
      html += bind_properties;
      html += bind_actions;
      html += ' }}';
      return $comp.replaceWith(html);
    },
    _get_bind_properties: function(path, hash) {
      var bind, j, key, keys, len;
      keys = [];
      for (key in hash) {
        if (!hasProp.call(hash, key)) continue;
        keys.push(key);
      }
      bind = '';
      if (ember['default'].isBlank(keys)) {
        return bind;
      }
      for (j = 0, len = keys.length; j < len; j++) {
        key = keys[j];
        bind += " " + key + "=tvo." + path + "." + key;
      }
      return bind;
    },
    _get_bind_actions: function($comp) {
      var actions, bind, key, value;
      actions = $comp.data('actions');
      bind = '';
      if (!actions) {
        return bind;
      }
      for (key in actions) {
        if (!hasProp.call(actions, key)) continue;
        value = actions[key];
        bind += " " + key + "='" + value + "'";
      }
      return bind;
    },
    _preprocess: function(preprocessors, componentable, value) {
      if (value == null) {
        value = null;
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var promise, tasks;
          tasks = preprocessors.map(function() {
            return _this._call_preprocessor;
          });
          promise = tasks.reduce(function(cur, next, i) {
            return cur.then(function(value) {
              var preprocessor;
              preprocessor = preprocessors[i];
              return next.call(_this, preprocessor, componentable, value);
            });
          }, ember['default'].RSVP.resolve(value));
          return promise.then(function(value) {
            return resolve(value);
          }, function(error) {
            return reject(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return reject(error);
        };
      })(this));
    },
    _call_preprocessor: function(preprocessor, componentable, value) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var comp_mod, inst, mod, req_mod;
          mod = "" + config.mp + preprocessor;
          comp_mod = config.mp + "components/" + preprocessor;
          req_mod = null;
          ember['default'].tryCatchFinally((function() {
            return req_mod = require(comp_mod);
          }), (function() {
            return req_mod = require(mod);
          }), (function() {
            return req_mod;
          }));
          if (!req_mod) {
            return reject("Preprocessor [" + preprocessor + "] could not be found by a require of path [" + comp_mod + "] -or- [" + mod + "].");
          }
          inst = req_mod["default"].create({
            tvo: _this.tvo
          });
          return inst.process(componentable, value).then(function(value) {
            return resolve(value);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});