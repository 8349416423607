define('totem-messages/mixins/services/messages/add', ['exports', 'ember', 'totem/scope'], function (exports, ember, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    add: function(data, type) {
      var message, msg;
      if (data == null) {
        data = {};
      }
      if (type == null) {
        type = null;
      }
      if (type == null) {
        type = this.message_model_type;
      }
      message = this.get_message_properties(data);
      if (ember['default'].isPresent(type)) {
        msg = totem_scope['default'].get_store().createRecord(type, message);
        if (msg.save_message) {
          this.save_message(msg);
        }
      } else {
        message.tms = this;
        msg = this.message_item.create(message);
        this.message_queue.unshiftObject(msg);
      }
      return msg;
    },
    save_message: function(msg) {
      if (msg.save_message === true) {
        return msg.save();
      } else {
        if (this.is_function(msg.save_message)) {
          return msg.save_message();
        }
      }
    },
    get_message_properties: function(data) {
      var msg;
      if (ember['default'].isBlank(data)) {
        return {};
      }
      msg = {};
      this.add_message_state(data.state, msg);
      this.add_message_time(data.time, msg);
      this.add_message_to(data.to, msg);
      this.add_message_from(data.from, msg);
      this.add_message_body(data.message, msg);
      this.add_message_source(data.source, msg);
      this.add_rooms(data.room || data.rooms, msg);
      return msg;
    },
    add_message_state: function(state, msg) {
      return msg.state = ember['default'].isBlank(state) ? 'new' : state;
    },
    add_message_body: function(body, msg) {
      return msg.body = body;
    },
    add_message_source: function(source, msg) {
      return msg.source = ember['default'].isBlank(source) ? null : source.toString && source.toString();
    },
    add_message_time: function(time, msg) {
      msg.date = time || new Date();
      return msg.time = this.ttz.format(msg.date, {
        format: 'MMM Do, h:mm a'
      });
    },
    add_rooms: function(room, msg) {
      var rooms;
      if (!(this.is_string(room) || ember['default'].isArray(room))) {
        msg.rooms = null;
        return;
      }
      rooms = ember['default'].makeArray(room);
      return msg.rooms = rooms.map(function(r) {
        if (r.match(/^server:/)) {
          return r.replace(/^server:/, '');
        } else {
          return r;
        }
      });
    },
    add_message_from: function(from, msg) {
      var teams, users, values;
      if (this.is_string(from)) {
        msg.from = from;
        return;
      }
      values = ember['default'].makeArray(from);
      users = msg.from_users = this.extract_message_type(values, 'user');
      teams = msg.from_teams = this.extract_message_type(values, 'team');
      return msg.from = this.format_users_and_teams(users, teams);
    },
    add_message_to: function(to, msg) {
      var teams, users, values;
      if (this.is_string(to)) {
        msg.to = to;
        return;
      }
      values = ember['default'].makeArray(to);
      users = msg.to_users = this.extract_message_type(values, 'user');
      teams = msg.to_teams = this.extract_message_type(values, 'team');
      return msg.to = this.format_users_and_teams(users, teams);
    },
    extract_message_type: function(array, type) {
      if (ember['default'].isBlank(array) || !ember['default'].isArray(array)) {
        return [];
      }
      return array.filterBy('type', type);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});