define('thinkspace-common/mixins/scrollable', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    tse_threshold_height: 500,
    tse_scrollable_class: 'tse-scrollable',
    tse_scrollable_selector: ember['default'].computed('tse_scrollable_class', function() {
      return "." + (this.get('tse_scrollable_class'));
    }),
    didInsertElement: function() {},
    set_scrollable_height: function(element, threshold) {
      if (threshold == null) {
        threshold = 500;
      }
    },
    get_tse_scrollables: function() {},
    tse_recalculate: function() {},
    tse_run_next_recalculate: function() {},
    tse_scroll_to_top: function() {},
    tse_resize: function() {}
  });

  exports['default'] = ___DefaultExportObject___;;

});