define('ember-validations/index', ['exports', 'ember-validations/mixin'], function (exports, Mixin) {

  'use strict';

  exports['default'] = {
    Mixin: Mixin['default'],
    validator: function(callback) {
      return { callback: callback };
    }
  };

});