define('thinkspace-builder/steps/details', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-builder/step'], function (exports, ember, ns, ta, step) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = step['default'].extend({
    title: 'Details',
    id: 'details',
    component_path: ns['default'].to_p('builder', 'steps', 'details'),
    route_path: ns['default'].to_r('builder', 'cases', 'details'),
    is_completed: ember['default'].computed('builder.model', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var builder, model;
          builder = _this.get('builder');
          model = builder.get('model');
          if (!ember['default'].isPresent(model)) {
            return false;
          }
          return builder.get_assignment().then(function(assignment) {
            return resolve(ember['default'].isPresent(assignment.get('title')));
          });
        };
      })(this));
      return ta['default'].PromiseObject.create({
        promise: promise
      });
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});