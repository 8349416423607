define('totem/config/routes', ['exports', 'ember', 'totem/util', 'totem/config/require_modules'], function (exports, ember, util, reqm) {

  'use strict';

  var TotemRoutes, ___DefaultExportObject___,
    hasProp = {}.hasOwnProperty;

  TotemRoutes = (function() {
    function TotemRoutes() {
      this.root_routes = {};
      this.nested_routes = {};
      this.path_to_map = {};
      this.ember_routes = [];
      this.map_options = {};
      this.populate_routes();
    }

    TotemRoutes.prototype.populate_routes = function() {
      var hash, i, len, mod, mods, name, options, regex, results;
      regex = reqm['default'].config_regex('routes');
      mods = reqm['default'].filter_by(regex);
      if (ember['default'].isBlank(mods)) {
        return;
      }
      results = [];
      for (i = 0, len = mods.length; i < len; i++) {
        mod = mods[i];
        hash = reqm['default'].require_module(mod);
        if (!reqm['default'].is_hash(hash)) {
          this.error("Module '" + mod + "' is not a hash.");
        }
        results.push((function() {
          var results1;
          results1 = [];
          for (name in hash) {
            options = hash[name];
            if (this.is_root_route(name)) {
              results1.push(this.root_route(mod, name, options));
            } else {
              results1.push(this.nested_route(mod, name, options));
            }
          }
          return results1;
        }).call(this));
      }
      return results;
    };

    TotemRoutes.prototype.is_root_route = function(name) {
      return !this.is_nested_route(name);
    };

    TotemRoutes.prototype.is_nested_route = function(name) {
      return util['default'].starts_with(name, '/');
    };

    TotemRoutes.prototype.root_route = function(mod, name, options) {
      var path;
      if (options == null) {
        options = {};
      }
      this.standardize_route_options(mod, name, options);
      path = options.path;
      this.validate_route_path(this.root_routes, path, options);
      this.validate_route_name(this.root_routes, name, options);
      return this.root_routes[path] = options;
    };

    TotemRoutes.prototype.nested_route = function(mod, parent, options) {
      var base, name, opts, parent_routes, path, results;
      if (options == null) {
        options = {};
      }
      results = [];
      for (name in options) {
        if (!hasProp.call(options, name)) continue;
        opts = options[name];
        if (opts == null) {
          opts = {};
        }
        this.standardize_route_options(mod, name, opts, parent);
        path = opts.path;
        parent_routes = (base = this.nested_routes)[parent] != null ? base[parent] : base[parent] = {};
        this.validate_route_name(parent_routes, name, opts);
        this.validate_route_path(parent_routes, path, opts);
        results.push(parent_routes[path] = opts);
      }
      return results;
    };

    TotemRoutes.prototype.standardize_route_options = function(mod, name, options, parent) {
      if (parent == null) {
        parent = null;
      }
      if (!reqm['default'].is_hash(options)) {
        this.error("Route options for '" + name + "' is not a hash.");
      }
      if (options.path == null) {
        options.path = '/' + name;
      }
      options.name = name;
      options.mod = mod;
      return options.parent = parent;
    };

    TotemRoutes.prototype.get_routes_path_module_name = function(routes, path) {
      return (routes[path] || {}).mod || 'unknown';
    };

    TotemRoutes.prototype.validate_route_path = function(routes, path, options) {
      var dup_mod, dup_name, dup_opts, message, mod, name, type;
      if (routes[path]) {
        type = this.get_route_name_type(options);
        mod = options.mod;
        name = options.name;
        dup_opts = routes[path];
        dup_mod = dup_opts.mod;
        dup_name = dup_opts.name;
        message = "Duplicate " + type + " route PATH '" + path + "'.\nModule: " + mod + "\n  name: " + name + "\nModule: " + dup_mod + "\n  name: " + dup_name;
        return this.error(message);
      }
    };

    TotemRoutes.prototype.validate_route_name = function(routes, name, options) {
      var dup_mod, dup_opts, dup_path, message, mod, path, results, type;
      results = [];
      for (dup_path in routes) {
        dup_opts = routes[dup_path];
        if (name === dup_opts.name) {
          type = this.get_route_name_type(options);
          mod = options.mod;
          path = this.get_options_path(options);
          dup_mod = dup_opts.mod;
          dup_path = this.get_options_path(dup_opts);
          message = "Duplicate " + type + " route NAME '" + name + "'.\nModule: " + mod + "\n  path: '" + path + "'\nModule: " + dup_mod + "\n  path: '" + dup_path + "'";
          results.push(this.error(message));
        } else {
          results.push(void 0);
        }
      }
      return results;
    };

    TotemRoutes.prototype.get_options_path = function(options) {
      var parent, path;
      path = options.path;
      parent = options.parent;
      return (parent && (parent + path)) || path;
    };

    TotemRoutes.prototype.get_route_name_type = function(options) {
      return (options && options.parent && 'nested') || 'root';
    };

    TotemRoutes.prototype.map = function(rmap) {
      this.map_roots(rmap);
      this.map_nests();
      if (this.map_options.print_routes) {
        this.print_routes();
      }
      return this.reset_temporary_map_values();
    };

    TotemRoutes.prototype.reset_temporary_map_values = function() {
      this.path_to_map = {};
      return this.ember_routes = [];
    };

    TotemRoutes.prototype.map_roots = function(rmap) {
      var options, path, ref, results;
      ref = this.root_routes;
      results = [];
      for (path in ref) {
        options = ref[path];
        results.push(this.map_root(rmap, path, options));
      }
      return results;
    };

    TotemRoutes.prototype.map_root = function(rmap, path, options) {
      var _this, key, name;
      key = path;
      name = options.name;
      this.remove_non_ember_options(options);
      _this = this;
      return rmap.resource(name, options, function() {
        _this.path_to_map[key] = this;
        if (_this.map_options.print_routes) {
          return _this.ember_routes.push(this.matches);
        }
      });
    };

    TotemRoutes.prototype.map_nests = function() {
      var parent, paths, ref, results;
      ref = this.nested_routes;
      results = [];
      for (parent in ref) {
        paths = ref[parent];
        results.push(this.map_nest(parent, paths));
      }
      return results;
    };

    TotemRoutes.prototype.map_nest = function(parent, paths) {
      var _this, fn, name, options, path, results, rmap, routes;
      rmap = this.get_nested_path_map(parent);
      routes = this.ember_routes;
      _this = this;
      results = [];
      for (path in paths) {
        if (!hasProp.call(paths, path)) continue;
        options = paths[path];
        name = options.name;
        fn = (options.resource && 'resource') || 'route';
        this.remove_non_ember_options(options);
        results.push(rmap[fn](name, options, function() {
          var key;
          key = parent + path;
          _this.path_to_map[key] = this;
          if (_this.map_options.print_routes) {
            return _this.ember_routes.push(this.matches);
          }
        }));
      }
      return results;
    };

    TotemRoutes.prototype.get_nested_path_map = function(parent) {
      var message, options, path, ref, rmap, similar;
      rmap = this.path_to_map[parent];
      if (ember['default'].isPresent(rmap)) {
        return rmap;
      }
      similar = [];
      ref = this.path_to_map;
      for (path in ref) {
        if (!hasProp.call(ref, path)) continue;
        options = ref[path];
        if (path.match(parent)) {
          similar.push(path);
        }
        if (parent.match(path)) {
          similar.push(path);
        }
      }
      similar = similar.sort();
      this.print();
      message = '';
      if (ember['default'].isPresent(similar)) {
        message = "  Did you mean one of these?\n[" + (similar.join('], [')) + "]";
      }
      return this.error(("No parent-path route map matches [" + parent + "].") + message);
    };

    TotemRoutes.prototype.remove_non_ember_options = function(options) {
      delete options.mod;
      delete options.name;
      delete options.parent;
      return delete options.resource;
    };

    TotemRoutes.prototype.print_routes_on = function() {
      return this.map_options.print_routes = true;
    };

    TotemRoutes.prototype.print_routes_off = function() {
      return this.map_options.print_routes = false;
    };

    TotemRoutes.prototype.print_routes = function() {
      var c, count, i, len, output, ref, route, routes;
      output = [];
      routes = util['default'].flatten_array(this.ember_routes).compact().filter(function(r) {
        return typeof r === 'string';
      });
      routes = routes.uniq().filter(function(r) {
        return !(util['default'].starts_with(r, '/_unused_dummy'));
      });
      routes = routes.filter(function(r) {
        return !(util['default'].starts_with(r, '/') && r !== '/');
      });
      count = 0;
      ref = routes.sort();
      for (i = 0, len = ref.length; i < len; i++) {
        route = ref[i];
        count += 1;
        c = util['default'].rjust(count, 4);
        output.push(c + ". " + route);
      }
      return console.info(output.join("\n"));
    };

    TotemRoutes.prototype.print = function() {
      console.warn('root_routes:', this.root_routes);
      return console.warn('nested_routes:', this.nested_routes);
    };

    TotemRoutes.prototype.stringify = function(obj) {
      return JSON.stringify(obj);
    };

    TotemRoutes.prototype.error = function(message) {
      if (message == null) {
        message = '';
      }
      return reqm['default'].error(this, message);
    };

    TotemRoutes.prototype.toString = function() {
      return 'TotemRoutes';
    };

    return TotemRoutes;

  })();

  ___DefaultExportObject___ = new TotemRoutes;

  exports['default'] = ___DefaultExportObject___;;

});