define('thinkspace-casespace/mixins/services/server_events/initialize', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    casespace: ember['default'].inject.service(),
    phase_manager: ember['default'].inject.service(),
    messages: ember['default'].inject.service(ns['default'].to_p('casespace', 'messages')),
    init: function() {
      this._super();
      this.is_active = ember['default'].isPresent(window.io);
      if (this.is_active) {
        this.store = this.get_store();
        this.casespace = this.get('casespace');
        this.pm = this.get('phase_manager');
        this.pubsub = null;
        return this.messages = this.get('messages');
      }
    },
    reset_all: function() {
      return this.leave_all();
    },
    toString: function() {
      return 'CasespaceServerEvents';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});