define('totem-application/mixins/validated_field', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    show_errors: false,
    errors: null,
    errors_partial: null,
    has_errors: ember['default'].computed.gt('errors.length', 0),
    input_invalid: ember['default'].computed('has_errors', 'has_focused', function() {
      return this.get('has_errors') && this.get('has_focused');
    }),
    display_errors: ember['default'].computed('has_errors', 'input_invalid', 'show_errors', function() {
      return this.get('input_invalid') || (this.get('show_errors') && this.get('has_errors'));
    }),
    focusOut: function() {
      return this.set('has_focused', true);
    },
    scroll_to_error: ember['default'].observer('show_errors', function() {
      var errors, show_errors, top;
      show_errors = this.get('show_errors');
      if (ember['default'].isPresent(show_errors)) {
        errors = $('.totem-errors_error');
        if (ember['default'].isPresent(errors)) {
          top = errors.position().top;
          return window.scrollTo(0, top - 100);
        }
      }
    }),
    first_error: ember['default'].computed('errors.length', function() {
      var errors;
      errors = this.get('errors');
      if (!ember['default'].isEmpty(errors)) {
        return errors.get('firstObject');
      }
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});