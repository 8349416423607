define('ember-validations/validators/local/acceptance', ['exports', 'ember', 'ember-validations/validators/base', 'ember-validations/messages'], function (exports, Ember, Base, Messages) {

  'use strict';

  var get = Ember['default'].get;
  var set = Ember['default'].set;

  exports['default'] = Base['default'].extend({
    init: function() {
      this._super();
      /*jshint expr:true*/
      if (this.options === true) {
        set(this, 'options', {});
      }

      if (this.options.message === undefined) {
        set(this, 'options.message', Messages['default'].render('accepted', this.options));
      }
    },
    call: function() {
      if (this.options.accept) {
        if (get(this.model, this.property) !== this.options.accept) {
          this.errors.pushObject(this.options.message);
        }
      } else if (get(this.model, this.property) !== '1' && get(this.model, this.property) !== 1 && get(this.model, this.property) !== true) {
        this.errors.pushObject(this.options.message);
      }
    }
  });

});