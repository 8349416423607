define('thinkspace-peer-assessment/managers/evaluation/reviews', ['exports', 'ember', 'totem/scope', 'totem/cache', 'totem/ds/associations', 'totem-messages/messages'], function (exports, ember, totem_scope, tc, ta, tm) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    thinkspace: ember['default'].inject.service(),
    set_reviewable: function(reviewable) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.save_review();
          _this.set('reviewable', reviewable);
          return _this.set_review().then(function() {
            _this.get('thinkspace').scroll_to_top();
            return resolve();
          });
        };
      })(this));
    },
    set_reviewable_from_offset: function(offset) {
      var index, new_index, new_reviewable, reviewable, reviewables;
      reviewables = this.get('reviewables');
      reviewable = this.get('reviewable');
      if (!(ember['default'].isPresent(reviewables) && ember['default'].isPresent(reviewable))) {
        return;
      }
      index = reviewables.indexOf(reviewable);
      if (index === -1) {
        return;
      }
      new_index = index + offset;
      new_reviewable = reviewables.objectAt(new_index);
      if (ember['default'].isPresent(new_reviewable)) {
        this.set_reviewable(new_reviewable);
      }
      if (!ember['default'].isPresent(new_reviewable) && offset === 1) {
        return this.set_confirmation();
      }
    },
    set_confirmation: function() {
      return this.set_reviewable('confirmation');
    },
    set_quantitative_value: function(id, value) {
      this.get('review').set_quantitative_value(id, value);
      return this.points_expended_changed();
    },
    set_quantitative_comment: function(id, value) {
      this.get('review').set_quantitative_comment(id, value);
      return this.save_review();
    },
    set_qualitative_value: function(id, type, value) {
      return this.get('review').set_qualitative_value(id, type, value);
    },
    get_review_for_reviewable: function(reviewable) {
      var review, reviewable_class, reviewable_id, reviews;
      reviews = this.get('reviews');
      reviewable_id = parseInt(reviewable.get('id'));
      reviewable_class = this.totem_scope.standard_record_path(reviewable);
      return review = reviews.find((function(_this) {
        return function(item) {
          var item_class, item_id;
          item_class = _this.totem_scope.standard_record_path(item.get('reviewable_type'));
          item_id = parseInt(item.get('reviewable_id'));
          return (reviewable_class === item_class) && (reviewable_id === item_id);
        };
      })(this));
    },
    points_expended_changed: function() {
      return this.propertyDidChange('points_expended');
    },
    save_review: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          if (_this.get('is_read_only') || _this.get('is_confirmation') || _this.get('is_review_read_only')) {
            return resolve();
          }
          model = _this.get('review');
          return model.save().then(function() {
            _this.totem_messages.api_success({
              source: _this,
              model: model,
              action: 'update',
              i18n_path: ta['default'].to_o('peer_assessment', 'review', 'save')
            });
            return resolve();
          }, function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: model,
              action: 'update'
            });
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});