define('thinkspace-diagnostic-path/path_manager', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/error', 'totem/scope', 'totem-messages/messages', 'thinkspace-diagnostic-path/path_manager/jquery_sortable/delta'], function (exports, ember, ns, ajax, totem_error, totem_scope, totem_messages, js_delta) {

  'use strict';

  var ___DefaultExportObject___,
    hasProp = {}.hasOwnProperty;

  ___DefaultExportObject___ = ember['default'].Object.create({
    dragend_new_diagnostic_path_item: function(path, event, itemable_type, itemable_id) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var delta, item;
          item = _this.get_new_path_item(path, {
            path_itemable_type: itemable_type,
            path_itemable_id: itemable_id
          });
          delta = new js_delta['default'](path, event, item);
          return delta.process().then(function() {
            return _this.update_path(delta).then(function() {
              return resolve();
            });
          });
        };
      })(this));
    },
    dragend_move_diagnostic_path_items: function(path, event) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var delta;
          delta = new js_delta['default'](path, event);
          return delta.process().then(function() {
            return _this.update_path(delta).then(function() {
              return resolve();
            });
          });
        };
      })(this));
    },
    dragend_new_mechanism_path_item: function(path, event, description) {
      if (description == null) {
        description = '';
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var delta, item;
          item = _this.get_new_mechanism_path_item(path, description);
          delta = new js_delta['default'](path, event, item);
          return delta.process().then(function() {
            return _this.update_path(delta).then(function() {
              return resolve();
            });
          });
        };
      })(this));
    },
    update_path: function(delta) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var status;
          console.info(" [Start] Processing with delta: ", delta);
          if (_this.is_invalid_change(delta)) {
            return resolve();
          }
          status = delta.get('status');
          switch (status) {
            case 'add_new_item':
              _this.process_increments_for_move('future', delta);
              break;
            case 'reorder_item_down':
              _this.process_decrements_for_reorder('future', delta);
              delta.set('future.position', parseInt(delta.get('future.position')) - 1);
              break;
            case 'reorder_item_up':
              _this.process_increments_for_reorder('future', delta);
              break;
            case 'move_item':
              if (ember['default'].isPresent(delta.get('original.parent.children')) && !delta.get('is_new')) {
                _this.process_decrements_for_move('original', delta);
              }
              if (ember['default'].isPresent(delta.get('future.parent.children'))) {
                _this.process_increments_for_move('future', delta);
              }
          }
          console.info("[End] Finished processing, resulting delta: ", delta);
          return _this.process_handlers(delta).then(function() {
            _this.set_original_item_parent_and_position(delta);
            return _this.save_delta(delta).then(function() {
              return resolve();
            });
          });
        };
      })(this));
    },
    set_original_item_parent_and_position: function(delta) {
      var item, parent, parent_id, path, position;
      path = delta.get('path');
      item = delta.get('original.item.record');
      parent = delta.get('future.parent.record');
      position = delta.get('future.position');
      if (ember['default'].isPresent(parent)) {
        parent_id = parent.get('id');
      } else {
        parent_id = null;
      }
      item.set('parent_id', parent_id);
      item.set('position', position);
      return item.set('path_id', path.get('id'));
    },
    process_decrements_for_reorder: function(type, delta) {
      var children, decrements;
      children = delta.get(type + ".parent.children");
      if (!ember['default'].isPresent(children)) {
        return;
      }
      decrements = children.slice(delta.get('original.position') + 1, delta.get('future.position'));
      return this.add_items_to_changes(decrements, -1, delta);
    },
    process_increments_for_reorder: function(type, delta) {
      var children, increments;
      children = delta.get(type + ".parent.children");
      if (!ember['default'].isPresent(children)) {
        return;
      }
      increments = children.slice(delta.get('future.position'), delta.get('original.position'));
      increments.removeObject(delta.get('original.item.record'));
      return this.add_items_to_changes(increments, 1, delta);
    },
    process_decrements_for_move: function(type, delta) {
      var children, decrements, index;
      children = delta.get(type + ".parent.children");
      if (!ember['default'].isPresent(children)) {
        return;
      }
      index = children.indexOf(delta.get('original.item.record'));
      decrements = children.slice(index + 1, children.get('length'));
      return this.add_items_to_changes(decrements, -1, delta);
    },
    process_increments_for_move: function(type, delta) {
      var children, increments, position;
      children = delta.get(type + ".parent.children");
      position = delta.get(type + ".position");
      if (!ember['default'].isPresent(children)) {
        return;
      }
      increments = children.slice(position, children.get('length'));
      return this.add_items_to_changes(increments, 1, delta);
    },
    process_handlers: function(delta) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var handlers;
          handlers = [];
          return ember['default'].RSVP.all(handlers).then(function() {
            return resolve();
          });
        };
      })(this));
    },
    add_items_to_changes: function(items, position_offset, delta) {
      var changes;
      changes = delta.get('changes');
      items.forEach((function(_this) {
        return function(item) {
          return changes[item.get('id')] = {
            position: item.get('position') + position_offset,
            parent_id: item.get('parent_id')
          };
        };
      })(this));
      return delta.set('changes', changes);
    },
    save_delta: function(delta) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var changes, path, path_item;
          path = delta.get('path');
          path_item = delta.get('original.item.record');
          changes = delta.get('changes');
          return path_item.save().then(function(path_item) {
            var query;
            if (ember['default'].isPresent(ember['default'].keys(changes))) {
              query = {
                verb: 'put',
                action: 'bulk',
                model: path,
                id: path.get('id'),
                ownerable: totem_scope['default'].get_ownerable_record(),
                data: {
                  path_items: changes
                }
              };
              return ajax['default'].object(query).then(function(payload) {
                totem_messages['default'].api_success({
                  source: _this,
                  model: path_item,
                  i18n_path: ns['default'].to_o('path_item', 'save')
                });
                path_item.store.pushPayload(payload);
                return resolve();
              }, function(error) {
                return totem_messages['default'].api_failure(error, {
                  source: _this,
                  model: path_item
                });
              });
            } else {
              totem_messages['default'].api_success({
                source: _this,
                model: path_item,
                i18n_path: ns['default'].to_o('path_item', 'save')
              });
              return resolve();
            }
          });
        };
      })(this));
    },
    get_path_item_from_id: function(path, id) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (!ember['default'].isPresent(id)) {
            return resolve(null);
          }
          return path.store.find(ns['default'].to_p('path_item'), id).then(function(path_item) {
            return resolve(path_item);
          });
        };
      })(this));
    },
    is_invalid_change: function(delta) {
      var $path_item, invalid, item, parent, parents_query, path, position;
      path = delta.get('path');
      item = delta.get('original.item.record');
      parent = delta.get('future.parent.record');
      position = delta.get('future.position');
      invalid = false;
      if (ember['default'].isEqual(parent, item)) {
        invalid = true;
      }
      if (ember['default'].isPresent(parent)) {
        $path_item = $(".diag-path_list-item [model_id='" + (parent.get('id')) + "']");
        parents_query = $path_item.parents("[model_id='" + (item.get('id')) + "']");
        if (parents_query.length !== 0) {
          invalid = true;
        }
      }
      return invalid;
    },
    assign_children_to_grandparent: function(delta) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var item, parent, parent_id, path;
          path = delta.get('path');
          if (delta.get('event.is_collapsed')) {
            return resolve();
          }
          item = delta.get('original.item.record');
          if (!ember['default'].isPresent(item)) {
            return resolve();
          }
          parent = delta.get('original.parent.record');
          if (ember['default'].isPresent(parent)) {
            parent_id = parent.get('id');
          } else {
            parent_id = null;
          }
          switch (false) {
            case !ember['default'].isPresent(parent_id):
              return item.store.find(ns['default'].to_p('path_item'), parent_id).then(function(grandparent) {
                return grandparent.get(ns['default'].to_p('path_items')).then(function(siblings) {
                  return item.get(ns['default'].to_p('path_items')).then(function(children) {
                    var grandparent_id, position;
                    children = children.sortBy('position');
                    grandparent_id = grandparent.get('id');
                    position = siblings.get('length');
                    if (!ember['default'].isEqual(parent, delta.get('future.parent.record'))) {
                      position = position - 1;
                    }
                    children.forEach(function(child) {
                      var changes;
                      child.set('parent_id', grandparent_id);
                      child.set('position', position);
                      changes = delta.get('changes');
                      changes[child.get('id')] = {
                        position: position,
                        parent_id: grandparent_id
                      };
                      delta.set('changes', changes);
                      return position++;
                    });
                    return resolve();
                  });
                });
              });
            default:
              return item.get(ns['default'].to_p('path_items')).then(function(children) {
                var position;
                children = children.sortBy('position');
                position = path.get('children.length');
                if (ember['default'].isPresent(delta.get('future.parent.record'))) {
                  position = position - 1;
                }
                children.forEach(function(child) {
                  var changes;
                  child.set('parent_id', null);
                  child.set('position', position);
                  changes = delta.get('changes');
                  changes[child.get('id')] = {
                    position: position,
                    parent_id: null
                  };
                  delta.set('changes', changes);
                  return position++;
                });
                return resolve();
              });
          }
        };
      })(this));
    },
    sync_ember_path_item_parent: function(path, path_item, changes) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve();
        };
      })(this));
    },
    all_path_item_children: function(path_item, children, depth, max) {
      var items;
      if (children == null) {
        children = [];
      }
      if (depth == null) {
        depth = 0;
      }
      if (max == null) {
        max = 20;
      }
      depth += 1;
      if (depth > max) {
        totem_error['default']["throw"](this, "Maximum depth exceeded (" + max + ") getting all path item children .");
      }
      items = path_item.get('path_items');
      items.forEach((function(_this) {
        return function(item) {
          children.push(item);
          return _this.all_path_item_children(item, children, depth);
        };
      })(this));
      return children;
    },
    destroy_path_item: function(path_item) {
      return path_item.get(ns['default'].to_p('path')).then((function(_this) {
        return function(path) {
          var items;
          items = _this.all_path_item_children(path_item);
          items.push(path_item);
          return _this.destroy_path_items_and_unload(path, items);
        };
      })(this));
    },
    destroy_path_items_and_unload: function(path, path_items) {
      var query;
      if (ember['default'].isPresent(path_items)) {
        query = {
          verb: 'delete',
          action: 'bulk_destroy',
          model: path,
          id: path.get('id'),
          data: {
            path_items: path_items.mapBy('id')
          }
        };
        return ajax['default'].object(query).then((function(_this) {
          return function() {
            path_items.forEach(function(path_item) {
              return path_item.deleteRecord();
            });
            totem_messages['default'].api_success({
              source: _this,
              model: path_items,
              action: 'bulk_destroy',
              i18n_path: ns['default'].to_o('path', 'save'),
              i18n: []
            });
            return _this.unload_path_items(path, path_items);
          };
        })(this), (function(_this) {
          return function(error) {
            return totem_messages['default'].api_failure(error, {
              source: _this,
              model: path_items,
              action: 'bulk_destroy'
            });
          };
        })(this));
      }
    },
    unload_path_items: function(path, path_items) {
      return this.set_path_items_itemables_is_used(path_items, false).then((function(_this) {
        return function() {
          path_items.forEach(function(item) {
            return item.unloadRecord();
          });
          return _this.set_path_path_item_itemables_is_used(path, true);
        };
      })(this));
    },
    get_new_path_item: function(path, new_options) {
      var attr, new_item;
      new_item = path.store.createRecord(ns['default'].to_p('path_item'));
      totem_scope['default'].set_record_ownerable_attributes(new_item);
      for (attr in new_options) {
        if (!hasProp.call(new_options, attr)) continue;
        new_item.set(attr, new_options[attr]);
      }
      return new_item;
    },
    get_new_mechanism_path_item: function(path, label) {
      var item;
      item = path.store.createRecord(ns['default'].to_p('path_item'), {
        description: label
      });
      totem_scope['default'].set_record_ownerable_attributes(item);
      return item;
    },
    is_same_level: function($item, $items) {
      return $.inArray($item, $items);
    },
    is_same_model: function($one, $two) {
      if (!ember['default'].isPresent($one)) {
        return false;
      }
      if (!ember['default'].isPresent($two)) {
        return false;
      }
      return $one.attr('model_id') === $two.attr('model_id');
    },
    debug_event_status: function(event) {
      var $items, $parent, $prev, container;
      container = event.dropped_container;
      $items = $(container.items);
      $parent = container.parentContainer && $(container.parentContainer.items);
      $prev = $(container.prevItem);
      console.info(event);
      console.info('item first:', $items.first().attr('model_id'));
      if ($parent) {
        console.info('parent first:', $parent.first().attr('model_id'));
      } else {
        console.info('no parent');
      }
      console.info('prev item: ', $prev.attr('model_id'));
      console.info('prev level = items level:', this.is_same_level($prev, $items));
      return console.info('dragged item: ', $(event.currentTarget).attr('model_id'));
    },
    set_path_path_item_itemables_is_used: function(path, value) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return path.get(ns['default'].to_p('path_items')).then(function(path_items) {
            return _this.set_path_items_itemables_is_used(path_items, value).then(function() {
              return resolve();
            });
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    set_path_items_itemables_is_used: function(path_items, value) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var itemable_promises;
          if (ember['default'].isBlank(path_items)) {
            return resolve();
          }
          itemable_promises = path_items.map(function(path_item) {
            return _this.set_path_itemable_is_used(path_item, value);
          });
          return ember['default'].RSVP.Promise.all(itemable_promises).then(function() {
            return resolve();
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    set_path_itemable_is_used: function(path_item, value) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return path_item.get('path_itemable').then(function(itemable) {
            if (!itemable) {
              return;
            }
            if (typeof itemable.set_is_used !== 'function') {
              return;
            }
            itemable.set_is_used(value);
            return resolve();
          }, function() {
            return reject();
          });
        };
      })(this));
    },
    toString: function() {
      return 'DiagnosticPath::PathManager';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});