define('totem-messages/mixins/services/messages/item', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    message_item: ember['default'].Object.extend({
      is_new: ember['default'].computed.equal('state', 'new'),
      pre_message: ember['default'].computed(function() {
        return this.tms.format_pre(this);
      }),
      set_new: function() {
        return this.set('state', 'new');
      },
      set_prevous: function() {
        return this.set('state', 'previous');
      },
      toString: function() {
        return 'TotemMessageQueueItem';
      }
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});