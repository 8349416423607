define('thinkspace-casespace-case-manager/controllers/assignment_base', ['exports', 'ember', 'totem/ns', 'thinkspace-casespace-case-manager/controllers/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    queryParams: ['bundle_type', 'step', 'space_id'],
    bundle_type: 'selector',
    step: null,
    space_id: null,
    c_assignment_wizard: ns['default'].to_p('case_manager', 'assignment', 'wizard'),
    c_wizard: ns['default'].to_p('case_manager', 'assignment', 'wizards', 'casespace'),
    reset_query_params: function() {
      return this.set('step', null);
    },
    actions: {
      set_bundle_type: function(bundle_type) {
        return this.set('bundle_type', bundle_type);
      },
      set_step: function(step) {
        return this.set('step', step);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});