define('thinkspace-indented-list/mixins/item/component', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    get_item_value: function(item) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var description;
          description = item.description;
          if (ember['default'].isPresent(description)) {
            return resolve(description);
          }
          return _this.get_item_itemable(item).then(function(itemable) {
            var value_path;
            value_path = item.itemable_value_path;
            if (ember['default'].isBlank(itemable) || ember['default'].isBlank(value_path)) {
              return resolve(item.description || _this.default_item_description());
            } else {
              return resolve(itemable.get(value_path));
            }
          });
        };
      })(this));
    },
    set_all_items_visibility: function(visible) {
      return this.set_children_items_visibility(this.value_items, visible);
    },
    set_children_items_visibility: function(items, visible) {
      var min_x;
      min_x = this.get_minimum_pos_x();
      items.forEach((function(_this) {
        return function(item) {
          var comp;
          comp = _this.get_item_component(item);
          if (item.pos_x !== min_x) {
            comp.set('item_visible', visible);
          }
          if (comp.get('has_children')) {
            return comp.set('children_visible', visible);
          }
        };
      })(this));
      return items.forEach((function(_this) {
        return function(item) {
          var comp;
          comp = _this.get_item_component(item);
          return comp.set_number_of_children();
        };
      })(this));
    },
    show_confirm_remove: function() {
      return this.confirm_remove;
    },
    calc_item_indent: function(item) {
      return (item.pos_x || 0) * this.indent_px;
    },
    add_element_item_classes: function($el, item) {
      var class_names;
      class_names = item.class_names;
      if (ember['default'].isBlank(class_names)) {
        return;
      }
      return $el.addClass(ember['default'].makeArray(class_names).join(' '));
    },
    add_draggable_class: function($el) {
      return $el.addClass(this.draggable_class);
    },
    remove_draggable_class: function($el) {
      return $el.removeClass(this.draggable_class);
    },
    clear_item_itemable: function(item) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var oitem;
          if (ember['default'].isBlank(item.itemable_id) && ember['default'].isBlank(item.itemable_type) && ember['default'].isBlank(item.itemable_value_path)) {
            return resolve();
          }
          oitem = ember['default'].merge({}, item);
          delete item.itemable_id;
          delete item.itemable_type;
          delete item.itemable_value_path;
          return _this.clear_itemable_is_used_unless_used_by_another_item(oitem).then(function() {
            return resolve();
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});