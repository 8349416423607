define('thinkspace-lab/vet_med_lab_observation', ['exports', 'ember', 'totem/scope'], function (exports, ember, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    init: function() {
      var value;
      this._super();
      this.set('totem_scope', totem_scope['default']);
      value = this.get_observation_value();
      this.set_value(value);
      this.define_observation_properties();
      this.get_lab().register_lab_observation(this);
      return this.get_lab_result().register_lab_observation(this);
    },
    define_observation_properties: function() {
      var detail_key;
      detail_key = "result.observation.detail." + (this.get_observation_key());
      ember['default'].defineProperty(this, 'is_correct', ember['default'].computed.or('component.is_correct', detail_key + ".correct", 'normal_is_correct', 'result.observation.all_correct'));
      return ember['default'].defineProperty(this, 'detail_key_is_correct', ember['default'].computed.or(detail_key + ".correct", 'result.observation.all_correct'));
    },
    register_component: function(component) {
      return this.set('component', component);
    },
    get_lab: function() {
      return this.get('lab');
    },
    get_lab_result: function() {
      return this.get('lab_result');
    },
    get_result: function() {
      return this.get('result');
    },
    get_component: function() {
      return this.get('component');
    },
    get_observation_key: function() {
      return this.get('observation_key');
    },
    get_value: function() {
      return this.get('observation_value');
    },
    set_value: function(value) {
      return this.set('observation_value', value);
    },
    get_observation_value: function() {
      return this.get("result.observation.value." + (this.get_observation_key()));
    },
    get_observation_detail_value: function(path) {
      return this.get("result.observation.detail." + (this.get_observation_key()) + "." + path);
    },
    get_observation_detail_attempts: function() {
      return this.get_observation_detail_value('attempts') || 0;
    },
    get_category_value: function(path) {
      return this.get_lab().get_value(this.get_observation_key(), path);
    },
    get_result_value: function(path) {
      return this.get("result.values.observations." + (this.get_observation_key()) + "." + path);
    },
    get_result_lab_observation_for_key: function(key) {
      return this.get_lab().get_result_lab_observation_for_key(this.get_result(), key);
    },
    get_correct_value: function() {
      return this.get_result_value('validate.correct');
    },
    error_message: null,
    get_error_message: function() {
      return this.get('error_message');
    },
    set_error_message: function(message) {
      return this.set('error_message', message);
    },
    clear_error_message: function() {
      return this.set('error_message', null);
    },
    is_view_only: ember['default'].computed.bool('totem_scope.is_view_only'),
    is_disabled: ember['default'].computed('is_correct', 'is_view_only', 'error_message', function() {
      if (this.get('is_view_only')) {
        return true;
      }
      if (this.get('normal_is_correct') && this.get('user_has_interacted')) {
        return true;
      }
      if (this.get('normal_is_correct')) {
        return false;
      }
      if (this.get('error_message')) {
        return false;
      }
      return this.get('is_correct');
    }),
    set_focus_on_selected_category: function() {
      return this.get_lab().set_focus_on_selected_category();
    },
    set_focus_on_next_result: function() {
      return this.get_lab().set_focus_on_next_result(this.get_result());
    },
    set_focused: function() {
      return this.get_lab_result().set_focused();
    },
    focus_self: function() {
      return ember['default'].run.schedule('afterRender', this, (function(_this) {
        return function() {
          var component;
          component = _this.get_component();
          if (component.focus_self != null) {
            return component.focus_self();
          }
        };
      })(this));
    },
    save_result: function() {
      return this.get_lab().save_result(this.get_result(), this);
    },
    get_is_correct: function() {
      return this.get('is_correct');
    },
    get_is_disabled: function() {
      return this.get('is_disabled');
    },
    after_save: function() {},
    get_selections: function() {
      return this.get_result_value('selections');
    },
    get_normal_value: function() {
      return this.get_result_value('normal');
    },
    normal_is_correct: ember['default'].computed('result', function() {
      var correct, normal, value;
      value = this.get_value();
      correct = this.get_correct_value();
      normal = this.get_normal_value();
      return ember['default'].isEqual(correct, normal) && ember['default'].isEqual(value, correct);
    }),
    user_has_interacted: ember['default'].computed('normal_is_correct', 'detail_key_is_correct', 'component.is_correct', function() {
      var normal_is_correct;
      normal_is_correct = this.get('normal_is_correct');
      if (!normal_is_correct) {
        return false;
      }
      return normal_is_correct && (this.get('detail_key_is_correct') || this.get('component.is_correct'));
    }),
    normal_is_correct_without_interaction: function() {
      var correct, normal;
      correct = this.get_correct_value();
      normal = this.get_normal_value();
      return ember['default'].isEqual(correct, normal);
    },
    get_user_inputs: function() {
      return this.get_lab().get_user_inputs(this.get_observation_key());
    },
    add_user_input: function(input) {
      return this.get_lab().add_user_input(this.get_observation_key(), input);
    },
    entered_user_input: function(input) {
      return this.get_lab().entered_user_input(this.get_observation_key(), input);
    },
    get_max_attempts: function() {
      return this.get_result_value('max_attempts');
    },
    show_correct_values_text: function() {
      return 'Click for correct values.';
    },
    show_incorrect_text: function() {
      return 'Incorrect, please try again.';
    },
    add_to_observation_list: function(input) {
      return this.get_lab().add_to_observation_list(input);
    },
    show_input: null,
    set_show_input: function(value) {
      return this.set('show_input', value);
    },
    get_correctable_prompt: function() {
      return this.get_category_value('correctable_prompt');
    },
    get_correctable_placeholder: function() {
      return this.get_category_value('correctable_placeholder');
    },
    toString: function() {
      return 'LabObservation';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});