define('thinkspace-casespace-gradebook/common_helpers', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/scope', 'totem-messages/messages'], function (exports, ember, ns, ajax, totem_scope, totem_messages) {

  'use strict';

  var ___DefaultExportObject___,
    hasProp = {}.hasOwnProperty;

  ___DefaultExportObject___ = ember['default'].Object.create({
    get_sort_links: function(sort_def, sort_order, link_for) {
      var display_text, hash, key, links, sort_by, text;
      links = [];
      for (key in sort_def) {
        if (!hasProp.call(sort_def, key)) continue;
        hash = sort_def[key];
        if (link_for === 'all' || hash["for"] === link_for || hash["for"] === 'all') {
          text = [hash.heading.column_1, hash.heading.column_2];
          sort_by = hash.heading_sort_by;
          if (sort_by) {
            text.unshift(sort_by);
          }
          display_text = hash.title || text.compact().join('->');
          links.push({
            text: display_text,
            key: key,
            active: key === sort_order
          });
        }
      }
      return links;
    },
    update_roster_score: function(scores, values, score) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var score_hashes, state_id;
          state_id = values.get('state_id');
          score_hashes = scores.filterBy('state_id', state_id);
          if (ember['default'].isBlank(score_hashes)) {
            return reject();
          }
          score = Number(score);
          score_hashes.forEach(function(hash) {
            return hash.set('score', score);
          });
          _this.update_phase_state_value(values, state_id, 'new_score', score);
          return resolve();
        };
      })(this));
    },
    update_roster_state: function(scores, values, state) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var score_hashes, state_id;
          state_id = values.get('state_id');
          score_hashes = scores.filterBy('state_id', state_id);
          if (ember['default'].isBlank(score_hashes)) {
            return reject();
          }
          score_hashes.forEach(function(hash) {
            return hash.set('state', state);
          });
          _this.update_phase_state_value(values, state_id, 'new_state', state);
          return resolve();
        };
      })(this));
    },
    update_phase_state_value: function(values, state_id, key, value) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var phase_id, store;
          store = totem_scope['default'].get_store();
          phase_id = values.get('phase_id');
          if (!phase_id) {
            return reject();
          }
          return store.find(ns['default'].to_p('phase'), phase_id).then(function(phase) {
            var obj, path, query, record;
            if (!phase) {
              return reject();
            }
            totem_scope['default'].authable(phase);
            path = ns['default'].to_p('phase_state');
            record = {};
            record[path] = (
              obj = {},
              obj["" + key] = value,
              obj
            );
            query = {
              action: 'roster_update',
              verb: 'put',
              model: ns['default'].to_p('phase_state'),
              id: state_id,
              data: record
            };
            return ajax['default'].object(query).then(function() {
              return resolve();
            }, function(error) {
              return reject(error);
            });
          });
        };
      })(this));
    },
    get_assignment_roster_from_server: function(assignment) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          if (!assignment) {
            return reject();
          }
          query = {
            verb: 'post',
            action: 'roster',
            model: assignment,
            id: assignment.get('id'),
            data: {
              auth: {
                sub_action: 'assignment_roster'
              }
            }
          };
          totem_messages['default'].show_loading_outlet();
          return ajax['default'].object(query).then(function(roster) {
            return resolve(roster);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    get_phase_roster_from_server: function(assignment, phase) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          if (!(phase && assignment)) {
            return reject();
          }
          query = {
            verb: 'post',
            action: 'roster',
            model: assignment,
            id: assignment.get('id'),
            data: {
              auth: {
                sub_action: 'phase_roster',
                phase_id: phase.get('id')
              }
            }
          };
          totem_messages['default'].show_loading_outlet();
          return ajax['default'].object(query).then(function(roster) {
            return resolve(roster);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});