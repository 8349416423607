define('totem-messages/messages', ['exports', 'ember', 'totem/util', 'totem/config', 'totem/logger', 'totem-messages/api', 'totem/error'], function (exports, ember, util, config, logger, api, totem_error) {

  'use strict';

  var ___DefaultExportObject___, totem_messages,
    hasProp = {}.hasOwnProperty;

  totem_messages = ember['default'].Object.extend({
    init: function() {
      var i, len, ref, type;
      this._super();
      this.reset_elapsed_time();
      ref = this.all_message_types();
      for (i = 0, len = ref.length; i < len; i++) {
        type = ref[i];
        this.set_type_visibility(type, true);
      }
      return api['default'].set_app_msgs(this);
    },
    api_success: function(options) {
      if (options == null) {
        options = {};
      }
      this.reset_session_timer();
      if (this.suppress_all_messages()) {
        return options["return"] || options.model || null;
      }
      return api['default'].success(options);
    },
    api_failure: function(error, options) {
      if (options == null) {
        options = {};
      }
      return api['default'].failure(error, options);
    },
    info: function(message, sticky) {
      if (sticky == null) {
        sticky = false;
      }
      return this.add_message(this.message_type.info, message, sticky);
    },
    warn: function(message, sticky) {
      if (sticky == null) {
        sticky = true;
      }
      return this.add_message(this.message_type.warn, message, sticky);
    },
    error: function(message, sticky) {
      if (sticky == null) {
        sticky = true;
      }
      return this.add_message(this.message_type.error, message, sticky);
    },
    debug: function(message, sticky) {
      if (sticky == null) {
        sticky = true;
      }
      return this.add_message(this.message_type.debug, message, sticky);
    },
    fatal: function(message, sticky) {
      if (sticky == null) {
        sticky = true;
      }
      return this.add_message(this.message_type.fatal, message, sticky);
    },
    hide_type: function(type) {
      return this.toggle_visibility(type);
    },
    show_type: function(type) {
      return this.toggle_visibility(type);
    },
    clear_type: function(type) {
      return this.all_messages().removeObjects(this.type_messages(type));
    },
    hide_all: function() {
      return this.hide_all_messages();
    },
    show_all: function() {
      return this.show_all_messages();
    },
    clear_all: function() {
      return this.all_messages().clear();
    },
    sign_out_user: function() {
      return this.get_app_route().send('sign_out');
    },
    show_message_outlet: function(options) {
      if (options == null) {
        options = {};
      }
      return this.get_app_controller().totem_message_outlet(options);
    },
    hide_message_outlet: function() {
      return this.get_app_route().send('hide_totem_message_outlet');
    },
    loading_outlet_visible: false,
    show_loading_outlet: function(options) {
      var obj;
      if (options == null) {
        options = {};
      }
      if (this.get('loading_outlet_visible')) {
        return;
      }
      if (options.template_name == null) {
        options.template_name = config['default'].messages.loading_template;
      }
      if (options.outlet_messages == null) {
        options.outlet_messages = options.messages || options.message;
      }
      this.set('loading_outlet_visible', true);
      this.message_outlet(options);
      if (options["function"]) {
        obj = options.object;
        if (!obj) {
          totem_error['default']["throw"](this, "Cannot call show_loading_outlet with a function and a blank [options.object] value.");
        }
        ember['default'].run.sync();
        return ember['default'].run.later(obj, options["function"], options.params, options.interval || 1);
      }
    },
    hide_loading_outlet: function() {
      if (!this.get('loading_outlet_visible')) {
        return;
      }
      this.set('loading_outlet_visible', false);
      return this.hide_message_outlet();
    },
    message_outlet: function(options) {
      var template_name;
      template_name = options.template_name;
      if (!template_name) {
        totem_error['default']["throw"](this, "Message outlet template name is blank");
      }
      return this.show_message_outlet(options);
    },
    reset_elapsed_time: function() {
      return this.set('last_message_date', util['default'].current_date());
    },
    message_queue: ember['default'].ArrayController.create(),
    message_type: {
      all: 'all',
      info: 'info',
      warn: 'warn',
      error: 'error',
      debug: 'debug',
      fatal: 'fatal'
    },
    last_message_date: null,
    message_type_visible: ember['default'].Object.create(),
    message_present: ember['default'].computed('message_queue.length', function() {
      return this.all_messages().get('length');
    }),
    info_present: ember['default'].computed('message_queue.length', function() {
      return this.type_messages(this.message_type.info).length > 0;
    }),
    warn_present: ember['default'].computed('message_queue.length', function() {
      return this.type_messages(this.message_type.warn).length > 0;
    }),
    error_present: ember['default'].computed('message_queue.length', function() {
      return this.type_messages(this.message_type.error).length > 0;
    }),
    debug_present: ember['default'].computed('message_queue.length', function() {
      return this.type_messages(this.message_type.debug).length > 0;
    }),
    debug_on: ember['default'].computed(function() {
      return this.is_debug();
    }),
    container: null,
    application_route: null,
    application_controller: null,
    session_timeout_controller: null,
    get_container: function() {
      return this.get('container');
    },
    set_container: function(container) {
      return this.set('container', container);
    },
    container_lookup: function(name) {
      return this.get_container().lookup(name);
    },
    get_app_route: function() {
      var route;
      if (!(route = this.get('application_route'))) {
        this.set('application_route', (route = this.container_lookup('route:application')));
      }
      return route;
    },
    get_app_controller: function() {
      var controller;
      if (!(controller = this.get('application_controller'))) {
        this.set('application_controller', (controller = this.container_lookup('controller:application')));
      }
      return controller;
    },
    get_session_timeout_controller: function() {
      var controller;
      if (!(controller = this.get('session_timeout_controller'))) {
        this.set('session_timeout_controller', (controller = this.container_lookup('controller:session_timeout')));
      }
      return controller;
    },
    invalidate_session: function() {
      return this.get_app_route().invalidate_session();
    },
    reset_session_timer: function(options) {
      if (options == null) {
        options = {};
      }
      return this.get_session_timeout_controller().reset_session_timer(options);
    },
    cancel_session_timer: function() {
      return this.get_session_timeout_controller().cancel_session_timer();
    },
    all_messages: function() {
      return this.get('message_queue');
    },
    all_types_visible: function() {
      return this.get('message_type_visible');
    },
    all_message_types: function() {
      var ref, results, type, value;
      ref = this.get('message_type');
      results = [];
      for (type in ref) {
        if (!hasProp.call(ref, type)) continue;
        value = ref[type];
        results.push(type);
      }
      return results;
    },
    type_messages: function(type) {
      return this.all_messages().filterBy('type', type);
    },
    add_message: function(type, message, sticky) {
      var i, len, msg, results, visible;
      if (this.suppress_all_messages(type)) {
        return;
      }
      visible = true;
      if (ember['default'].isArray(message)) {
        results = [];
        for (i = 0, len = message.length; i < len; i++) {
          msg = message[i];
          results.push(this.all_messages().pushObject(this.message_entry(type, msg, visible, sticky)));
        }
        return results;
      } else {
        return this.all_messages().pushObject(this.message_entry(type, message, visible, sticky));
      }
    },
    remove_message: function(message) {
      if (ember['default'].isArray(message)) {
        return this.all_messages().removeObjects(message);
      } else {
        return this.all_messages().removeObject(message);
      }
    },
    get_elapsed_time: function(message_date) {
      var elapsed;
      elapsed = message_date - this.get('last_message_date');
      this.set('last_message_date', message_date);
      return elapsed;
    },
    message_entry: function(type, message, visible, sticky) {
      var date, elapsed, timestamp;
      timestamp = null;
      date = util['default'].current_date();
      if (this.get('debug_on')) {
        elapsed = util['default'].rjust(this.get_elapsed_time(date), 6, '0');
        timestamp = "[" + elapsed + "] ";
      }
      return ember['default'].Object.create({
        type: type,
        message: message,
        visible: visible,
        date: util['default'].date_time(date),
        timestamp: timestamp,
        sticky: sticky
      });
    },
    show_all_messages: function() {
      this.set_all_message_visibility(true);
      this.set_all_type_visibility(true);
      return this.set_type_visibility(this.message_type.all, true);
    },
    hide_all_messages: function() {
      this.set_all_message_visibility(false);
      this.set_all_type_visibility(false);
      return this.set_type_visibility(this.message_type.all, false);
    },
    toggle_visibility: function(type) {
      var visible;
      visible = !this.all_types_visible().get(type);
      this.set_type_visibility(type, visible);
      return this.type_messages(type).map(function(message) {
        return message.set('visible', visible);
      });
    },
    set_all_message_visibility: function(visible) {
      return this.all_messages().map(function(message) {
        return message.set('visible', visible);
      });
    },
    set_all_type_visibility: function(visible) {
      var i, len, ref, results, type;
      ref = this.all_message_types();
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        type = ref[i];
        results.push(this.set_type_visibility(type, visible));
      }
      return results;
    },
    set_type_visibility: function(type, visible) {
      return this.all_types_visible().set(type, visible);
    },
    set_suppress_all: function() {
      return this.set('suppress_all', true);
    },
    reset_suppress_all: function() {
      return this.set('suppress_all', false);
    },
    get_suppress_all: function() {
      return this.get('suppress_all');
    },
    suppress_all_messages: function(type) {
      if (type === 'error') {
        return false;
      }
      return config['default'].messages.suppress_all === true || this.get_suppress_all();
    },
    is_debug: function() {
      return logger['default'].is_debug || false;
    },
    toString: function() {
      return 'totem_messages';
    }
  });

  ___DefaultExportObject___ = totem_messages.create();

  exports['default'] = ___DefaultExportObject___;;

});