define('totem/mixins/group_by', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___,
    hasProp = {}.hasOwnProperty;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    group_values: function(values, options) {
      var find, group_obj, groups, last_index, ref, sort_keys, sort_objs, sorted_values;
      if (options == null) {
        options = {};
      }
      if (ember['default'].isBlank(values)) {
        return [];
      }
      ref = this.get_group_sort_keys_and_sorted_values(values, options), sort_keys = ref[0], sorted_values = ref[1];
      if (!sort_keys) {
        return [];
      }
      group_obj = ember['default'].Object.create({
        content: sorted_values
      });
      find = this.get_group_find(sort_keys, options);
      groups = null;
      sort_objs = null;
      last_index = sort_keys.get('length') - 1;
      sort_keys.forEach((function(_this) {
        return function(key, sort_index) {
          var next_objs, obj_find;
          obj_find = find[sort_index] || (key + "_id");
          if (sort_objs) {
            next_objs = [];
            sort_objs.forEach(function(obj) {
              var result;
              result = _this.get_group_results(obj, key, obj_find, options);
              obj.set('content', result);
              return result.forEach(function(result_obj) {
                result_obj.set('is_last_group', sort_index === last_index);
                return next_objs.push(result_obj);
              });
            });
            return sort_objs = next_objs;
          } else {
            groups = _this.get_group_results(group_obj, key, obj_find, options);
            return sort_objs = groups;
          }
        };
      })(this));
      return groups;
    },
    get_group_results: function(group_obj, key, find, options) {
      var result;
      if (options == null) {
        options = {};
      }
      result = [];
      group_obj.get('content').forEach((function(_this) {
        return function(obj) {
          var has_group, id, j, k, len, len1, prop, props, result_obj;
          id = _this.get_group_object_id(obj, key, find);
          has_group = result.findBy('id', id);
          if (!has_group) {
            result_obj = ember['default'].Object.create({
              id: id,
              label: obj.get(key + "_label"),
              model: obj.get(key + "_model"),
              path: obj.get(key + "_path"),
              content: []
            });
            if (result_obj.get('id') == null) {
              _this.totem_error["throw"](_this, "GroupByMixin: group id is blank for key [" + key + "].  Either add " + key + "_id or " + key + "_model to the values object or a find option .");
            }
            if (options.add_key_props) {
              props = ember['default'].makeArray(options.add_key_props).compact();
              for (j = 0, len = props.length; j < len; j++) {
                prop = props[j];
                result_obj.set(prop, obj.get(key + "_" + prop));
              }
            }
            if (options.add_props) {
              props = ember['default'].makeArray(options.add_props).compact();
              for (k = 0, len1 = props.length; k < len1; k++) {
                prop = props[k];
                result_obj.set(prop, obj.get(prop));
              }
            }
            result.pushObject(result_obj);
          }
          return result.findBy('id', id).get('content').pushObject(obj);
        };
      })(this));
      return result;
    },
    get_group_sort_keys_and_sorted_values: function(values, options) {
      var first_obj, sort, sort_keys, sort_orders, sort_props;
      sort = options.sort || this.get('group_sort');
      if (!sort) {
        return [null, null];
      }
      sort = ember['default'].makeArray(sort);
      sort_props = [];
      sort_orders = [];
      sort_keys = [];
      first_obj = values.get('firstObject');
      sort.forEach((function(_this) {
        return function(sort_value) {
          var key, order, ref, sort_prop;
          ref = sort_value.split(':'), key = ref[0], order = ref[1];
          if (!order) {
            order = 'asc';
          }
          if (!(order === 'asc' || order === 'desc')) {
            _this.totem_error["throw"](_this, "GroupByMixin: Sort value [" + sort_value + "] must use 'asc' or 'desc' not [" + order + "].");
          }
          sort_prop = _this.get_group_sort_by(first_obj, key, options);
          sort_props.push(sort_prop);
          sort_orders.push(order);
          return sort_keys.push(key);
        };
      })(this));
      return [sort_keys, this.get_group_sorted_values(values, sort_props, sort_orders)];
    },
    get_group_sorted_values: function(values, sort_props, orders) {
      return values.toArray().sort(function(a, b) {
        var i, j, len, prop, prop_a, prop_b, rc;
        for (i = j = 0, len = sort_props.length; j < len; i = ++j) {
          prop = sort_props[i];
          prop_a = a.get(prop);
          prop_b = b.get(prop);
          rc = ember['default'].compare(prop_a, prop_b);
          if (rc && orders[i] === 'desc') {
            rc = rc * -1;
          }
          if (rc) {
            return rc;
          }
        }
        return 0;
      });
    },
    get_group_sort_by: function(obj, key, options) {
      var key_sort_by, sort_by;
      sort_by = options.sort_by;
      if (sort_by) {
        if (!(typeof sort_by === 'object' && !ember['default'].isArray(sort_by))) {
          this.totem_error["throw"](this, "GroupByMixin: Options sort_by [" + sort_by + "] must be an object.");
        }
        key_sort_by = sort_by[key];
        if (key_sort_by) {
          if (obj.get(key_sort_by) == null) {
            this.totem_error["throw"](this, "GroupByMixin: Options sort_by [" + key_sort_by + "] for key [" + key + "] is not an object property.  Missing option add_props?");
          } else {
            return key_sort_by;
          }
        }
      }
      switch (false) {
        case obj.get(key) == null:
          return key;
        case !obj.get(key + "_label"):
          return key + "_label";
        default:
          return this.totem_error["throw"](this, "GroupByMixin: Value objects do not contain sort property for [" + key + "].");
      }
    },
    get_group_find: function(sort_keys, options) {
      var find;
      find = options.find;
      switch (false) {
        case !ember['default'].isArray(find):
          return find;
        case typeof find !== 'string':
          return ember['default'].makeArray(find);
        case typeof find !== 'object':
          return sort_keys.map((function(_this) {
            return function(key) {
              return find[key] || null;
            };
          })(this));
        default:
          return [];
      }
    },
    get_group_object_id: function(obj, key, find) {
      var id;
      id = obj.get(find);
      if (id != null) {
        return id;
      }
      return obj.get(key + "_model.id");
    },
    contains_group_values: function(array, values) {
      return array.find(function(obj) {
        var key, value;
        for (key in values) {
          if (!hasProp.call(values, key)) continue;
          value = values[key];
          if (obj[key] !== value) {
            return false;
          }
        }
        return true;
      });
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});