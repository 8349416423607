define('thinkspace-indented-list/response_manager', ['exports', 'ember', 'thinkspace-indented-list/mixins/configuration', 'thinkspace-indented-list/mixins/dragula', 'thinkspace-indented-list/mixins/handle_events', 'thinkspace-indented-list/mixins/helpers', 'thinkspace-indented-list/mixins/initialize', 'thinkspace-indented-list/mixins/register', 'thinkspace-indented-list/mixins/save_response', 'thinkspace-indented-list/mixins/item/actions', 'thinkspace-indented-list/mixins/item/change', 'thinkspace-indented-list/mixins/item/component', 'thinkspace-indented-list/mixins/item/helpers', 'thinkspace-indented-list/mixins/item/itemable', 'thinkspace-indented-list/mixins/item/new', 'thinkspace-indented-list/mixins/item/selected', 'thinkspace-indented-list/mixins/item/itemable_change'], function (exports, ember, m_config, m_dragula, m_handle_events, m_helpers, m_initialize, m_register, m_save_response, m_item_actions, m_item_change, m_item_component, m_item_helpers, m_item_itemable, m_item_new, m_item_selected, m_itemable_change) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Object.extend(m_initialize['default'], m_config['default'], m_dragula['default'], m_handle_events['default'], m_helpers['default'], m_register['default'], m_save_response['default'], m_item_actions['default'], m_item_change['default'], m_item_component['default'], m_item_helpers['default'], m_item_itemable['default'], m_item_new['default'], m_item_selected['default'], m_itemable_change['default']);

	exports['default'] = ___DefaultExportObject___;;

});