define('totem/ds/associations', ['exports', 'ember', 'ember-data', 'totem/ns', 'totem/error', 'totem/mixins/data'], function (exports, ember, ds, ns, totem_error, totem_data_mixin) {

  'use strict';

  var TotemAssociations, ___DefaultExportObject___;

  TotemAssociations = (function() {
    var TotemAssociationsFilter, TotemAssociationsFrom, TotemAssociationsPolymorphic, TotemAssociationsStoreFilter;

    function TotemAssociations() {}

    TotemAssociations.totem_data = totem_data_mixin['default'];

    TotemAssociations.Model = ds['default'].Model;

    TotemAssociations.attr = function(type, options) {
      return ds['default'].attr(type, options);
    };

    TotemAssociations.PromiseArray = ds['default'].PromiseArray;

    TotemAssociations.PromiseObject = ds['default'].PromiseObject;

    TotemAssociations.to_p = function() {
      return ns['default'].to_p(arguments);
    };

    TotemAssociations.to_o = function() {
      return ns['default'].to_o(arguments);
    };

    TotemAssociations.to_t = function() {
      return ns['default'].to_t(arguments);
    };

    TotemAssociations.to_prop = function() {
      return ns['default'].to_prop(arguments);
    };

    TotemAssociations.add = function() {
      var association, i, len, result;
      result = {};
      for (i = 0, len = arguments.length; i < len; i++) {
        association = arguments[i];
        ember['default'].merge(result, association);
      }
      return ember['default'].Mixin.create(result);
    };

    TotemAssociations.belongs_to = function(type, options) {
      var assoc, result;
      if (options == null) {
        options = {};
      }
      if (options.ns !== false) {
        type = TotemAssociations.to_p(type);
      }
      TotemAssociations.association_defaults(options);
      assoc = options.type || type;
      delete options.type;
      result = {};
      TotemAssociations.include_notify_observable(result, assoc, options);
      TotemAssociations.include_reads(result, assoc, options);
      delete options.belongs_to;
      result[type] = ds['default'].belongsTo(assoc, options);
      return result;
    };

    TotemAssociations.has_many = function(type, options) {
      var assoc, result;
      if (options == null) {
        options = {};
      }
      if (options.ns !== false) {
        type = TotemAssociations.to_p(type);
      }
      TotemAssociations.association_defaults(options);
      assoc = options.type || type.pluralize();
      delete options.type;
      result = {};
      TotemAssociations.include_notify_observable(result, assoc, options);
      TotemAssociations.include_reads(result, assoc, options);
      delete options.has_many;
      result[assoc] = ds['default'].hasMany(type.singularize(), options);
      return result;
    };

    TotemAssociations.polymorphic = function(name, options) {
      var attribute, result;
      if (options == null) {
        options = {};
      }
      result = {};
      if (!(name && typeof name === 'string')) {
        totem_error['default']["throw"](this, "A totem_polymorphic requires a string name as the first parameter.");
      }
      attribute = options.attribute || name;
      result[name] = ember['default'].computed(function() {
        return (new TotemAssociationsPolymorphic(attribute)).call(this);
      });
      return result;
    };

    TotemAssociations.store_filter = function(name, options) {
      var result, type;
      if (options == null) {
        options = {};
      }
      if (!options.on) {
        totem_error['default']["throw"](this, "A totem_associations store filter 'on' is blank.");
      }
      if (options.reads) {
        if (!options.reads.name) {
          totem_error['default']["throw"](this, "A totem_associations store 'reads' must contain a name.");
        }
        if (name === options.reads.name) {
          totem_error['default']["throw"](this, "A totem_associations store 'reads' name must be different than the store name.");
        }
      }
      type = TotemAssociations.to_p(name).singularize();
      result = {};
      TotemAssociations.include_notify_observable(result, name, options);
      TotemAssociations.include_reads(result, name, options);
      result[name] = ember['default'].computed(function() {
        return (new TotemAssociationsStoreFilter(name, type, options)).call(this);
      });
      return result;
    };

    TotemAssociations.association_defaults = function(options) {
      if (options.async !== false) {
        return options.async = true;
      }
    };

    TotemAssociations.include_notify_observable = function(result, assoc, options) {
      var i, j, len, len1, notifies, notify, notify_observer, notify_properties, observe_on, prop, ref, ref1;
      if (!(options.notify || options.notify_property)) {
        return;
      }
      if (options.notify) {
        notifies = ember['default'].makeArray(options.notify);
        delete options.notify;
        observe_on = [];
        for (i = 0, len = notifies.length; i < len; i++) {
          notify = notifies[i];
          switch (notify) {
            case true:
              observe_on.push('totem_scope.path_ids');
              break;
            default:
              observe_on.push("totem_scope.path_ids." + notify);
          }
        }
        notify_observer = (ref = (function() {
          return this.notifyPropertyChange(assoc);
        })).observes.apply(ref, observe_on);
        prop = '_notify_' + assoc;
        result[prop] = notify_observer;
      }
      if (options.notify_property) {
        notify_properties = ember['default'].makeArray(options.notify_property);
        delete options.notify_property;
        observe_on = [];
        for (j = 0, len1 = notify_properties.length; j < len1; j++) {
          notify = notify_properties[j];
          observe_on.push(notify);
        }
        notify_observer = (ref1 = (function() {
          return this.notifyPropertyChange(assoc);
        })).observes.apply(ref1, observe_on);
        prop = '_notify_property_' + assoc;
        return result[prop] = notify_observer;
      }
    };

    TotemAssociations.include_reads = function(result, assoc, options) {
      var alias_prop, assoc_prop, filter, filter_fn, filter_function, from, from_notify, i, len, name, opts, reads, sort, sort_on_prop, sort_prop;
      if (!options.reads) {
        return;
      }
      reads = ember['default'].makeArray(options.reads);
      delete options.reads;
      for (i = 0, len = reads.length; i < len; i++) {
        opts = reads[i];
        name = opts.name || assoc.split('/').pop();
        filter = opts.filter;
        filter_fn = opts.filter_fn;
        sort = opts.sort;
        from = opts.from;
        from_notify = opts.notify !== false;
        if (!name) {
          totem_error['default']["throw"](this, "A totem_associations 'reads' object must contain a name.");
        }
        if (opts.notify || opts.notify_property) {
          alias_prop = "_ta_alias_" + name + "_" + assoc;
          result[alias_prop] = ember['default'].computed.alias(assoc);
          assoc_prop = alias_prop;
        } else {
          assoc_prop = assoc;
        }
        TotemAssociations.include_notify_observable(result, assoc_prop, opts);
        if (from) {
          if (opts.ns !== false) {
            from = TotemAssociations.to_p(from);
          }
          if (sort || filter) {
            assoc_prop = '_ta_store_' + name;
          } else {
            assoc_prop = name;
          }
          result[assoc_prop] = ember['default'].computed(function() {
            return (new TotemAssociationsFrom(assoc, from, opts.self, opts.id)).call(this);
          });
          if (assoc_prop === name) {
            return;
          }
        }
        if (filter) {
          filter_function = new TotemAssociationsFilter(filter, filter_fn);
        }
        if (sort) {
          sort_on_prop = '_ta_sort_' + name + '_by';
          sort_prop = '_ta_sort';
          if (!assoc_prop.match(/^_/)) {
            sort_prop += '_';
          }
          sort_prop += assoc_prop;
          sort_prop += '_' + name;
          result[sort_on_prop] = ember['default'].makeArray(sort);
        }
        if (filter && sort) {
          result[sort_prop] = ember['default'].computed.sort(assoc_prop, sort_on_prop);
          result[name] = ember['default'].computed.filter(sort_prop, filter_function);
          if (from && from_notify) {
            TotemAssociations.include_notify_observable(result, sort_prop, {
              notify: true
            });
          }
        } else if (filter) {
          result[name] = ember['default'].computed.filter(assoc_prop, filter_function);
          if (from && from_notify) {
            TotemAssociations.include_notify_observable(result, assoc_prop, {
              notify: true
            });
          }
        } else if (sort) {
          result[name] = ember['default'].computed.sort(assoc_prop, sort_on_prop);
        } else {
          result[name] = ember['default'].computed.alias(assoc_prop);
        }
      }
    };

    TotemAssociationsPolymorphic = (function() {
      function TotemAssociationsPolymorphic(attribute) {
        return this.get_polymorphic_function(attribute);
      }

      TotemAssociationsPolymorphic.prototype.get_polymorphic_function = function(attribute) {
        var fn;
        return fn = function() {
          var promise;
          promise = new ember['default'].RSVP.Promise((function(_this) {
            return function(resolve, reject) {
              var id, type;
              type = _this.get(attribute + "_type");
              id = _this.get(attribute + "_id");
              if (!(type && id)) {
                return resolve(null);
              }
              type = _this.totem_scope.rails_polymorphic_type_to_path(type);
              return _this.store.find(type, id).then(function(record) {
                if (!record.get('isDeleted')) {
                  return resolve(record);
                } else {
                  return resolve(null);
                }
              }, function(error) {
                return reject(error);
              });
            };
          })(this));
          return ds['default'].PromiseObject.create({
            promise: promise
          });
        };
      };

      return TotemAssociationsPolymorphic;

    })();

    TotemAssociationsFrom = (function() {
      function TotemAssociationsFrom(assoc, from, self, id) {
        if (id == null) {
          id = null;
        }
        return this.get_from_function(assoc, from, self, id);
      }

      TotemAssociationsFrom.prototype.get_from_function = function(assoc, from, self, id) {
        var fn;
        return fn = function() {
          var promise;
          promise = new ember['default'].RSVP.Promise((function(_this) {
            return function(resolve, reject) {
              var add_self, id_prop, type;
              id_prop = id || from.split('/').pop().singularize() + '_id';
              type = assoc.singularize();
              add_self = self !== false && from === _this.totem_scope.get_record_path(_this).pluralize();
              return _this.get(from).then(function(records) {
                var assoc_promises, filter_ids, record_ids;
                record_ids = records.mapBy('id');
                if (add_self) {
                  id = _this.get('id');
                  if (!record_ids.contains(id)) {
                    record_ids.push(id);
                  }
                }
                filter_ids = _this.totem_scope.make_ids_array(record_ids.uniq());
                assoc_promises = records.map(function(record) {
                  return record.get(assoc);
                });
                return ember['default'].RSVP.Promise.all(assoc_promises).then(function() {
                  return _this.store.filter(type, function(record) {
                    return filter_ids.contains(record.get(id_prop));
                  }).then(function(filtered_store_records) {
                    return resolve(filtered_store_records);
                  }, function(error) {
                    return reject(error);
                  });
                });
              });
            };
          })(this));
          return ds['default'].PromiseArray.create({
            promise: promise
          });
        };
      };

      return TotemAssociationsFrom;

    })();

    TotemAssociationsFilter = (function() {
      function TotemAssociationsFilter(filter, filter_fn) {
        return this.get_filter_function(filter, filter_fn);
      }

      TotemAssociationsFilter.prototype.get_filter_function = function(filter, filter_fn) {
        if (typeof filter === 'function') {
          return filter;
        }
        switch (filter) {
          case 'users':
            return this.filter_by_users(filter, filter_fn);
          default:
            return this.filter_by_path(filter, filter_fn);
        }
      };

      TotemAssociationsFilter.prototype.filter_by_users = function(filter, filter_fn) {
        var fn;
        if (filter_fn) {
          fn = function(record) {
            if (filter_fn(record) === false) {
              return false;
            }
            return this.totem_scope.can_view_record_user_id(record);
          };
        } else {
          fn = function(record) {
            return this.totem_scope.can_view_record_user_id(record);
          };
        }
        return fn;
      };

      TotemAssociationsFilter.prototype.filter_by_path = function(filter, filter_fn) {
        var fn;
        if (filter_fn) {
          fn = function(record) {
            if (filter_fn(record) === false) {
              return false;
            }
            return this.totem_scope.can_view_record_current_path_id(record);
          };
        } else {
          fn = function(record) {
            return this.totem_scope.can_view_record_current_path_id(record);
          };
        }
        return fn;
      };

      return TotemAssociationsFilter;

    })();

    TotemAssociationsStoreFilter = (function() {
      function TotemAssociationsStoreFilter(name, type, options) {
        return this.get_store_filter(name, type, options);
      }

      TotemAssociationsStoreFilter.prototype.get_store_filter = function(name, type, options) {
        var fn;
        return fn = function() {
          var __filter_on, promise;
          __filter_on = function(record, options) {
            var filter_attr, value;
            filter_attr = options.filter_on;
            if (!filter_attr) {
              return true;
            }
            value = record.get(filter_attr);
            if (options.is_blank) {
              return ember['default'].isBlank(value);
            }
            if (options.is_equal) {
              return value === options.is_equal;
            }
            return true;
          };
          if (options.destroy !== false) {
            this.willDestroy = (function() {
              var filter;
              this._super();
              filter = this.get(name + ".content");
              if (!filter) {
                return;
              }
              return filter.destroy();
            });
          }
          promise = new ember['default'].RSVP.Promise((function(_this) {
            return function(resolve, reject) {
              var load, model, promises;
              if (options.load) {
                load = ember['default'].makeArray(options.load);
                promises = load.map(function(load_prop) {
                  return _this.get(load_prop);
                });
              } else {
                promises = [ember['default'].RSVP.resolve()];
              }
              model = options.model || _this;
              if (options.model) {
                model = _this.get(model);
              }
              if (model.then != null) {
                promises.unshift(model);
              } else {
                promises.unshift(ember['default'].RSVP.resolve(model));
              }
              return ember['default'].RSVP.Promise.all(promises).then(function(results) {
                var filter_id, filter_type, id_attr, type_attr;
                model = results.get('firstObject');
                filter_id = parseInt(model.get('id'));
                if (options.polymorphic) {
                  id_attr = options.on + '_id';
                  type_attr = options.on + '_type';
                  filter_type = _this.totem_scope.get_record_path(model);
                  return _this.store.filter(type, function(record) {
                    var record_id, record_type;
                    record_id = parseInt(record.get(id_attr));
                    record_type = _this.totem_scope.rails_polymorphic_type_to_path(record.get(type_attr));
                    console.warn('filter record', record_id, filter_id, record_type, filter_type, record.toString());
                    if (!(filter_id === record_id && filter_type === record_type)) {
                      return false;
                    }
                    return __filter_on(record, options);
                  }).then(function(filtered_store_records) {
                    return resolve(filtered_store_records);
                  }, function(error) {
                    return reject(error);
                  });
                } else {
                  id_attr = options.on;
                  return _this.store.filter(type, function(record) {
                    var record_id;
                    record_id = parseInt(record.get(id_attr));
                    if (filter_id !== record_id) {
                      return false;
                    }
                    return __filter_on(record, options);
                  }).then(function(filtered_store_records) {
                    return resolve(filtered_store_records);
                  }, function(error) {
                    return reject(error);
                  });
                }
              }, function(error) {
                return reject(error);
              });
            };
          })(this));
          return ds['default'].PromiseArray.create({
            promise: promise
          });
        };
      };

      return TotemAssociationsStoreFilter;

    })();

    return TotemAssociations;

  })();

  ___DefaultExportObject___ = TotemAssociations;

  exports['default'] = ___DefaultExportObject___;;

});