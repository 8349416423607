define('thinkspace-dock/map', ['exports', 'ember', 'totem/ns', 'totem/util'], function (exports, ember, ns, util) {

  'use strict';

  var ___DefaultExportObject___,
    hasProp = {}.hasOwnProperty;

  ___DefaultExportObject___ = ember['default'].Object.create({
    show_map: ember['default'].Object.create(),
    except_map: ember['default'].Object.create(),
    required_addons: null,
    show: function(route, addons) {
      var addon, i, len, map, map_addons, results;
      addons = ember['default'].makeArray(addons);
      map = this.get('show_map');
      util['default'].add_path_objects(map, route);
      map_addons = this.get_map_addons(map, route);
      if (ember['default'].isArray(map_addons)) {
        results = [];
        for (i = 0, len = addons.length; i < len; i++) {
          addon = addons[i];
          if (!this.addon_included(map_addons, addon)) {
            results.push(map_addons.push(addon));
          } else {
            results.push(void 0);
          }
        }
        return results;
      } else {
        return map.set(route, {
          _doc_addons: addons
        });
      }
    },
    except: function(route) {
      var map;
      map = this.get('except_map');
      util['default'].add_path_objects(map, route);
      return map.set(route, true);
    },
    get_required_addons: function(route) {
      return this.get('required_addons');
    },
    set_required_addons: function(addons) {
      var addon, i, len, required_addons, results;
      addons = ember['default'].makeArray(addons);
      required_addons = this.get('required_addons');
      if (ember['default'].isArray(required_addons)) {
        results = [];
        for (i = 0, len = addons.length; i < len; i++) {
          addon = addons[i];
          if (!this.addon_included(required_addons, addon)) {
            results.push(required_addons.push(addon));
          } else {
            results.push(void 0);
          }
        }
        return results;
      } else {
        return this.set('required_addons', addons);
      }
    },
    get_route_addons: function(route) {
      var addons, map, route_match;
      if (!route) {
        return [];
      }
      if (this.is_except_route(route)) {
        return [];
      }
      map = this.get('show_map');
      addons = this.get_map_addons(map, route);
      if (!ember['default'].isArray(addons)) {
        route_match = route.replace(/\..*$/, '.*');
        addons = this.get_map_addons(map, route_match);
      }
      return addons || [];
    },
    is_except_route: function(route) {
      var except, key, last, map, path, ref, value;
      map = this.get('except_map');
      if (!map) {
        return false;
      }
      if (map.get(route) === true) {
        return true;
      }
      for (key in map) {
        if (!hasProp.call(map, key)) continue;
        value = map[key];
        if (typeof value !== 'function') {
          if (except = map.get(key + ".*")) {
            ref = this.get_path_route(route), path = ref[0], last = ref[1];
            if (path && util['default'].starts_with(route, path)) {
              if (except === true) {
                return true;
              }
              if (except[last] === true) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    addon_included: function(addons, addon) {
      return addons.findBy('path', addon.path);
    },
    get_map_addons: function(map, route) {
      return map.get(route + '._doc_addons');
    },
    get_path_route: function(route_name) {
      var parts, path, route;
      parts = route_name.split('.');
      route = parts.pop();
      path = parts.join('.');
      return [path, route];
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});