define('thinkspace-readiness-assurance/base/admin/rad', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    validate: 'validate',
    width_selector: null,
    show_select: true,
    message: null,
    base_message: null,
    teams: null,
    users: null,
    due_at: null,
    state: null,
    timer: null,
    timer_type: 'countdown',
    timer_unit: 'minute',
    timer_intervals: null,
    team_users: null,
    select_all_teams: false,
    select_all_users: false,
    show_all: false,
    init: function() {
      this._super();
      this.time_at = new Date();
      return this.errors = [];
    },
    error_message: 'There are errors.  Please correct before doing the transition.',
    clear_errors: function() {
      return this.get('errors').clear();
    },
    error: function(e) {
      return this.get('errors').pushObject(e);
    },
    clear_message: function() {
      return this.set_message(null);
    },
    get_message: function() {
      return this.get('message');
    },
    set_message: function(msg) {
      return this.set('message', msg);
    },
    get_base_message: function() {
      return this.get('base_message');
    },
    set_base_message: function(msg) {
      return this.set('base_message', msg);
    },
    get_team_users: function() {
      return this.get('team_users');
    },
    set_team_users: function(team_users) {
      return this.set('team_users', team_users);
    },
    get_all_users: function() {
      var i, j, len, len1, ref, team_users, tu, u, user, users;
      team_users = this.get_team_users() || [];
      users = [];
      for (i = 0, len = team_users.length; i < len; i++) {
        tu = team_users[i];
        ref = tu.users;
        for (j = 0, len1 = ref.length; j < len1; j++) {
          user = ref[j];
          u = ember['default'].merge({}, user);
          u.team = ember['default'].merge({}, tu.team || {});
          users.push(u);
        }
      }
      return users;
    },
    get_teams: function() {
      return this.get('teams');
    },
    set_teams: function(teams) {
      return this.set('teams', teams);
    },
    get_users: function() {
      return this.get('users');
    },
    set_users: function(users) {
      return this.set('users', users);
    },
    get_timer: function() {
      return this.get('timer');
    },
    set_timer: function(opts) {
      return this.set('timer', opts);
    },
    get_phase_state: function() {
      return this.get('phase_state');
    },
    set_phase_state: function(state) {
      return this.set('phase_state', state);
    },
    select_all_teams_on: function() {
      return this.set('all_teams', true);
    },
    select_all_users_on: function() {
      return this.set('all_users', true);
    },
    select_all_teams: function() {
      return this.get('all_teams') === true;
    },
    select_all_users: function() {
      return this.get('all_users') === true;
    },
    get_show_all: function() {
      return this.get('show_all');
    },
    show_all_on: function() {
      return this.set('show_all', true);
    },
    get_data: function() {
      var data, due_at, end_at, message, phase_state, release_at, settings, start_at, team_ids, teams, timer, transition_now, user_ids, users;
      users = this.get_users();
      teams = this.get_teams();
      user_ids = ember['default'].isBlank(users) ? null : users.mapBy('id');
      team_ids = ember['default'].isBlank(teams) ? null : teams.mapBy('id');
      message = this.get_message();
      release_at = this.get('release_at');
      due_at = this.get('due_at');
      transition_now = this.get('transition_now');
      phase_state = this.get_phase_state();
      timer = this.get_timer() || {};
      settings = timer.settings;
      start_at = timer.start_at;
      end_at = timer.end_at;
      data = {};
      if (ember['default'].isPresent(user_ids)) {
        data.user_ids = user_ids;
      }
      if (ember['default'].isPresent(team_ids)) {
        data.team_ids = team_ids;
      }
      if (ember['default'].isPresent(message)) {
        data.message = message;
      }
      if (ember['default'].isPresent(release_at)) {
        data.release_at = release_at;
      }
      if (ember['default'].isPresent(due_at)) {
        data.due_at = due_at;
      }
      if (ember['default'].isPresent(transition_now)) {
        data.transition_now = transition_now;
      }
      if (ember['default'].isPresent(phase_state)) {
        data.phase_state = phase_state;
      }
      if (ember['default'].isPresent(settings)) {
        data.timer_settings = settings;
      }
      if (ember['default'].isPresent(start_at)) {
        data.timer_start_at = start_at;
      }
      if (ember['default'].isPresent(end_at)) {
        data.timer_end_at = end_at;
      }
      return data;
    },
    timer_message_change: ember['default'].observer('due_at', function() {
      if (this.has_default_message()) {
        return this.add_default_message();
      }
    }),
    get_timer_message: function() {
      var msg;
      msg = this.get_base_message();
      if (ember['default'].isPresent(msg)) {
        return msg;
      }
      this.get_default_message();
      return this.get_base_message();
    },
    default_message: function() {
      return "Your " + this.name + " is due at";
    },
    default_message_regex: function() {
      var message;
      message = this.default_message();
      return new RegExp(message + ".*?\\\.", 'i');
    },
    has_default_message: function() {
      var message, regex;
      message = this.get_message();
      if (ember['default'].isBlank(message)) {
        return false;
      }
      regex = this.default_message_regex();
      return ember['default'].isPresent(message.match(regex));
    },
    get_default_message: function() {
      var dmsg, due_at, hhmm, inmm, mins;
      this.set_base_message(null);
      due_at = this.get('due_at');
      if (ember['default'].isBlank(due_at)) {
        return null;
      }
      hhmm = this.am.date_to_hh_mm(due_at);
      mins = this.am.minutes_from_now(due_at);
      inmm = this.am.minutes_from_now_message(mins);
      dmsg = this.default_message() + (" " + hhmm);
      this.set_base_message(dmsg);
      dmsg += mins <= 0 ? ' (now).' : " (in about " + inmm + ").";
      return dmsg;
    },
    add_default_message: function() {
      var dmsg, message, msg, regex;
      dmsg = this.get_default_message();
      if (ember['default'].isBlank(dmsg)) {
        return;
      }
      message = this.get_message();
      if (ember['default'].isPresent(message)) {
        if (this.has_default_message()) {
          regex = this.default_message_regex();
          msg = message.replace(regex, dmsg);
        } else {
          msg = message + '  ' + dmsg;
        }
      } else {
        msg = dmsg;
      }
      return this.set_message(msg);
    },
    toString: function() {
      return (this.name || '') + 'ReadinessAssuranceData';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});