define('thinkspace-peer-assessment/managers/evaluation', ['exports', 'ember', 'totem/scope', 'totem/cache', 'totem/ds/associations', 'totem-messages/messages', 'totem/mixins/validations', 'thinkspace-peer-assessment/managers/evaluation/reviews', 'thinkspace-peer-assessment/managers/evaluation/user_data', 'thinkspace-peer-assessment/managers/evaluation/balance', 'thinkspace-peer-assessment/managers/evaluation/qualitative'], function (exports, ember, totem_scope, tc, ta, tm, val_mixin, reviews, user_data, balance, qualitative) {

  'use strict';

  var ___DefaultExportObject___,
    slice = [].slice;

  ___DefaultExportObject___ = ember['default'].Object.extend(val_mixin['default'], reviews['default'], user_data['default'], balance['default'], qualitative['default'], {
    casespace: ember['default'].inject.service(),
    component: null,
    model: null,
    team: null,
    has_team_members: null,
    reviewables: null,
    review_set: null,
    reviews: null,
    reviewable: null,
    review: null,
    is_confirmation: ember['default'].computed.equal('reviewable', 'confirmation'),
    is_read_only: ember['default'].computed.or('totem_scope.is_read_only', 'review_set.is_read_only'),
    is_review_read_only: ember['default'].computed.or('review.is_not_approvable'),
    is_disabled: ember['default'].computed.or('has_errors', 'is_read_only'),
    has_errors: ember['default'].computed.equal('isValid', false),
    phase_settings: ember['default'].computed.reads('casespace.phase_settings'),
    phase_settings_observer: ember['default'].observer('phase_settings', function() {
      this.totem_messages.show_loading_outlet({
        message: 'Changing teammate...'
      });
      return this.set_review_from_phase_settings().then((function(_this) {
        return function() {
          return _this.totem_messages.hide_loading_outlet();
        };
      })(this));
    }),
    reviewable_observer: ember['default'].observer('reviewable', function() {
      return this.get_reviewable_from_phase_settings().then((function(_this) {
        return function(reviewable) {
          var current_reviewable;
          current_reviewable = _this.get('reviewable');
          if (reviewable === current_reviewable) {
            return;
          }
          if (ember['default'].isPresent(reviewable)) {
            return _this.set_reviewable_phase_settings();
          }
        };
      })(this));
    }),
    init: function() {
      this._super();
      this.totem_scope = totem_scope['default'];
      this.tc = tc['default'];
      this.totem_messages = tm['default'];
      return this.is_debug = true;
    },
    submit: function() {
      return this.validate().then((function(_this) {
        return function(valid) {
          var query, review_set;
          if (!valid) {
            return;
          }
          review_set = _this.get('review_set');
          _this.debug("Submitting review set: ", review_set);
          query = {
            id: review_set.get('id'),
            action: 'submit',
            verb: 'PUT'
          };
          _this.totem_messages.show_loading_outlet();
          return _this.tc.query(ta['default'].to_p('tbl:review_set'), query, {
            single: true
          }).then(function() {
            _this.totem_messages.hide_loading_outlet();
            _this.totem_messages.api_success({
              source: _this,
              model: review_set,
              action: 'submit',
              i18n_path: ta['default'].to_o('tbl:review_set', 'submit')
            });
            return _this.get('casespace').transition_to_current_assignment();
          });
        };
      })(this));
    },
    debug: function() {
      var args, message;
      message = arguments[0], args = 2 <= arguments.length ? slice.call(arguments, 1) : [];
      if (this.is_debug) {
        return console.log("[tbl:evaluation_manager] " + message, args);
      }
    },
    validations: {
      points_different: {
        numericality: {
          'if': 'is_balance_and_points_different',
          greaterThanOrEqualTo: 2,
          messages: {
            greaterThanOrEqualTo: 'Not all evaluations can have the same score.'
          }
        }
      },
      points_remaining: {
        numericality: {
          'if': 'is_balance',
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 0,
          messages: {
            greaterThanOrEqualTo: 'You cannot have negative points.',
            lessThanOrEqualTo: 'You must spend all of your points.'
          }
        }
      },
      valid_qual_sections: {
        presence: {
          message: 'You must have inputs for all qualitative sections.'
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});