define('thinkspace-readiness-assurance/mixins/am/tracker', ['exports', 'ember', 'totem/ns', 'totem/ajax'], function (exports, ember, ns, ajax) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    send_tracker: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = _this.get_auth_query(_this.get_tracker_url('tracker'), {});
          return ajax['default'].object(query).then(function() {
            return resolve();
          });
        };
      })(this));
    },
    get_tracker_url: function(action) {
      return ns['default'].to_p('readiness_assurance', 'tracker', action);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});