define('totem/error', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var TotemError, ___DefaultExportObject___, totem_error;

  TotemError = (function() {
    TotemError.prototype.toString = function() {
      return 'totem_error';
    };

    function TotemError(options) {
      if (options == null) {
        options = {};
      }
      this.is_totem_error = true;
      this.is_handled = false;
      this.is_api_error = options.api || false;
      this.stack = options.stack;
      this.message = options.message;
    }

    TotemError.prototype["throw"] = function(source, message, options) {
      var ember_error;
      if (source == null) {
        source = null;
      }
      if (message == null) {
        message = null;
      }
      if (options == null) {
        options = null;
      }
      if (typeof source === 'string') {
        options = message;
        message = source;
        source = null;
      }
      if (message == null) {
        message = '';
      }
      if (source && source.toString) {
        message = (source.toString()) + ": " + message;
      }
      if (options) {
        message += "[" + (JSON.stringify(options)) + "] ";
      }
      if (!(options && typeof options === 'object')) {
        options = {};
      }
      ember_error = new ember['default'].Error();
      options.message = message;
      options.stack = ember_error.stack;
      if (ember['default'].onerror) {
        throw new TotemError(options);
      } else {
        if (options.stack) {
          message += "\n" + options.stack;
        }
        throw new Error(message);
      }
    };

    TotemError["throw"] = function(source, message, options) {
      if (source == null) {
        source = null;
      }
      if (message == null) {
        message = null;
      }
      if (options == null) {
        options = null;
      }
      return totem_error["throw"](source, message, options);
    };

    return TotemError;

  })();

  totem_error = new TotemError;

  ___DefaultExportObject___ = TotemError;

  exports['default'] = ___DefaultExportObject___;;

});