define('totem-template-manager/services/template_value_object', ['exports', 'ember', 'totem-template-manager/tvo/helper', 'totem-template-manager/tvo/value', 'totem-template-manager/tvo/hash', 'totem-template-manager/tvo/status', 'totem-template-manager/tvo/template', 'totem-template-manager/tvo/section'], function (exports, ember, tvo_helper, tvo_value, tvo_hash, tvo_status, tvo_template, tvo_section) {

  'use strict';

  var ___DefaultExportObject___,
    hasProp = {}.hasOwnProperty;

  ___DefaultExportObject___ = ember['default'].Service.extend({
    regenerate_view: null,
    regenerate: function() {
      return this.notifyPropertyChange('regenerate_view');
    },
    clear: function() {
      return this._clear();
    },
    get_path_value: function(path) {
      return this.get(path);
    },
    set_path_value: function(path, value) {
      return this.set(path, value);
    },
    guid_for: function(source) {
      return ember['default'].guidFor(source) || 'bad_guid';
    },
    generate_guid: function() {
      return ember['default'].generateGuid();
    },
    tag_attribute_hash: function($tag) {
      var attr, attrs, hash, i, len;
      hash = {};
      attrs = $tag.prop('attributes') || [];
      for (i = 0, len = attrs.length; i < len; i++) {
        attr = attrs[i];
        hash[attr.name] = attr.nodeValue;
      }
      return hash;
    },
    tag_kind: function($tag) {
      return $tag.prop('tagName').toLowerCase();
    },
    tag_name: function($tag) {
      return $tag.attr('name');
    },
    tag_title: function($tag) {
      return $tag.attr('title');
    },
    tag_type: function($tag) {
      return $tag.attr('type');
    },
    tag_class: function($tag) {
      return $tag.attr('class') || '';
    },
    component_bind_properties: function(path, hash) {
      var bind, i, key, keys, len;
      keys = [];
      for (key in hash) {
        if (!hasProp.call(hash, key)) continue;
        keys.push(key);
      }
      bind = '';
      if (ember['default'].isBlank(keys)) {
        return bind;
      }
      for (i = 0, len = keys.length; i < len; i++) {
        key = keys[i];
        bind += " " + key + "=tvo." + path + "." + key;
      }
      return bind;
    },
    add_property: function(options) {
      return this._add_property(options);
    },
    stringify: function(hash) {
      return JSON.stringify(hash);
    },
    attribute_value_array: function(value) {
      return value && value.split(' ').map(function(part) {
        return part.trim();
      });
    },
    is_object_valid: function(object) {
      return object && (!object.get('isDestroyed') && !object.get('isDestroying'));
    },
    tvo_properties: [
      {
        property: 'helper',
        "class": tvo_helper['default'],
        create_once: true
      }, {
        property: 'value',
        "class": tvo_value['default']
      }, {
        property: 'hash',
        "class": tvo_hash['default']
      }, {
        property: 'status',
        "class": tvo_status['default']
      }, {
        property: 'template',
        "class": tvo_template['default']
      }, {
        property: 'section',
        "class": tvo_section['default']
      }
    ],
    _get_tvo_properties: function() {
      return this.get('tvo_properties');
    },
    _reset_object: function(options) {
      var prop;
      if (options == null) {
        options = {};
      }
      prop = options.property;
      if (options.create_once === true) {
        if (!this.get(prop)) {
          return this._create_object(options);
        }
      } else {
        this._destroy_object(prop);
        return this._create_object(options);
      }
    },
    _destroy_object: function(prop) {
      var obj;
      obj = this.get(prop);
      if (obj) {
        return obj.destroy();
      }
    },
    _create_object: function(options) {
      var klass, prop;
      prop = options.property;
      klass = options["class"];
      return this.set(prop, klass.create({
        tvo: this,
        tvo_property: prop
      }));
    },
    _clear: function() {
      var i, len, obj, ref;
      ref = this._get_tvo_properties() || [];
      for (i = 0, len = ref.length; i < len; i++) {
        obj = ref[i];
        this._reset_object(obj);
      }
      return this._destroy_added_properties();
    },
    added_properties: null,
    _get_added_properties: function() {
      return this.get('added_properties');
    },
    _add_property: function(options) {
      var added;
      added = ember['default'].makeArray(this._get_added_properties());
      added.push(options.property);
      this.set('added_properties', added);
      return this._create_object(options);
    },
    _destroy_added_properties: function() {
      var i, len, prop, ref;
      ref = this._get_added_properties() || [];
      for (i = 0, len = ref.length; i < len; i++) {
        prop = ref[i];
        this._destroy_object(prop);
        delete this[prop];
      }
      return this.set('added_properties', null);
    },
    toString: function() {
      return 'TemplateValueObject';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});