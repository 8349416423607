define('totem/mixins', ['exports', 'ember', 'totem/config/require_modules'], function (exports, ember, reqm) {

  'use strict';

  var TotemMixins, ___DefaultExportObject___;

  TotemMixins = (function() {
    function TotemMixins() {
      this.target_cache = {};
      this.mixin_cache = {};
      this.target_mixins = {};
      this.show_warnings = true;
    }

    TotemMixins.prototype.add = function(target_paths, mixin_paths) {
      return this.add_mixins(target_paths, mixin_paths);
    };

    TotemMixins.prototype.add_to = function(mixin_paths, target_paths) {
      return this.add_mixins(target_paths, mixin_paths);
    };

    TotemMixins.prototype.turn_warnings_on = function() {
      return this.show_warnings = true;
    };

    TotemMixins.prototype.turn_warnings_off = function() {
      return this.show_warnings = false;
    };

    TotemMixins.prototype.add_mixins = function(target_paths, mixin_paths) {
      var i, len, mixin_path, ref, results, target_path;
      if (ember['default'].isBlank(target_paths)) {
        this.error("must pass 'target paths' to add mixins [" + (this.stringify(target_paths)) + "].");
      }
      if (ember['default'].isBlank(mixin_paths)) {
        this.error("must pass 'mixin paths' to add mixins [" + (this.stringify(mixin_paths)) + "].");
      }
      ref = ember['default'].makeArray(target_paths);
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        target_path = ref[i];
        results.push((function() {
          var j, len1, ref1, results1;
          ref1 = ember['default'].makeArray(mixin_paths);
          results1 = [];
          for (j = 0, len1 = ref1.length; j < len1; j++) {
            mixin_path = ref1[j];
            results1.push(this.add_mixin(target_path, mixin_path));
          }
          return results1;
        }).call(this));
      }
      return results;
    };

    TotemMixins.prototype.add_mixin = function(target_path, mixin_path) {
      var mixin, target;
      if (!this.valid_path(target_path)) {
        this.error("'target_path' is blank or not a string [" + (this.stringify(target_path)) + "].");
      }
      if (!this.valid_path(mixin_path)) {
        this.error("'mixin_path' is blank or not a string [" + (this.stringify(mixin_path)) + "].");
      }
      if (this.target_has_mixin(target_path, mixin_path)) {
        return;
      }
      target = this.require_target(target_path);
      if (!this.valid_target(target)) {
        this.error("target at path '" + path + "' is invalid -- a target must be a class or mixin.'");
      }
      mixin = this.require_mixin(mixin_path);
      if (!this.is_mixin(mixin)) {
        this.error("mixin at path '" + mixin_path + "' is not a mixin.");
      }
      return target.reopen(mixin);
    };

    TotemMixins.prototype.target_has_mixin = function(target_path, mixin_path) {
      var base, mixins;
      mixins = ((base = this.target_mixins)[target_path] != null ? base[target_path] : base[target_path] = []);
      if (mixins.contains(mixin_path)) {
        this.warn("'" + target_path + "' has a duplicate mixin request for '" + mixin_path + "'.  Ignoring.");
        return true;
      } else {
        mixins.push(mixin_path);
        return false;
      }
    };

    TotemMixins.prototype.require_target = function(path) {
      var target;
      target = this.target_cache[path];
      if (target) {
        return target;
      }
      target = this.require_module(path);
      if (!target) {
        this.error("target module at path '" + path + "' not found.");
      }
      this.target_cache[path] = target;
      return target;
    };

    TotemMixins.prototype.require_mixin = function(path) {
      var mixin;
      mixin = this.mixin_cache[path];
      if (mixin) {
        return mixin;
      }
      mixin = this.require_module(path);
      if (!mixin) {
        this.error("mixin module at path '" + path + "' not found.");
      }
      this.mixin_cache[path] = mixin;
      return mixin;
    };

    TotemMixins.prototype.require_module = function(path) {
      var app_path, mod;
      mod = reqm['default'].require_module(path);
      if (mod) {
        return mod;
      }
      app_path = reqm['default'].app_path(path);
      return reqm['default'].require_module(app_path);
    };

    TotemMixins.prototype.valid_path = function(obj) {
      return obj && typeof obj === 'string';
    };

    TotemMixins.prototype.valid_target = function(obj) {
      return obj && (obj.isClass || this.is_mixin(obj));
    };

    TotemMixins.prototype.is_mixin = function(obj) {
      return obj && obj instanceof ember['default'].Mixin;
    };

    TotemMixins.prototype.warn = function(message) {
      return this.show_warnings && reqm['default'].warn(this, message);
    };

    TotemMixins.prototype.error = function(message) {
      return reqm['default'].error(this, message);
    };

    TotemMixins.prototype.stringify = function(obj) {
      return reqm['default'].stringify(obj);
    };

    TotemMixins.prototype.toString = function() {
      return 'TotemMixins';
    };

    return TotemMixins;

  })();

  ___DefaultExportObject___ = new TotemMixins;

  exports['default'] = ___DefaultExportObject___;;

});