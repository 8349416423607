define('totem-messages/mixins/services/messages/initialize', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    ttz: ember['default'].inject.service(),
    message_model_type: null,
    message_load_url: null,
    messages_queue: null,
    init: function() {
      this._super();
      this.ttz = this.get('ttz');
      return this.reset();
    },
    reset: function() {
      this.messages_queue = [];
      return this.reset_filters();
    },
    reset_filters: function(map) {
      var key, ref, results, value;
      ref = this;
      results = [];
      for (key in ref) {
        value = ref[key];
        if (key.match(/^_msg_/)) {
          if (value.content && value.content.destroy) {
            value.content.destroy();
          }
          if (value.destroy) {
            value.destroy();
          }
          results.push(delete this[key]);
        } else {
          results.push(void 0);
        }
      }
      return results;
    },
    toString: function() {
      return 'TotemMessagesService';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});