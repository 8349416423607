define('totem/cache', ['exports', 'ember', 'ember-data', 'totem/ajax', 'totem/cache_image', 'totem-application/pagination/array', 'totem-application/pagination/object'], function (exports, ember, ds, ajax, cache_image, pagination_array, pagination_object) {

  'use strict';

  var ___DefaultExportObject___, totem_cache_module,
    slice = [].slice;

  totem_cache_module = ember['default'].Object.extend({
    cache: null,
    container: null,
    init: function() {
      this.set('cache', ember['default'].Object.create());
      this.ajax = ajax['default'];
      return this.image = cache_image['default'].create({
        tc: this
      });
    },
    all: function(type, options) {
      if (options == null) {
        options = {};
      }
      this.deprecation("Using tc's `all` when you should be using `peek_all`.");
      return this.peek_all(type);
    },
    find: function(type, id, options) {
      if (id == null) {
        id = null;
      }
      if (options == null) {
        options = {};
      }
      if (ember['default'].isPresent(id)) {
        this.deprecation("Using tc's `find` when you should be using `find_record`.");
        return this.find_record(type, id, options);
      } else {
        this.deprecation("Using tc's `find` when you should be using `find_all`.");
        return this.find_all(type, options);
      }
    },
    find_record: function(type, id, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve(_this.get_store().find(type, id, options));
        };
      })(this), (function(_this) {
        return function(error) {
          _this.warn("Error in `find_record` when querying for [" + type + "] id: [" + id + "] with: ", options);
          return reject(error);
        };
      })(this));
    },
    find_all: function(type, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (_this.cache_contains(type) && !options.reload) {
            return resolve(_this.get_from_cache(type));
          } else {
            return _this.get_store().find(type, options).then(function(results) {
              _this.set_cache(type, results);
              return resolve(results);
            });
          }
        };
      })(this), (function(_this) {
        return function(error) {
          _this.warn("Error in `find_all` when querying for [" + type + "] with: ", options);
          return reject(error);
        };
      })(this));
    },
    peek_record: function(type, id) {
      return this.get_store().getById(type, id);
    },
    peek_all: function(type) {
      return this.store.all(type);
    },
    query_record: function(type, query) {
      return console.error("[tc] Has not defined `query_record` yet.");
    },
    query: function(type, query, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var action, ajax_options, id, verb;
          action = query.action || '';
          verb = query.verb || 'GET';
          id = query.id || null;
          delete query.action;
          delete query.verb;
          delete query.id;
          ajax_options = {
            model: type,
            data: query,
            action: action,
            verb: verb,
            id: id
          };
          if (ember['default'].isPresent(options.payload_type)) {
            type = options.payload_type;
          }
          return ajax['default'].object(ajax_options).then(function(payload) {
            var array, hash, json, key, records, value;
            if (payload === void 0 || ember['default'].isEmpty(ember['default'].keys(payload))) {
              return resolve([]);
            }
            if (ember['default'].isPresent(payload.links)) {
              array = _this.get_paginated_array(type, payload);
              return resolve(array);
            } else {
              options.skip_ns = true;
              if (options.multitype) {
                hash = {};
                for (key in payload) {
                  value = payload[key];
                  json = {};
                  json[key] = value;
                  type = ember['default'].Inflector.inflector.singularize(key);
                  hash[key] = ajax['default'].normalize_and_push_payload(type, json, options);
                }
                resolve(hash);
              } else {
                records = ajax['default'].normalize_and_push_payload(type, payload, options);
              }
              return resolve(records);
            }
          }, function(error) {
            _this.warn("ajax.object failed for query of: ", query);
            return reject(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          _this.warn("Could not process query of: ", query);
          return reject(error);
        };
      })(this));
    },
    get_cache: function() {
      return this.get('cache');
    },
    set_cache: function(key, records) {
      return this.get_cache().set(key, records);
    },
    get_from_cache: function(key) {
      return this.get_cache().get(key);
    },
    cache_contains: function(key) {
      return ember['default'].isPresent(this.get_from_cache(key));
    },
    add_pagination_to_query: function(query, number, count) {
      if (count == null) {
        count = 15;
      }
      query.page = this.get_pagination_options(number, count);
      return query;
    },
    get_default_pagination_query: function() {
      var query;
      query = {};
      query.page = this.get_pagination_options(1);
      return query;
    },
    get_pagination_options: function(number, count) {
      if (count == null) {
        count = 15;
      }
      return {
        number: number,
        count: count
      };
    },
    get_paginated_array: function(type, payload) {
      var array;
      array = pagination_array['default'].create();
      array.process_pagination_payload(payload, type);
      return array;
    },
    add_filter_to_query: function(query, filter) {
      query.filter = JSON.stringify(filter);
      return query;
    },
    get_filter_array: function(method, values) {
      return [
        {
          method: method,
          values: values
        }
      ];
    },
    warn: function() {
      var args, message;
      message = arguments[0], args = 2 <= arguments.length ? slice.call(arguments, 1) : [];
      return console.warn("[tc] WARN: " + message, args);
    },
    get_store: function() {
      return this.get_container().lookup('store:main');
    },
    get_container: function() {
      return this.get('container');
    },
    set_container: function(container) {
      return this.set('container', container);
    },
    deprecation: function(message) {
      return console.warn("[tc] DEPRECATION: " + message);
    }
  });

  ___DefaultExportObject___ = totem_cache_module.create();

  exports['default'] = ___DefaultExportObject___;;

});