define('thinkspace-indented-list/mixins/item/selected', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    select_item: function(item, options) {
      if (options == null) {
        options = {};
      }
      return ember['default'].run.schedule('afterRender', this, (function(_this) {
        return function() {
          var $el, comp;
          comp = _this.get_item_component(item);
          $el = _this.get_item_element(item);
          comp.set('is_selected', true);
          if (!options.skip_focus) {
            return _this.focus_element($el);
          }
        };
      })(this));
    },
    focus_element: function($el) {
      return $el.focus();
    },
    insert_before: function(item) {
      return this.process_insert_before_after(item, 0);
    },
    insert_after: function(item) {
      return this.process_insert_before_after(item, 1);
    },
    process_insert_before_after: function(item, n) {
      var bottom, index, insert_index, new_item;
      if (this.readonly) {
        return;
      }
      new_item = {
        pos_x: item.pos_x
      };
      index = this.value_items.indexOf(item);
      bottom = this.get_value_items_bottom_index();
      insert_index = index + n;
      if (index < 0) {
        insert_index = 0;
      }
      if (index > bottom) {
        insert_index = this.get_value_items_bottom_index();
      }
      this.value_items.insertAt(insert_index, new_item);
      this.reset_has_children(new_item);
      this.select_item(new_item);
      return this.save_response();
    },
    move_left: function(item) {
      return this.process_move_left_right(item, -1);
    },
    move_right: function(item) {
      return this.process_move_left_right(item, +1);
    },
    process_move_left_right: function(item, n, min, max) {
      var items, pos_x;
      if (min == null) {
        min = 0;
      }
      if (max == null) {
        max = this.max_indent;
      }
      if (this.readonly) {
        return;
      }
      if (ember['default'].isBlank(item)) {
        return;
      }
      pos_x = item.pos_x;
      if (ember['default'].isBlank(pos_x)) {
        return;
      }
      if (n < 0 && pos_x <= min) {
        return;
      }
      if (n > 0 && pos_x >= max) {
        return;
      }
      items = this.get_item_change_position_children(item);
      items.push(item);
      this.change_item_pos_x(items, n);
      this.reset_has_children(item);
      return this.save_response();
    },
    move_up: function(item) {
      return this.process_move_up(item);
    },
    move_down: function(item) {
      return this.process_move_down(item);
    },
    process_move_up: function(item) {
      var $el, $sibling, bottom, index;
      if (this.readonly) {
        return;
      }
      if (ember['default'].isBlank(item)) {
        return;
      }
      $el = this.get_item_element(item);
      if (ember['default'].isBlank($el)) {
        return;
      }
      $sibling = this.get_visible_prev($el);
      index = this.get_change_item_index_from_sibling_element($sibling);
      bottom = this.get_value_items_bottom_index();
      if (index === bottom) {
        return;
      }
      return this.change_item_position(item, index).then((function(_this) {
        return function() {
          _this.reset_has_children(item);
          _this.reset_prev_parent_item_component_has_children(item);
          _this.reset_next_item_component_has_children(item);
          return _this.save_response();
        };
      })(this));
    },
    process_move_down: function(item) {
      var $el, $next, $next_visible, $sibling, bottom, index;
      if (this.readonly) {
        return;
      }
      if (ember['default'].isBlank(item)) {
        return;
      }
      $el = this.get_item_element(item);
      if (ember['default'].isBlank($el)) {
        return;
      }
      $next = this.get_visible_next($el);
      $sibling = this.get_visible_next($next);
      index = this.get_change_item_index_from_sibling_element($sibling);
      bottom = this.get_value_items_bottom_index();
      $next_visible = this.get_visible_next($el);
      if (ember['default'].isBlank($next_visible) && index === bottom) {
        return;
      }
      return this.change_item_position(item, index).then((function(_this) {
        return function() {
          _this.reset_has_children(item);
          _this.reset_prev_parent_item_component_has_children(item);
          _this.reset_next_item_component_has_children(item);
          return _this.save_response();
        };
      })(this));
    },
    duplicate_before: function(item) {
      return this.process_duplicate_before_after(item);
    },
    duplicate_after: function(item) {
      return this.process_duplicate_before_after(item, false);
    },
    process_duplicate_before_after: function(item, before) {
      var $el, $sibling, dup_index, dup_item, options;
      if (before == null) {
        before = true;
      }
      if (this.readonly) {
        return;
      }
      if (ember['default'].isBlank(item)) {
        return;
      }
      $el = this.get_item_element(item);
      if (ember['default'].isBlank($el)) {
        return;
      }
      $sibling = before ? $el : this.get_visible_next($el);
      dup_index = this.get_change_item_index_from_sibling_element($sibling);
      dup_item = ember['default'].merge({}, item);
      options = this.get_item_duplicate_options(item, dup_item);
      return this.change_item_position_items(dup_item, dup_index, options).then((function(_this) {
        return function() {
          _this.reset_has_children(dup_item);
          _this.select_item(dup_item);
          return _this.save_response();
        };
      })(this));
    },
    get_item_duplicate_options: function(item, dup_item) {
      var dup_children, map, options;
      if (this.readonly) {
        return;
      }
      options = {};
      map = this.new_options_item_property_map(options);
      map.set(dup_item, this.get_item_component_properties(item));
      dup_children = [];
      this.get_item_change_position_children(item).forEach((function(_this) {
        return function(child_item) {
          var dup_child;
          dup_child = ember['default'].merge({}, child_item);
          dup_children.push(dup_child);
          return map.set(dup_child, _this.get_item_component_properties(child_item));
        };
      })(this));
      options.children = dup_children;
      return options;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});