define('thinkspace-indented-list/mixins/initialize', ['exports', 'ember', 'totem/scope'], function (exports, ember, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    init_manager: function(options) {
      if (options == null) {
        options = {};
      }
      this.init_maps();
      this.init_readonly(options);
      this.init_manager_properties(options);
      if (!this.readonly) {
        this.init_drake(options);
      }
      return this.init_value_items();
    },
    init_maps: function() {
      this.item_to_component = ember['default'].Map.create();
      this.el_id_to_component = ember['default'].Map.create();
      return this.new_source_containers = ember['default'].Map.create();
    },
    init_readonly: function(options) {
      this.readonly = options.readonly === true;
      return this.updateable = !this.readonly;
    },
    init_manager_properties: function(options) {
      this.store = totem_scope['default'].get_store();
      this.init_model(options);
      this.init_containers(options);
      this.init_draggable(options);
      this.set_indent_px(options.indent_px || this.default_indent_px());
      this.set_max_indent(options.max_indent || this.default_max_indent());
      this.set_zoom_level(options.zoom_level || this.default_zoom_level());
      this.set_confirm_remove(!(options.confirm_remove === false));
      this.set_send_response_to_server(!(options.save_response === false));
      this.queued_saves = [];
      return this.save_error = false;
    },
    init_model: function(options) {
      var response;
      response = options.model;
      if (ember['default'].isBlank(response)) {
        this.error("Required 'options.model' is blank.");
      }
      return this.response = response;
    },
    init_containers: function(options) {
      return this.list_container = null;
    },
    init_draggable: function(options) {
      var selectors;
      this.draggable_classes = ember['default'].makeArray(options.draggable || this.default_draggable_class());
      this.draggable_class = this.draggable_classes.join(' ');
      selectors = this.draggable_classes.map(function(class_name) {
        return "." + class_name;
      });
      this.draggable_selector = selectors.join(', ');
      return options.draggable_classes = this.draggable_classes;
    },
    init_drake: function(options) {
      options.revertOnSpill = true;
      options.direction = 'veritcal';
      options.moves = this.moves;
      options.copy = this.copy;
      options.accepts = this.accepts;
      options.response_manager = this;
      this.drake = dragula(options);
      this.drake.response_manager = this;
      return this.init_drake_events(options);
    },
    init_drake_events: function(options) {
      this.drake.on('drop', this.drop);
      this.drake.on('cloned', this.cloned);
      this.drake.on('cancel', this.cancel);
      this.drake.on('shadow', this.shadow);
      this.drake.on('drag', this.drag);
      return this.drake.on('dragend', this.dragend);
    },
    init_value_items: function(items) {
      return this.value_items = (this.response.get('value.items') || []).sortBy('pos_y');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});