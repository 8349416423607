define('thinkspace-casespace/components/dock_base', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    casespace: ember['default'].inject.service(),
    phase_manager: ember['default'].inject.service(),
    current_space: ember['default'].computed.reads('casespace.current_space'),
    current_assignment: ember['default'].computed.reads('casespace.current_assignment'),
    current_phase: ember['default'].computed.reads('casespace.current_phase'),
    addon_ownerable: ember['default'].computed.reads('casespace.active_addon_ownerable'),
    has_phase_view: ember['default'].computed.and('current_phase', 'phase_manager.has_phase_view'),
    addon_name: null,
    addon_display_name: null,
    toggle_width_text: null,
    addon_visible: false,
    addon_maximized: false,
    t_toggle_width: ns['default'].to_t('dock', 'shared', 'toggle_width'),
    is_current_html: '<i class="fa fa-check-circle">',
    addon_visible_on: function() {
      return this.set('addon_visible', true);
    },
    addon_visible_off: function() {
      return this.set('addon_visible', false);
    },
    actions: {
      toggle_addon_visible: function() {
        if (this.toggleProperty('addon_visible')) {
          if (!this.get('addon_maximized')) {
            this.send('toggle_width');
          }
          return this.set_active_addon();
        } else {
          return this.send('exit');
        }
      },
      toggle_width: function() {
        return this.set('toggle_width_text', (this.toggleProperty('addon_maximized') && 'Hide') || ("Show " + (this.get('addon_display_name'))));
      },
      exit: function() {
        this.exit_addon_common();
        this.set_ownerable();
        if (this.get('current_phase')) {
          return this.generate_phase_view();
        }
      }
    },
    exit_addon: function() {},
    exit_addon_common: function() {
      this.mock_phase_states_off();
      this.addon_visible_off();
      return this.reset_active_addon();
    },
    set_active_addon: function(component) {
      if (component == null) {
        component = this;
      }
      return this.get('casespace').set_active_addon(component);
    },
    set_active_addon_ownerable: function(ownerable) {
      return this.get('casespace').set_active_addon_ownerable(ownerable);
    },
    reset_active_addon: function() {
      return this.get('casespace').reset_active_addon();
    },
    mock_phase_states_on: function() {
      return this.get('phase_manager').mock_phase_states_on();
    },
    mock_phase_states_off: function() {
      return this.get('phase_manager').mock_phase_states_off();
    },
    set_ownerable: function(ownerable) {
      if (ownerable == null) {
        ownerable = null;
      }
      return this.get('phase_manager').set_ownerable(ownerable);
    },
    generate_phase_view: function() {
      return this.get('phase_manager').generate_view_with_ownerable();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});