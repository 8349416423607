define('thinkspace-html/components/base', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    classNames: ['html_content'],
    tvo: ember['default'].inject.service(),
    layout: ember['default'].computed(function() {
      return this.get('template');
    }),
    template: ember['default'].computed(function() {
      return ember['default'].Handlebars.compile(this.get('html_content'));
    }),
    totem_data_config: {
      ability: {
        ajax_source: true
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});