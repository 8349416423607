define('thinkspace-readiness-assurance/mixins/rm/rooms', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    join_id_room_event: function(source, event, id, callback) {
      var room, room_event;
      if (callback == null) {
        callback = null;
      }
      room = this.room;
      room_event = this.get_room_event_with_id(event, id);
      if (!callback) {
        callback = "handle_" + event;
      }
      return this.pubsub.join({
        room: room,
        source: source,
        callback: callback,
        room_event: room_event
      });
    },
    join_room_event: function(source, event, callback) {
      var room, room_event;
      if (callback == null) {
        callback = null;
      }
      room = this.room;
      room_event = event;
      if (!callback) {
        callback = "handle_" + event;
      }
      return this.pubsub.join({
        room: room,
        source: source,
        callback: callback,
        room_event: room_event
      });
    },
    broadcast_id_room_event: function(event, id, value) {
      var data, room_event;
      if (value == null) {
        value = null;
      }
      room_event = this.get_room_event_with_id(event, id);
      data = {
        room_event: room_event
      };
      if (value) {
        data.value = value;
      }
      return this.pubsub.broadcast_to_room(this.room, data);
    },
    broadcast_to_room_event: function(event, value) {
      var data, room_event;
      if (value == null) {
        value = null;
      }
      room_event = this.get_room_event_with_id(event);
      data = {
        room_event: room_event
      };
      if (value) {
        data.value = value;
      }
      return this.pubsub.broadcast_to_room(this.room, data);
    },
    get_room_event_with_id: function(event, id) {
      return event + "/" + id;
    },
    join_room: function(options) {
      if (options == null) {
        options = {};
      }
      if (!options.room) {
        options.room = this.room;
      }
      return this.pubsub.join(options);
    },
    leave_room: function(room_type) {
      var options;
      if (room_type == null) {
        room_type = null;
      }
      options = {};
      if (!options.room) {
        options.room = this.room;
      }
      if (room_type) {
        options.room_type = room_type;
      }
      return this.pubsub.leave(options);
    },
    message_to_room_members: function(event, options) {
      var rooms;
      if (options == null) {
        options = {};
      }
      rooms = options.room || options.rooms || this.room;
      delete options.room;
      delete options.rooms;
      return this.pubsub.message_to_rooms_members(event, rooms, options);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});