define('ember-moment/computed', ['exports', 'ember-moment/computeds/moment', 'ember-moment/computeds/ago', 'ember-moment/computeds/duration'], function (exports, moment, ago, duration) {

  'use strict';



  exports.moment = moment['default'];
  exports.ago = ago['default'];
  exports.duration = duration['default'];

});