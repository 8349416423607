define('totem/mixins/validations', ['exports', 'ember', 'ember-validations'], function (exports, ember, ember_validations) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember_validations['default'].Mixin.reopen({
    is_validation_mixin_included: true,
    is_valid: ember['default'].computed.reads('isValid'),
    inline_validator: function() {
      return ember_validations['default'].validator;
    },
    set_validation_rules: function(rules) {
      this.set_validation_rules_only(rules);
      this.setup_validators();
      return this.validate();
    },
    set_validation_rules_only: function(rules) {
      return this.set('validations', rules);
    },
    setup_validators: function() {
      this.set('dependentValidationKeys', {});
      this.set('validators', ember['default'].A());
      this.buildValidators();
      return this.validators.forEach((function(_this) {
        return function(validator) {
          return validator.addObserver('errors.[]', _this, function(sender) {
            var errors;
            errors = ember['default'].A();
            _this.validators.forEach(function(validator) {
              if (validator.property === sender.property) {
                return errors.addObjects(validator.errors);
              }
            });
            return ember['default'].set(_this, 'errors.' + sender.property, errors);
          });
        };
      })(this));
    },
    camelize_validation_keys: function(rules) {
      var camel_key, key, value;
      for (key in rules) {
        value = rules[key];
        camel_key = key.camelize();
        if (camel_key !== key) {
          delete rules[key];
          rules[camel_key] = value;
        }
        rules[camel_key] = value;
        if (typeof value === 'object') {
          this.camelize_validation_keys(value);
        }
      }
      return rules;
    },
    observe_validation_model_errors: null,
    validation_model_errors: null,
    add_validation_model_errors: function(errors) {
      this.set('validation_model_errors', ember['default'].merge({}, errors));
      return this.notifyPropertyChange('observe_validation_model_errors');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});