define('thinkspace-peer-assessment/components/peer_assessment/builder/assessment/quant/item', ['exports', 'ember', 'totem/ns', 'totem/util'], function (exports, ember, ns, util) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    model: null,
    id: null,
    label: null,
    type: null,
    settings: null,
    assessment: null,
    slider_step: 1,
    slider_value: 3,
    is_selected: false,
    points_descriptive_enabled: ember['default'].computed('assessment.points_descriptive_enabled', 'model.settings.labels.scale.min', 'model.settings.labels.scale.max', function() {
      return (this.get('points_descriptive_low') && this.get('points_descriptive_high')) || this.get('assessment.points_descriptive_enabled');
    }),
    points_descriptive_low: ember['default'].computed('assessment.points_descriptive_low', 'model.settings.labels.scale.min', function() {
      return this.get_model_property('settings.labels.scale.min') || this.get('assessment.points_descriptive_low');
    }),
    points_descriptive_high: ember['default'].computed('assessment.points_descriptive_high', 'model.settings.labels.scale.max', function() {
      return this.get_model_property('settings.labels.scale.max') || this.get('assessment.points_descriptive_high');
    }),
    points_min: ember['default'].computed('assessment.points_min', 'settings.points.min', function() {
      var a_points, s_points;
      s_points = this.get('settings.points.min');
      if (ember['default'].isPresent(s_points)) {
        return s_points;
      }
      a_points = this.get('assessment.points_min');
      if (ember['default'].isPresent(a_points)) {
        return a_points;
      } else {
        return 0;
      }
    }),
    points_max: ember['default'].computed('assessment.points_max', 'settings.points.max', function() {
      var a_points, s_points;
      s_points = this.get('settings.points.max');
      if (ember['default'].isPresent(s_points)) {
        return s_points;
      }
      a_points = this.get('assessment.points_max');
      if (ember['default'].isPresent(a_points)) {
        return a_points;
      } else {
        return 0;
      }
    }),
    has_comments: ember['default'].computed('settings.comments.enabled', function() {
      return this.get('settings.comments.enabled');
    }),
    init: function() {
      this._super();
      return this.map_model_properties();
    },
    map_model_properties: function() {
      var model;
      model = this.get('model');
      this.set('id', model.id);
      this.set('label', model.label);
      this.set('type', model.type);
      return this.set('settings', model.settings);
    },
    get_model_property: function(path) {
      var model;
      model = this.get('model');
      model = ember['default'].Object.create(model);
      return model.get(path);
    },
    set_value: function(property, value) {
      var fn;
      fn = "set_" + property;
      if (this[fn] == null) {
        return;
      }
      this[fn](value);
      return this.map_model_properties();
    },
    set_points_min: function(points) {
      return util['default'].set_path_value(this, 'model.settings.points.min', parseInt(points));
    },
    set_points_max: function(points) {
      return util['default'].set_path_value(this, 'model.settings.points.max', parseInt(points));
    },
    set_scale_label_min: function(label) {
      return util['default'].set_path_value(this, 'model.settings.labels.scale.min', label);
    },
    set_scale_label_max: function(label) {
      return util['default'].set_path_value(this, 'model.settings.labels.scale.max', label);
    },
    set_has_comments: function(has_comments) {
      return util['default'].set_path_value(this, 'model.settings.comments.enabled', has_comments);
    },
    set_label: function(label) {
      var model;
      model = this.get('model');
      return model.label = label;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});