define('thinkspace-common/mixins/avatar', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    avatar_url: ta['default'].attr('string'),
    avatar_title: ta['default'].attr('string'),
    avatar_updated_at: ta['default'].attr('date'),
    has_avatar: ember['default'].computed.notEmpty('avatar_title')
  });

  exports['default'] = ___DefaultExportObject___;;

});