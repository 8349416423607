define('thinkspace-lab/vet_med_lab', ['exports', 'ember', 'totem/ns', 'totem/util'], function (exports, ember, ns, util) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    init: function() {
      this._super();
      this.set('user_inputs', {});
      this.set('user_input_map', {});
      return this.set('lab_results', []);
    },
    register_lab_observation: function(result) {
      return this.get_lab_observations().pushObject(result);
    },
    register_lab_result: function(result) {
      return this.get_lab_results().pushObject(result);
    },
    get_value: function(key, path) {
      return this.get_category_observation_value(key, path) || this.get_category_value(path);
    },
    get_category_observation_value: function(key, path) {
      return this.get("category.value.observations." + key + "." + path);
    },
    get_category_value: function(path) {
      return this.get("category.value." + path);
    },
    get_lab_observations: function() {
      return this.get('lab_observations');
    },
    get_lab_results: function() {
      return this.get('lab_results');
    },
    get_results: function() {
      return (this.get_lab_observations().mapBy('result')).uniq();
    },
    get_lab_observations_for_result: function(result) {
      return this.get_lab_observations().filterBy('result', result);
    },
    get_result_lab_observation_for_key: function(result, key) {
      return (this.get_lab_observations_for_result(result).filterBy('observation_key', key)).get('firstObject');
    },
    add_to_observation_list: function(value) {
      var create_action, list_action, section, tvo;
      tvo = this.get('tvo');
      section = 'obs-list';
      create_action = 'select-text';
      list_action = 'obs-list-values';
      if (tvo.section.has_action(section, list_action)) {
        return tvo.section.call_action(section, list_action).then((function(_this) {
          return function(values) {
            if (values.contains(value)) {
              return;
            }
            if (tvo.section.has_action(section, create_action)) {
              return tvo.section.send_action(section, create_action, value);
            }
          };
        })(this));
      }
    },
    save_all: function() {
      return this.get_results().forEach((function(_this) {
        return function(result) {
          return _this.save_result(result);
        };
      })(this));
    },
    save_result: function(result, saving_lab_observation) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var lab_observations;
          lab_observations = _this.get_lab_observations_for_result(result);
          if (ember['default'].isBlank(lab_observations)) {
            return resolve();
          }
          return _this.get_result_observation(result).then(function(observation) {
            if (_this.skip_observation_save(observation)) {
              return resolve();
            }
            lab_observations.map(function(lab_observation) {
              return _this.set_observation_lab_observation_value(observation, lab_observation);
            });
            return _this.save_observation(observation).then(function() {
              lab_observations.map(function(lab_observation) {
                return lab_observation.after_save(saving_lab_observation);
              });
              return resolve();
            }, function(error) {
              return reject(error);
            });
          });
        };
      })(this));
    },
    get_result_observation: function(result) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var observation;
          observation = result.get('observation');
          if (!observation) {
            observation = result.store.createRecord(ns['default'].to_p('lab:observation'), {
              locked: false,
              value: {}
            });
            _this.totem_scope.set_record_ownerable_attributes(observation);
            observation.set(ns['default'].to_p('lab:result'), result);
          }
          return resolve(observation);
        };
      })(this));
    },
    save_observation: function(observation) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return observation.save().then(function() {
            _this.totem_messages.api_success({
              source: _this,
              model: observation,
              i18n_path: ns['default'].to_o('lab:observation', 'save')
            });
            return resolve();
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    set_observation_lab_observation_value: function(observation, lab_observation) {
      var key, value;
      key = lab_observation.get_observation_key();
      value = lab_observation.get_value();
      return observation.set("value." + key, value);
    },
    skip_observation_save: function(observation) {
      var all_correct, is_new, locked;
      is_new = observation.get('isNew');
      if (is_new) {
        return false;
      }
      locked = observation.get('state') === 'locked';
      all_correct = observation.get('all_correct');
      return locked || all_correct;
    },
    set_focus_on_selected_category: function(tag) {
      if (tag == null) {
        tag = null;
      }
      return ember['default'].run.schedule('afterRender', this, (function(_this) {
        return function() {
          var lab_observation;
          lab_observation = _this.get_lab_observations().findBy('is_disabled', false);
          return lab_observation && _this.set_focus(lab_observation, tag);
        };
      })(this));
    },
    set_focus: function(lab_observation, tag) {
      var $next_element, component;
      if (tag == null) {
        tag = null;
      }
      if (!lab_observation) {
        return;
      }
      component = lab_observation.get_component();
      $next_element = (tag && component.$(tag)) || component.$(':input');
      return $next_element.focus();
    },
    set_focus_on_next_result: function(result) {
      var index, lab_observation, lab_observations, next;
      if (!result) {
        return;
      }
      lab_observation = this.get_lab_observations_for_result(result).get('lastObject');
      if (!lab_observation) {
        return;
      }
      lab_observations = this.get_lab_observations();
      index = lab_observations.indexOf(lab_observation);
      if (index == null) {
        return;
      }
      next = lab_observations.slice(index + 1).findBy('is_disabled', false);
      if (next) {
        return next.focus_self();
      } else {
        return this.set_focus_on_selected_category();
      }
    },
    get_user_inputs: function(key) {
      return this.get("user_inputs." + key);
    },
    add_user_input: function(key, input) {
      var hash, inputs, kmap, kv, l_input, map;
      if (ember['default'].isBlank(input)) {
        return;
      }
      inputs = this.get('user_inputs');
      if (!(kv = inputs[key])) {
        kv = inputs[key] = [];
      }
      l_input = this.convert_to_lowercase(input);
      if (kv.contains(l_input)) {
        return;
      }
      kv.push(l_input);
      map = this.get('user_input_map');
      if (!(kmap = map[key])) {
        kmap = map[key] = {};
      }
      hash = {};
      hash.input = input;
      return kmap[l_input] = hash;
    },
    entered_user_input: function(key, input) {
      var l_input;
      if (!(key && input)) {
        return null;
      }
      l_input = this.convert_to_lowercase(input);
      return this.get("user_input_map." + key + "." + l_input + ".input");
    },
    convert_to_lowercase: function(string) {
      if (typeof string !== 'string') {
        return '';
      }
      return string.toLowerCase();
    },
    validate_lab_observations: function(status) {
      var messages;
      messages = [];
      status.set_is_valid(true);
      this.get_lab_observations().forEach((function(_this) {
        return function(lo) {
          var has_correct, is_correct, nic;
          nic = lo.normal_is_correct_without_interaction();
          is_correct = lo.get_is_correct();
          has_correct = lo.get_correct_value();
          switch (false) {
            case !!has_correct:
              return status.increment_valid_count();
            case !nic:
              return status.increment_valid_count();
            case !is_correct:
              return status.increment_valid_count();
            default:
              status.set_is_valid(false);
              status.increment_invalid_count();
              return messages.push(lo.get_result().get('description'));
          }
        };
      })(this));
      return status.set_status_messages(messages.uniq());
    },
    toString: function() {
      return 'Lab';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});