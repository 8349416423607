define('thinkspace-base/mixins/common_helper', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___,
    slice = [].slice;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    taf: ember['default'].inject.service(),
    lg: function() {
      var args, context;
      context = arguments[0], args = 2 <= arguments.length ? slice.call(arguments, 1) : [];
      if (context == null) {
        context = true;
      }
      if (context) {
        return console.trace.apply(console, [this.toString()].concat(slice.call(args)));
      } else {
        return console.trace.apply(console, args);
      }
    },
    all_present: function(values) {
      var i, len, value;
      for (i = 0, len = values.length; i < len; i++) {
        value = values[i];
        if (!ember['default'].isPresent(value)) {
          return false;
        }
      }
      return true;
    },
    rsvp_hash_with_set: function(promises, context, prepend, append) {
      if (prepend == null) {
        prepend = '';
      }
      if (append == null) {
        append = '';
      }
      return ember['default'].RSVP.hash(promises).then(function(results) {
        var key, value;
        for (key in results) {
          value = results[key];
          context.set("" + prepend + key + append, value);
        }
        return results;
      });
    },
    resolve_promise: function(promise) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (promise.then != null) {
            return promise.then(function(resolution) {
              return resolve(resolution);
            });
          } else {
            return resolve(promise);
          }
        };
      })(this));
    },
    set_polymorphic: function(record, property, value, totem_scope) {
      if (totem_scope == null) {
        totem_scope = null;
      }
      if (!this.all_present([record, property, value])) {
        console.warn("No record, property, or value provided to set_polymorphic, relationship not set.", this);
        return;
      }
      totem_scope = totem_scope || this.totem_scope;
      record.set(property, value);
      record.set(property + "_id", value.get('id'));
      if (ember['default'].isPresent(totem_scope)) {
        return record.set(property + "_type", totem_scope.get_record_path(value));
      }
    },
    save_all: function(records) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var promises;
          promises = [];
          records.forEach(function(record) {
            return promises.pushObject(_this.save_if_changed(record));
          });
          return ember['default'].RSVP.Promise.all(promises).then(function(saved_records) {
            return resolve(saved_records);
          });
        };
      })(this));
    },
    save_if_changed: function(record) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (record.get('isDirty')) {
            return record.save().then(function(saved_record) {
              return resolve(saved_record);
            });
          } else {
            return resolve(record);
          }
        };
      })(this));
    },
    destroy_all: function(records) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var promises;
          promises = [];
          records.forEach(function(record) {
            return promises.pushObject(record.destroyRecord());
          });
          return ember['default'].RSVP.Promise.all(promises).then(function(destroyed_records) {
            return resolve(destroyed_records);
          });
        };
      })(this));
    },
    destroy_record: function(record) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (record.get('isNew')) {
            record.deleteRecord();
            return resolve(record);
          } else {
            return record.destroyRecord().then(function(saved_record) {
              return resolve(saved_record);
            });
          }
        };
      })(this));
    },
    validate_all: (function(_this) {
      return function(validatables, debug) {
        if (debug == null) {
          debug = false;
        }
        return new ember['default'].RSVP.Promise(function(resolve, reject) {
          var promises;
          promises = [];
          validatables.forEach(function(validatable) {
            return promises.pushObject(validatable.validate().then(function() {
              return validatable.get('isValid');
            })["catch"](function() {
              if (debug) {
                console.log("[validate_all] Validatable is not valid:", validatable, validatable.get('errors'));
              }
              return validatable.get('isValid');
            }));
          });
          return ember['default'].RSVP.Promise.all(promises).then(function(validities) {
            var all_valid;
            all_valid = validities.every(function(is_valid) {
              return is_valid;
            });
            return resolve(all_valid);
          });
        });
      };
    })(undefined),
    replace_at: (function(_this) {
      return function(array, index, obj) {
        array.removeObject(obj);
        array.insertAt(index, obj);
        return array;
      };
    })(undefined),
    push_unless_contains: (function(_this) {
      return function(array, obj) {
        if (!array.contains(obj)) {
          return array.pushObject(obj);
        }
      };
    })(undefined),
    flatten: function(arrays) {
      return this.get('taf').flatten(arrays);
    },
    intersection: function(arrays) {
      return this.get('taf').intersection(arrays);
    },
    difference: function(array1, array2) {
      return this.get('taf').difference(array1, array2);
    },
    filter_by: function(array, conditions) {
      var k, v;
      if (!ember['default'].isPresent(conditions)) {
        return array;
      }
      for (k in conditions) {
        v = conditions[k];
        array = array.filterBy(k, v);
      }
      return array;
    },
    find_by: function(array, conditions) {
      var k, v;
      if (!ember['default'].isPresent(conditions)) {
        return array;
      }
      for (k in conditions) {
        v = conditions[k];
        array = array.filterBy(k, v);
      }
      return array.get('firstObject');
    },
    minimum_for_property: function(records, property) {
      return records.sortBy(property).get('firstObject');
    },
    maximum_for_property: function(records, property) {
      return records.sortBy(property).get('lastObject');
    },
    duplicate_array: function(array) {
      var copy;
      copy = [];
      array.forEach((function(_this) {
        return function(a) {
          return copy.pushObject(a);
        };
      })(this));
      return copy;
    },
    has_many_to_array: function(context, property) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return context.get(property).then(function(records) {
            return resolve(records.toArray());
          });
        };
      })(this));
    },
    sync_array: function(array1, array2) {
      array1.forEach((function(_this) {
        return function(a) {
          if (!array2.contains(a)) {
            return array1.removeObject(a);
          }
        };
      })(this));
      return array2.forEach((function(_this) {
        return function(b) {
          if (!array1.contains(b)) {
            return array1.pushObject(b);
          }
        };
      })(this));
    },
    get_each: function(model, relationship) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var promises;
          promises = model.getEach(relationship);
          return ember['default'].RSVP.Promise.all(promises).then(function(results) {
            return resolve(results);
          });
        };
      })(this));
    },
    get_key_for_value: function(obj, val) {
      var k;
      for (k in obj) {
        if (obj[k] === val) {
          return k;
        }
      }
      return void 0;
    },
    get_values: function(obj) {
      var a, k, v;
      a = [];
      for (k in obj) {
        v = obj[k];
        a.push(v);
      }
      return a;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});