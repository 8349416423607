define('thinkspace-readiness-assurance/mixins/rm/response', ['exports', 'ember', 'totem-messages/messages'], function (exports, ember, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    join_response_received_event: function() {
      return this.join_room_event(this, 'response');
    },
    handle_response: function(data) {
      var payload;
      payload = data.value;
      if (payload) {
        this.store.pushPayload(payload);
      }
      console.info('received response--->', payload, this.response);
      return this.question_manager_map.forEach((function(_this) {
        return function(qm) {
          return qm.reset_values();
        };
      })(this));
    },
    save_response: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (_this.readonly) {
            return resolve();
          }
          if (!_this.save_to_server) {
            _this.save_off_message(_this.response);
            return resolve();
          }
          return _this.response.save().then(function() {
            return resolve();
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});