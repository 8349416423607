define('thinkspace-readiness-assurance/mixins/data_rows', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    columns_per_row: 2,
    column_width: 300,
    columns_class: null,
    get_data_rows: function(data) {
      var cpr, i, index, j, k, len, nrows, r, ref, ref1, row, row_array, row_data, rows;
      cpr = this.get_column_per_row();
      row_data = ember['default'].makeArray(data);
      this.set_columns_class(row_data);
      rows = [];
      len = row_data.length;
      nrows = Math.ceil(len / cpr);
      for (row = j = 0, ref = nrows - 1; 0 <= ref ? j <= ref : j >= ref; row = 0 <= ref ? ++j : --j) {
        row_array = [];
        r = row * cpr;
        for (i = k = 0, ref1 = cpr - 1; 0 <= ref1 ? k <= ref1 : k >= ref1; i = 0 <= ref1 ? ++k : --k) {
          index = r + i;
          if (!(index >= len)) {
            row_array.push(row_data[index]);
          }
        }
        rows.push(row_array);
      }
      return rows;
    },
    set_columns_class: function(data) {
      var col, cpr, div_by, dlen;
      cpr = this.get_column_per_row();
      dlen = data.length;
      div_by = dlen < cpr ? dlen : cpr;
      col = Math.floor(12 / div_by);
      return this.set('columns_class', "small-" + col);
    },
    get_column_per_row: function() {
      var cpr;
      cpr = this.get('columns_per_row');
      if (typeof cpr === 'string') {
        return this.auto_columns_per_row(cpr);
      } else {
        return cpr;
      }
    },
    auto_columns_per_row: function(cpr) {
      var $element, dcpr, element, ncols, wclient, wcol;
      dcpr = 1;
      $element = $(cpr).first();
      element = $element[0];
      if (ember['default'].isBlank(element)) {
        return dcpr;
      }
      wclient = element.clientWidth;
      if (ember['default'].isBlank(wclient) || wclient <= 0) {
        return dcpr;
      }
      wcol = this.get('column_width');
      if (ember['default'].isBlank(wcol) || wcol <= 0) {
        return dcpr;
      }
      if (wcol >= wclient) {
        return dcpr;
      }
      ncols = Math.floor(wclient / wcol);
      if (ncols > 0) {
        return ncols;
      } else {
        return 1;
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});