define('totem/mixins/data/did_load', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    didLoad: function() {
      return this.totem_data_refresh_model_data_name();
    },
    totem_data_refresh_model_data_name: function() {
      var id, ref, type;
      if (!this.data_name) {
        console.error("Authorization model 'data_name' property is blank.", this);
        return;
      }
      ref = this.totem_data_get_type_and_id_for_model(), type = ref[0], id = ref[1];
      if (!(type && id)) {
        return;
      }
      return this.totem_data_model_refresh(type, id);
    },
    totem_data_get_type_and_id_for_model: function() {
      var model, ownerable, rec_id, ref;
      rec_id = this.get('id');
      ref = rec_id.split('::', 2), model = ref[0], ownerable = ref[1];
      return model.split('.', 2);
    },
    totem_data_model_refresh: function(type, id) {
      var data_mod, record;
      record = this.store.getById(type, id);
      if (!record) {
        return;
      }
      if (!record.totem_data) {
        return;
      }
      data_mod = record.totem_data[this.data_name];
      if (!data_mod) {
        return;
      }
      return data_mod.refresh();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});