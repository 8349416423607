define('thinkspace-casespace/mixins/assignments/validity', ['exports', 'ember', 'totem/ds/associations', 'totem/ns'], function (exports, ember, ta, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    has_phase_errors: ember['default'].computed.or('has_inactive_peer_assessments', 'model.has_inactive_phases', 'model.has_phases_without_team_set'),
    has_inactive_peer_assessments: ember['default'].computed('model', 'assessments', 'assessments.@each.state', function() {
      var assessments, states;
      assessments = this.get('assessments');
      if (!ember['default'].isPresent(assessments)) {
        return false;
      }
      states = assessments.mapBy('state');
      return states.contains('neutral');
    }),
    assessments: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = {
            id: _this.get('model.id'),
            action: 'phase_componentables',
            componentable_type: ns['default'].to_p('tbl:assessment')
          };
          return _this.tc.query(ns['default'].to_p('assignment'), query, {
            payload_type: ns['default'].to_p('tbl:assessment')
          }).then(function(assessments) {
            return resolve(assessments);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    }),
    actions: {
      activate_assessments: function() {
        var confirm, promises;
        if (!(this.get('assessments.length') > 0)) {
          return;
        }
        confirm = window.confirm('Are you sure you want to activate this evaluation?  You will not be able to make changes to the evaluation or teams once this is done.');
        if (!confirm) {
          return;
        }
        this.totem_messages.show_loading_outlet();
        promises = [];
        return this.get('assessments').forEach((function(_this) {
          return function(assessment) {
            var promise;
            promise = _this.tc.query(ns['default'].to_p('tbl:assessment'), {
              id: assessment.get('id'),
              action: 'activate',
              verb: 'PUT'
            }, {
              single: true
            });
            promises.pushObject(promise);
            return ember['default'].RSVP.all(promises).then(function() {
              _this.totem_messages.hide_loading_outlet();
              return _this.totem_messages.api_success({
                source: _this,
                model: assessment,
                action: 'activate',
                i18n_path: ns['default'].to_o('tbl:assessment', 'activate')
              });
            });
          };
        })(this));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});