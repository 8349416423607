define('thinkspace-team/controllers/base', ['exports', 'ember', 'ember-data', 'totem/ns'], function (exports, ember, ds, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].ObjectController.extend({
    team_route: ember['default'].computed.reads('parentController.team_route'),
    all_teams: ember['default'].computed.reads('parentController.all_teams'),
    all_team_users: ember['default'].computed.reads('parentController.all_team_users'),
    team_categories: ember['default'].computed.reads('parentController.team_categories'),
    team_category_sort_by: ['title'],
    team_categories_sorted: ember['default'].computed.sort('team_categories', 'team_category_sort_by'),
    collaboration_category: ember['default'].computed(function() {
      return this.store.all(ns['default'].to_p('team_category')).findBy('is_collaboration');
    }),
    peer_review_category: ember['default'].computed(function() {
      return this.store.all(ns['default'].to_p('team_category')).findBy('is_peer_review');
    }),
    team_sort_by: ['title'],
    all_teams_sorted: ember['default'].computed.sort('all_teams', 'team_sort_by'),
    team_filter_category: null,
    all_teams_filtered_by_category: ember['default'].computed.filter('all_teams_sorted', function(team) {
      var filter_id;
      filter_id = this.get('team_filter_category.id');
      if (!filter_id) {
        return true;
      }
      return team.get('category_id') === filter_id;
    }),
    all_collaboration_teams: ember['default'].computed('all_teams_filtered_by_category', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('all_teams').then(function() {
            _this.set_team_filter_category(_this.get('collaboration_category'));
            return resolve(_this.get('all_teams_filtered_by_category'));
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    set_team_filter_category: function(category) {
      this.set('team_filter_category', category);
      return this.notifyPropertyChange('all_teams_sorted');
    },
    polymorphic_type_to_path: function(type) {
      return this.totem_scope.rails_polymorphic_type_to_path(type);
    },
    record_is_polymorphic: function(record, polymorphic_record, key) {
      var polymorphic_path, record_path;
      record_path = this.totem_scope.get_record_path(record);
      polymorphic_path = this.totem_scope.rails_polymorphic_type_to_path(polymorphic_record.get(key + "_type"));
      return polymorphic_path === record_path && polymorphic_record.get(key + "_id") === parseInt(record.get('id'));
    },
    transition_to_index: function() {
      return this.transitionToRoute(this.get('team_route.index'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});