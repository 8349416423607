define('thinkspace-readiness-assurance/mixins/am/trat', ['exports', 'ember', 'totem/ns', 'totem/ajax'], function (exports, ember, ns, ajax) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    send_trat_phase_states: function(data) {
      return this.send_trat_request(data, 'phase_states');
    },
    send_trat_request: function(data, action) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = _this.get_auth_query(_this.get_trat_url(action), data);
          return ajax['default'].object(query).then(function() {
            return resolve();
          });
        };
      })(this));
    },
    get_trat_url: function(action) {
      return ns['default'].to_p('readiness_assurance', 'trats', action);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});