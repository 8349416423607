define('totem-template-manager/tvo/status_base', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    init_status_values: function() {
      this.set('is_valid', false);
      this.set('valid_count', 0);
      this.set('invalid_count', 0);
      return this.set('status_messages', []);
    },
    get_is_valid: function() {
      return this.get('is_valid');
    },
    set_is_valid: function(value) {
      return this.set('is_valid', value);
    },
    get_status_messages: function() {
      return this.get('status_messages');
    },
    set_status_messages: function(status_messages) {
      return this.set('status_messages', ember['default'].makeArray(status_messages));
    },
    get_valid_count: function() {
      return this.get('valid_count');
    },
    set_valid_count: function(num) {
      return this.set('valid_count', num);
    },
    get_invalid_count: function() {
      return this.get('invalid_count');
    },
    set_invalid_count: function(num) {
      return this.set('invalid_count', num);
    },
    set_status_values: function(status_instances) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var invalid_count, is_valid, promises, status_messages, valid_count;
          status_messages = [];
          promises = [];
          valid_count = 0;
          invalid_count = 0;
          is_valid = true;
          status_instances.forEach(function(status) {
            return promises.push(status.set_status_values());
          });
          return ember['default'].RSVP.allSettled(promises).then(function() {
            status_instances.forEach(function(status) {
              if (status.get_is_valid()) {
                valid_count += status.get_valid_count();
              } else {
                is_valid = false;
                invalid_count += status.get_invalid_count();
              }
              return status_messages = status_messages.concat(status.get_status_messages());
            });
            _this.set_is_valid(is_valid);
            _this.set_valid_count(valid_count);
            _this.set_invalid_count(invalid_count);
            _this.set_status_messages(status_messages);
            return resolve({
              is_valid: is_valid,
              status_messages: status_messages
            });
          });
        };
      })(this));
    },
    toString: function() {
      return 'TvoStatusBase';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});