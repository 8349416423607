define('thinkspace-indented-list/mixins/dragula', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    moves: function(el, source, handle, sibling) {
      var $el;
      $el = $(el);
      return this.draggable_classes.find(function(class_name) {
        return $el.hasClass(class_name);
      });
    },
    accepts: function(el, target, source, sibling) {
      return this.response_manager.list_container === target;
    },
    copy: function(el, source) {
      return this.response_manager.list_container !== source;
    },
    cloned: function(clone, original, type) {
      this.cloned_element = clone;
      return this.cloned_item = $(original).data('item');
    },
    drop: function(el, target, source, sibling) {
      return this.response_manager.handle_drop(this.cloned_element, el, target, source, sibling);
    },
    cancel: function(el, container, source) {
      return this.response_manager.handle_cancel(this.cloned_element, el, container, source);
    },
    shadow: function(el, original, source) {
      var $item, $transit, margin_left, pos_x;
      $item = $('.gu-mirror');
      if (ember['default'].isPresent($item)) {
        $transit = $('.gu-transit');
        pos_x = this.response_manager.get_element_pos_x($item);
        margin_left = this.response_manager.calc_item_indent({
          pos_x: pos_x
        });
        return $transit.css('margin-left', margin_left + 'px');
      }
    },
    drag: function(el, source) {
      this.is_dragging = true;
      return $(document).mousemove((function(_this) {
        return function(event) {
          return _this.response_manager.shadow.call(_this, el, null, source);
        };
      })(this));
    },
    dragend: function(el) {
      if (this.is_dragging) {
        $(document).unbind('mousemove');
        return this.is_dragging = false;
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});