define('thinkspace-peer-assessment/components/peer_assessment/builder/assessment/qual/item', ['exports', 'ember', 'totem/ns', 'totem/util'], function (exports, ember, ns, util) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    model: null,
    id: null,
    label: null,
    type: null,
    feedback_type: null,
    assessment: null,
    is_textarea: ember['default'].computed.equal('type', 'textarea'),
    is_text: ember['default'].computed.equal('type', 'text'),
    init: function() {
      this._super();
      return this.map_model_properties();
    },
    map_model_properties: function() {
      var model;
      model = this.get('model');
      this.set('id', model.id);
      this.set('label', model.label);
      this.set('type', model.type);
      return this.set('feedback_type', model.feedback_type);
    },
    set_value: function(property, value) {
      var fn;
      fn = "set_" + property;
      if (this[fn] == null) {
        return;
      }
      this[fn](value);
      return this.map_model_properties();
    },
    set_id: function(id) {
      return util['default'].set_path_value(this, 'model.id', parseInt(id));
    },
    set_label: function(label) {
      return util['default'].set_path_value(this, 'model.label', label);
    },
    set_type: function(type) {
      return util['default'].set_path_value(this, 'model.type', type);
    },
    set_feedback_type: function(type) {
      return util['default'].set_path_value(this, 'model.feedback_type', type);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});