define('thinkspace-lab/vet_med_lab_result', ['exports', 'ember', 'totem/ns', 'totem/util'], function (exports, ember, ns, util) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    lab_observations: null,
    is_focused: false,
    is_disabled: ember['default'].computed('lab_observations.@each.is_disabled', function() {
      var correctness, lab_observations;
      correctness = [];
      lab_observations = this.get_lab_observations();
      lab_observations.forEach((function(_this) {
        return function(lo) {
          var has_correct, is_disabled;
          is_disabled = lo.get('is_disabled');
          has_correct = lo.get_correct_value();
          if (has_correct) {
            return correctness.pushObject(is_disabled);
          }
        };
      })(this));
      return !correctness.contains(false);
    }),
    init: function() {
      this._super();
      this.set('lab_observations', []);
      return this.get_lab().register_lab_result(this);
    },
    register_lab_observation: function(lab_observation) {
      return this.get_lab_observations().pushObject(lab_observation);
    },
    get_lab_observations: function() {
      return this.get('lab_observations');
    },
    get_lab: function() {
      return this.get('lab');
    },
    set_focused: function() {
      this.get_lab().get_lab_results().forEach((function(_this) {
        return function(lr) {
          return lr.reset_focused();
        };
      })(this));
      return this.set('is_focused', true);
    },
    reset_focused: function() {
      return this.set('is_focused', false);
    },
    toString: function() {
      return 'LabResult';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});