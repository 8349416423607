define('totem-template-manager/tvo/hash', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    get_value: function(key) {
      return this.tvo.get_path_value(this.get_path(key));
    },
    set_value: function(key, value) {
      var path;
      path = this.get_path(key);
      this.tvo.set_path_value(path, value);
      return path;
    },
    get_path: function(key) {
      return this.tvo_property + "." + key;
    },
    toString: function() {
      return 'TvoHash';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});