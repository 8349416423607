define('thinkspace-readiness-assurance/mixins/qm/rooms', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    broadcast: function(event, value) {
      if (value == null) {
        value = null;
      }
      return this.rm.broadcast_id_room_event(event, this.qid, value);
    },
    join_id_room_event: function(source, event, callback) {
      if (callback == null) {
        callback = null;
      }
      return this.rm.join_id_room_event(source, event, this.qid, callback);
    },
    join_room_event: function(source, event, callback) {
      if (callback == null) {
        callback = null;
      }
      return this.rm.join_room_event(source, event, callback);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});