define('thinkspace-casespace/components/casespace', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    toolbar_is_hidden: ember['default'].computed.reads('thinkspace.toolbar_is_hidden'),
    toolbar_is_minimized: ember['default'].computed.reads('thinkspace.toolbar_is_minimized'),
    sidepocket_is_expanded: ember['default'].computed.reads('casespace.sidepocket_is_expanded'),
    sidepocket_width_class: ember['default'].computed.reads('casespace.sidepocket_width_class'),
    dock_is_visible: ember['default'].computed.reads('casespace.dock_is_visible'),
    terms_modal_visible: ember['default'].computed.reads('thinkspace.display_terms_modal'),
    has_sticky_addon: ember['default'].computed.reads('casespace.has_sticky_addon'),
    c_toolbar: ns['default'].to_p('toolbar'),
    c_dock: ns['default'].to_p('dock'),
    c_sidepocket: ns['default'].to_p('sidepocket'),
    c_messages: ns['default'].to_p('dock', 'messages', 'messages'),
    c_terms_modal: ns['default'].to_p('common', 'user', 'terms_modal'),
    thinkspace: ember['default'].inject.service(),
    casespace: ember['default'].inject.service()
  });

  exports['default'] = ___DefaultExportObject___;;

});