define('totem-simple-auth/cookie_store', ['exports', 'ember', 'simple-auth/utils/objects-are-equal', 'simple-auth/stores/base'], function (exports, ember, objects_are_equal, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      return this.bind_cookie_event();
    },
    persist: function(data) {
      Cookies.set(this.get_cookie_key(), data);
      this._lastData = this.restore();
      return this.trigger_cookie_event();
    },
    restore: function() {
      var json;
      json = Cookies.get(this.get_cookie_key());
      return JSON.parse(json);
    },
    clear: function() {
      Cookies.remove(this.get_cookie_key());
      this._lastData = {};
      return this.trigger_cookie_event();
    },
    trigger_cookie_event: function() {
      return localStorage.setItem(this.get_event_key(), new Date());
    },
    bind_cookie_event: function() {
      return $(window).bind('storage', (function(_this) {
        return function(e) {
          var data;
          if (e.originalEvent.key === _this.get_event_key() || e.key === _this.get_event_key()) {
            data = _this.restore();
            if (!objects_are_equal['default'](data, _this._lastData)) {
              _this._lastData = data;
              return _this.trigger('sessionDataUpdated', data);
            }
          }
        };
      })(this));
    },
    get_cookie_key: function() {
      return 'totem-cookie-store';
    },
    get_event_key: function() {
      return 'totem-cookie';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});