define('thinkspace-readiness-assurance/mixins/am/initialize', ['exports', 'ember', 'totem/ns', 'thinkspace-readiness-assurance/base/admin/rad'], function (exports, ember, ns, rad) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    pubsub: ember['default'].inject.service(),
    ttz: ember['default'].inject.service(),
    ra: ember['default'].inject.service(ns['default'].to_p('ra')),
    init: function() {
      this._super();
      this.pubsub = this.get('pubsub');
      this.ttz = this.get('ttz');
      this.ra = this.get('ra');
      this.messages = this.ra.get('messages');
      this.store = this.get_store();
      this.reset_data();
      return this.ra.join_admin_room();
    },
    rad: function(options) {
      if (options == null) {
        options = {};
      }
      options.am = this;
      return rad['default'].create(options);
    },
    reset: function() {
      return this.reset_data();
    },
    toString: function() {
      return 'ReadinessAssuranceAdminManager';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});