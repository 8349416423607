define('totem-messages/mixins/services/messages/filter', ['exports', 'ember', 'totem/scope'], function (exports, ember, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    get_new_messages: function(options) {
      if (options == null) {
        options = {};
      }
      return this.get_messages('is_new', options);
    },
    get_previous_messages: function(options) {
      if (options == null) {
        options = {};
      }
      return this.get_messages('is_previous', options);
    },
    move_all_to_previous: function(rooms) {
      if (rooms == null) {
        rooms = null;
      }
      return this.get_new_messages(rooms).forEach(function(message) {
        return message.set_previous();
      });
    },
    get_messages: function(msg_prop, options) {
      var filter_only, filter_prop, messages, rooms, sort_by, sort_by_prop, sort_prop, type_prop;
      filter_only = options.filter_only || false;
      rooms = ember['default'].makeArray(options.room || options.rooms).compact();
      sort_by = ember['default'].makeArray(options.sort || ['date:desc']);
      sort_by_prop = this.get_sort_by_property(sort_by);
      type_prop = this.get_message_store_filter_for_type();
      filter_prop = this.get_filter_property(type_prop, msg_prop, rooms);
      sort_prop = this.get_sort_property(filter_prop, sort_by_prop);
      messages = filter_only ? this.get(filter_prop) : this.get(sort_prop);
      if (messages) {
        return messages;
      }
      ember['default'].defineProperty(this, filter_prop, ember['default'].computed.filter(type_prop + ".@each.state", function(message) {
        return this.filter_messages(message, msg_prop, rooms);
      }));
      if (filter_only) {
        return this.get(filter_prop);
      }
      if (!this.get(sort_by_prop)) {
        this.set(sort_by_prop, sort_by);
      }
      ember['default'].defineProperty(this, sort_prop, ember['default'].computed.sort(filter_prop, sort_by_prop));
      return this.get(sort_prop);
    },
    get_filter_property: function(type_prop, msg_prop, rooms) {
      var prop;
      prop = "_msg_filter_" + type_prop + "_" + msg_prop;
      if (ember['default'].isBlank(rooms)) {
        return prop + "_all";
      } else {
        return (prop + "_") + rooms.join(':');
      }
    },
    get_sort_by_property: function(sort_by) {
      return "_msg_sort_by_" + sort_by;
    },
    get_sort_property: function(filter_prop, sort_by_prop) {
      return "_msg_sort_" + sort_by_prop + "_" + filter_prop;
    },
    filter_messages: function(message, prop, filter_rooms) {
      var msg_rooms;
      if (filter_rooms == null) {
        filter_rooms = null;
      }
      if (!message.get(prop)) {
        return false;
      }
      if (ember['default'].isBlank(filter_rooms)) {
        return true;
      }
      msg_rooms = message.get('rooms');
      if (ember['default'].isBlank(msg_rooms)) {
        return false;
      }
      msg_rooms = ember['default'].makeArray(msg_rooms);
      return this.in_filter_rooms(filter_rooms, msg_rooms);
    },
    in_filter_rooms: function(filter_rooms, msg_rooms) {
      var room;
      if ((function() {
        var i, len, results;
        results = [];
        for (i = 0, len = msg_rooms.length; i < len; i++) {
          room = msg_rooms[i];
          results.push(filter_rooms.contains(room));
        }
        return results;
      })()) {
        return true;
      }
      return false;
    },
    get_message_store_filter_for_type: function() {
      var filter, prop, store, type;
      type = this.message_model_type;
      prop = "_msg_store_filter_" + type;
      if (ember['default'].isPresent(this.get(prop))) {
        return prop;
      }
      store = totem_scope['default'].get_store();
      filter = store.filter(type, function(message) {
        return true;
      });
      this.set(prop, filter);
      return prop;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});