define('totem/scope', ['exports', 'ember', 'totem/logger'], function (exports, ember, logger) {

  'use strict';

  var ___DefaultExportObject___, totem_scope_module,
    indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

  totem_scope_module = ember['default'].Object.extend({
    toString: function() {
      return 'totem_scope';
    },
    container: null,
    get_container: function() {
      return this.get('container');
    },
    set_container: function(container) {
      return this.set('container', container);
    },
    container_lookup: function(key) {
      return this.get_container().lookup(key);
    },
    data_store: null,
    get_store: function() {
      var store;
      store = this.get('data_store');
      if (store) {
        return store;
      }
      store = this.container_lookup('store:main');
      this.set('data_store', store);
      return store;
    }
  });

  totem_scope_module.reopen({
    is_read_only: false,
    is_disabled: false,
    is_view_only: ember['default'].computed.or('is_read_only', 'is_disabled'),
    read_only_on: function() {
      return this.set('is_read_only', true);
    },
    read_only_off: function() {
      return this.set('is_read_only', false);
    },
    disabled_on: function() {
      return this.set('is_disabled', true);
    },
    disabled_off: function() {
      return this.set('is_disabled', false);
    },
    view_only_on: function() {
      this.read_only_on();
      return this.disabled_on();
    },
    view_only_off: function() {
      this.read_only_off();
      return this.disabled_off();
    }
  });

  totem_scope_module.reopen({
    path_ids: {},
    get_path_ids: function(path) {
      return this.get("path_ids." + path);
    },
    set_path_ids: function(path, ids) {
      path && this.set("path_ids." + path, this.make_ids_array(ids));
      return this.notify_path_ids_property_change();
    },
    reset_all_ids: function() {
      return this.set('path_ids', {});
    },
    reset_path_ids: function(path) {
      return this.set_path_ids(path, null);
    },
    notify_path_ids_property_change: function() {
      return this.notifyPropertyChange('path_ids');
    },
    path_ids_blank: function(path) {
      return path && this.is_blank(this.get_path_ids(path));
    },
    path_ids_present: function(path) {
      return !this.path_ids_blank(path);
    },
    can_view_path_id: function(path, id) {
      var ids;
      if (!(path && id)) {
        return false;
      }
      ids = this.get_path_ids(path) || [];
      return indexOf.call(ids, id) >= 0;
    },
    path_to_attrs: {},
    get_path_type_attr: function(path) {
      if (path == null) {
        path = null;
      }
      return (path && this.get("path_to_attrs." + path + ".type")) || this.get_ownerable_type_attr();
    },
    get_path_id_attr: function(path) {
      if (path == null) {
        path = null;
      }
      return (path && this.get("path_to_attrs." + path + ".id")) || this.get_ownerable_id_attr();
    },
    set_path_attrs: function(path, attrs) {
      return path && (this.set("path_to_attrs." + path, attrs));
    }
  });

  totem_scope_module.reopen({
    current_path: null,
    get_current_path: function() {
      return this.get('current_path');
    },
    get_current_ids: function() {
      return this.get_path_ids(this.get_current_path());
    },
    set_current_path: function(path) {
      if (path == null) {
        path = null;
      }
      return this.set('current_path', path);
    },
    set_current_ids: function(ids) {
      return this.set_path_ids(this.get_current_path(), ids);
    },
    set_current_path_and_ids: function(path, ids) {
      this.set_current_path(path);
      return this.set_current_ids(ids);
    },
    change_current_path: function(path) {
      if (path == null) {
        path = null;
      }
      if (this.get_current_path() === path) {
        return;
      }
      this.set_current_path(path);
      return this.notify_path_ids_property_change();
    },
    current_path_blank: function() {
      return !this.get_current_path();
    },
    current_path_present: function() {
      return !this.current_path_blank();
    },
    current_ids_blank: function() {
      return !this.path_ids_blank(this.get_current_path());
    },
    current_ids_present: function() {
      return !this.current_ids_blank();
    }
  });

  totem_scope_module.reopen({
    current_user: null,
    current_user_id: null,
    no_current_user: ember['default'].computed.none('current_user'),
    current_user_blank: function() {
      return this.get('no_current_user');
    },
    current_user_present: function() {
      return !this.current_user_blank();
    },
    get_current_user: function() {
      if (this.current_user_blank()) {
        this.set_current_user();
      }
      return this.get('current_user');
    },
    get_current_user_id: function() {
      if (this.current_user_blank()) {
        this.set_current_user();
      }
      return this.get('current_user_id');
    },
    set_current_user: function(user) {
      var id;
      if (user) {
        id = parseInt(user.get('id'));
      } else {
        id = null;
      }
      this.set('current_user', user);
      return this.set('current_user_id', id);
    },
    get_current_user_path: function() {
      return this.get_record_path(this.get_current_user());
    },
    get_current_user_type: function() {
      return this.get_current_user_path();
    }
  });

  totem_scope_module.reopen({
    view_user_ids_blank: ember['default'].computed('path_ids', 'current_user_id', function() {
      return this.is_user_ids_current_user();
    }),
    view_user_is_current_user: ember['default'].computed.reads('view_user_ids_blank'),
    user_ids_path: null,
    get_user_ids_path: function() {
      return this.get('user_ids_path') || this.set_user_ids_path();
    },
    set_user_ids_path: function(user) {
      if (user == null) {
        user = this.get_current_user();
      }
      this.set('user_ids_path', this.get_record_path(user));
      return this.get('user_ids_path');
    },
    get_user_ids: function() {
      return this.get_path_ids(this.get_user_ids_path());
    },
    is_user_ids_current_path: function() {
      return this.current_path_blank() || this.get_current_path() === this.get_user_ids_path();
    },
    set_user_ids_path_attr: function(attr) {
      if (attr == null) {
        attr = 'user_id';
      }
      return this.set_path_attrs(this.get_user_ids_path(), {
        id: attr
      });
    },
    is_user_ids_current_user: function() {
      var ids;
      ids = this.get_user_ids();
      if (!ids) {
        return true;
      }
      return ids.get('length') === 1 && ids.objectAt(0) === this.get_current_user_id();
    }
  });

  totem_scope_module.reopen({
    can_view_record_current_path_id: function(record) {
      var id_attr, path;
      if (!record) {
        return false;
      }
      path = this.get_current_path() || this.get_user_ids_path();
      if (path === this.get_user_ids_path()) {
        return this.can_view_record_user_id(record);
      } else {
        if (this.record_is_deleted(record)) {
          return false;
        }
        id_attr = this.get_path_id_attr(path);
        return this.valid_record_path_type(path, record) && this.can_view_path_id(path, record.get(id_attr));
      }
    },
    can_view_record_user_id: function(record) {
      var current_user_id, id, id_attr, ids, path;
      if (!record) {
        return false;
      }
      if (this.record_is_deleted(record)) {
        return false;
      }
      path = this.get_user_ids_path();
      id_attr = this.get_path_id_attr(path);
      id = record.get(id_attr);
      if (!id) {
        return false;
      }
      if (!this.valid_record_path_type(path, record)) {
        return false;
      }
      ids = this.get_path_ids(path);
      if (!ids) {
        current_user_id = this.get_current_user_id();
        ids = (current_user_id && [current_user_id]) || [];
      }
      return indexOf.call(ids, id) >= 0;
    },
    valid_record_path_type: function(path, record) {
      var type, type_attr;
      type_attr = this.get_path_type_attr(path);
      if (!type_attr) {
        return true;
      }
      type = record.get(type_attr);
      if (!type) {
        return false;
      }
      return path === this.rails_polymorphic_type_to_path(type);
    },
    rails_polymorphic_type_to_path: function(type) {
      return type.underscore().replace(/::/g, '/');
    },
    record_is_deleted: function(record) {
      return record.get('isDeleted') || record.get('isDestroyed') || record.get('isDestroying');
    }
  });

  totem_scope_module.reopen({
    user_data_action: function(record) {
      return (record.user_data_action && record.user_data_action()) || 'view';
    },
    sub_action: null,
    get_sub_action: function() {
      return this.get('sub_action');
    },
    set_sub_action: function(sub) {
      if (sub == null) {
        sub = null;
      }
      return this.set('sub_action', sub);
    }
  });

  totem_scope_module.reopen({
    get_view_query: function(record, options) {
      var query, view_ids;
      if (options == null) {
        options = {};
      }
      view_ids = ember['default'].makeArray(options.view_ids || this.get_ownerable_id());
      query = {
        verb: options.verb || 'post',
        action: options.action || this.user_data_action(record),
        id: options.id || record.get('id'),
        auth: {
          sub_action: options.sub_action || this.get_sub_action(),
          view_ids: view_ids,
          view_type: options.view_type || this.get_ownerable_type()
        }
      };
      this.add_authable_to_query(query, options.authable);
      this.add_ownerable_to_query(query, options.ownerable);
      return query;
    },
    get_unviewed_query: function(record, options) {
      var query, unviewed_ids;
      if (options == null) {
        options = {};
      }
      unviewed_ids = this.get_unviewed_record_path_ids(record, options);
      if (options.set_viewed !== false) {
        this.set_viewed_record_path_ids(record, options);
      }
      if (this.is_blank(unviewed_ids)) {
        return null;
      }
      query = {
        verb: options.verb || 'post',
        action: options.action || this.user_data_action(record),
        id: options.id || record.get('id'),
        auth: {
          sub_action: options.sub_action || this.get_sub_action(),
          view_ids: unviewed_ids,
          view_type: this.get_ownerable_type()
        }
      };
      this.add_auth_to_query(record, query);
      return query;
    },
    current_path_and_ids_to_record: function(record, options) {
      if (options == null) {
        options = {};
      }
      this.set_current_path_and_ids(this.get_record_path(record), record.get('id'));
      if (options.sub_action) {
        return this.set_sub_action(options.sub_action);
      }
    },
    record_type_key: function(record) {
      return record.constructor.typeKey;
    },
    model_class_from_string: function(string) {
      return this.get_store().modelFor(string);
    },
    model_class_type_key: function(model_class) {
      return ember['default'].get(model_class, 'typeKey');
    },
    get_record_path: function(record) {
      var key;
      if (!record) {
        logger['default'].error('totem_scope.get_record_path record is blank.');
      }
      return key = this.record_type_key(record).underscore().replace(/\./g, '/');
    },
    get_record_path_no_ns: function(record) {
      var path;
      path = this.get_record_path(record);
      return this.remove_namespace_from_path(path);
    },
    remove_namespace_from_path: function(path) {
      return path.split('/').get('lastObject');
    },
    standard_record_path: function(path_or_record) {
      var value;
      if (typeof path_or_record === 'string') {
        value = path_or_record.replace(/\::/g, '/').underscore();
      } else {
        value = this.get_record_path(path_or_record);
      }
      return value;
    },
    record_has_viewed_id: function(record, id, options) {
      var viewed_ids;
      if (options == null) {
        options = {};
      }
      if (!(record && id)) {
        return false;
      }
      viewed_ids = this.get_viewed_record_path_ids(record, options);
      return viewed_ids.contains(parseInt(id));
    },
    record_has_not_viewed_id: function(record, id, options) {
      if (options == null) {
        options = {};
      }
      return !this.record_has_viewed_id(record, id, options);
    },
    set_viewed_record_path_ids: function(record, options) {
      var unviewed_ids, viewed_ids;
      if (options == null) {
        options = {};
      }
      viewed_ids = this.get_viewed_record_path_ids(record, options);
      unviewed_ids = this.get_unviewed_record_path_ids(record, options);
      return record.set(this.get_record_path_ids_prop(record, options), this.concat_id_arrays(viewed_ids, unviewed_ids));
    },
    get_viewed_record_path_ids: function(record, options) {
      var viewed_ids;
      if (options == null) {
        options = {};
      }
      viewed_ids = this.make_ids_array(record.get(this.get_record_path_ids_prop(record, options)));
      if (this.is_user_ids_current_path()) {
        if (options.viewed_ownerable === true) {
          viewed_ids = this.concat_id_arrays(viewed_ids, this.get_current_user_id());
        }
      } else {
        if (this.is_blank(viewed_ids)) {
          if (options.viewed_ownerable === true) {
            viewed_ids = this.concat_id_arrays(viewed_ids, this.get_ownerable_id());
          }
        }
      }
      return viewed_ids;
    },
    get_unviewed_record_path_ids: function(record, options) {
      var current_ids, viewed_ids;
      if (options == null) {
        options = {};
      }
      viewed_ids = this.get_viewed_record_path_ids(record, options);
      current_ids = this.get_current_ids() || [];
      return current_ids.filter(function(id) {
        return !viewed_ids.contains(id);
      });
    },
    unviewed_record_path_ids_blank: function(record, options) {
      if (options == null) {
        options = {};
      }
      return this.is_blank(this.get_unviewed_record_path_ids(record, options));
    },
    unviewed_record_path_ids_present: function(record, options) {
      if (options == null) {
        options = {};
      }
      return !this.unviewed_record_path_ids_blank(record, options);
    },
    get_record_path_ids_prop: function(record, options) {
      var path, path_ids;
      if (options == null) {
        options = {};
      }
      path_ids = '_ts_path_ids_';
      if (!record.get(path_ids)) {
        record.set(path_ids, {});
      }
      path = options.id_prop || options.sub_action || this.get_sub_action() || this.get_current_path() || this.get_user_ids_path();
      return path_ids + "." + path;
    },
    record_ownerable_match_ownerable: function(record, ownerable) {
      var ownerable_id, ownerable_type, record_ownerable_id, record_ownerable_type;
      if (ownerable == null) {
        ownerable = null;
      }
      if (!record) {
        return false;
      }
      record_ownerable_type = this.rails_polymorphic_type_to_path(record.get('ownerable_type'));
      record_ownerable_id = record.get('ownerable_id');
      if (ownerable) {
        ownerable_type = this.get_record_path(ownerable);
        ownerable_id = ownerable.get('id');
      } else {
        ownerable_type = this.get_ownerable_type();
        ownerable_id = this.get_ownerable_id();
      }
      return record_ownerable_type === ownerable_type && parseInt(record_ownerable_id) === parseInt(ownerable_id);
    },
    polymorphic_values_are_equal: function(c1, c2) {
      var c1_id, c1_type, c2_id, c2_type;
      if (!(ember['default'].isPresent(c1) && ember['default'].isPresent(c2))) {
        return false;
      }
      if (!((c1.type != null) && (c1.id != null) && (c2.type != null) && (c2.id != null))) {
        console.warn("Cannot compare polymorphic values without a type and id present in object: ", c1, c2);
        return false;
      }
      c1_type = this.standard_record_path(c1.type);
      c1_id = parseInt(c1.id);
      c2_type = this.standard_record_path(c2.type);
      c2_id = parseInt(c2.id);
      return ember['default'].isEqual(c1_id, c2_id) && ember['default'].isEqual(c1_type, c2_type);
    }
  });

  totem_scope_module.reopen({
    make_ids_array: function(ids) {
      ids = ember['default'].makeArray(ids).map(function(id) {
        return parseInt(id) || null;
      });
      return ids.compact();
    },
    is_blank: function(ids) {
      return ember['default'].isEmpty(ids);
    },
    is_present: function(ids) {
      return !this.is_blank(ids);
    },
    is_empty: function(ids) {
      return this.is_blank(ids);
    },
    concat_id_arrays: function(array_1, array_2) {
      var array;
      array = [];
      array_1 = this.make_ids_array(array_1);
      array_2 = this.make_ids_array(array_2);
      array_1.forEach(function(value) {
        return array.push(value);
      });
      array_2.forEach(function(value) {
        return array.push(value);
      });
      return array.uniq();
    }
  });

  totem_scope_module.reopen({
    authable_type: null,
    authable_id: null,
    get_authable_type: function() {
      return this.get('authable_type');
    },
    get_authable_id: function() {
      return this.get('authable_id');
    },
    set_authable: function(record) {
      return this.authable(record);
    },
    authable: function(record) {
      var id, type;
      if (!record) {
        return;
      }
      type = this.get_record_path(record);
      id = record.get('id');
      this.set('authable_type', type);
      return this.set('authable_id', id);
    },
    record_authable_match_authable: function(record, authable) {
      var authable_id, authable_type, record_authable_id, record_authable_type;
      if (authable == null) {
        authable = null;
      }
      if (!record) {
        return false;
      }
      record_authable_type = this.rails_polymorphic_type_to_path(record.get('authable_type'));
      record_authable_id = record.get('authable_id');
      if (authable) {
        authable_type = this.get_record_path(authable);
        authable_id = authable.get('id');
      } else {
        authable_type = this.get_authable_type();
        authable_id = this.get_authable_id();
      }
      return record_authable_type === authable_type && parseInt(record_authable_id) === parseInt(authable_id);
    }
  });

  totem_scope_module.reopen({
    ownerable_type_attr: null,
    ownerable_id_attr: null,
    ownerable_type: null,
    ownerable_id: null,
    ownerable_record: null,
    ownerable: function(ownerable, options) {
      if (options == null) {
        options = {};
      }
      this.set_ownerable(ownerable, options);
      return this.current_path_and_ids_to_ownerable();
    },
    ownerable_to_current_user: function(options) {
      if (options == null) {
        options = {};
      }
      return this.ownerable(this.get_current_user(), options);
    },
    current_path_and_ids_to_ownerable: function(options) {
      if (options == null) {
        options = {};
      }
      this.set_current_path_and_ids(this.get_ownerable_type(), this.get_ownerable_id());
      if (options.sub_action) {
        return this.set_sub_action(options.sub_action);
      }
    },
    get_default_ownerable_type_attr: function() {
      return 'ownerable_type';
    },
    get_default_ownerable_id_attr: function() {
      return 'ownerable_id';
    },
    get_ownerable_type_attr: function() {
      return this.get('ownerable_type_attr') || this.get_default_ownerable_type_attr();
    },
    get_ownerable_id_attr: function() {
      return this.get('ownerable_id_attr') || this.get_default_ownerable_id_attr();
    },
    get_ownerable_type: function() {
      return this.get('ownerable_type');
    },
    get_ownerable_id: function() {
      return this.get('ownerable_id');
    },
    get_ownerable_record: function() {
      return this.get('ownerable_record') || this.get_current_user();
    },
    has_ownerable: function() {
      return this.get_ownerable_type() && this.get_ownerable_id();
    },
    ownerable_is_type_user: function() {
      return this.get_ownerable_type() === this.get_current_user_type();
    },
    set_ownerable: function(record, options) {
      var id, id_attr, type, type_attr;
      if (record == null) {
        record = null;
      }
      if (options == null) {
        options = {};
      }
      if (record == null) {
        record = this.get_current_user();
      }
      type_attr = options.type_attr;
      id_attr = options.id_attr;
      if (type_attr && (!id_attr)) {
        id_attr = type_attr.replace('type', 'id');
      }
      type = this.get_record_path(record);
      id = record.get('id');
      this.set('ownerable_type', type);
      this.set('ownerable_id', parseInt(id));
      this.set('ownerable_type_attr', type_attr);
      this.set('ownerable_id_attr', id_attr);
      return this.set('ownerable_record', record);
    },
    set_record_ownerable_attributes: function(record) {
      var id_attr, type_attr;
      if (!record) {
        return;
      }
      type_attr = this.get_ownerable_type_attr();
      id_attr = this.get_ownerable_id_attr();
      return record.eachAttribute((function(_this) {
        return function(rec_attr) {
          switch (rec_attr) {
            case type_attr:
              return record.set(type_attr, _this.get_ownerable_type());
            case id_attr:
              return record.set(id_attr, _this.get_ownerable_id());
          }
        };
      })(this));
    }
  });

  totem_scope_module.reopen({
    delete_record: function() {},
    find: function() {},
    find_all: function() {},
    find_many: function() {},
    find_query: function() {},
    serialize_into_hash: function() {},
    add_auth_to_query: function() {}
  });

  totem_scope_module.reopen({
    delete_record: function(type, record, query) {
      return this.add_auth_to_query(type, query);
    },
    find: function(type, id, query) {
      return this.add_auth_to_query(type, query);
    },
    find_all: function(type, query) {
      return this.add_auth_to_query(type, query);
    },
    find_many: function(type, query) {
      return this.add_auth_to_query(type, query);
    },
    find_query: function(type, query) {
      return this.add_auth_to_query(type, query);
    },
    serialize_into_hash: function(hash, type, record, options) {
      return this.add_auth_to_query(type, hash);
    },
    add_auth_to_query: function(object, query) {
      if (query == null) {
        query = {};
      }
      if (!(object && query)) {
        return;
      }
      if (!ember['default'].get(object, 'isClass')) {
        object = object.constructor;
      }
      if (ember['default'].get(object, 'include_ownerable_in_query') || query.ownerable) {
        this.add_ownerable_to_query(query);
      }
      if (ember['default'].get(object, 'include_authable_in_query') || query.authable) {
        this.add_authable_to_query(query);
      }
      return this.add_sub_action_to_query(query);
    },
    add_ownerable_to_query: function(query, ownerable) {
      var ownerable_id, ownerable_type;
      if (ownerable == null) {
        ownerable = null;
      }
      if (query.auth == null) {
        query.auth = {};
      }
      if (ownerable || (ownerable = query.ownerable)) {
        ownerable_type = this.get_record_path(ownerable);
        ownerable_id = ownerable.get('id');
        delete query.ownerable;
      } else {
        if (!this.has_ownerable()) {
          this.ownerable_to_current_user();
        }
        ownerable_type = this.get_ownerable_type();
        ownerable_id = this.get_ownerable_id();
      }
      query.auth.ownerable_type = ownerable_type;
      return query.auth.ownerable_id = ownerable_id;
    },
    add_authable_to_query: function(query, authable) {
      var authable_id, authable_type;
      if (authable == null) {
        authable = null;
      }
      if (query.auth == null) {
        query.auth = {};
      }
      if (authable || (authable = query.authable)) {
        authable_type = this.get_record_path(authable);
        authable_id = authable.get('id');
        delete query.authable;
      } else {
        authable_type = this.get_authable_type();
        authable_id = this.get_authable_id();
      }
      query.auth.authable_type = authable_type;
      return query.auth.authable_id = authable_id;
    },
    add_sub_action_to_query: function(query, sub_action) {
      if (sub_action == null) {
        sub_action = null;
      }
      if (query.auth && query.auth.sub_action) {
        return;
      }
      if (sub_action || (sub_action = query.sub_action)) {
        if (query.auth == null) {
          query.auth = {};
        }
        query.auth.sub_action = sub_action;
        delete query.sub_action;
        return;
      }
      if (sub_action = this.get_sub_action()) {
        if (query.auth == null) {
          query.auth = {};
        }
        return query.auth.sub_action = sub_action;
      }
    },
    add_auth_to_ajax_query: function(query) {
      if (query == null) {
        query = {};
      }
      if (query.data == null) {
        query.data = {};
      }
      if (!query.data.authable) {
        query.data.authable = query.authable;
      }
      if (!query.data.ownerable) {
        query.data.ownerable = query.ownerable;
      }
      if (!query.data.sub_action) {
        query.data.sub_action = query.sub_action;
      }
      delete query.authable;
      delete query.ownerable;
      delete query.sub_action;
      this.add_authable_to_query(query.data);
      this.add_ownerable_to_query(query.data);
      this.add_sub_action_to_query(query.data);
      delete query.data.authable;
      delete query.data.ownerable;
      delete query.data.sub_action;
      return query;
    }
  });

  ___DefaultExportObject___ = totem_scope_module.create();

  exports['default'] = ___DefaultExportObject___;;

});