define('thinkspace-readiness-assurance/base/admin/menu', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    init: function() {
      this._super();
      this.selected_components = [];
      this.totem_scope.authable(this.get('model'));
      return this.inactivate_menu();
    },
    willInsertElement: function() {
      this.reset();
      return this.setup();
    },
    reset: function() {
      this.set_ready_off();
      return this.clear();
    },
    clear: function() {
      this.inactivate_menu();
      return this.selected_components.clear();
    },
    clear_except_unclearables: function() {
      var config, i, len, non_clearable_configs, results;
      non_clearable_configs = this.selected_components.filter(function(config) {
        return config.clearable === false;
      });
      this.clear();
      if (ember['default'].isBlank(non_clearable_configs)) {
        return;
      }
      results = [];
      for (i = 0, len = non_clearable_configs.length; i < len; i++) {
        config = non_clearable_configs[i];
        results.push(this.select_action(config));
      }
      return results;
    },
    setup: function() {
      var default_config;
      default_config = this.get_default_config();
      if (ember['default'].isPresent(default_config)) {
        return this.select_action(default_config);
      }
    },
    inactivate_menu: function() {
      var config, i, len, ref, results;
      ref = this.get_menu_configs();
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        config = ref[i];
        results.push(ember['default'].set(config, 'active', false));
      }
      return results;
    },
    actions: {
      clear: function() {
        return this.clear();
      },
      select: function(config) {
        return this.select_action(config);
      },
      done: function(config) {
        return this.select_action(config);
      }
    },
    select_action: function(config) {
      var index;
      if (ember['default'].isBlank(config)) {
        this.error('Select action config is blank.');
      }
      index = this.selected_components.indexOf(config);
      if (index >= 0) {
        this.selected_components.removeAt(index);
        if (config) {
          return ember['default'].set(config, 'active', false);
        }
      } else {
        if (config.clear === true) {
          this.clear_except_unclearables();
        }
        this.add_selected_config(config);
        if (config) {
          return ember['default'].set(config, 'active', true);
        }
      }
    },
    add_selected_config: function(config) {
      if (config.top === true) {
        return this.add_selected_top_config(config);
      } else {
        return this.selected_components.pushObject(config);
      }
    },
    add_selected_top_config: function(config) {
      var configs, first, index, last_top;
      configs = this.selected_components.filterBy('top', true);
      first = config.first || false;
      if (first || ember['default'].isBlank(configs)) {
        return this.selected_components.unshiftObject(config);
      } else {
        last_top = configs.get('lastObject');
        index = this.selected_components.indexOf(last_top);
        if (index >= 0) {
          return this.selected_components.insertAt(index + 1, config);
        } else {
          return this.selected_components.pushObject(config);
        }
      }
    },
    get_default_config: function() {
      return this.get_menu_configs().findBy('default', true) || null;
    },
    get_menu_configs: function() {
      var menu_configs;
      menu_configs = this.get('menu');
      if (ember['default'].isArray(menu_configs)) {
        return menu_configs;
      } else {
        return [];
      }
    },
    find_config: function(comp, options) {
      var found_config;
      if (options == null) {
        options = {};
      }
      options.component = comp;
      found_config = this.get_menu_configs().find((function(_this) {
        return function(config) {
          var found, key, value;
          found = true;
          for (key in options) {
            value = options[key];
            if (config[key] !== value) {
              found = false;
            }
          }
          return found;
        };
      })(this));
      if (ember['default'].isBlank(found_config)) {
        this.error("Finding menu config for component '" + comp + "' is blank with options:", options);
      }
      return found_config;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});