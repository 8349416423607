define('totem/mixins/filter', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    is_filtering: false,
    filter_fn: 'filter_results',
    select_filter_record: function(key, record) {
      var records;
      records = this.get(key);
      if (!records.contains(record)) {
        records.pushObject(record);
        return this[this.get('filter_fn')]();
      }
    },
    deselect_filter_record: function(key, record) {
      var records;
      records = this.get(key);
      if (records.contains(record)) {
        records.removeObject(record);
        return this[this.get('filter_fn')]();
      }
    },
    clear_filter_key: function(key, value) {
      if (value == null) {
        value = [];
      }
      this.set("filter_" + key, value);
      return this[this.get('filter_fn')]();
    },
    set_is_filtering: function() {
      return this.set('is_filtering', true);
    },
    reset_is_filtering: function() {
      return this.set('is_filtering', false);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});