define('thinkspace-peer-assessment/managers/evaluation/qualitative', ['exports', 'ember', 'totem/scope', 'totem/cache', 'totem/ds/associations', 'totem-messages/messages'], function (exports, ember, totem_scope, tc, ta, tm) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    valid_qual_sections: null,
    has_qualitative_section: ember['default'].computed.notEmpty('model.qualitative_items'),
    qual_section_error_observer: ember['default'].observer('is_confirmation', 'reviews.@each.value', function() {
      return this.process_reviews_errors();
    }),
    process_reviews_errors: function() {
      var assessment, count, has_errors, qualitative_items, reviews;
      if (!this.get('is_confirmation')) {
        return;
      }
      assessment = this.get('model');
      qualitative_items = assessment.get('qualitative_items');
      if (!ember['default'].isPresent(qualitative_items)) {
        this.reset_required_comments_error();
        return;
      }
      count = qualitative_items.get('length');
      reviews = this.get('reviews');
      has_errors = false;
      reviews.forEach((function(_this) {
        return function(review) {
          var valid_count;
          valid_count = review.valid_qualitative_items_count();
          if (!ember['default'].isEqual(valid_count, count)) {
            return has_errors = true;
          }
        };
      })(this));
      if (has_errors) {
        return this.set_required_comments_error();
      } else {
        return this.reset_required_comments_error();
      }
    },
    set_required_comments_error: function() {
      return this.set('valid_qual_sections', null);
    },
    reset_required_comments_error: function() {
      return this.set('valid_qual_sections', true);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});