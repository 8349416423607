define('totem/cache_image', ['exports', 'ember', 'totem/ns', 'totem/scope', 'totem/ajax'], function (exports, ember, ns, totem_scope, ajax) {

  'use strict';

  var ___DefaultExportObject___, totem_image_module;

  totem_image_module = ember['default'].Object.extend({
    cache: null,
    init: function() {
      return this.clear();
    },
    clear: function() {
      return this.cache = {};
    },
    url: function(options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var id, query;
          id = options.id || (options.model && options.model.get('id'));
          query = {
            verb: 'get',
            action: 'image_url',
            id: id
          };
          return _this.get_image_url(query).then(function(url) {
            return resolve(url);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    carry_forward_url: function(options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var action, from_phase, is_expert, query;
          from_phase = options.from_phase;
          is_expert = options.is_expert || false;
          action = (is_expert && 'carry_forward_expert_image_url') || 'carry_forward_image_url';
          query = {
            verb: 'post',
            action: action,
            is_expert: is_expert,
            data: {
              from_phase: from_phase
            }
          };
          return _this.get_image_url(query).then(function(url) {
            return resolve(url);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    revoke_url: function(url) {
      var cache, cache_url, key, results;
      if (ember['default'].isBlank(url)) {
        return;
      }
      cache = this.get_cache();
      results = [];
      for (key in cache) {
        cache_url = cache[key];
        if (cache_url === url) {
          results.push(delete cache[key]);
        } else {
          results.push(void 0);
        }
      }
      return results;
    },
    revoke_phase_url: function(options) {
      var cache, cache_key, url;
      if (options == null) {
        options = {};
      }
      cache = this.get_cache();
      cache_key = this.get_phase_cache_key(options);
      url = cache[cache_key];
      if (ember['default'].isBlank(url)) {
        return;
      }
      return delete cache[cache_key];
    },
    get_image_url: function(query) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (query.data == null) {
            query.data = {};
          }
          if (query.model == null) {
            query.model = ns['default'].to_p('artifact', 'file');
          }
          totem_scope['default'].add_ownerable_to_query(query.data);
          totem_scope['default'].add_authable_to_query(query.data);
          return _this.get_query_image_phase_id(query).then(function(phase_id) {
            var cache_key, cache_url;
            if (ember['default'].isBlank(phase_id)) {
              return reject("Image phase 'id' is blank.");
            }
            cache_key = _this.get_phase_cache_key({
              phase_id: phase_id,
              is_expert: query.is_expert
            });
            cache_url = _this.get_cache_url(cache_key);
            if (ember['default'].isPresent(cache_url)) {
              return resolve(cache_url);
            }
            return ajax['default'].object(query).then(function(json) {
              var url;
              if (ember['default'].isBlank(json)) {
                return resolve(null);
              }
              url = json.url;
              if (ember['default'].isBlank(url)) {
                return resolve(null);
              }
              _this.set_cache_url(cache_key, url);
              return resolve(url);
            }, function(error) {
              return reject(error);
            });
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    get_phase_cache_key: function(options) {
      var cache_key;
      if (options == null) {
        options = {};
      }
      cache_key = {
        phase_id: options.phase_id || totem_scope['default'].get_authable_id(),
        is_expert: options.is_expert || false,
        ownerable_id: totem_scope['default'].get_ownerable_id(),
        ownerable_type: totem_scope['default'].get_ownerable_type()
      };
      return ajax['default'].stringify(cache_key);
    },
    get_query_image_phase_id: function(query) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var authable_id, from_phase, index;
          authable_id = query.data.auth.authable_id;
          if (ember['default'].isBlank(query.id)) {
            from_phase = query.data.from_phase;
            if (ember['default'].isBlank(from_phase)) {
              return reject("Image carry forward 'from_phase' is blank.");
            }
            switch (false) {
              case from_phase !== 'prev':
                index = -1;
                break;
              case !from_phase.match(/^prev-\d+$/):
                index = Number(from_phase.split('-', 2)[1]) * -1;
                break;
              case !from_phase.match(/^\d+$/):
                return resolve(from_phase);
              default:
                return reject("Image carry forward [from_phase: " + from_phase + "] is unknown.");
            }
            return _this.tc.find_record(ns['default'].to_p('phase'), authable_id).then(function(phase) {
              var assignment, cf_phase, current_index, find_index, id, phases;
              assignment = phase.get('assignment');
              phases = assignment.get('phases');
              phases = phases.filter(function(p) {
                return p.get('is_not_archived') || !p.get('is_inactive');
              });
              current_index = phases.indexOf(phase);
              find_index = current_index + index;
              cf_phase = phases.objectAt(find_index);
              if (ember['default'].isBlank(cf_phase)) {
                return reject("Image carry forward phase for [from_phase: " + from_phase + "] is blank.");
              }
              id = cf_phase.get('id');
              query.data.from_phase = id;
              return resolve(id);
            });
          } else {
            return resolve(authable_id);
          }
        };
      })(this));
    },
    get_cache_url: function(key) {
      return this.get_cache()[key];
    },
    set_cache_url: function(key, url) {
      return this.get_cache()[key] = url;
    },
    get_cache: function() {
      return this.get('cache') || {};
    }
  });

  ___DefaultExportObject___ = totem_image_module;

  exports['default'] = ___DefaultExportObject___;;

});