define('totem-messages/mixins/services/messages/base', ['exports', 'ember', 'totem-messages/mixins/services/messages/add', 'totem-messages/mixins/services/messages/format', 'totem-messages/mixins/services/messages/filter', 'totem-messages/mixins/services/messages/helpers', 'totem-messages/mixins/services/messages/initialize', 'totem-messages/mixins/services/messages/item', 'totem-messages/mixins/services/messages/load'], function (exports, ember, m_add, m_format, m_filter, m_helpers, m_init, m_item, m_load) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Mixin.create(m_init['default'], m_add['default'], m_filter['default'], m_item['default'], m_format['default'], m_load['default'], m_helpers['default']);

	exports['default'] = ___DefaultExportObject___;;

});