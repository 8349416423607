define('thinkspace-readiness-assurance/mixins/rm/initialize', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/scope', 'thinkspace-readiness-assurance/question_manager', 'thinkspace-readiness-assurance/chat_manager'], function (exports, ember, ns, ajax, totem_scope, question_manager, chat_manager) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    tvo: ember['default'].inject.service(),
    ttz: ember['default'].inject.service(),
    pubsub: ember['default'].inject.service(),
    ra: ember['default'].inject.service(ns['default'].to_p('ra')),
    ready: false,
    question_manager_map: null,
    chat_manager_map: null,
    init_manager: function(options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.init_manager_properties(options);
          _this.init_assessment(options);
          return _this.init_response_models(options).then(function() {
            _this.init_random_choices(options);
            _this.init_question_managers(options);
            _this.init_room(options);
            if (_this.is_trat) {
              _this.init_chat_managers(options);
              return _this.init_room_users(options).then(function() {
                _this.set('ready', true);
                return resolve(_this);
              }, function(msg) {
                return _this.error(msg);
              });
            } else {
              _this.set('ready', true);
              return resolve(_this);
            }
          }, function(msg) {
            return _this.error(msg);
          });
        };
      })(this));
    },
    init_manager_properties: function(options) {
      this.init_maps(options);
      this.init_readonly(options);
      this.init_can_update_assessment(options);
      this.init_ra_type(options);
      this.store = totem_scope['default'].get_store();
      this.pubsub = this.get('pubsub');
      this.tvo = this.get('tvo');
      this.ttz = this.get('ttz');
      this.ra = this.get('ra');
      this.ready = false;
      this.save_error = false;
      this.current_user = totem_scope['default'].get_current_user();
      this.title = options.title || options.username || 'unknown';
      this.is_admin = options.admin || false;
      return this.save_to_server = !(options.save_response === false);
    },
    init_maps: function(options) {
      this.question_manager_map = ember['default'].Map.create();
      return this.chat_manager_map = ember['default'].Map.create();
    },
    init_readonly: function(options) {
      this.readonly = options.readonly === true;
      return this.updateable = !this.readonly;
    },
    init_can_update_assessment: function(options) {
      this.can_update_assessment = options.can_update_assessment || false;
      return this.cannot_update_assessment = !this.can_update_assessment;
    },
    init_ra_type: function(options) {
      this.is_irat = options.irat || false;
      this.is_trat = options.trat || false;
      if (!this.is_irat && !this.is_trat) {
        this.error("Both required option 'irat' or 'trat' are blank. Add either 'irat: true' or 'trat: true'.");
      }
      if (this.is_irat && this.is_trat) {
        return this.error("Both 'irat' or 'trat' options are present.  Specify only one.");
      }
    },
    init_assessment: function(options) {
      this.assessment = options.assessment;
      if (ember['default'].isBlank(this.assessment)) {
        return this.error("Required assessment model in 'options.model' is blank.");
      }
    },
    init_response_models: function(options) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.init_response(options).then(function() {
            return _this.init_status().then(function() {
              if (!_this.is_trat) {
                return resolve();
              }
              return _this.init_chat().then(function() {
                return resolve();
              }, function(msg) {
                return reject(msg);
              });
            }, function(msg) {
              return reject(msg);
            });
          }, function(msg) {
            return reject(msg);
          });
        };
      })(this));
    },
    init_response: function(options) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var response;
          response = options.response;
          if (ember['default'].isPresent(response)) {
            _this.response = response;
            return resolve();
          }
          return _this.tvo.helper.ownerable_view_association_records(_this.assessment, {
            association: ns['default'].to_p('ra:responses')
          }).then(function(responses) {
            var msg;
            _this.response = _this.assessment.get('responses.firstObject');
            if (ember['default'].isPresent(_this.response)) {
              return resolve();
            }
            msg = "Assessment model [id: " + (_this.assessment.get('id')) + "] response is blank " + (_this.ownerable_error_message()) + ".";
            return reject(msg);
          });
        };
      })(this));
    },
    init_status: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.response.get(ns['default'].to_p('ra:status')).then(function(status) {
            var msg;
            _this.status = status;
            if (ember['default'].isPresent(_this.status)) {
              return resolve();
            }
            msg = "Assessment model [id: " + (_this.assessment.get('id')) + "] status is blank for response [id: " + (_this.response.get('id')) + "] " + (_this.ownerable_error_message()) + ".";
            return reject(msg);
          });
        };
      })(this));
    },
    init_random_choices: function(options) {
      this.random_choices = options.random_choices || false;
      return this.random_by_client = options.random_by_client || false;
    },
    init_question_managers: function(options) {
      var i, id, len, qm, qn, question, ref, results;
      qn = 0;
      this.qids = [];
      ref = this.assessment.get('questions');
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        question = ref[i];
        qn += 1;
        qm = question_manager['default'].create({
          question_hash: question,
          rm: this,
          response: this.response,
          status: this.status,
          qn: qn
        });
        id = question.id;
        if (ember['default'].isBlank(id)) {
          this.error("Assessment [id: " + (this.assessment.get('id')) + "] question 'id' is blank.", question);
        }
        if (this.qids.contains(id)) {
          this.error("Assessment [id: " + (this.assessment.get('id')) + "] question id '" + id + "' is a duplicate.", question);
        }
        this.qids.push(id);
        results.push(this.question_manager_map.set(id, qm));
      }
      return results;
    },
    init_room: function(options) {
      this.join_server_event_received_event();
      this.room = this.init_get_room(options);
      if (this.is_trat && this.room) {
        this.room_users_header = options.room_users_header;
        this.pubsub.join({
          room: this.room
        });
        this.join_response_received_event();
        this.join_status_received_event();
        return this.join_chat_received_event();
      }
    },
    init_get_room: function(options) {
      var room;
      room = options.room || null;
      if (this.is_trat && ember['default'].isBlank(room)) {
        room = this.ra.phase_ownerable_room();
      }
      return room;
    },
    init_chat: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.response.get(ns['default'].to_p('ra:chat')).then(function(chat) {
            var msg;
            _this.chat = chat;
            if (ember['default'].isPresent(_this.chat)) {
              return resolve();
            }
            msg = "Assessment model [id: " + (_this.assessment.get('id')) + "] chat is blank for response [id: " + (_this.response.get('id')) + "] " + (_this.ownerable_error_message()) + ".";
            return reject(msg);
          });
        };
      })(this));
    },
    init_room_users: function(options) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var action, id, model, query, room_users, verb;
          room_users = options.room_users;
          if (ember['default'].isPresent(room_users)) {
            _this.room_users = room_users;
            return resolve();
          }
          model = _this.assessment;
          id = model.get('id');
          action = 'teams';
          verb = 'post';
          query = {
            model: model,
            id: id,
            action: action,
            verb: verb
          };
          return ajax['default'].object(query).then(function(data) {
            if ((!data || data.length < 1) && _this.cannot_update_assessment) {
              return reject("Team room users are blank.");
            }
            if (data.length > 1) {
              return rejest("More than one team room users.");
            }
            _this.room_users = (data.get('firstObject') || {}).users;
            return resolve();
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    init_chat_managers: function(options) {
      var cm, i, id, len, question, ref, results;
      ref = this.assessment.get('questions');
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        question = ref[i];
        cm = chat_manager['default'].create({
          question_hash: question,
          rm: this,
          chat: this.chat,
          status: this.status
        });
        id = question.id;
        if (ember['default'].isBlank(id)) {
          this.error("Assessment [id: " + (this.assessment.get('id')) + "] question 'id' is blank.", question);
        }
        results.push(this.chat_manager_map.set(id, cm));
      }
      return results;
    },
    ownerable_error_message: function() {
      var id, ownerable, type;
      ownerable = totem_scope['default'].get_ownerable_record();
      id = ownerable.get('id');
      type = totem_scope['default'].get_record_path(ownerable);
      return "for ownerable [type: " + type + " ] [id: " + id + "]";
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});