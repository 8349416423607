define('thinkspace-readiness-assurance/mixins/am/base', ['exports', 'ember', 'thinkspace-readiness-assurance/mixins/am/data', 'thinkspace-readiness-assurance/mixins/am/helpers', 'thinkspace-readiness-assurance/mixins/am/initialize', 'thinkspace-readiness-assurance/mixins/am/irat', 'thinkspace-readiness-assurance/mixins/am/menus', 'thinkspace-readiness-assurance/mixins/am/messages', 'thinkspace-readiness-assurance/mixins/am/timers', 'thinkspace-readiness-assurance/mixins/am/tracker', 'thinkspace-readiness-assurance/mixins/am/trat'], function (exports, ember, m_data, m_helpers, m_init, m_irat, m_menus, m_msgs, m_timers, m_tracker, m_trat) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Mixin.create(m_init['default'], m_menus['default'], m_irat['default'], m_trat['default'], m_data['default'], m_timers['default'], m_tracker['default'], m_helpers['default'], m_msgs['default']);

	exports['default'] = ___DefaultExportObject___;;

});