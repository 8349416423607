define('totem-messages/mixins/services/messages/load', ['exports', 'ember', 'totem/ajax', 'totem/scope'], function (exports, ember, ajax, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    load: function(options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query, url, verb;
          if (ember['default'].isBlank(_this.message_model_type)) {
            return resolve();
          }
          verb = 'post';
          url = _this.message_load_url;
          query = {
            url: url,
            verb: verb
          };
          totem_scope['default'].add_auth_to_ajax_query(query);
          query.data.load_messages = _this.get_load_data(options);
          return ajax['default'].object(query).then(function(payload) {
            var messages;
            if (ember['default'].isBlank(payload)) {
              return resolve();
            }
            messages = payload.messages;
            if (ember['default'].isBlank(messages)) {
              return resolve();
            }
            messages.forEach(function(msg) {
              var data;
              data = msg.value || {};
              data.rooms = msg.rooms;
              data.state = 'previous';
              return _this.add(data);
            });
            return resolve();
          });
        };
      })(this));
    },
    get_load_data: function(options) {
      var data;
      data = {};
      data.from_time = options.from_time;
      data.to_time = options.to_time;
      data.rooms = options.room || options.rooms;
      return data;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});