define('thinkspace-casespace-case-manager/routes/base', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'simple-auth/mixins/authenticated-route-mixin', 'thinkspace-base/base/route'], function (exports, ember, ns, ajax, auth_mixin, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend(auth_mixin['default'], {
    setupController: function(controller, model) {
      var wizard_manager;
      wizard_manager = this.get('wizard_manager');
      wizard_manager.set_controller(controller);
      wizard_manager.set_route(this);
      if (ember['default'].isPresent(model)) {
        controller.set('model', model);
        controller.set('bundle_type', (model.get('isNew') && 'selector') || model.get('bundle_type'));
        if (!ember['default'].isPresent(controller.get('step'))) {
          return controller.set('step', this.get('wizard_manager.transition_to_step'));
        }
      }
    },
    wizard_manager: ember['default'].inject.service(),
    case_manager: ember['default'].inject.service(),
    get_wizard_manager: function() {
      return this.get('wizard_manager');
    },
    get_case_manager: function() {
      return this.get('case_manager');
    },
    set_current_models: function(current_models) {
      if (current_models == null) {
        current_models = {};
      }
      return this.get_case_manager().set_current_models(current_models);
    },
    get_current_assignment: function() {
      return this.get_case_manager().get_current_assignment();
    },
    clear_all_current_models: function() {
      return this.get_case_manager().reset_all();
    },
    get_space_from_params: function(params) {
      return this.store.find(ns['default'].to_p('space'), params.space_id).then((function(_this) {
        return function(space) {
          return _this.totem_messages.api_success({
            source: _this,
            model: space
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: ns['default'].to_p('space')
          });
        };
      })(this));
    },
    get_assignment_from_params: function(params) {
      return this.store.find(ns['default'].to_p('assignment'), params.assignment_id).then((function(_this) {
        return function(assignment) {
          return _this.totem_messages.api_success({
            source: _this,
            model: assignment
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: ns['default'].to_p('assignment')
          });
        };
      })(this));
    },
    get_phase_from_params: function(params) {
      return this.store.find(ns['default'].to_p('phase'), params.phase_id).then((function(_this) {
        return function(phase) {
          return _this.totem_messages.api_success({
            source: _this,
            model: phase
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.totem_messages.api_failure(error, {
            source: _this,
            model: ns['default'].to_p('phase')
          });
        };
      })(this));
    },
    load_assignment: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assignment, case_manager, options;
          case_manager = _this.get_case_manager();
          if (case_manager.has_assignment_loaded()) {
            return resolve();
          }
          assignment = _this.get_current_assignment();
          options = {
            model: assignment,
            id: assignment.get('id'),
            action: 'load'
          };
          return ajax['default'].object(options).then(function(payload) {
            case_manager.set_assignment_loaded();
            _this.store.pushPayload(payload);
            return resolve();
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    load_spaces: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var controller;
          controller = _this.controllerFor(_this.ns.to_p('spaces'));
          if (controller.get('all_spaces')) {
            return resolve();
          }
          return _this.store.find(_this.ns.to_p('space')).then(function(spaces) {
            controller.set('all_spaces', true);
            return resolve();
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});