define('thinkspace-readiness-assurance/mixins/am/helpers', ['exports', 'ember', 'totem/ns', 'totem/scope'], function (exports, ember, ns, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    get_store: function() {
      return totem_scope['default'].get_store();
    },
    get_auth_query: function(url, data) {
      var query, verb;
      if (data == null) {
        data = {};
      }
      verb = 'post';
      query = {
        url: url,
        verb: verb,
        data: data
      };
      this.add_auth_to_query(query);
      return query;
    },
    add_auth_to_query: function(query) {
      return totem_scope['default'].add_auth_to_ajax_query(query);
    },
    current_user_message_name: function() {
      return totem_scope['default'].get_current_user().get('full_name');
    },
    pluralize: function(count, singular, plural) {
      if (plural == null) {
        plural = null;
      }
      if (count === 1) {
        return singular;
      } else {
        return plural || singular.pluralize();
      }
    },
    is_date: function(obj) {
      return obj && (obj instanceof Date);
    },
    clone_date: function(date) {
      return new Date(date.valueOf());
    },
    format_time: function(time) {
      return this.ttz.format(time || new Date(), {
        format: 'MMM Do, h:mm a'
      });
    },
    format_time_only: function(time) {
      return this.ttz.format(time || new Date(), {
        format: 'h:mm a'
      });
    },
    date_to_hh_mm: function(date) {
      return this.ttz.format(date, {
        format: 'h:mm a'
      });
    },
    date_from_now: function(date) {
      var zdate;
      zdate = this.ttz.format(date, {});
      return moment(zdate).fromNow();
    },
    minutes_from_now: function(date) {
      return Math.floor(((+date) - (+new Date())) / 60000);
    },
    minutes_from_now_message: function(mins) {
      return mins + ' minute' + (mins === 1 ? '' : 's');
    },
    minutes_between_dates: function(date1, date2) {
      return Math.floor(((+date1) - (+date2)) / 60000);
    },
    adjust_by_minutes: function(date, mins) {
      return date.setMinutes(date.getMinutes() + mins);
    },
    round_minutes: function(date, int) {
      var minutes;
      if (ember['default'].isBlank(date) || ember['default'].isBlank(int)) {
        return;
      }
      minutes = date.getMinutes();
      minutes = Math.round(minutes / int) * int;
      return date.setMinutes(minutes);
    },
    round_down_minutes: function(date, int) {
      var minutes;
      if (ember['default'].isBlank(date) || ember['default'].isBlank(int)) {
        return;
      }
      minutes = date.getMinutes();
      minutes = Math.floor(minutes / int) * int;
      return date.setMinutes(minutes);
    },
    round_up_minutes: function(date, int) {
      var minutes;
      if (ember['default'].isBlank(date) || ember['default'].isBlank(int)) {
        return;
      }
      minutes = date.getMinutes();
      minutes = Math.ceil(minutes / int) * int;
      return date.setMinutes(minutes);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});