define('thinkspace-readiness-assurance/mixins/am/data', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-readiness-assurance/response_manager'], function (exports, ember, ns, ajax, response_manager) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    data_values: null,
    reset_data: function() {
      return this.set('data_values', {});
    },
    get_data_value: function(prop) {
      return this.get("data_values." + prop);
    },
    set_data_value: function(prop, value) {
      return this.set("data_values." + prop, value);
    },
    get_model: function() {
      return this.get_data_value('model');
    },
    set_model: function(model) {
      return this.set_data_value('model', model);
    },
    get_trat_team_users: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query, team_users;
          team_users = _this.get_data_value('trat_team_users');
          if (ember['default'].isPresent(team_users)) {
            return resolve(team_users);
          }
          query = _this.get_auth_query(_this.get_trat_url('team_users'));
          return ajax['default'].object(query).then(function(payload) {
            team_users = _this.sort_team_users(payload.teams || []);
            _this.set_data_value('trat_team_users', team_users);
            return resolve(team_users);
          });
        };
      })(this));
    },
    sort_team_users: function(team_users) {
      if (ember['default'].isBlank(team_users)) {
        return team_users;
      }
      return team_users.sortBy('team.title');
    },
    get_trat_assessment: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assessment, query;
          assessment = _this.get_data_value('trat_assessment');
          if (ember['default'].isPresent(assessment)) {
            return resolve(assessment);
          }
          query = _this.get_auth_query(_this.get_trat_url('assessment'));
          return ajax['default'].object(query).then(function(payload) {
            assessment = ajax['default'].normalize_and_push_payload('ra:assessment', payload, {
              single: true
            });
            _this.set_data_value('trat_assessment', assessment);
            return resolve(assessment);
          });
        };
      })(this));
    },
    get_irat_authable: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var authable;
          authable = _this.get_data_value('irat_authable');
          if (ember['default'].isPresent(authable)) {
            return resolve(authable);
          }
          return _this.get_irat_assessment().then(function(assessment) {
            return assessment.get('authable').then(function(authable) {
              _this.set_data_value('irat_authable', authable);
              return resolve(authable);
            });
          });
        };
      })(this));
    },
    get_irat_assessment: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var assessment, query;
          assessment = _this.get_data_value('irat_assessment');
          if (ember['default'].isPresent(assessment)) {
            return resolve(assessment);
          }
          query = _this.get_auth_query(_this.get_irat_url('assessment'));
          return ajax['default'].object(query).then(function(payload) {
            assessment = ajax['default'].normalize_and_push_payload('ra:assessment', payload, {
              single: true
            });
            _this.set_data_value('irat_assessment', assessment);
            return resolve(assessment);
          });
        };
      })(this));
    },
    load_trat_responses: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          if (_this.get_data_value('responses_loaded') === true) {
            return reolve();
          }
          query = _this.get_auth_query(_this.get_trat_url('responses'));
          return ajax['default'].object(query).then(function(payload) {
            _this.store.pushPayload(payload);
            _this.set_data_value('responses_loaded', true);
            return resolve();
          });
        };
      })(this));
    },
    get_trat_response_managers: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var rms;
          rms = _this.get_data_value('trat_response_managers');
          if (ember['default'].isPresent(rms)) {
            return resolve(rms);
          }
          return _this.get_trat_assessment().then(function(assessment) {
            return _this.get_trat_team_users().then(function(team_users) {
              return _this.load_trat_responses().then(function() {
                var promises;
                promises = [];
                return assessment.get(ns['default'].to_p('ra:responses')).then(function(responses) {
                  return responses.forEach(function(response) {
                    var data, rm, room, room_users, team_id, title;
                    team_id = response.get('ownerable_id');
                    data = team_users.find(function(data) {
                      return data.team.id === team_id;
                    });
                    if (!data) {
                      _this.error("Team [id: " + team_id + "] not found in team data.");
                    }
                    room = data.team.room;
                    title = data.team.title;
                    room_users = data.users;
                    rm = response_manager['default'].create({
                      container: _this.container
                    });
                    promises.push(rm.init_manager({
                      assessment: assessment,
                      response: response,
                      room: room,
                      room_users: room_users,
                      title: title,
                      readonly: true,
                      admin: true,
                      trat: true
                    }));
                    return ember['default'].RSVP.all(promises).then(function(rms) {
                      var sorted_rms;
                      sorted_rms = rms.sortBy('title');
                      _this.set_data_value('trat_response_managers', sorted_rms);
                      return resolve(sorted_rms);
                    });
                  });
                });
              });
            });
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});