define('thinkspace-indented-list/mixins/item/change', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    change_item_pos_x: function(items, delta_x) {
      if (ember['default'].isBlank(items)) {
        return;
      }
      return ember['default'].makeArray(items).forEach((function(_this) {
        return function(item) {
          var pos_x;
          pos_x = item.pos_x + delta_x;
          _this.set_item_pos_x(item, pos_x);
          return _this.reset_item_component_indent(item);
        };
      })(this));
    },
    change_item_position: function(item, to_index, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var children, index, map, new_index, num_items;
          if (ember['default'].isBlank(to_index)) {
            return resolve();
          }
          children = _this.get_item_change_position_children(item);
          index = _this.value_items.indexOf(item);
          num_items = children.length + 1;
          new_index = index < to_index ? to_index - num_items : to_index;
          map = _this.new_options_item_property_map(options);
          _this.populate_item_component_property_map(item, map);
          _this.populate_item_component_property_map(children, map);
          _this.value_items.removeAt(index, num_items);
          options.children = children;
          return _this.change_item_position_items(item, new_index, options).then(function() {
            return resolve();
          });
        };
      })(this));
    },
    change_item_position_items: function(item, index, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var children, delta_x, pos_x;
          children = options.children || [];
          pos_x = options.pos_x;
          if (ember['default'].isPresent(pos_x)) {
            delta_x = pos_x - item.pos_x;
            _this.set_item_pos_x(item, pos_x);
          }
          _this.value_items.insertAt(index, item);
          children.forEach(function(child_item) {
            index += 1;
            if (ember['default'].isPresent(delta_x)) {
              child_item.pos_x += delta_x;
            }
            return _this.value_items.insertAt(index, child_item);
          });
          return ember['default'].run.schedule('afterRender', _this, function() {
            _this.restore_item_component_properties(options);
            _this.select_item(item);
            return resolve();
          });
        };
      })(this));
    },
    get_change_item_index_from_sibling_element: function(sibling) {
      if (ember['default'].isBlank(sibling)) {
        return this.get_value_items_bottom_index();
      }
      return this.get_change_item_index_from_sibling_item(this.get_visible_item($(sibling)));
    },
    get_change_item_index_from_sibling_item: function(sibling_item) {
      if (!(sibling_item && ember['default'].isPresent(sibling_item))) {
        return this.get_value_items_bottom_index();
      }
      return this.value_items.indexOf(sibling_item);
    },
    get_item_change_position_children: function(item) {
      if (this.include_item_children_on_change(item)) {
        return this.get_item_children(item, false);
      } else {
        return [];
      }
    },
    get_visible_item: function($el) {
      var $next;
      if (this.is_element_visible($el)) {
        return this.get_element_item($el);
      }
      $next = this.get_visible_next($el);
      return ember['default'].isPresent($next) && this.get_element_item($next);
    },
    include_item_children_on_change: function(item) {
      return this.has_item_children(item) && !this.are_item_children_visible(item);
    },
    is_element_visible: function($el) {
      return this.is_item_visible(this.get_element_item($el));
    },
    is_item_visible: function(item) {
      return item && this.get_item_component(item).get('item_visible');
    },
    are_item_children_visible: function(item) {
      return this.get_item_component(item).get('children_visible');
    },
    map_component_properties: ['item_visible', 'children_visible', 'is_selected', 'overflow_visible'],
    new_options_item_property_map: function(options) {
      return options.item_property_map = ember['default'].Map.create();
    },
    populate_item_component_property_map: function(items, map) {
      return ember['default'].makeArray(items).forEach((function(_this) {
        return function(item) {
          return map.set(item, _this.get_item_component_properties(item));
        };
      })(this));
    },
    restore_item_component_properties: function(options) {
      var map;
      map = options.item_property_map;
      if (ember['default'].isBlank(map)) {
        return;
      }
      return map.forEach((function(_this) {
        return function(props, item) {
          return _this.set_item_component_properties(item, props);
        };
      })(this));
    },
    get_item_component_properties: function(item) {
      var comp, props;
      props = this.map_component_properties || [];
      comp = this.get_item_component(item);
      return comp.getProperties.apply(comp, props);
    },
    set_item_component_properties: function(item, props) {
      var comp;
      comp = this.get_item_component(item);
      return comp.setProperties(props);
    },
    reset_item_component_indent: function(items) {
      return ember['default'].makeArray(items).forEach((function(_this) {
        return function(item) {
          var comp;
          comp = _this.get_item_component(item);
          return comp.set_indent();
        };
      })(this));
    },
    reset_has_children: function(item) {
      return ember['default'].run.schedule('afterRender', this, (function(_this) {
        return function() {
          _this.reset_prev_item_component_has_children(item);
          _this.reset_next_item_component_has_children(item);
          _this.reset_item_component_has_children(item);
          return _this.reset_number_of_children(item);
        };
      })(this));
    },
    reset_prev_parent_item_component_has_children: function(item) {
      var items, prev_item, prev_parent_item;
      prev_item = this.get_prev_item(item);
      if (!prev_item) {
        return;
      }
      prev_parent_item = this.get_item_parent(prev_item);
      if (prev_parent_item) {
        items = this.get_item_children(prev_parent_item);
        items.push(prev_parent_item);
        return this.reset_item_component_has_children(items);
      } else {
        return this.reset_item_component_has_children(prev_item);
      }
    },
    reset_prev_item_component_has_children: function(item) {
      var prev_item;
      prev_item = this.get_prev_item(item);
      if (prev_item) {
        return this.reset_item_component_has_children(prev_item);
      }
    },
    reset_next_item_component_has_children: function(item) {
      var next_item;
      next_item = this.get_next_item(item);
      if (next_item) {
        return this.reset_item_component_has_children(next_item);
      }
    },
    reset_item_component_has_children: function(items) {
      return ember['default'].makeArray(items).forEach((function(_this) {
        return function(item) {
          var comp;
          comp = _this.get_item_component(item);
          return comp.set_has_children();
        };
      })(this));
    },
    reset_number_of_children: function(item) {
      var comp;
      comp = this.get_item_component(item);
      return comp.set_number_of_children();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});