define('totem-messages/model_validation', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ModelValidation, ___DefaultExportObject___,
    hasProp = {}.hasOwnProperty;

  ModelValidation = (function() {
    function ModelValidation() {}

    ModelValidation.prototype.message_prop = 'validation_model_messages';

    ModelValidation.prototype.handle = function(api, options) {
      var prop, target;
      console.warn('options', options);
      target = options.validation || options.source;
      if (!target) {
        api.throw_error('The model had validation errors but no message target [validation|source] was provided.', options);
      }
      if (prop = options.validation_prop) {
        if (prop === true) {
          prop = this.message_prop;
        }
        this.set_errors_on_target_property(api, target, prop, options);
        return;
      }
      if (this.has_validation_mixin(target)) {
        return this.add_to_validation_mixin_errors(target, options);
      } else {
        return this.set_errors_on_target_property(api, target, this.message_prop, options);
      }
    };

    ModelValidation.prototype.add_to_validation_mixin_errors = function(target, options) {
      var errors;
      errors = this.get_errors_from_options(options);
      return target.add_validation_model_errors(errors);
    };

    ModelValidation.prototype.set_errors_on_target_property = function(api, target, prop, options) {
      var i, key, len, messages, msg, msg_array, ref;
      if (typeof (target.get(prop)) === 'undefined') {
        api.throw_error("The model validation target does not have the property [" + prop + "].", options);
      }
      messages = [];
      ref = this.get_errors_from_options(options);
      for (key in ref) {
        if (!hasProp.call(ref, key)) continue;
        msg_array = ref[key];
        for (i = 0, len = msg_array.length; i < len; i++) {
          msg = msg_array[i];
          messages.push(this.get_message(key, msg, options));
        }
      }
      return target.set(prop, messages);
    };

    ModelValidation.prototype.get_errors_from_options = function(options) {
      var error, errors;
      error = options.error || {};
      if (error.responseJSON) {
        errors = error.responseJSON.errors;
      } else {
        errors = error.errors;
      }
      return errors || {};
    };

    ModelValidation.prototype.get_message = function(key, message, options) {
      return (options.with_key === false && message) || (key + " " + message);
    };

    ModelValidation.prototype.has_validation_mixin = function(target) {
      return target.get('is_validation_mixin_included');
    };

    return ModelValidation;

  })();

  ___DefaultExportObject___ = new ModelValidation;

  exports['default'] = ___DefaultExportObject___;;

});