define('totem/config/require_modules', ['exports', 'ember', 'totem/config'], function (exports, ember, config) {

  'use strict';

  var TotemRequireModules, ___DefaultExportObject___;

  TotemRequireModules = (function() {
    function TotemRequireModules() {
      this.module_names = null;
    }

    TotemRequireModules.prototype.all = function() {
      return this.module_names != null ? this.module_names : this.module_names = Object.keys(window.require.entries);
    };

    TotemRequireModules.prototype.filter_by = function(regex) {
      return this.all().filter(function(mod) {
        return mod.match(regex);
      });
    };

    TotemRequireModules.prototype.config_regex = function(modname) {
      return this.get_regex('_config', modname);
    };

    TotemRequireModules.prototype.factory = function(container, type, path) {
      return container.lookupFactory(type + ":" + path);
    };

    TotemRequireModules.prototype.app_path = function(path) {
      return "" + config.mp + path;
    };

    TotemRequireModules.prototype.require_module = function(path) {
      var e, mod;
      mod = null;
      try {
        return mod = require(path);
      } catch (error) {
        e = error;
      } finally {
        return mod && mod["default"];
      }
    };

    TotemRequireModules.prototype.get_regex = function(dir, modname) {
      var app_name;
      app_name = config.mp.slice(0, -1);
      return new RegExp("^" + app_name + "\/" + dir + "\/.*" + modname + "$");
    };

    TotemRequireModules.prototype.is_string = function(obj) {
      return obj && typeof obj === 'string';
    };

    TotemRequireModules.prototype.is_hash = function(obj) {
      return obj && typeof obj === 'object' && !ember['default'].isArray(obj);
    };

    TotemRequireModules.prototype.is_function = function(fn) {
      return fn && typeof fn === 'function';
    };

    TotemRequireModules.prototype.stringify = function(obj) {
      return JSON.stringify(obj);
    };

    TotemRequireModules.prototype.warn = function(source, message) {
      if (message == null) {
        message = '';
      }
      return console.warn(this.get_source_message(source, message));
    };

    TotemRequireModules.prototype.error = function(source, message) {
      if (message == null) {
        message = '';
      }
      throw new ember['default'].Error(this.get_source_message(source, message));
    };

    TotemRequireModules.prototype.get_source_message = function(source, message) {
      var name;
      name = (this.is_hash(source) && this.is_function(source.toString) && source.toString()) || '';
      if (name) {
        name += ': ';
      }
      return name + message;
    };

    TotemRequireModules.prototype.toString = function() {
      return 'TotemRequireModules';
    };

    return TotemRequireModules;

  })();

  ___DefaultExportObject___ = new TotemRequireModules;

  exports['default'] = ___DefaultExportObject___;;

});