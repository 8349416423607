define('thinkspace-casespace/mixins/services/server_events/events', ['exports', 'ember', 'totem/ns', 'totem/scope'], function (exports, ember, ns, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    handle_server_event: function(data, socketio_event) {
      var event, value;
      value = data.value || {};
      event = value.event;
      console.info('casespace received server_event--->', event, data);
      switch (event) {
        case 'transition_to_phase':
          return this.event_transition_to_phase(value, socketio_event);
        case 'phase_states':
          return this.event_phase_states(value, socketio_event);
        case 'message':
          return this.event_message(value, socketio_event);
      }
    },
    event_transition_to_phase: function(value, socketio_event) {
      return this.load_records_into_store(value).then((function(_this) {
        return function() {
          return _this.change_phase_states(value).then(function() {
            return _this.transition_to_phase(value.transition_to_phase_id);
          });
        };
      })(this));
    },
    event_phase_states: function(value, socketio_event) {
      return this.load_records_into_store(value).then((function(_this) {
        return function() {
          return _this.change_phase_states(value).then(function() {});
        };
      })(this));
    },
    event_message: function(data, socketio_event) {
      var rooms;
      console.info('recevied assignment message:', {
        data: data,
        socketio_event: socketio_event
      });
      if (ember['default'].isBlank(data)) {
        return;
      }
      rooms = data.room || data.rooms;
      if (ember['default'].isBlank(rooms)) {
        data.rooms = socketio_event;
      }
      return this.messages.add(data);
    },
    transition_to_phase: function(phase_id) {
      if (ember['default'].isBlank(phase_id)) {
        return;
      }
      return this.find_phase(phase_id).then((function(_this) {
        return function(phase) {
          return _this.casespace.transition_to_phase(phase);
        };
      })(this));
    },
    change_phase_states: function(value) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (ember['default'].isBlank(value)) {
            return resolve();
          }
          return _this.lock_phase_states(value.lock_phase_ids).then(function() {
            return _this.complete_phase_states(value.complete_phase_ids).then(function() {
              return _this.unlock_phase_states(value.unlock_phase_ids).then(function() {
                return resolve();
              });
            });
          });
        };
      })(this));
    },
    lock_phase_states: function(ids) {
      return this.update_phase_states('lock', ids);
    },
    unlock_phase_states: function(ids) {
      return this.update_phase_states('unlock', ids);
    },
    complete_phase_states: function(ids) {
      return this.update_phase_states('complete', ids);
    },
    update_phase_states: function(fn, phase_ids) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var i, len, phase_id, ref, results;
          if (ember['default'].isBlank(phase_ids)) {
            return resolve();
          }
          ref = ember['default'].makeArray(phase_ids);
          results = [];
          for (i = 0, len = ref.length; i < len; i++) {
            phase_id = ref[i];
            results.push(_this.get_phase_states(phase_id).then(function(phase_states) {
              phase_states.forEach(function(phase_state) {
                return phase_state[fn]();
              });
              return resolve();
            }));
          }
          return results;
        };
      })(this));
    },
    get_phase_states: function(phase_id) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (ember['default'].isBlank(phase_id)) {
            return resolve([]);
          }
          return _this.find_phase(phase_id).then(function(phase) {
            if (ember['default'].isPresent(phase)) {
              return resolve(_this.pm.map.get_current_user_phase_states(phase));
            } else {
              return resolve([]);
            }
          });
        };
      })(this));
    },
    find_phase: function(id) {
      return this.find_record(ns['default'].to_p('phase'), id);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});