define('thinkspace-casespace/phase_manager_map', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/scope', 'totem-messages/messages'], function (exports, ember, ns, ajax, totem_scope, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    toString: function() {
      return 'PhaseManagerMap';
    },
    ownerable_phase_values: null,
    get_ownerable: function() {
      return totem_scope['default'].get_ownerable_record();
    },
    get_current_user: function() {
      return totem_scope['default'].get_current_user();
    },
    has_ownerable_phase_states: function(assignment) {
      return this.get_or_init_ownerable_map().get(assignment) === true;
    },
    set_map_without_phase_states: function(assignment) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.set_ownerable_phase_map(assignment, {}).then(function() {
            return resolve();
          });
        };
      })(this));
    },
    set_map: function(assignment) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          if (_this.has_ownerable_phase_states(assignment)) {
            return _this.reset_ownerable_phase_states(assignment).then(function() {
              return resolve();
            });
          } else {
            query = {
              model: assignment,
              id: assignment.get('id'),
              action: 'phase_states',
              data: {}
            };
            totem_scope['default'].add_ownerable_to_query(query.data);
            return ajax['default'].object(query).then(function(payload) {
              return _this.set_ownerable_phase_map(assignment, payload).then(function() {
                _this.get_or_init_ownerable_map().set(assignment, true);
                return resolve();
              });
            });
          }
        };
      })(this));
    },
    set_ownerable_phase_map: function(assignment, payload) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var phase_scores, phase_states, phases;
          _this.push_phase_state_metadata_in_store(assignment, payload);
          phases = _this.push_association_in_store(assignment, payload, 'phase');
          phase_states = _this.push_association_in_store(assignment, payload, 'phase_state');
          phase_scores = _this.push_association_in_store(assignment, payload, 'phase_score');
          phases.forEach(function(phase) {
            var phase_map, phase_states_for_phase;
            phase_map = _this.get_or_init_ownerable_phase_map(phase);
            phase_states_for_phase = _this.get_phase_states_for_phase(phase, phase_states);
            phase_map.set('phase_states', phase_states_for_phase);
            return phase_map.set('selected_phase_state', _this.get_selected_phase_state_for_ownerable(phase, phase_states_for_phase));
          });
          return resolve();
        };
      })(this));
    },
    get_selected_phase_state_for_ownerable: function(phase, phase_states) {
      var filtered;
      if (this.ownerable_has_multiple_phase_states(phase)) {
        if (phase.is_team_ownerable()) {
          filtered = this.filter_phase_states_by_is_team_ownerable(phase_states, true);
          if (filtered.get('length') === 0 && phase_states.get('length') > 0) {
            filtered = phase_states;
          }
          return filtered.get('firstObject');
        } else {
          filtered = this.filter_phase_states_by_is_team_ownerable(phase_states, false);
          return filtered.get('firstObject');
        }
      } else {
        return phase_states.get('firstObject');
      }
    },
    filter_phase_states_by_is_team_ownerable: function(phase_states, is_team_ownerable) {
      return phase_states.filter((function(_this) {
        return function(phase_state) {
          return phase_state.is_team_ownerable() === is_team_ownerable;
        };
      })(this));
    },
    get_phase_states_for_phase: function(phase, phase_states) {
      var filtered_team, filtered_user, phase_id;
      phase_id = parseInt(phase.get('id'));
      phase_states = (phase_states.filter((function(_this) {
        return function(state) {
          return state.get('phase_id') === phase_id;
        };
      })(this))).sortBy('title');
      if (phase.is_team_ownerable()) {
        filtered_team = this.filter_phase_states_by_is_team_ownerable(phase_states, true);
        filtered_user = this.filter_phase_states_by_is_team_ownerable(phase_states, false);
        if (filtered_team.get('length') === 0 && filtered_user.get('length') > 0) {
          return filtered_user;
        } else {
          return filtered_team;
        }
      } else {
        return phase_states;
      }
    },
    push_association_in_store: function(assignment, payload, association) {
      var norm_records, records, type;
      type = ns['default'].to_p(association);
      records = payload[type.pluralize()];
      if (ember['default'].isBlank(records)) {
        return [];
      }
      norm_records = records.map((function(_this) {
        return function(record) {
          return assignment.store.normalize(type, record);
        };
      })(this));
      return assignment.store.pushMany(type, norm_records);
    },
    push_phase_state_metadata_in_store: function(assignment, payload) {
      var norm_records, records, type;
      type = ns['default'].to_p('metadata');
      records = payload[type];
      if (ember['default'].isBlank(records)) {
        return [];
      }
      norm_records = records.map((function(_this) {
        return function(record) {
          return assignment.store.normalize(type, record);
        };
      })(this));
      return assignment.store.pushMany(type, norm_records);
    },
    reset_ownerable_phase_states: function(assignment) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return assignment.get(ns['default'].to_p('phases')).then(function(phases) {
            var phase_state_association_promises;
            phase_state_association_promises = phases.getEach(ns['default'].to_p('phase_states'));
            return ember['default'].RSVP.Promise.all(phase_state_association_promises).then(function(phase_state_associations) {
              phases.forEach(function(phase, index) {
                var phase_phase_states, phase_states;
                phase_states = _this.get_ownerable_phase_states(phase) || [];
                phase_phase_states = phase_state_associations.objectAt(index);
                return phase_states.forEach(function(phase_state) {
                  if (!phase_phase_states.contains(phase_state)) {
                    return phase_phase_states.pushObject(phase_state);
                  }
                });
              });
              return resolve();
            });
          });
        };
      })(this));
    },
    get_current_user_phase_states: function(phase) {
      var phase_states;
      phase_states = ember['default'].makeArray(this.get_current_user_phase_map(phase).get('phase_states'));
      return ember['default'].makeArray(phase_states);
    },
    get_ownerable_phase_states: function(phase) {
      var phase_states;
      phase_states = this.get_or_init_ownerable_phase_map(phase).get('phase_states');
      return ember['default'].makeArray(phase_states);
    },
    set_global_selected_phase_state: function(phase_state) {
      return this.get_or_init_ownerable_map().set('global_selected_phase_state', phase_state);
    },
    get_global_selected_phase_state: function() {
      return this.get_or_init_ownerable_map().get('global_selected_phase_state');
    },
    set_phase_selected_phase_state: function(phase, phase_state) {
      return this.get_or_init_ownerable_phase_map(phase).set('selected_phase_state', phase_state);
    },
    get_phase_selected_phase_state: function(phase) {
      return this.get_or_init_ownerable_phase_map(phase).get('selected_phase_state');
    },
    ownerable_has_multiple_phase_states: function(phase) {
      return (this.get_ownerable_phase_states(phase) || []).get('length') > 1;
    },
    find_ownerable_phase_states: function(phase, ownerable) {
      return this.find_ownerable_phase_map_value(phase, ownerable, 'phase_states') || [];
    },
    find_ownerable_selected_phase_state: function(phase, ownerable) {
      return this.find_ownerable_phase_map_value(phase, ownerable, 'selected_phase_state');
    },
    find_ownerable_phase_map_value: function(phase, ownerable, key) {
      var map, ownerable_map, phase_map;
      map = this.get_or_init_map();
      ownerable_map = map.get(ownerable);
      if (!ownerable_map) {
        return null;
      }
      phase_map = ownerable_map.get(phase);
      if (!phase_map) {
        return null;
      }
      return phase_map.get(key);
    },
    find_phase_state_ownerable_in_phase_states: function(phase_state, phase_states) {
      var id, type;
      if (!phase_state) {
        return null;
      }
      if (ember['default'].isBlank(phase_states)) {
        return null;
      }
      id = phase_state.get('ownerable_id');
      type = phase_state.get('ownerable_type');
      return phase_states.find((function(_this) {
        return function(state) {
          return id === state.get('ownerable_id') && type === state.get('ownerable_type');
        };
      })(this));
    },
    new_map: function() {
      return ember['default'].Map.create();
    },
    get_map: function() {
      return this.get('ownerable_phase_values');
    },
    get_or_init_map: function() {
      var map;
      map = this.get_map();
      if (!map) {
        this.set('ownerable_phase_values', this.new_map());
        map = this.get_map();
      }
      return map;
    },
    get_or_init_ownerable_map: function(ownerable) {
      var map, ownerable_map;
      if (ownerable == null) {
        ownerable = null;
      }
      map = this.get_or_init_map();
      if (ownerable == null) {
        ownerable = this.get_ownerable();
      }
      ownerable_map = map.get(ownerable);
      if (!ownerable_map) {
        map.set(ownerable, this.new_map());
        ownerable_map = map.get(ownerable);
      }
      return ownerable_map;
    },
    get_or_init_ownerable_phase_map: function(phase, ownerable) {
      var ownerable_map, phase_map;
      if (ownerable == null) {
        ownerable = null;
      }
      ownerable_map = this.get_or_init_ownerable_map(ownerable);
      phase_map = ownerable_map.get(phase);
      if (!phase_map) {
        ownerable_map.set(phase, this.new_map());
        phase_map = ownerable_map.get(phase);
      }
      return phase_map;
    },
    get_current_user_phase_map: function(phase) {
      return this.get_or_init_ownerable_phase_map(phase, this.get_current_user());
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});