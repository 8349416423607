define('thinkspace-resource/mixins/resources', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create(ta['default'].add(ta['default'].has_many('files', {
    reads: {
      sort: ['title:asc', 'file_updated_at:asc']
    }
  }), ta['default'].has_many('links', {
    reads: {
      sort: 'title:asc'
    }
  }), ta['default'].has_many('tags', {
    reads: {
      sort: 'title:asc'
    }
  })), {
    has_resources_mixin: true,
    has_files: ember['default'].computed.notEmpty(ta['default'].to_p('files')),
    has_links: ember['default'].computed.notEmpty(ta['default'].to_p('links')),
    has_resources: ember['default'].computed.or('has_files', 'has_links'),
    resources_length: ember['default'].computed(ta['default'].to_prop('files', '@each'), ta['default'].to_prop('links', '@each'), function() {
      return this.get('files.length') + this.get('links.length');
    }),
    tagless_files: ember['default'].computed(ta['default'].to_prop('files', '@each.tag'), function() {
      return this.get(ta['default'].to_p('files')).reject(function(file) {
        return file.get(ta['default'].to_prop('tags', 'length')) > 0;
      });
    }),
    tagless_links: ember['default'].computed(ta['default'].to_prop('links', '@each.tag'), function() {
      return this.get(ta['default'].to_p('links')).reject(function(link) {
        return link.get(ta['default'].to_prop('tags', 'length')) > 0;
      });
    }),
    tagless_resources: ember['default'].computed('tagless_files', 'tagless_links', function() {
      return this.get('tagless_files').concat(this.get('tagless_links'));
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});