define('totem-simple-auth/authenticator', ['exports', 'ember', 'totem/ns', 'totem/config', 'totem/ajax', 'totem/util', 'totem/scope', 'totem-messages/messages', 'simple-auth/authenticators/base'], function (exports, ember, ns, config, ajax, util, totem_scope, totem_messages, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    restore: function(data) {
      console.warn('authenticator restore', data, ajax['default'].adapter_host());
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query, url, validate_user_url;
          if (!(data.token && data.email && data.user_id)) {
            return reject();
          }
          validate_user_url = config['default'].simple_auth && config['default'].simple_auth.validate_user_url;
          if (!validate_user_url) {
            return reject();
          }
          if (util['default'].starts_with(validate_user_url, 'http')) {
            url = validate_user_url;
          } else {
            url = ajax['default'].adapter_host();
            if (!url) {
              return reject();
            }
            if (!(util['default'].ends_with(url, '/') || util['default'].starts_with(validate_user_url, '/'))) {
              url += '/';
            }
            url += validate_user_url;
          }
          query = {
            url: url,
            data: {
              user_id: data.user_id
            },
            beforeSend: function(jqXHR) {
              var auth;
              auth = 'token' + '="' + data.token + '", ' + 'email' + '="' + data.email + '"';
              return jqXHR.setRequestHeader('Authorization', 'Token ' + auth);
            },
            success: function(payload) {
              var metadata, store, type, user;
              store = _this.get_store();
              type = ns['default'].to_p('user');
              metadata = ns['default'].to_p('metadata');
              user = store.push(type, store.normalize(type, payload[type]));
              totem_scope['default'].set_current_user(user);
              if (payload[metadata]) {
                store.pushMany(metadata, store.normalize(metadata, payload[metadata]));
              }
              return resolve(data);
            },
            error: function(error) {
              return reject();
            }
          };
          return ember['default'].$.ajax(query);
        };
      })(this));
    },
    authenticate: function(options) {
      totem_messages['default'].clear_all();
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var local_store, query, store;
          query = {
            model: ns['default'].to_p('user'),
            verb: 'post',
            action: 'sign_in',
            data: options,
            skip_message: true
          };
          store = _this.get_store();
          local_store = _this.get_local_store();
          if (!store) {
            return reject('totem_simple_auth authenticate store is blank.');
          }
          if (!local_store) {
            return reject('totem_simple_auth authenticate local store is blank.');
          }
          local_store.set('isAuthenticated', false);
          return ajax['default'].object(query).then(function(payload) {
            var metadata, type, user;
            type = ns['default'].to_p('user');
            metadata = ns['default'].to_p('metadata');
            user = store.push(type, store.normalize(type, payload[type]));
            totem_scope['default'].set_current_user(user);
            if (payload[metadata]) {
              store.pushMany(metadata, store.normalize(metadata, payload[metadata]));
            }
            return resolve({
              token: payload.token,
              email: user.get('email'),
              user_id: user.get('id'),
              switch_user: false,
              original_user: true,
              original_user_id: user.get('id')
            });
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    invalidate: function(data) {
      console.warn('authenticator invalidate', data);
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = {
            model: ns['default'].to_p('user'),
            verb: 'post',
            action: 'sign_out',
            skip_message: true
          };
          return ajax['default'].object(query).then(function(payload) {
            return resolve();
          }, function(error) {
            return resolve();
          });
        };
      })(this));
    },
    get_store: function() {
      var container;
      container = ajax['default'].get_container();
      if (!container) {
        return null;
      }
      return container.lookup('store:main');
    },
    get_local_store: function() {
      var container;
      container = ajax['default'].get_container();
      if (!container) {
        return null;
      }
      return container.lookup('simple-auth-session:main');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});