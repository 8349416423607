define('totem/mixins/data/base_data', ['exports', 'ember', 'totem/scope', 'totem/mixins/data/ability', 'totem/mixins/data/metadata', 'totem/mixins/data/queue'], function (exports, ember, totem_scope, totem_ability, totem_metadata, totem_queue) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    data_names: ['ability', 'metadata'],
    source: null,
    init: function() {
      this._super();
      return this.setup_totem_data();
    },
    setup_totem_data: function() {
      var config;
      config = this.get('source.totem_data_config') || {};
      if (!this.is_object(config)) {
        console.error("totem_data_config property in " + (this.source.toString()) + " must be a hash e.g. {ability: {}} not:", config);
        return;
      }
      this.totem_data_config = config;
      this.all_config = this.get_all_data_names_config();
      return this.include_totem_data_modules();
    },
    include_totem_data_modules: function() {
      this.include_totem_data_module('ability', totem_ability['default']);
      return this.include_totem_data_module('metadata', totem_metadata['default']);
    },
    include_totem_data_module: function(data_name, mod) {
      var base_name, inst, mod_config, mod_name;
      mod_config = this.merge_all_and_mod_configs(data_name);
      if (!mod_config) {
        return;
      }
      base_name = 'totem_data';
      mod_name = "" + (base_name.classify()) + (data_name.classify());
      inst = mod.create({
        data_name: data_name,
        base_name: base_name,
        mod_name: mod_name,
        totem_scope: totem_scope['default'],
        requests_queue: totem_queue['default'],
        totem_data_config: mod_config,
        totem_data: this
      });
      this.set(data_name, inst);
      return inst.init_values(this.source);
    },
    merge_all_and_mod_configs: function(data_name) {
      var all, mod;
      mod = this.totem_data_config[data_name];
      if (mod === true) {
        mod = {};
      }
      if (!this.is_object(mod)) {
        return null;
      }
      all = ember['default'].merge({}, this.all_config);
      return ember['default'].merge(all, mod);
    },
    get_all_data_names_config: function() {
      var all, key, ref, value;
      all = {};
      ref = this.totem_data_config;
      for (key in ref) {
        value = ref[key];
        if (!this.data_names.contains(key)) {
          all[key] = value;
        }
      }
      return all;
    },
    set_source_property: function(prop, value) {
      if (value == null) {
        value = {};
      }
      if (typeof this.source[prop] !== 'undefined') {
        console.warn("totem_data: '" + prop + "' is already defined in " + (this.source.toString()) + ".  The component's '" + prop + "' property is being overwritten.");
      }
      return this.source.set(prop, value);
    },
    define_source_property: function(prop, tab_prop) {
      var message, path;
      path = prop + "." + tab_prop;
      if (typeof this.source[tab_prop] === 'undefined') {
        return ember['default'].defineProperty(this.source, tab_prop, ember['default'].computed.reads(path));
      } else {
        message = "totem_data: '" + tab_prop + "' is already defined in " + (this.source.toString()) + ".  ";
        message += "Will need to use the full path '" + path + "' or rename the component's '" + tab_prop + "' property.";
        return console.warn(message);
      }
    },
    is_object: function(object) {
      if (ember['default'].isBlank(object)) {
        return false;
      }
      return typeof object === 'object' && !ember['default'].isArray(object);
    },
    toString: function() {
      return 'TotemData';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});