define('thinkspace-readiness-assurance/mixins/cm/helpers', ['exports', 'ember', 'totem/error'], function (exports, ember, totem_error) {

  'use strict';

  var ___DefaultExportObject___,
    slice = [].slice;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    current_user_full_name: function() {
      return this.rm.current_user_full_name();
    },
    error: function() {
      var args, message;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      message = args.shift() || '';
      if (ember['default'].isPresent(args)) {
        console.error(message, args);
      }
      return totem_error['default']["throw"](this, message);
    },
    toString: function() {
      return 'ReadinessAssuranceChatManager:' + ember['default'].guidFor(this);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});