define('thinkspace-indented-list/mixins/helpers', ['exports', 'ember', 'totem/error'], function (exports, ember, totem_error) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    get_element_item: function($el) {
      return this.get_element_component($el).get('item');
    },
    get_element_component: function($el) {
      return this.el_id_to_component.get($el.attr('id'));
    },
    get_item_component: function(item) {
      return this.item_to_component.get(item);
    },
    get_item_element: function(item) {
      var comp;
      comp = this.get_item_component(item);
      return comp && comp.$();
    },
    get_sibling: function(sibling) {
      var $sibling;
      $sibling = $(sibling);
      if ($sibling.hasClass(this.default_list_end_class())) {
        return null;
      } else {
        return sibling;
      }
    },
    get_minimum_pos_x: function() {
      return this.value_items.mapBy('pos_x').sort().shift() || 0;
    },
    set_item_pos_x: function(item, pos_x) {
      return ember['default'].set(item, 'pos_x', pos_x);
    },
    set_item_pos_y: function(item, pos_y) {
      return ember['default'].set(item, 'pos_y', pos_y);
    },
    set_items_pos_y: function(items) {
      return items.forEach((function(_this) {
        return function(item, index) {
          return _this.set_item_pos_y(item, index);
        };
      })(this));
    },
    is_jquery_object: function(obj) {
      return obj && jQuery && (obj instanceof jQuery);
    },
    is_function: function(fn) {
      return typeof fn === 'function';
    },
    is_object: function(obj) {
      return obj && typeof obj === 'object';
    },
    is_hash: function(obj) {
      return this.is_object(obj) && !ember['default'].isArray(obj);
    },
    debug_items: function(items) {
      var msg;
      msg = [];
      msg.push("Items:" + items.length + " ->");
      items.map((function(_this) {
        return function(item) {
          return msg.push("[y:" + item.pos_y + " id:" + item.itemable_id + "]");
        };
      })(this));
      return console.info(msg.join(', '));
    },
    stringify: function(obj) {
      return JSON.stringify(obj);
    },
    error: function(message) {
      if (message == null) {
        message = '';
      }
      return totem_error['default']["throw"](this, message);
    },
    toString: function() {
      return 'ResponseManager';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});