define('totem/mixins/data/base_module', ['exports', 'ember', 'ember-data', 'totem/ns', 'totem/ajax'], function (exports, ember, ds, ns, ajax) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    refresh: function() {
      return this.set_data();
    },
    unload: function(unload_record) {
      var id, record;
      if (unload_record == null) {
        unload_record = null;
      }
      id = this.generate_model_id(unload_record);
      if (ember['default'].isBlank(id)) {
        console.error(this.mod_name + ".unload could not generate an model id from:", unload_record, this.get_ownerable());
        return;
      }
      record = this.find_store_record_by_id(id);
      if (!ember['default'].isPresent(record)) {
        return;
      }
      return record.unloadRecord();
    },
    init_values: function(source) {
      if (!this.is_object(source)) {
        console.error(this.mod_name + ": init_values source is not not an object:", source);
        return;
      }
      this.set_source(source);
      this.source_name = source.toString();
      if (this.totem_data_config.current_user_observer === true) {
        this.add_current_user_observer();
      }
      if (this.totem_data_config.unload === true) {
        return this.unload();
      }
    },
    set_data: function() {
      return console.error(this.mod_name + ": 'set_data' function not implemented.");
    },
    add_current_user_observer: function() {
      return this.addObserver('totem_scope.current_user', this, 'current_user_switch');
    },
    current_user_switch: function() {
      return ember['default'].run.next((function(_this) {
        return function() {
          if (ember['default'].isBlank(_this.get_current_user())) {
            return;
          }
          return _this.refresh();
        };
      })(this));
    },
    get_data: function(ownerable) {
      if (ownerable == null) {
        ownerable = null;
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.resolve_source_model().then(function() {
            if (_this.is_record(_this.get_source_model())) {
              return _this.get_store_record_data(ownerable).then(function(data) {
                return resolve(data);
              });
            } else {
              return _this.get_store_value_data(ownerable).then(function(data) {
                return resolve(data);
              });
            }
          });
        };
      })(this));
    },
    resolve_source_model: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var model;
          model = _this.get_source_model();
          if (!_this.is_promise(model)) {
            return resolve(model);
          }
          return model.then(function(resolved) {
            _this.set_resolved_model(resolved);
            return resolve(resolved);
          });
        };
      })(this));
    },
    get_store_record_data: function(ownerable) {
      if (ownerable == null) {
        ownerable = null;
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var id;
          if (ownerable) {
            id = _this.generate_model_id(null, ownerable);
          } else {
            id = _this.generate_model_id();
          }
          return _this.get_store_record_data_by_id(id).then(function(data) {
            var fn, record, value;
            fn = "add_" + _this.data_name;
            record = _this.get_source_model();
            if (_this.is_object(data)) {
              return _this.call_object_function(record, fn, data).then(function() {
                return resolve(data);
              });
            } else {
              value = _this.get_ajax_source_property();
              if (ember['default'].isBlank(value)) {
                return resolve({});
              }
              return _this.get_ajax_data(id).then(function(data) {
                if (ember['default'].isBlank(data)) {
                  return resolve(null);
                }
                return _this.call_object_function(record, fn, data).then(function(data) {
                  _this.add_store_record(id, data);
                  return resolve(data);
                });
              });
            }
          });
        };
      })(this));
    },
    get_store_value_data: function(ownerable) {
      if (ownerable == null) {
        ownerable = null;
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var id, value;
          value = _this.get_ajax_source_property();
          if (ember['default'].isBlank(value)) {
            return resolve({});
          }
          id = _this.generate_value_id(value);
          return _this.get_store_record_data_by_id(id).then(function(data) {
            if (_this.is_object(data)) {
              return resolve(data);
            }
            return _this.get_ajax_data(id).then(function(data) {
              if (ember['default'].isBlank(data)) {
                return resolve(null);
              }
              _this.add_store_record(id, data);
              return resolve(data);
            });
          });
        };
      })(this));
    },
    get_ajax_data: function(id) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var value;
          value = _this.get_ajax_source_property();
          if (ember['default'].isBlank(value)) {
            return resolve(null);
          }
          return _this.send_ajax_request(id).then(function(data) {
            return resolve(data);
          });
        };
      })(this));
    },
    get_store_record_data_by_id: function(id) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var data, prop, record;
          if (ember['default'].isBlank(id)) {
            return resolve(null);
          }
          record = _this.find_store_record_by_id(id);
          if (record) {
            prop = _this.get_data_property();
            data = record.get(prop);
            if (_this.is_object(data)) {
              return resolve(ember['default'].merge({}, data));
            } else {
              return resolve(null);
            }
          } else {
            return resolve(null);
          }
        };
      })(this));
    },
    generate_model_id: function(record, ownerable) {
      var model, model_id, model_type, ownerable_id, ownerable_type;
      if (record == null) {
        record = null;
      }
      if (ownerable == null) {
        ownerable = null;
      }
      model = record || this.get_source_model();
      ownerable = ownerable || this.get_ownerable();
      if (!(this.is_record(model) && this.is_record(ownerable))) {
        return null;
      }
      model_id = model.get('id');
      model_type = this.get_record_type(model);
      ownerable_id = ownerable.get('id');
      ownerable_type = this.get_record_type(ownerable);
      return model_type + "." + model_id + "::" + ownerable_type + "." + ownerable_id;
    },
    generate_value_id: function(value) {
      var ownerable, ownerable_id, ownerable_type;
      ownerable = this.get_ownerable();
      ownerable_id = ownerable.get('id');
      ownerable_type = this.get_record_type(ownerable);
      return value + "::" + ownerable_type + "." + ownerable_id;
    },
    add_store_record: function(id, data) {
      var obj, record, type;
      record = this.find_store_record_by_id(id);
      if (ember['default'].isPresent(record)) {
        this.delete_queue_request(id);
        return;
      }
      type = this.get_model_type();
      record = (
        obj = {
          id: id
        },
        obj["" + (this.get_data_property())] = data,
        obj
      );
      this.get_store().push(type, record);
      return this.delete_queue_request(id);
    },
    find_store_record_by_id: function(id) {
      var type;
      type = this.get_model_type();
      return this.get_store().getById(type, id);
    },
    send_ajax_request: function(id) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var auth, method, model, ownerable, query, type, value;
          if (_this.queue_request(id)) {
            return resolve(null);
          }
          model = _this.get_source_model();
          ownerable = _this.get_ownerable();
          type = _this.get_model_type();
          value = _this.get_ajax_source_property();
          method = _this.get_ajax_method_property();
          query = {
            model: type,
            verb: 'get',
            action: _this.data_name.pluralize(),
            data: {
              auth: {
                ownerable_id: ownerable.get('id'),
                ownerable_type: _this.get_record_type(ownerable),
                source: _this.is_string(value) ? value : void 0,
                source_method: _this.is_string(method) ? method : void 0
              }
            }
          };
          if (_this.is_record(model)) {
            auth = query.data.auth;
            auth.model_id = model.get('id');
            auth.model_type = _this.get_record_type(model);
          }
          return ajax['default'].object(query).then(function(payload) {
            return resolve(payload);
          });
        };
      })(this));
    },
    queue_request: function(id) {
      var queue;
      queue = this.get_requests_queue();
      if (ember['default'].isBlank(queue)) {
        return false;
      }
      return queue.queue_request(this.get_source(), this.data_name, id);
    },
    delete_queue_request: function(id) {
      var queue;
      queue = this.get_requests_queue();
      if (ember['default'].isBlank(queue)) {
        return false;
      }
      return queue.delete_queue_request(this.data_name, id);
    },
    get_requests_queue: function() {
      return this.get('requests_queue');
    },
    call_source_callback: function(data) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var fn;
          fn = _this.get_source_ajax_callback();
          return _this.call_object_function(_this.get_source(), fn, data).then(function() {
            return resolve();
          });
        };
      })(this));
    },
    call_object_function: function(object, fn, data) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var response;
          if (_this.is_fn(object[fn])) {
            response = object[fn](data, _this);
            if (_this.is_object(response) && _this.is_fn(response.then)) {
              return response.then(function() {
                return resolve(data);
              });
            } else {
              return resolve(data);
            }
          } else {
            return resolve(data);
          }
        };
      })(this));
    },
    process_source_data: function() {
      var source;
      if (this.source_is_destroyed()) {
        return false;
      }
      source = this.get_source();
      if (source.get(this.base_name + "_module_only") === true) {
        return false;
      }
      if (source.get(this.base_name + "_" + this.data_name + "_module_only") === true) {
        return false;
      }
      return true;
    },
    get_store: function() {
      return this.totem_scope.get_store();
    },
    get_current_user: function() {
      return this.totem_scope.get_current_user();
    },
    get_ownerable: function() {
      return this.totem_scope.get_ownerable_record();
    },
    get_record_type: function(record) {
      return this.totem_scope.get_record_path(record);
    },
    get_model_type: function() {
      return ns['default'].to_p(this.data_name);
    },
    get_data_property: function() {
      return this.data_name.pluralize();
    },
    get_source: function() {
      return this.get('source_component');
    },
    set_source: function(source) {
      return this.set('source_component', source);
    },
    get_resolved_model: function() {
      return this.get('resolved_model');
    },
    set_resolved_model: function(model) {
      return this.set('resolved_model', model);
    },
    get_ajax_source_property: function() {
      return this.totem_data_config.ajax_source;
    },
    get_ajax_method_property: function() {
      return this.totem_data_config.ajax_method;
    },
    get_source_ajax_callback: function() {
      return this.totem_data_config.callback;
    },
    get_source_model_property: function() {
      return this.totem_data_config.model || 'model';
    },
    source_is_destroyed: function() {
      return this.get_source().get('isDestroyed') || this.get_source().get('isDestroying');
    },
    get_source_model: function() {
      var resolved, source;
      source = this.get_source();
      if (this.is_record(source)) {
        return source;
      }
      resolved = this.get_resolved_model();
      if (this.is_record(resolved)) {
        return resolved;
      }
      return source.get(this.get_source_model_property());
    },
    get_object_keys: function(object) {
      return Object.keys(object);
    },
    is_record: function(model) {
      return model && (model instanceof ds['default'].Model);
    },
    is_promise: function(model) {
      return model && (model instanceof ds['default'].PromiseObject);
    },
    is_object: function(object) {
      if (ember['default'].isBlank(object)) {
        return false;
      }
      return typeof object === 'object' && !ember['default'].isArray(object);
    },
    is_string: function(str) {
      return typeof str === 'string';
    },
    is_fn: function(fn) {
      return typeof fn === 'function';
    },
    get_inverse_abilities: function(abilities) {
      var inverse;
      inverse = {};
      this.get_object_keys(abilities).map((function(_this) {
        return function(key) {
          return inverse[key] = !abilities[key];
        };
      })(this));
      return inverse;
    },
    convert_values_to: function(hash, tf) {
      var value;
      value = this.boolean_value(tf);
      return this.get_object_keys(hash).map((function(_this) {
        return function(key) {
          return hash[key] = value;
        };
      })(this));
    },
    convert_to_boolean: function(hash) {
      return this.get_object_keys(hash).map((function(_this) {
        return function(key) {
          var tf;
          tf = _this.boolean_value(hash[key]);
          if (hash[key] !== tf) {
            return hash[key] = tf;
          }
        };
      })(this));
    },
    boolean_value: function(value) {
      return !!value;
    },
    print_header: function() {
      return console.log((this.toString()) + " ->", this.source_name);
    },
    print_data: function(data) {
      return this.get_object_keys(data).sort().map((function(_this) {
        return function(key) {
          return console.info("  " + key + " = ", data[key]);
        };
      })(this));
    },
    toString: function() {
      return this.mod_name + ':' + ember['default'].guidFor(this);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});