define('totem/components/base', ['exports', 'ember'], function (exports, ember) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Component.extend();

	exports['default'] = ___DefaultExportObject___;;

});