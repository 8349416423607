define('totem-application/pagination/array', ['exports', 'ember', 'totem/ajax'], function (exports, ember, ajax) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].ArrayProxy.extend({
    links: null,
    meta: null,
    type: null,
    all_content: null,
    is_paginated: true,
    is_loading: false,
    has_next_page: ember['default'].computed.notEmpty('links.next'),
    has_prev_page: ember['default'].computed.notEmpty('links.prev'),
    total_pages: ember['default'].computed.reads('meta.page.total'),
    total_records: ember['default'].computed.reads('meta.total'),
    current_page: ember['default'].computed.reads('meta.page.current'),
    init: function() {
      return this.set('all_content', []);
    },
    get_first_page: function(options) {
      if (options == null) {
        options = {};
      }
      return this.get_page_for_link('first', false, options);
    },
    get_last_page: function(options) {
      if (options == null) {
        options = {};
      }
      return this.get_page_for_link('last', false, options);
    },
    get_next_page: function(options) {
      if (options == null) {
        options = {};
      }
      return this.get_page_for_link('next', false, options);
    },
    get_prev_page: function(options) {
      if (options == null) {
        options = {};
      }
      return this.get_page_for_link('prev', false, options);
    },
    get_page_for_link: function(link, is_url, options) {
      if (is_url == null) {
        is_url = true;
      }
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var params;
          if (!is_url) {
            link = _this.get_pagination_link(link);
          }
          switch (false) {
            case !ember['default'].isPresent(link):
              _this.set_is_loading();
              params = options.params || {};
              options = {
                url: link,
                data: params
              };
              return ajax['default'].object(options).then(function(payload) {
                _this.process_pagination_payload(payload);
                _this.reset_is_loading();
                return resolve(_this);
              }, function(error) {
                return console.error("[pagination:array] Error in resolving the next page for type [" + type + "].", _this);
              });
            default:
              _this.set_content([]);
              return resolve(_this);
          }
        };
      })(this), (function(_this) {
        return function(error) {
          return console.error("[pagination:array] Error in `get_page_for_link`: ", _this);
        };
      })(this));
    },
    get_pagination_link: function(link) {
      return this.get("links." + link);
    },
    set_content_type: function(type) {
      return this.set('type', type);
    },
    get_content_type: function() {
      return this.get('type');
    },
    get_content: function() {
      return this.get('content');
    },
    get_all_content: function() {
      return this.get('all_content');
    },
    set_content: function(content) {
      this.add_to_all_content(content);
      return this.set('content', content);
    },
    add_to_all_content: function(content) {
      var all_content;
      all_content = this.get('all_content');
      return content.forEach((function(_this) {
        return function(record) {
          if (!all_content.contains(record)) {
            return all_content.pushObject(record);
          }
        };
      })(this));
    },
    set_is_loading: function() {
      return this.set('is_loading', true);
    },
    reset_is_loading: function() {
      return this.set('is_loading', false);
    },
    process_pagination_payload: function(payload, type) {
      if (type == null) {
        type = null;
      }
      if (ember['default'].isPresent(type)) {
        this.set_content_type(type);
      }
      this.set_pagination_links_from_payload(payload);
      this.set_pagination_meta_from_payload(payload);
      return this.set_content_from_payload(payload);
    },
    set_pagination_meta_from_payload: function(payload) {
      this.set('meta', payload.meta);
      return delete payload.meta;
    },
    set_pagination_links_from_payload: function(payload) {
      this.set('links', payload.links);
      return delete payload.links;
    },
    set_content_from_payload: function(payload) {
      var records, type;
      type = this.get_content_type();
      records = ajax['default'].normalize_and_push_payload(type, payload, {
        skip_ns: true
      });
      return this.set_content(records);
    },
    set_content_from_type_and_payload: function(type, payload) {
      this.set_content_type(type);
      return this.set_content_from_payload(payload);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});