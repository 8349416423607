define('thinkspace-casespace/generic_container', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].ContainerView.extend({
    classNames: []
  });

  exports['default'] = ___DefaultExportObject___;;

});