define('thinkspace-indented-list/mixins/item/actions', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    new_top: function() {
      var new_item;
      if (this.readonly) {
        return;
      }
      new_item = {
        pos_y: 0,
        pos_x: 0
      };
      this.value_items.insertAt(0, new_item);
      return this.save_response();
    },
    new_bottom: function() {
      var bottom, new_item;
      if (this.readonly) {
        return;
      }
      bottom = this.get_value_items_bottom_index();
      new_item = {
        pos_y: bottom,
        pos_x: 0
      };
      this.value_items.insertAt(bottom, new_item);
      return this.save_response();
    },
    remove_item: function(item) {
      var children, index, items, num_items, prev_item;
      if (this.readonly) {
        return;
      }
      prev_item = this.get_prev_item(item);
      num_items = 1;
      children = this.get_item_children(item);
      if (this.include_item_children_on_change(item)) {
        num_items += children.length;
      }
      index = this.value_items.indexOf(item);
      this.value_items.removeAt(index, num_items);
      if (prev_item) {
        this.reset_has_children(prev_item);
      }
      this.save_response();
      items = [item];
      if (num_items > 1) {
        items = items.concat(children);
      }
      return this.clear_itemable_is_used_unless_used_by_another_item(items).then((function(_this) {
        return function() {};
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});