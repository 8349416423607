define('thinkspace-casespace/mixins/services/server_events/rooms', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___,
    slice = [].slice;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    join: function(options) {
      var callback, room, room_event, source;
      if (options == null) {
        options = {};
      }
      if (!this.is_active) {
        return;
      }
      room = options.room;
      source = options.source || this;
      callback = options.callback || 'handle_server_event';
      room_event = options.room_event || 'server_event';
      return this.pubsub.join({
        room: room,
        source: source,
        callback: callback,
        room_event: room_event
      });
    },
    leave: function(options) {
      var room_type, rooms;
      if (options == null) {
        options = {};
      }
      if (!this.is_active) {
        return;
      }
      rooms = options.rooms;
      if (!rooms) {
        return;
      }
      room_type = options.room_type;
      return this.pubsub.leave({
        rooms: rooms,
        room_type: room_type
      });
    },
    leave_all: function(options) {
      if (options == null) {
        options = {};
      }
      return this.is_active && this.pubsub.leave_all(options);
    },
    assignment_room: function() {
      var args, ref;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return (ref = this.pubsub).room_for.apply(ref, [this.get_assignment()].concat(slice.call(args)));
    },
    assignment_ownerable_room: function() {
      var args, ref;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return (ref = this.pubsub).room_with_ownerable.apply(ref, [this.get_assignment()].concat(slice.call(args)));
    },
    assignment_current_user_room: function() {
      var args, ref;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return (ref = this.pubsub).room_with_current_user.apply(ref, [this.get_assignment()].concat(slice.call(args)));
    },
    phase_room: function() {
      var args, ref;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return (ref = this.pubsub).room_for.apply(ref, [this.get_phase()].concat(slice.call(args)));
    },
    phase_ownerable_room: function() {
      var args, ref;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return (ref = this.pubsub).room_with_ownerable.apply(ref, [this.get_phase()].concat(slice.call(args)));
    },
    phase_current_user_room: function() {
      var args, ref;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return (ref = this.pubsub).room_with_current_user.apply(ref, [this.get_phase()].concat(slice.call(args)));
    },
    server_event_room: 'server_event',
    assignment_current_user_server_event_room: function() {
      return this.assignment_current_user_room(this.server_event_room);
    },
    join_assignment: function(options) {
      if (options == null) {
        options = {};
      }
      options.room = this.assignment_room();
      return this.join(options);
    },
    join_assignment_with_ownerable: function(options) {
      if (options == null) {
        options = {};
      }
      options.room = this.assignment_ownerable_room();
      return this.join(options);
    },
    join_assignment_with_current_user: function(options) {
      if (options == null) {
        options = {};
      }
      options.room = this.assignment_current_user_room();
      return this.join(options);
    },
    join_phase: function(options) {
      if (options == null) {
        options = {};
      }
      options.room = this.phase_room();
      return this.join(options);
    },
    join_phase_with_ownerable: function(options) {
      if (options == null) {
        options = {};
      }
      options.room = this.phase_ownerable_room();
      return this.join(options);
    },
    join_phase_with_current_user: function(options) {
      if (options == null) {
        options = {};
      }
      options.room = this.phase_current_user_room();
      return this.join(options);
    },
    join_phase_or_assignment: function(options) {
      if (options == null) {
        options = {};
      }
      if (ember['default'].isPresent(this.get_phase())) {
        return join_phase(options);
      } else {
        return join_assignment(options);
      }
    },
    leave_all_except_assignment_room: function(options) {
      if (options == null) {
        options = {};
      }
      options.except = this.assignment_room();
      return this.leave_all(options);
    },
    leave_all_except_assignment_ownerable_room: function(options) {
      if (options == null) {
        options = {};
      }
      options.except = this.assignment_ownerable_room();
      return this.leave_all(options);
    },
    leave_all_except_assignment_current_user_room: function(options) {
      if (options == null) {
        options = {};
      }
      options.except = this.assignment_current_user_room();
      return this.leave_all(options);
    },
    leave_all_except_phase_room: function(options) {
      if (options == null) {
        options = {};
      }
      options.except = this.phase_room();
      return this.leave_all(options);
    },
    leave_all_except_phase_ownerable_room: function(options) {
      if (options == null) {
        options = {};
      }
      options.except = this.phase_ownerable_room();
      return this.leave_all(options);
    },
    leave_all_except_phase_current_user_room: function(options) {
      if (options == null) {
        options = {};
      }
      options.except = this.phase_current_user_room();
      return this.leave_all(options);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});