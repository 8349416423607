define('thinkspace-indented-list/mixins/item/itemable_change', ['exports', 'ember', 'totem/cache', 'totem/scope'], function (exports, ember, tc, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    clear_itemable_from_all_items: function(itemable) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var itemable_id, itemable_type, save;
          if (ember['default'].isBlank(itemable) || _this.readonly) {
            return resolve();
          }
          itemable_id = parseInt(itemable.get('id'));
          itemable_type = totem_scope['default'].get_record_path(itemable);
          save = false;
          _this.value_items.forEach(function(item) {
            var comp, desc, id, type;
            id = item.itemable_id;
            type = item.itemable_type;
            if (ember['default'].isPresent(id) && ember['default'].isPresent(type)) {
              if (itemable_id === id && itemable_type === totem_scope['default'].rails_polymorphic_type_to_path(type)) {
                save = true;
                comp = _this.get_item_component(item);
                desc = comp ? comp.get('show_value') : '';
                delete item.itemable_id;
                delete item.itemable_type;
                delete item.itemable_value_path;
                return item.description = desc;
              }
            }
          });
          if (save) {
            _this.save_response();
          }
          return resolve();
        };
      })(this));
    },
    remove_items_with_itemable: function(itemable, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var itemable_id, itemable_type, remove_items;
          if (ember['default'].isBlank(itemable) || _this.readonly) {
            return resolve();
          }
          itemable_id = parseInt(itemable.get('id'));
          itemable_type = totem_scope['default'].get_record_path(itemable);
          remove_items = [];
          _this.value_items.forEach(function(item) {
            var id, type;
            id = item.itemable_id;
            type = item.itemable_type;
            if (ember['default'].isPresent(id) && ember['default'].isPresent(type)) {
              if (itemable_id === id && itemable_type === totem_scope['default'].rails_polymorphic_type_to_path(type)) {
                return remove_items.push(item);
              }
            }
          });
          if (ember['default'].isBlank(remove_items)) {
            return resolve();
          }
          if (options.remove_packed_children === true) {
            _this.remove_items_itemable_and_packed_children(remove_items);
          } else {
            _this.remove_only_items_with_itemable(remove_items);
          }
          return resolve();
        };
      })(this));
    },
    remove_only_items_with_itemable: function(items) {
      items.map((function(_this) {
        return function(item) {
          var children, children_visible, index, prev_item;
          prev_item = _this.get_prev_item(item);
          children_visible = _this.are_item_children_visible(item);
          children = _this.get_item_children(item);
          index = _this.value_items.indexOf(item);
          _this.value_items.removeAt(index);
          if (!children_visible) {
            _this.set_children_items_visibility(children, true);
          }
          if (prev_item) {
            return _this.reset_has_children(prev_item);
          }
        };
      })(this));
      return this.save_response();
    },
    remove_items_itemable_and_packed_children: function(items) {
      return items.map((function(_this) {
        return function(item) {
          return _this.remove_item(item);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});