define('thinkspace-builder/steps/overview', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-builder/step'], function (exports, ember, ns, ta, step) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = step['default'].extend({
    title: 'Overview',
    id: 'overview',
    component_path: ns['default'].to_p('builder', 'steps', 'overview'),
    route_path: ns['default'].to_r('builder', 'cases', 'overview'),
    is_completed: ember['default'].computed('builder.model', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve(false);
        };
      })(this));
      return ta['default'].PromiseObject.create({
        promise: promise
      });
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});