define('totem-messages/mixins/services/messages/format', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    format_pre: function(item) {
      var message, parts;
      message = this.format_item(item);
      parts = [];
      if (ember['default'].isPresent(message.to_users)) {
        parts.push('To Users: ' + message.to_users);
      }
      if (ember['default'].isPresent(message.to_teams)) {
        parts.push('To Teams: ' + message.to_teams);
      }
      parts.push(message.body);
      return parts.join('\n');
    },
    format_item: function(item) {
      var body, to_teams, to_users;
      to_users = item.get('to_users');
      to_teams = item.get('to_teams');
      body = item.get('body');
      if (ember['default'].isPresent(to_users)) {
        to_users = this.format_to_users(to_users);
      }
      if (ember['default'].isPresent(to_teams)) {
        to_teams = this.format_to_teams(to_teams);
      }
      body = this.format_body(body);
      return {
        to_users: to_users,
        to_teams: to_teams,
        body: body
      };
    },
    format_to_users: function(users) {
      return this.format_titles_to_string(users);
    },
    format_to_teams: function(teams) {
      return this.format_titles_to_string(teams);
    },
    format_body: function(body) {
      return body;
    },
    format_titles_to_string: function(value) {
      var values;
      values = ember['default'].makeArray(value).compact().mapBy('title');
      return values.sort().join('; ');
    },
    format_users_and_teams: function(users, teams) {
      var values;
      values = users.copy();
      values.push.apply(values, teams);
      return this.format_titles_to_string(values);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});