define('totem-template-manager/tvo/value', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    init: function() {
      this._super();
      return this.set('guid_properties', []);
    },
    get_value: function(guid) {
      return this.tvo.get_path_value(this._get_path(guid));
    },
    set_value: function(value) {
      return this._set_value(this.tvo.generate_guid(), value);
    },
    set_value_for: function(source, value) {
      return this._set_value(this.tvo.guid_for(source), value);
    },
    get_all: function() {
      return this._get_guid_properties().map((function(_this) {
        return function(prop) {
          return _this.get(prop);
        };
      })(this));
    },
    _set_value: function(guid, value) {
      var path;
      path = this._get_path(guid);
      this._get_guid_properties().push(guid);
      this.tvo.set_path_value(path, value);
      return path;
    },
    _get_path: function(guid) {
      return this.tvo_property + "." + guid;
    },
    _get_guid_properties: function() {
      return this.get('guid_properties');
    },
    toString: function() {
      return 'TvoValue';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});