define('ember-validations/validators/local/format', ['exports', 'ember', 'ember-validations/validators/base', 'ember-validations/messages'], function (exports, Ember, Base, Messages) {

  'use strict';

  var get = Ember['default'].get;
  var set = Ember['default'].set;

  exports['default'] = Base['default'].extend({
    init: function() {
      this._super();
      if (this.options.constructor === RegExp) {
        set(this, 'options', { 'with': this.options });
      }

      if (this.options.message === undefined) {
        set(this, 'options.message',  Messages['default'].render('invalid', this.options));
      }
     },
     call: function() {
      if (Ember['default'].isEmpty(get(this.model, this.property))) {
        if (this.options.allowBlank === undefined) {
          this.errors.pushObject(this.options.message);
        }
      } else if (this.options['with'] && !this.options['with'].test(get(this.model, this.property))) {
        this.errors.pushObject(this.options.message);
      } else if (this.options.without && this.options.without.test(get(this.model, this.property))) {
        this.errors.pushObject(this.options.message);
      }
    }
  });

});