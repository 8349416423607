define('totem/mixins/data/metadata', ['exports', 'ember', 'totem/mixins/data/base_module'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    add: function(metadata) {
      if (!this.is_object(metadata)) {
        console.error("Must pass a 'key: value' object to " + this.mod_name + ".add() not:", metadata);
        return;
      }
      return ember['default'].merge(this.added_metadata, metadata);
    },
    init_values: function(source) {
      this._super(source);
      this.added_metadata = {};
      this.totem_data.set_source_property('metadata');
      return this.set_data();
    },
    set_data: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (!_this.process_source_data()) {
            return resolve();
          }
          return _this.get_metadata().then(function(metadata) {
            _this.get_source().setProperties({
              metadata: metadata
            });
            return resolve();
          });
        };
      })(this));
    },
    get_metadata: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_data().then(function(metadata) {
            if (ember['default'].isBlank(metadata)) {
              metadata = {};
            }
            ember['default'].merge(metadata, _this.added_metadata);
            return _this.call_source_callback({
              metadata: metadata
            }).then(function() {
              return resolve(metadata);
            });
          });
        };
      })(this));
    },
    print_metadata: function(options) {
      if (options == null) {
        options = {};
      }
      if (options.header !== false) {
        this.print_header();
      }
      console.info('METADATA ->');
      return this.print_data(this.get('metadata'));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});