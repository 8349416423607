define('thinkspace-readiness-assurance/mixins/rm/chat', ['exports', 'ember', 'totem/ajax', 'totem-messages/messages'], function (exports, ember, ajax, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    join_chat_received_event: function() {
      return this.join_room_event(this, 'chat');
    },
    handle_chat: function(data) {
      var cm, message, qid, value;
      console.info('received chat--->', data, this.chat);
      value = data.value || {};
      message = value.message;
      qid = value.question_id;
      if (ember['default'].isBlank(message)) {
        this.error("Received chat 'message' is blank.");
      }
      if (ember['default'].isBlank(qid)) {
        this.error("Received chat 'question_id' is blank.");
      }
      cm = this.chat_manager_map.get(qid);
      if (ember['default'].isBlank(cm)) {
        this.error("Received chat 'chat manager' for question_id '" + qid + "' not found.");
      }
      return cm.handle_chat(message);
    },
    save_chat: function(question_id, message) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var action, data, id, model, verb;
          if (_this.readonly) {
            return resolve();
          }
          if (!_this.save_to_server) {
            _this.save_off_message(_this.chat);
            return resolve();
          }
          model = _this.chat;
          id = _this.chat.get('id');
          action = 'add';
          verb = 'post';
          data = {
            question_id: question_id,
            message: message
          };
          return ajax['default'].object({
            verb: verb,
            model: model,
            id: id,
            action: action,
            data: data
          }).then(function() {
            return resolve();
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});