define('totem/config', ['exports', 'ember'], function (exports, ember) {

	'use strict';

	/* jshint node: true */
	var platform_name = ember['default'].ENV.PLATFORM_NAME;
	var mod           = require(platform_name + '/config/environment');
	var mod_default   = mod.default || {};
	var config        = mod_default.totem || {};
	var mod_prefix    = mod_default.modulePrefix || '';
	if (mod_prefix !== '') {mod_prefix = mod_prefix + '/';}
	var mp  = mod_prefix;
	var env = mod_default;
	exports['default'] = config;

	exports.mp = mp;
	exports.env = env;

});