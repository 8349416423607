define('thinkspace-casespace/components/ownerable/bar/base', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    casespace: ember['default'].inject.service(),
    phase_manager: ember['default'].inject.service(),
    tagName: '',
    is_selecting_ownerable: false,
    has_ownerable_generated: false,
    addon_ownerable: ember['default'].computed.reads('casespace.active_addon_ownerable'),
    model: ember['default'].computed.reads('casespace.current_phase'),
    current_phase: ember['default'].computed.reads('casespace.current_phase'),
    current_assignment: ember['default'].computed.reads('casespace.current_assignment'),
    c_ownerable_selector: ns['default'].to_p('casespace', 'ownerable', 'selector'),
    get_is_team_collaboration: function() {
      var id;
      id = this.get('model.team_category_id');
      if (!ember['default'].isPresent(id)) {
        return false;
      }
      return id === 2;
    },
    set_addon_ownerable: function(ownerable) {
      if (!ownerable) {
        this.totem_error["throw"](this, "Change to ownerable is blank.");
      }
      this.totem_scope.view_only_on();
      if (!this.get('is_gradebook')) {
        this.get('phase_manager').mock_phase_states_on();
      }
      return this.get('phase_manager').set_addon_ownerable_and_generate_view(ownerable).then((function(_this) {
        return function() {
          if (typeof _this['callback_set_addon_ownerable'] === 'function') {
            return _this.callback_set_addon_ownerable();
          }
        };
      })(this));
    },
    set_addon_ownerable_from_offset: function(offset) {
      var ownerable;
      ownerable = this.get('addon_ownerable');
      return this.get('ownerables').then((function(_this) {
        return function(ownerables) {
          var index, new_ownerable;
          if (ember['default'].isPresent(ownerable)) {
            index = ownerables.indexOf(ownerable);
            if (index === -1) {
              return;
            }
            new_ownerable = ownerables.objectAt(index + offset);
          } else {
            new_ownerable = ownerables.get('firstObject');
          }
          if (!ember['default'].isPresent(new_ownerable)) {
            return;
          }
          return _this.set_addon_ownerable(new_ownerable);
        };
      })(this));
    },
    actions: {
      set_is_selecting_ownerable: function() {
        return this.set('is_selecting_ownerable', true);
      },
      reset_is_selecting_ownerable: function() {
        return this.set('is_selecting_ownerable', false);
      },
      toggle_is_selecting_ownerable: function() {
        return this.toggleProperty('is_selecting_ownerable');
      },
      select_ownerable: function(ownerable) {
        return this.set_addon_ownerable(ownerable);
      },
      select_next_ownerable: function() {
        return this.set_addon_ownerable_from_offset(1);
      },
      select_previous_ownerable: function() {
        return this.set_addon_ownerable_from_offset(-1);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});