define('thinkspace-readiness-assurance/mixins/rm/server_events', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    join_server_event_received_event: function() {
      if (!this.is_admin) {
        return this.ra.join_with_current_user();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});