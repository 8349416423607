define('totem/mixins/data', ['exports', 'ember', 'totem/mixins/data/base_data', 'totem/scope'], function (exports, ember, totem_data, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    viewonly: ember['default'].computed.reads('totem_scope.is_view_only'),
    not_viewonly: ember['default'].computed.not('viewonly'),
    init: function() {
      this._super();
      return this.totem_data = totem_data['default'].create({
        source: this
      });
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});