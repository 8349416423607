define('thinkspace-base/base/route', ['exports', 'ember', 'totem/scope', 'totem/config', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, ember, totem_scope, config, auth_mixin) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Route.extend(auth_mixin['default'], {
    thinkspace: ember['default'].inject.service(),
    beforeModel: function(transition) {
      return this._super(transition);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});