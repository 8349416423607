define('thinkspace-peer-assessment/managers/evaluation/user_data', ['exports', 'ember', 'totem/scope', 'totem/cache', 'totem/ds/associations', 'totem-messages/messages'], function (exports, ember, totem_scope, tc, ta, tm) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    set_user_data: function() {
      return this.set_team().then((function(_this) {
        return function() {
          return _this.set_reviewables().then(function() {
            return _this.set_review_set().then(function() {
              return _this.set_reviews().then(function() {
                return _this.set_reviewable_from_settings().then(function() {
                  return _this.set_review();
                });
              });
            });
          });
        };
      })(this));
    },
    set_team: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = _this.get_sub_action_query('teams');
          return _this.tc.query(ta['default'].to_p('tbl:assessment'), query, {
            single: true,
            payload_type: ta['default'].to_p('team')
          }).then(function(team) {
            var has_team_members;
            _this.debug("Team: ", team);
            _this.set('team', team);
            has_team_members = team.get('users.length') > 1 ? true : false;
            _this.set('has_team_members', has_team_members);
            return resolve();
          }, function(error) {
            _this.set('has_team_members', false);
            return reject();
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_reviewables: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var team;
          team = _this.get('team');
          return team.get(ta['default'].to_p('users')).then(function(reviewables) {
            var ownerable;
            ownerable = _this.totem_scope.get_ownerable_record();
            reviewables = reviewables.without(ownerable);
            _this.debug("Reviewables: ", reviewables);
            _this.set('reviewables', reviewables);
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_review_set: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = _this.get_sub_action_query('review_sets');
          query.team_id = _this.get('team.id');
          return _this.tc.query(ta['default'].to_p('tbl:assessment'), query, {
            single: true,
            payload_type: ta['default'].to_p('tbl:review_set')
          }).then(function(review_set) {
            _this.debug("Review set: ", review_set);
            _this.set('review_set', review_set);
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_reviews: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var review_set;
          review_set = _this.get('review_set');
          return review_set.get(ta['default'].to_p('tbl:reviews')).then(function(reviews) {
            _this.debug("Reviews: ", reviews);
            _this.set('reviews', reviews);
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_reviewable_from_settings: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_reviewable_from_phase_settings().then(function(reviewable) {
            _this.debug("Reviewable found as: ", reviewable);
            _this.set('reviewable', reviewable);
            return resolve();
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_review: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var review, reviewable;
          reviewable = _this.get('reviewable');
          if (ember['default'].isEqual(reviewable, 'confirmation')) {
            return resolve();
          }
          review = _this.get_review_for_reviewable(reviewable);
          _this.debug("Setting review: ", review);
          _this.set('review', review);
          return resolve();
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    set_reviewable_phase_settings: function() {
      var id, reviewable, settings;
      reviewable = this.get('reviewable');
      id = typeof reviewable === 'string' ? reviewable : reviewable.get('id');
      settings = {
        reviewable_id: id
      };
      return this.get('casespace').set_phase_settings_query_params(settings);
    },
    set_review_from_phase_settings: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_reviewable_from_phase_settings().then(function(reviewable) {
            var current_reviewable;
            current_reviewable = _this.get('reviewable');
            if (current_reviewable === reviewable) {
              return resolve();
            }
            return _this.set_reviewable(reviewable).then(function() {
              return resolve();
            }, function(error) {
              return _this.error(error);
            });
          }, function(error) {
            return _this.error(error);
          });
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    get_reviewable_from_phase_settings: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var phase_settings, reviewable, reviewable_id;
          phase_settings = _this.get('phase_settings') || {};
          _this.debug("Phase settings: ", phase_settings);
          reviewable_id = phase_settings.reviewable_id;
          reviewable = null;
          switch (false) {
            case reviewable_id !== 'confirmation':
              reviewable = reviewable_id;
              break;
            case !ember['default'].isPresent(reviewable_id):
              reviewable = _this.get('reviewables').findBy('id', reviewable_id);
              break;
            default:
              reviewable = _this.get('reviewables.firstObject');
          }
          return resolve(reviewable);
        };
      })(this), (function(_this) {
        return function(error) {
          return _this.error(error);
        };
      })(this));
    },
    get_sub_action_query: function(sub_action, options) {
      var model, query;
      if (options == null) {
        options = {};
      }
      model = this.get('model');
      query = this.totem_scope.get_view_query(model, {
        sub_action: sub_action
      });
      query.verb = 'GET';
      query.id = model.get('id');
      this.totem_scope.add_authable_to_query(query);
      return query;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});