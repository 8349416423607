define('thinkspace-diagnostic-path/path_manager/jquery_sortable/delta', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/error', 'totem/scope', 'totem-messages/messages'], function (exports, ember, ns, ajax, totem_error, totem_scope, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    debug: true,
    init: function(path, event, item) {
      if (item == null) {
        item = null;
      }
      this.set('path', path);
      this.set('event', event);
      this.set('original', {});
      this.set('future', {});
      this.set('changes', {});
      this.set('status', {});
      if (ember['default'].isPresent(item)) {
        this.set('item', item);
        return this.set('is_new', true);
      }
    },
    process: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.parse_jquery_sortable_data();
          return _this.get_dragged_item().then(function(item) {
            var promises;
            _this.set('dragged_item', item);
            promises = _this.get_parent_sibling_promises();
            return ember['default'].RSVP.hash(promises).then(function(results) {
              promises = _this.get_children_promises(results);
              return ember['default'].RSVP.hash(promises).then(function(children_results) {
                var future, original;
                original = {};
                original.item = {
                  record: item
                };
                original.item.is_new = _this.get('is_new');
                original.parent = {
                  record: results.original_parent
                };
                if (ember['default'].isPresent(children_results.original_parent_children)) {
                  original.parent.children = children_results.original_parent_children.sortBy('position');
                }
                original.position = item.get('position');
                future = {};
                future.parent = {
                  record: results.future_parent
                };
                if (ember['default'].isPresent(children_results.future_parent_children)) {
                  future.parent.children = children_results.future_parent_children.sortBy('position');
                }
                future.sibling = results.future_sibling;
                if (ember['default'].isPresent(results.future_sibling)) {
                  future.position = results.future_sibling.get('position') + 1;
                }
                if (!ember['default'].isPresent(results.future_sibling)) {
                  future.position = 0;
                }
                _this.set('original', original);
                _this.set('future', future);
                _this.set_status();
                return resolve();
              });
            });
          });
        };
      })(this));
    },
    set_status: function() {
      var future_parent, future_position, original_item, original_parent, original_position, status;
      status = 'no_change';
      original_parent = this.get('original.parent.record');
      future_parent = this.get('future.parent.record');
      original_position = this.get('original.position');
      future_position = this.get('future.position');
      original_item = this.get('original.item.record');
      switch (false) {
        case !(ember['default'].isEqual(original_parent, future_parent) && this.get('is_new')):
          status = 'add_new_item';
          break;
        case !(ember['default'].isEqual(original_parent, future_parent) && (future_position > original_position)):
          status = 'reorder_item_down';
          break;
        case !(ember['default'].isEqual(original_parent, future_parent) && (future_position < original_position)):
          status = 'reorder_item_up';
          break;
        case !ember['default'].isEqual(original_item, future_parent):
          status = 'self_drop';
          break;
        case !!ember['default'].isEqual(original_parent, future_parent):
          status = 'move_item';
      }
      if (this.get('debug')) {
        console.info("[jquery-sortable Delta] Status detected as: [" + status + "]");
      }
      return this.set('status', status);
    },
    get_item_from_id: function(id) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (!ember['default'].isPresent(id)) {
            return resolve(null);
          }
          return _this.get('path').store.find(ns['default'].to_p('path_item'), id).then(function(item) {
            return resolve(item);
          });
        };
      })(this));
    },
    get_dragged_item: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var id, item;
          item = _this.get('item');
          if (ember['default'].isPresent(item)) {
            return resolve(item);
          }
          id = _this.get('dragged_item_id');
          return _this.get_item_from_id(id).then(function(item) {
            return resolve(item);
          });
        };
      })(this));
    },
    get_parent_sibling_promises: function() {
      var promises;
      return promises = {
        original_parent: this.get_item_from_id(this.get('dragged_item.parent_id')),
        future_parent: this.get_item_from_id(this.get('future_parent_id')),
        future_sibling: this.get_item_from_id(this.get('future_sibling_id'))
      };
    },
    get_children_promises: function(results) {
      var path, promises;
      path = this.get('path');
      promises = {};
      if (ember['default'].isPresent(results.original_parent)) {
        promises.original_parent_children = results.original_parent.get(ns['default'].to_p('path_items'));
      }
      if (ember['default'].isPresent(results.future_parent)) {
        promises.future_parent_children = results.future_parent.get(ns['default'].to_p('path_items'));
      }
      if (!ember['default'].isPresent(results.original_parent)) {
        promises.original_parent_children = path.get('children');
      }
      if (!ember['default'].isPresent(results.future_parent)) {
        promises.future_parent_children = path.get('children');
      }
      return promises;
    },
    parse_jquery_sortable_data: function() {
      var $future_parent, $future_sibling, drop_container, event, item;
      event = this.get('event');
      item = this.get('item');
      drop_container = event.dropped_container;
      $future_parent = $(drop_container.el).parents('li').first();
      $future_sibling = $(drop_container.prevItem);
      this.set('drop_container', drop_container);
      if (ember['default'].isPresent($future_parent)) {
        this.set('future_parent_id', $future_parent.attr('model_id'));
      }
      if (ember['default'].isPresent($future_sibling)) {
        this.set('future_sibling_id', $future_sibling.attr('model_id'));
      }
      if (ember['default'].isPresent(item)) {
        return this.set('dragged_item_id', null);
      } else {
        return this.set('dragged_item_id', $(event.currentTarget).attr('model_id'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});