define('thinkspace-builder/components/wizard/steps/base', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    builder: ember['default'].inject.service(),
    all_data_loaded: false,
    model: ember['default'].computed.reads('builder.model'),
    c_loader: ns['default'].to_p('common', 'loader'),
    get_store: function() {
      return this.container.lookup('store:main');
    },
    set_all_data_loaded: function() {
      return this.set('all_data_loaded', true);
    },
    error: function(message) {
      return console.warn("[builder] ERROR: " + message);
    },
    actions: {
      next: function() {
        if (this.callbacks_next_step == null) {
          console.error('[wizard:steps:base] Object does not respond to `callbacks_next_step`');
        }
        return this.callbacks_next_step();
      },
      next_without_callback: function() {
        return this.get('builder').transition_to_next_step();
      },
      back: function() {
        return this.get('builder').transition_to_previous_step();
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});