define('thinkspace-indented-list/mixins/item/helpers', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    get_value_items_bottom_index: function() {
      if (this.value_items.length <= 0) {
        return 0;
      } else {
        return this.value_items.length;
      }
    },
    get_visible_next: function($el) {
      return $el.nextAll(':visible:first');
    },
    get_visible_prev: function($el) {
      return $el.prevAll(':visible:first');
    },
    has_item_children: function(item) {
      var child_item, end, i, j, pos_x, ref, ref1, start;
      pos_x = item.pos_x;
      start = this.value_items.indexOf(item);
      if (start == null) {
        return false;
      }
      start += 1;
      end = this.get_value_items_last_index();
      if (start > end) {
        return false;
      }
      for (i = j = ref = start, ref1 = end; ref <= ref1 ? j <= ref1 : j >= ref1; i = ref <= ref1 ? ++j : --j) {
        child_item = this.value_items[i];
        if (child_item.pos_x > pos_x) {
          return true;
        }
        if (child_item.pos_x <= pos_x) {
          return false;
        }
      }
      return false;
    },
    get_item_children: function(item, visible) {
      var child_item, children, comp, end, i, item_visible, j, pos_x, ref, ref1, start;
      if (visible == null) {
        visible = true;
      }
      children = [];
      pos_x = item.pos_x;
      start = this.value_items.indexOf(item);
      if (start == null) {
        return children;
      }
      start += 1;
      end = this.get_value_items_last_index();
      if (start > end) {
        return children;
      }
      for (i = j = ref = start, ref1 = end; ref <= ref1 ? j <= ref1 : j >= ref1; i = ref <= ref1 ? ++j : --j) {
        child_item = this.value_items[i];
        if (child_item.pos_x <= pos_x) {
          return children;
        }
        if (visible) {
          children.push(child_item);
        } else {
          comp = this.get_item_component(child_item);
          item_visible = comp.get('item_visible');
          if (!item_visible) {
            children.push(child_item);
          }
        }
      }
      return children;
    },
    get_item_parent: function(item) {
      var end, i, index, j, parent_item, pi, pos_x, ref;
      pos_x = item.pos_x;
      index = this.value_items.indexOf(item);
      if (!(index > 0)) {
        return null;
      }
      end = index - 1;
      for (i = j = 0, ref = end; 0 <= ref ? j <= ref : j >= ref; i = 0 <= ref ? ++j : --j) {
        pi = end - i;
        parent_item = this.value_items[pi];
        if (parent_item.pos_x <= pos_x) {
          return parent_item;
        }
        if (pi === end) {
          return null;
        }
      }
      return null;
    },
    get_prev_item: function(item) {
      var index;
      index = this.value_items.indexOf(item);
      if (!(index > 0)) {
        return null;
      }
      return this.value_items[index - 1];
    },
    get_next_item: function(item) {
      var index;
      index = this.value_items.indexOf(item);
      if (!(index >= 0)) {
        return null;
      }
      if (index === this.get_value_items_last_index()) {
        return null;
      }
      return this.value_items[index + 1];
    },
    get_value_items_last_index: function() {
      var index;
      index = this.value_items.length - 1;
      if (index >= 0) {
        return index;
      } else {
        return 0;
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});