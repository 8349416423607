define('thinkspace-casespace-case-manager/components/wizards/base', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    step: '',
    steps: [],
    default_step: ember['default'].computed(function() {
      return this.get('steps.firstObject');
    }),
    is_editing: ember['default'].computed.not('model.isNew'),
    debug: true,
    thinkspace: ember['default'].inject.service(),
    case_manager: ember['default'].inject.service(),
    wizard_manager: ember['default'].inject.service(),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    init: function() {
      var wizard_manager;
      wizard_manager = this.get('wizard_manager');
      if (this.get('debug')) {
        console.log("[wizard] init called, setting service...", wizard_manager);
      }
      wizard_manager.set('wizard', this);
      return this._super();
    },
    check_bundle_type: function(bundle_type, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve();
        };
      })(this));
    },
    check_step: function(step, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return resolve();
        };
      })(this));
    },
    actions: {
      complete_step: function(step) {
        var fn, next_step, step_index, steps;
        fn = "complete_" + step;
        if (this.get('debug')) {
          console.log("[wizard] Completing step with fn: ", step, fn);
        }
        steps = this.get('steps');
        step_index = steps.indexOf(step);
        if (ember['default'].isPresent(step_index)) {
          next_step = steps[step_index + 1];
        }
        if (typeof this[fn] !== 'function') {
          return ember['default'].RSVP.resolve();
        }
        return this[fn]().then((function(_this) {
          return function(should_return) {
            if (should_return == null) {
              should_return = false;
            }
            if (should_return) {
              return;
            }
            if (_this.get('debug')) {
              console.log("[wizard] Step, next_step: ", step, next_step);
            }
            if (ember['default'].isPresent(next_step)) {
              _this.get('wizard_manager').set_query_param('step', next_step, {
                direction: 'forward'
              });
            }
            return _this.get('thinkspace').scroll_to_top();
          };
        })(this));
      },
      back: function(step) {
        var prev_step, step_index, steps;
        steps = this.get('steps');
        step_index = steps.indexOf(step);
        if (ember['default'].isPresent(step_index)) {
          prev_step = steps[step_index - 1];
        }
        if (ember['default'].isPresent(prev_step)) {
          return this.get('wizard_manager').set_query_param('step', prev_step, {
            direction: 'back'
          });
        } else {
          return this.get('wizard_manager').transition_to_selector();
        }
      },
      go_to_step: function(step) {
        var go_step, step_index, steps;
        steps = this.get('steps');
        step_index = steps.indexOf(step);
        if (ember['default'].isPresent(step_index)) {
          go_step = steps[step_index];
        }
        if (ember['default'].isPresent(go_step)) {
          return this.get('wizard_manager').set_query_param('step', go_step, {
            direction: 'back'
          });
        } else {
          return this.get('wizard_manager').transition_to_selector();
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});