define('thinkspace-peer-assessment/components/peer_assessment/overview/type/base', ['exports', 'ember', 'totem/ns', 'thinkspace-base/components/base'], function (exports, ember, ns, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    model: null,
    calculated_overview: null,
    assessment: null,
    t_qualitative: ns['default'].to_t('tbl:overview', 'type', 'shared', 'qualitative'),
    has_comments: ember['default'].computed.or('has_qualitative_constructive_comments', 'has_qualitative_positive_comments'),
    has_qualitative_positive_comments: ember['default'].computed.notEmpty('calculated_overview.qualitative.positive'),
    has_qualitative_constructive_comments: ember['default'].computed.notEmpty('calculated_overview.qualitative.constructive')
  });

  exports['default'] = ___DefaultExportObject___;;

});