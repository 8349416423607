define('totem-template-manager/tvo/helper', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'totem/error', 'totem/scope', 'totem-messages/messages'], function (exports, ember, ns, ta, totem_error, totem_scope, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    register: function(component, options) {
      var section;
      if (options == null) {
        options = {};
      }
      this.is_component(component);
      section = options.section || this.get_component_section(component);
      options.component = component;
      return this.tvo.section.register(section, options);
    },
    define_ready: function(component, options) {
      var ready_prop, ready_watch, ref;
      if (options == null) {
        options = {};
      }
      this.is_component(component);
      ready_watch = this.tvo.section.ready_properties(this.get_component_attribute(component, options.ready || 'source'));
      ready_prop = options.property || 'ready';
      if (ember['default'].isBlank(ready_watch)) {
        return ember['default'].defineProperty(component, ready_prop, ember['default'].computed(function() {
          return true;
        }));
      } else {
        return ember['default'].defineProperty(component, ready_prop, (ref = ember['default'].computed).and.apply(ref, ready_watch));
      }
    },
    get_component_attribute: function(component, attr) {
      this.is_component(component);
      return component.get("attributes." + attr);
    },
    get_component_section: function(component) {
      this.is_component(component);
      return this.get_component_attribute(component, 'section');
    },
    is_component: function(component) {
      if (!component) {
        totem_error['default']["throw"](this, "Must pass a component as the first argument.");
      }
      if (component.get('instrumentName') !== 'component') {
        return totem_error['default']["throw"](this, "First argument must a component instance.");
      }
    },
    ownerable_view_association_promise_array: function(component, options) {
      var promise;
      if (options == null) {
        options = {};
      }
      this.is_component(component);
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var record;
          record = options.model || component.get('model');
          return _this.ownerable_view_association_records(record, options).then(function(records) {
            _this.set_component_ready(component, options);
            _this.after_component_ready(component, options);
            return resolve(records);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
      return ta['default'].PromiseArray.create({
        promise: promise
      });
    },
    set_component_ready: function(component, options) {
      var section;
      if (options == null) {
        options = {};
      }
      if (!(options.ready || options.after_ready)) {
        return;
      }
      this.is_component(component);
      section = options.section || this.get_component_section(component);
      if (!section) {
        totem_error['default']["throw"](this, "Must pass a section component_ready.");
      }
      return this.tvo.section.ready(section);
    },
    after_component_ready: function(component, options) {
      var args, fn;
      if (options == null) {
        options = {};
      }
      fn = options.after_ready;
      if (!fn) {
        return;
      }
      this.is_component(component);
      if (typeof fn !== 'function') {
        totem_error['default']["throw"](this, "Must pass a function to after_component_ready.");
      }
      args = options.args || null;
      return fn.call(component, args);
    },
    load_ownerable_view_records: function(record, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query, record_type_key, show_loading;
          query = totem_scope['default'].get_unviewed_query(record, options);
          if (query) {
            show_loading = !(options.show_loading === false);
            if (show_loading) {
              totem_messages['default'].show_loading_outlet();
            }
            record_type_key = totem_scope['default'].record_type_key(record);
            return record.store.find(record_type_key, query).then(function() {
              resolve();
              if (show_loading) {
                return totem_messages['default'].hide_loading_outlet();
              }
            }, function(error) {
              return reject(error);
            });
          } else {
            return resolve();
          }
        };
      })(this));
    },
    ownerable_view_association_records: function(record, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var association;
          association = options.association;
          if (!association) {
            totem_error['default']["throw"](_this, "Must pass an association name to ownerable_view_association_records.");
          }
          return _this.load_ownerable_view_records(record, options).then(function() {
            return resolve(record.get(association));
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    ownerable_view_association_records_each: function(record, association_array, options) {
      if (options == null) {
        options = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var association_each;
          association_each = options.each;
          if (!association_array) {
            totem_error['default']["throw"](_this, "Must pass an association_array to ownerable_view_association_records_each.");
          }
          if (!association_each) {
            totem_error['default']["throw"](_this, "Must pass an association_each to ownerable_view_association_records_each.");
          }
          return _this.load_ownerable_view_records(record, options).then(function() {
            var promises;
            promises = association_array.getEach(association_each);
            return ember['default'].RSVP.Promise.all(promises).then(function(records) {
              return resolve(records);
            }, function(error) {
              return reject(error);
            });
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    toString: function() {
      return 'TvoHelper';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});