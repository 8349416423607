define('thinkspace-observation-list/mixins/dragula', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    init_dragula: function() {
      var container, container_class, options;
      if (this.get('viewonly')) {
        return;
      }
      if (this.get('attributes.sortable') === 'false') {
        return;
      }
      if (this.get('attributes.droppable') === 'false') {
        return;
      }
      container_class = this.get('container_class');
      container = this.$("." + container_class)[0];
      this.draggable_class = ember['default'].guidFor(this) + '-gu-draggable';
      options = {
        revertOnSpill: true,
        direction: 'veritcal',
        draggable_class: this.draggable_class,
        drop_container: container,
        moves: this.dragula_moves,
        accepts: this.dragula_accepts,
        copy: this.dragula_copy
      };
      this.drake = dragula(options);
      this.drake.containers.push(container);
      this.drake.component = this;
      return this.init_drake_events(options);
    },
    init_drake_events: function(options) {
      return this.drake.on('drop', this.dragula_drop);
    },
    dragula_moves: function(el, source, handle, sibling) {
      var $el;
      $el = $(el);
      return $el.hasClass(this.draggable_class);
    },
    dragula_accepts: function(el, target, source, sibling) {
      return this.drop_container === target;
    },
    dragula_copy: function(el, source) {
      return false;
    },
    dragula_drop: function(el, target, source, sibling) {
      return this.component.handle_dragula_drop(el, target, source, sibling);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});