define('thinkspace-readiness-assurance/mixins/qm/values', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    answer_id: null,
    answer: null,
    justification: null,
    response_updated: null,
    init_values: function() {
      this.save_prev_values();
      return this.reset_values();
    },
    reset_values: function() {
      this.set_answer();
      this.set_justification();
      this.set_status();
      return this.notify_response_updated();
    },
    set_answer: function() {
      var answer_id;
      answer_id = this.get(this.answer_path);
      this.set('answer_id', answer_id || null);
      this.set('answer', (this.choices.findBy('id', answer_id) || {}).label || null);
      if (this.prev_answer_id !== answer_id) {
        return this.call_callbacks('answer');
      }
    },
    set_justification: function() {
      this.set('justification', this.get(this.justification_path) || null);
      if (this.prev_justification !== this.get('justification')) {
        return this.call_callbacks('justification');
      }
    },
    save_prev_values: function() {
      this.save_prev_answer_id();
      return this.save_prev_justfication();
    },
    save_prev_answer_id: function() {
      return this.prev_answer_id = this.get(this.answer_path);
    },
    save_prev_justfication: function() {
      return this.prev_justification = this.get(this.justification_path);
    },
    set_status: function() {
      var locked, name, status, user_id;
      status = this.get(this.status_path) || {};
      locked = status.locked;
      if (ember['default'].isBlank(locked)) {
        this.set_question_disabled_by(null);
        return this.set_question_disabled_off();
      } else {
        user_id = locked.id;
        if (ember['default'].isBlank(user_id)) {
          this.error("Status locked 'id' is blank.");
        }
        if (this.rm.pubsub.is_current_user_id(user_id)) {
          return this.set_question_disabled_by_self();
        } else {
          name = locked.first_name + " " + locked.last_name;
          this.set_question_disabled_by(name);
          return this.set_question_disabled_on();
        }
      }
    },
    save_answer: function(answer_id) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.save_prev_answer_id();
          _this.set(_this.answer_path, answer_id);
          _this.set_answer();
          return _this.rm.save_response().then(function() {
            _this.notify_response_updated();
            return resolve();
          });
        };
      })(this));
    },
    save_justification: function(value) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          _this.save_prev_justfication();
          _this.set(_this.justification_path, value);
          _this.set_justification();
          return _this.rm.save_response().then(function() {
            _this.notify_response_updated();
            return resolve();
          });
        };
      })(this));
    },
    notify_response_updated: function() {
      return this.notifyPropertyChange('response_updated');
    },
    call_callbacks: function(key) {
      return this.callbacks.forEach((function(_this) {
        return function(method_array, source) {
          var i, len, method, results;
          if (_this.is_active(source)) {
            results = [];
            for (i = 0, len = method_array.length; i < len; i++) {
              method = method_array[i];
              if (_this.is_function(source[method])) {
                results.push(source[method](_this, key));
              } else {
                results.push(void 0);
              }
            }
            return results;
          } else {
            return _this.callbacks["delete"](source);
          }
        };
      })(this));
    },
    register_change_callback: function(source, method) {
      var methods;
      methods = this.callbacks.get(source);
      if (ember['default'].isBlank(methods)) {
        return this.callbacks.set(source, [method]);
      } else {
        return methods.push(method);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});