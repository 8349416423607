define('thinkspace-readiness-assurance/base/admin/irat/to_trat', ['exports', 'ember', 'thinkspace-readiness-assurance/base/admin/component'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    has_errors: ember['default'].computed('irad.errors.length', 'trad.errors.length', 'selected_send', function() {
      return this.get('selected_send') === true && (ember['default'].isPresent(this.irad.errors) || ember['default'].isPresent(this.trad.errors));
    }),
    init: function() {
      this._super();
      this.irad = this.am.rad({
        name: 'IRAT'
      });
      return this.trad = this.am.rad({
        name: 'TRAT',
        width_selector: '.ts-ra_admin-irat-to-trat-content'
      });
    },
    willInsertElement: function() {
      return this.am.get_trat_team_users().then((function(_this) {
        return function(team_users) {
          _this.setup(team_users);
          return _this.set_ready_on();
        };
      })(this));
    },
    setup: function(team_users) {
      this.irad.set_team_users(team_users);
      this.trad.set_team_users(team_users);
      this.irad.set('due_at_max', 10);
      this.irad.set('due_at_interval', 5);
      this.trad.set('due_at_max', 10);
      return this.trad.set('due_at_interval', 5);
    },
    actions: {
      validate: function() {
        return this.validate_data();
      },
      send_transition: function() {
        return this.send_transition();
      }
    },
    send_transition: function() {
      var irat, trat;
      this.validate_data();
      this.selected_send_on();
      if (this.get('has_errors')) {
        return;
      }
      this.set_timer();
      irat = this.irad.get_data();
      trat = this.trad.get_data();
      return this.am.send_irat_to_trat({
        irat: irat,
        trat: trat
      });
    },
    set_timer: function() {
      var msg, settings, timer;
      if (this.irad.get('transition_now') === true) {
        return;
      }
      timer = this.irad.get_timer();
      if (ember['default'].isPresent(timer)) {
        settings = timer.settings;
        if (ember['default'].isPresent(settings)) {
          msg = this.irad.get_timer_message();
          if (ember['default'].isPresent(msg)) {
            return timer.settings.message = msg;
          }
        }
      } else {
        settings = {
          type: 'once',
          unit: this.irad.get('timer_unit')
        };
        return this.irad.set_timer({
          settings: settings
        });
      }
    },
    validate_data: function() {
      var irat_due_at, trat_due_at;
      this.irad.clear_errors();
      this.trad.clear_errors();
      if (ember['default'].isBlank(this.trad.get_teams())) {
        this.trad.error('You have not selected any teams.');
      }
      irat_due_at = this.irad.get('due_at');
      if (ember['default'].isBlank(irat_due_at)) {
        this.irad.error('You have not selected an IRAT due at time');
        return;
      } else {
        if (irat_due_at <= new Date()) {
          this.irad.error('The IRAT due at time is in the past.');
        }
      }
      trat_due_at = this.trad.get('due_at');
      if (ember['default'].isPresent(trat_due_at)) {
        if (irat_due_at >= trat_due_at) {
          return this.trad.error('TRAT due at time must be greater than IRAT due at time.');
        }
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});