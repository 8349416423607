define('thinkspace-readiness-assurance/mixins/qm/initialize', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    readonly: ember['default'].computed.reads('rm.readonly'),
    init: function() {
      this._super();
      this.init_manager_properties();
      this.init_question_hash_values();
      this.init_response_path_values();
      this.init_choices();
      return this.init_values();
    },
    init_manager_properties: function() {
      return this.callbacks = ember['default'].Map.create();
    },
    init_question_hash_values: function() {
      this.qid = this.get('question_hash.id');
      this.question = this.get('question_hash.question');
      this.qchoices = this.get('question_hash.choices');
      this.qrandom = this.get('question_hash.questions.random');
      return this.is_ifat = this.get('question_hash.questions.ifat') === true;
    },
    init_response_path_values: function() {
      this.answer_path = "response.answers." + this.qid;
      this.justification_path = "response.justifications." + this.qid;
      this.status_path = "status.questions." + this.qid;
      return this.random_path = "status.settings.choices.order." + this.qid;
    },
    init_choices: function() {
      var ids, random;
      if (this.rm.random_by_client || this.qrandom === 'client') {
        this.choices = this.randomize_choices();
        return;
      }
      random = this.rm.random_choices || this.qrandom;
      if (ember['default'].isBlank(random)) {
        this.choices = this.qchoices;
        return;
      }
      ids = this.get(this.random_path);
      return this.choices = ember['default'].isPresent(ids) ? this.init_random_choices(ids) : this.randomize_choices();
    },
    init_random_choices: function(ids) {
      var choice, id, j, len, random_choices;
      random_choices = [];
      for (j = 0, len = ids.length; j < len; j++) {
        id = ids[j];
        choice = this.qchoices.findBy('id', id);
        if (!choice) {
          this.error("Choice id '" + id + "' not found in assessment choices.");
        }
        random_choices.push(choice);
      }
      this.validate_random_choices(random_choices);
      return random_choices;
    },
    validate_random_choices: function(random_choices) {
      var choice, found, id, j, len, ref, results;
      ref = this.qchoices;
      results = [];
      for (j = 0, len = ref.length; j < len; j++) {
        choice = ref[j];
        id = choice.id;
        found = random_choices.findBy('id', id);
        if (!found) {
          results.push(this.error("Choice id '" + id + "' not included in random choices."));
        } else {
          results.push(void 0);
        }
      }
      return results;
    },
    randomize_choices: function() {
      var i, irandom, j, k, l, len, length, ref, ref1, results, results1, ri, temp;
      if (ember['default'].isBlank(this.qchoices)) {
        return [];
      }
      length = this.qchoices.length;
      if (!(length > 1)) {
        return this.qchoices;
      }
      irandom = (function() {
        results = [];
        for (var j = 0, ref = length - 1; 0 <= ref ? j <= ref : j >= ref; 0 <= ref ? j++ : j--){ results.push(j); }
        return results;
      }).apply(this);
      for (i = k = 0, ref1 = length - 1; 0 <= ref1 ? k <= ref1 : k >= ref1; i = 0 <= ref1 ? ++k : --k) {
        ri = Math.floor(Math.random() * i);
        temp = irandom[i];
        irandom[i] = irandom[ri];
        irandom[ri] = temp;
      }
      results1 = [];
      for (l = 0, len = irandom.length; l < len; l++) {
        i = irandom[l];
        results1.push(this.qchoices[i]);
      }
      return results1;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});