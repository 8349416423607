define('totem/mixins/data/ability', ['exports', 'ember', 'totem/mixins/data/base_module'], function (exports, ember, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    add: function(abilities) {
      if (!this.is_object(abilities)) {
        console.error("Must pass a 'key: value' object to " + this.mod_name + ".add() not:", abilities);
        return;
      }
      this.convert_to_boolean_abilities(abilities);
      return ember['default'].merge(this.added_abilities, abilities);
    },
    init_values: function(source) {
      this._super(source);
      this.added_abilities = {};
      this.totem_data.set_source_property('can');
      this.totem_data.set_source_property('cannot');
      return this.set_data();
    },
    set_data: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (!_this.process_source_data()) {
            return resolve();
          }
          return _this.get_ability().then(function(abilities) {
            _this.get_source().setProperties({
              can: abilities.can,
              cannot: abilities.cannot
            });
            return resolve();
          });
        };
      })(this));
    },
    for_ownerable: function(ownerable) {
      return this.get_ability(ownerable);
    },
    get_ability: function(ownerable) {
      if (ownerable == null) {
        ownerable = null;
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_data(ownerable).then(function(abilities) {
            var ab;
            if (ember['default'].isBlank(abilities)) {
              abilities = {};
            }
            ember['default'].merge(abilities, _this.added_abilities);
            ab = _this.get_can_and_cannot_abilities(abilities);
            return _this.call_source_callback(ab).then(function() {
              return resolve(ab);
            });
          });
        };
      })(this));
    },
    get_can_and_cannot_abilities: function(abilities) {
      var can, cannot;
      this.add_model_abilities(abilities);
      this.add_missing_abilities(abilities);
      this.convert_to_boolean(abilities);
      can = abilities;
      cannot = this.get_inverse_abilities(abilities);
      return {
        can: can,
        cannot: cannot
      };
    },
    add_model_abilities: function(abilities) {
      var keys, model, model_abilities;
      model = this.get_source_model();
      if (!this.is_record(model)) {
        return;
      }
      model_abilities = model.get('abilities');
      if (!this.is_object(model_abilities)) {
        return;
      }
      keys = this.get_object_keys(model_abilities);
      return keys.map(function(key) {
        if (!ember['default'].isPresent(abilities[key])) {
          return abilities[key] = model_abilities[key];
        }
      });
    },
    add_missing_abilities: function(abilities) {
      var update;
      update = abilities.update || false;
      abilities.update = update;
      if (!ember['default'].isPresent(abilities.create)) {
        abilities.create = update;
      }
      if (!ember['default'].isPresent(abilities.destroy)) {
        return abilities.destroy = update;
      }
    },
    print_ability: function(options) {
      var can, cannot;
      if (options == null) {
        options = {};
      }
      can = options.can;
      if (!ember['default'].isPresent(can)) {
        can = true;
      }
      cannot = options.cannot;
      if (can) {
        this.print_header();
        console.info('CAN ->');
        this.print_data(this.get('can'));
      }
      if (cannot) {
        if (!can) {
          this.print_header();
        }
        console.info('CANNOT ->');
        return this.print_data(this.get('cannot'));
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});