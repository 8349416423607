define('thinkspace-indented-list/mixins/item/itemable', ['exports', 'ember', 'totem/cache', 'totem/scope'], function (exports, ember, tc, totem_scope) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    get_item_itemable: function(item) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var id, type;
          id = item.itemable_id;
          type = item.itemable_type;
          if (ember['default'].isBlank(id) || ember['default'].isBlank(type)) {
            return resolve(null);
          }
          type = totem_scope['default'].rails_polymorphic_type_to_path(type);
          return resolve(tc['default'].find_record(type, id));
        };
      })(this));
    },
    set_itemable_is_used: function(item) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_item_itemable(item).then(function(itemable) {
            if (ember['default'].isBlank(itemable)) {
              return resolve();
            }
            if (_this.is_function(itemable.set_is_used)) {
              itemable.set_is_used();
            }
            return resolve();
          });
        };
      })(this));
    },
    clear_itemable_is_used_unless_used_by_another_item: function(items) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var clear_promises;
          if (ember['default'].isBlank(items)) {
            return resolve();
          }
          items = ember['default'].makeArray(items);
          clear_promises = items.map(function(item) {
            if (_this.is_itemable_used_by_another_item(item)) {
              return ember['default'].RSVP.resolve();
            } else {
              return _this.clear_itemable_is_used(item);
            }
          });
          return ember['default'].RSVP.all(clear_promises).then(function() {
            return resolve();
          });
        };
      })(this));
    },
    is_itemable_used_by_another_item: function(item) {
      var id, type;
      id = item.itemable_id;
      type = item.itemable_type;
      if (ember['default'].isBlank(id) || ember['default'].isBlank(type)) {
        return true;
      }
      type = totem_scope['default'].rails_polymorphic_type_to_path(type);
      return this.value_items.find((function(_this) {
        return function(i) {
          return i.itemable_id === id && totem_scope['default'].rails_polymorphic_type_to_path(i.itemable_type) === type;
        };
      })(this));
    },
    clear_itemable_is_used: function(item) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get_item_itemable(item).then(function(itemable) {
            if (ember['default'].isBlank(itemable)) {
              return resolve();
            }
            if (_this.is_function(itemable.set_is_used)) {
              itemable.set_is_used(false);
            }
            return resolve();
          });
        };
      })(this));
    },
    get_item_itemable_icon: function(item) {
      if (ember['default'].isBlank(item.icon)) {
        return null;
      }
      return this.convert_itemable_icon_to_html(item.icon).htmlSafe();
    },
    convert_itemable_icon_to_html: function(icon_id) {
      switch (icon_id.toLowerCase()) {
        case 'html':
          return '<i class="im im-book history" title="History"></i>';
        case 'lab':
          return '<i class="fa fa-flask data" title="Data"></i>';
        case 'mechanism':
          return '<i class="fa fa-circle-o mechanism" title="Mechanism"></i>';
        default:
          return '<i class="fa fa-square-o unknown" title="Unknown"></i>';
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});