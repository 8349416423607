define('thinkspace-readiness-assurance/base/admin/component', ['exports', 'ember', 'totem/ns', 'totem/error', 'thinkspace-base/components/base'], function (exports, ember, ns, totem_error, base_component) {

  'use strict';

  var ___DefaultExportObject___,
    slice = [].slice;

  ___DefaultExportObject___ = base_component['default'].extend({
    tagName: '',
    manager: ember['default'].inject.service(ns['default'].to_p('readiness_assurance', 'admin_manager')),
    init: function() {
      this._super();
      this.am = this.get('manager');
      this.ready = false;
      return this.selected_send = false;
    },
    get_ready: function() {
      return this.get('ready');
    },
    set_ready_on: function() {
      return this.set('ready', true);
    },
    set_ready_off: function() {
      return this.set('ready', false);
    },
    selected_send_on: function() {
      return this.set('selected_send', true);
    },
    selected_send_off: function() {
      return this.set('selected_send', false);
    },
    sort_users: function(users) {
      var i, len, user;
      if (ember['default'].isBlank(users)) {
        return [];
      }
      for (i = 0, len = users.length; i < len; i++) {
        user = users[i];
        user.name = this.get_username(user);
      }
      return users.sortBy('name');
    },
    get_username: function(user) {
      return user.last_name + ", " + user.first_name;
    },
    error: function() {
      var args, message;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      message = args.shift() || '';
      if (ember['default'].isPresent(args)) {
        console.error(message, args);
      }
      return totem_error['default']["throw"](this, message);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});