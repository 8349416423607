define('thinkspace-casespace-gradebook/calc', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.create({
    values: function(value_array) {
      var result, vals;
      vals = ember['default'].makeArray(value_array);
      result = {};
      result.count = this.count(vals);
      result.total = this.total(vals);
      result.average = this.average(result.total, result.count);
      result.median = this.median(vals);
      return result;
    },
    count: function(value_array) {
      return ember['default'].makeArray(value_array).get('length');
    },
    count_uniq: function(value_array) {
      return ember['default'].makeArray(value_array).uniq().get('length');
    },
    total: function(value_array, total) {
      var i, len, ref, value;
      if (total == null) {
        total = 0;
      }
      ref = ember['default'].makeArray(value_array);
      for (i = 0, len = ref.length; i < len; i++) {
        value = ref[i];
        total = total + value;
      }
      return total;
    },
    average: function(total, count) {
      return (count && (total / count)) || 0;
    },
    median: function(value_array) {
      var half, sort_values;
      sort_values = ember['default'].makeArray(value_array).sort();
      half = Math.floor(sort_values.length / 2);
      if (sort_values.get('length') % 2) {
        return sort_values[half];
      }
      return (sort_values[half - 1] + sort_values[half]) / 2.0;
    },
    count_uniq_key_values: function(hash_array, key) {
      return ember['default'].makeArray(hash_array).mapBy(key).uniq().get('length');
    },
    non_zero_values: function(value_array) {
      return this.values(value_array.filter(function(value) {
        return value > 0;
      }));
    },
    count_non_zero_uniq_key_values: function(hash_array, non_zero_key, key) {
      var non_zero_hash_array;
      non_zero_hash_array = ember['default'].makeArray(hash_array).filter(function(hash) {
        return hash.get(non_zero_key) > 0;
      });
      return this.count_uniq_key_values(non_zero_hash_array, key);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});