define('thinkspace-readiness-assurance/mixins/qm/status', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    chat_displayed: false,
    set_chat_displayed_on: function() {
      return this.set('chat_displayed', true);
    },
    set_chat_displayed_off: function() {
      return this.set('chat_displayed', false);
    },
    answers_disabled: false,
    justification_disabled: false,
    question_disabled_by: null,
    set_question_disabled_by: function(value) {
      return this.set('question_disabled_by', value);
    },
    set_question_disabled_by_self: function() {
      return this.set('question_disabled_by', 'yourself');
    },
    set_answers_disabled_on: function() {
      return this.set('answers_disabled', true);
    },
    set_answers_disabled_off: function() {
      return this.set('answers_disabled', false);
    },
    set_justification_disabled_on: function() {
      return this.set('justification_disabled', true);
    },
    set_justification_disabled_off: function() {
      return this.set('justification_disabled', false);
    },
    set_question_disabled_on: function() {
      this.set_justification_disabled_on();
      return this.set_answers_disabled_on();
    },
    set_question_disabled_off: function() {
      this.set_justification_disabled_off();
      return this.set_answers_disabled_off();
    },
    lock: function() {
      this.set_question_disabled_by_self();
      return this.rm.save_status(this.qid, 'lock').then((function(_this) {
        return function() {};
      })(this));
    },
    unlock: function() {
      this.set_question_disabled_by(null);
      return this.rm.save_status(this.qid, 'unlock').then((function(_this) {
        return function() {};
      })(this));
    },
    handle_status: function(status) {
      this.set(this.status_path, status);
      return this.set_status();
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});