define('thinkspace-common/mixins/sign_up_terms', ['exports', 'ember', 'totem/cache', 'totem/ns', 'totem/config'], function (exports, ember, tc, ns, config) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    tos_link: ember['default'].computed(function() {
      return config['default'].tos_link;
    }),
    pn_link: ember['default'].computed(function() {
      return config['default'].pn_link;
    }),
    get_latest_terms: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = {
            action: 'latest_for',
            verb: 'GET',
            data: {
              doc_type: 'terms_of_service'
            }
          };
          return _this.tc.query(ns['default'].to_p('agreement'), query, {
            single: true
          }).then(function(tos) {
            return resolve(tos);
          });
        };
      })(this));
    },
    tos: null,
    has_terms: ember['default'].computed.notEmpty('tos')
  });

  exports['default'] = ___DefaultExportObject___;;

});