define('totem/mixins/ckeditor', ['exports', 'ember', 'totem/util', 'totem/config'], function (exports, ember, util, config) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    ckeditor_tag: null,
    ckeditor_load: function(asset_path) {
      if (asset_path == null) {
        asset_path = '';
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var options;
          if (typeof window.CKEDITOR === 'object') {
            return resolve();
          }
          asset_path = config['default'].asset_path;
          if (!util['default'].ends_with(asset_path, '/')) {
            asset_path += '/';
          }
          asset_path += 'ckeditor/';
          window.CKEDITOR_BASEPATH = asset_path;
          _this.totem_messages.show_loading_outlet();
          options = {
            dataType: 'script',
            cache: true,
            url: asset_path + 'ckeditor.js'
          };
          return $.ajax(options).done(function() {
            options.url = asset_path + 'adapters/jquery.js';
            return $.ajax(options).done(function() {
              _this.totem_messages.hide_loading_outlet();
              return resolve();
            }).fail(function() {
              return reject('ckeditor jquery adapter load failed.');
            });
          }).fail(function() {
            return reject('ckeditor load failed.');
          });
        };
      })(this));
    },
    ckeditor_value: function($tag) {
      if ($tag == null) {
        $tag = this.get('ckeditor_tag');
      }
      if (!($tag && $tag.length === 1)) {
        return null;
      }
      return $tag.ckeditor && $tag.ckeditor().val();
    },
    ckeditor_destroy: function($tag) {
      if ($tag == null) {
        $tag = this.get('ckeditor_tag');
      }
      this.set('ckeditor_tag', null);
      if (!($tag && $tag.length === 1)) {
        return null;
      }
      return $tag.ckeditorGet && $tag.ckeditorGet().destroy();
    },
    ckeditor_view: ember['default'].View.extend({
      tagName: 'textarea',
      ckeditor_tag: null,
      didInsertElement: function() {
        var $tag, value;
        $tag = $(this.element);
        this.set('ckeditor_tag', $tag);
        value = this.get('value') || '';
        $tag.html(value);
        return this.get('controller').ckeditor_load().then((function(_this) {
          return function() {
            var options;
            options = {
              height: _this.get('height') || 100
            };
            $tag.ckeditor((function() {}), options);
            return $tag.editor.on('change', function(e) {
              return _this.set('value', e.editor.getData());
            });
          };
        })(this));
      },
      willDestroyElement: function() {
        return this.get('controller').ckeditor_destroy(this.get('ckeditor_tag'));
      }
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});