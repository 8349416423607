define('thinkspace-readiness-assurance/mixins/user_list', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    pubsub: ember['default'].inject.service(),
    init: function() {
      this._super();
      return this.ps = this.get('pubsub');
    },
    current_users: [],
    users_added: [],
    users_removed: [],
    collapsed: false,
    show_number_of_users: true,
    number_of_users: 0,
    number_of_all_users: 0,
    all_sorted_users: null,
    room: 'override_in_component',
    room_type: 'user_list',
    get_room_type: function() {
      return this.get('room_type');
    },
    get_room: function() {
      return this.get('room');
    },
    get_name: function(user) {
      return user.last_name + ", " + user.first_name;
    },
    actions: {
      toggle_collapsed: function() {
        this.toggleProperty('collapsed');
      },
      refresh: function() {
        return this.refresh_users();
      }
    },
    refresh_users: function() {
      var event, room, room_type;
      room = this.get_room();
      room_type = this.get_room_type();
      event = this.ps.client_event(room_type);
      return this.ps.message_to_room_members(event, room, {
        room_type: room_type
      });
    },
    set_all_sorted_users: function() {
      var all_sorted_users, current_user, i, id, len, name, present, user, users;
      users = this.get('users');
      all_sorted_users = [];
      if (ember['default'].isPresent(users)) {
        for (i = 0, len = users.length; i < len; i++) {
          user = users[i];
          id = user.id;
          name = this.get_name(user);
          present = false;
          current_user = this.ps.is_current_user_id(id);
          all_sorted_users.push({
            id: id,
            name: name,
            present: present,
            current_user: current_user
          });
        }
      }
      return this.set('all_sorted_users', all_sorted_users.sortBy('name'));
    },
    handle_room_users: function(data) {
      var current_users, i, j, k, len, len1, len2, ref, user, user_list, users, users_added, users_removed;
      user_list = data.user_list || [];
      current_users = this.get('current_users');
      users_added = [];
      users_removed = [];
      users = [];
      for (i = 0, len = user_list.length; i < len; i++) {
        user = user_list[i];
        if (!this.ps.is_current_user_id(user.id)) {
          if (!current_users.findBy('id', user.id)) {
            users_added.push(user);
          }
        }
        user.name = this.get_name(user);
        users.push(user);
      }
      for (j = 0, len1 = current_users.length; j < len1; j++) {
        user = current_users[j];
        if (!users.findBy('id', user.id)) {
          users_removed.push(user);
        }
      }
      ref = this.all_sorted_users;
      for (k = 0, len2 = ref.length; k < len2; k++) {
        user = ref[k];
        ember['default'].set(user, 'present', ember['default'].isPresent(users.findBy('id', user.id)));
      }
      this.set('users_added', users_added);
      this.set('users_removed', users_removed);
      this.set('current_users', users);
      this.set('number_of_users', users.length);
      this.set('number_of_all_users', this.all_sorted_users.length);
      this.set('number_of_users_changed', users_added.length + users_removed.length);
      return this.number_of_users_changed_animation();
    },
    number_of_users_changed_animation: function() {
      if (this.get('number_of_users_changed') === 0) {
        return;
      }
      this.set('show_number_of_users', false);
      return ember['default'].run.schedule('afterRender', (function(_this) {
        return function() {
          return _this.set('show_number_of_users', true);
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});