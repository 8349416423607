define('thinkspace-readiness-assurance/question_manager', ['exports', 'ember', 'thinkspace-readiness-assurance/mixins/qm/helpers', 'thinkspace-readiness-assurance/mixins/qm/initialize', 'thinkspace-readiness-assurance/mixins/qm/rooms', 'thinkspace-readiness-assurance/mixins/qm/status', 'thinkspace-readiness-assurance/mixins/qm/values'], function (exports, ember, m_helpers, m_initialize, m_rooms, m_status, m_values) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Object.extend(m_initialize['default'], m_values['default'], m_rooms['default'], m_status['default'], m_helpers['default']);

	exports['default'] = ___DefaultExportObject___;;

});