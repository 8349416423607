define('thinkspace-base/components/base', ['exports', 'ember', 'totem/components/base', 'totem/mixins/data', 'thinkspace-base/mixins/common_helper'], function (exports, ember, base, totem_data_mixin, common_helper) {

  'use strict';

  var ___DefaultExportObject___,
    slice = [].slice;

  ___DefaultExportObject___ = base['default'].extend(totem_data_mixin['default'], common_helper['default'], {
    all_data_loaded: false,
    get_store: function() {
      return this.container.lookup('store:main');
    },
    set_all_data_loaded: function() {
      return this.set('all_data_loaded', true);
    },
    reset_all_data_loaded: function() {
      return this.set('all_data_loaded', false);
    },
    totem_data_config: {
      ability: true,
      metadata: true
    },
    transitionToRoute: function() {
      var args, ref;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return (ref = this.container.lookup('controller:application')).transitionToRoute.apply(ref, args);
    },
    current_user: ember['default'].computed(function() {
      return this.totem_scope.get('current_user');
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});