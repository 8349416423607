define('totem-application/mixins/included_models', ['exports', 'ember', 'totem/ds/associations'], function (exports, ember, ta) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    included: ta['default'].attr('json-api-models'),
    add_included_models: function(models) {
      var included, includes;
      models = ember['default'].makeArray(models);
      includes = [];
      models.forEach((function(_this) {
        return function(model) {
          return includes.pushObject(model);
        };
      })(this));
      included = this.get('included');
      switch (false) {
        case !ember['default'].isNone(included):
          included = includes;
          break;
        case !ember['default'].isArray(included):
          included = included.concat(includes);
          break;
        case !ember['default'].isPresent(included):
          included = ember['default'].makeArray(included);
          included = included.concat(includes);
      }
      return this.set('included', included);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});