define('thinkspace-indented-list/mixins/configuration', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    default_item_description: function() {
      return 'No description added.';
    },
    default_draggable_class: function() {
      return 'gu-draggable';
    },
    default_indent_px: function() {
      return 20;
    },
    default_max_indent: function() {
      return 25;
    },
    default_zoom_level: function() {
      return 3;
    },
    default_list_end_class: function() {
      return 'gu-list-end';
    },
    set_indent_px: function(px) {
      return this.indent_px = px;
    },
    set_max_indent: function(n) {
      return this.max_indent = n;
    },
    set_confirm_remove: function(tf) {
      return this.confirm_remove = tf;
    },
    set_send_response_to_server: function(tf) {
      return this.send_response_to_server = tf;
    },
    set_zoom_level: function(zl) {
      return this.set('zoom_level', zl);
    },
    set_list_container: function(container) {
      if (ember['default'].isPresent(this.list_container)) {
        this.error("Cannot set the list container after it has already been set.");
      }
      this.list_container = this.is_jquery_object(container) ? container[0] : container;
      this.$list_container = $(this.list_container);
      return this.add_source_container(container);
    },
    add_source_container: function(containers) {
      var container, i, len, results, source_containers;
      if (this.readonly) {
        return;
      }
      source_containers = this.is_jquery_object(containers) ? containers.toArray() : ember['default'].makeArray(containers);
      results = [];
      for (i = 0, len = source_containers.length; i < len; i++) {
        container = source_containers[i];
        if (!this.drake.containers.contains(container)) {
          results.push(this.drake.containers.push(container));
        } else {
          results.push(void 0);
        }
      }
      return results;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});