define('totem-application/mixins/totem_wizard', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    tw_sync: function() {
      this._tw_initialize();
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this._tw_get_nested_promise(_this._tw_sync_promise).then(function() {
            if (_this._tw_is_debug()) {
              console.log("[tw_sync] Final resolve being called.");
            }
            return resolve();
          });
        };
      })(this));
    },
    tw_edit: function() {
      this._tw_initialize();
      this._tw_set_is_edit();
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this._tw_get_nested_promise(_this._tw_edit_promise).then(function() {
            if (_this._tw_is_debug()) {
              console.log("[tw_edit] Final resolve being called.");
            }
            return resolve();
          });
        };
      })(this));
    },
    _tw_sync_promise: function(data, options) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var instructions;
          if (_this._tw_is_debug()) {
            console.log("[_tw_sync_promise] data, options: ", data, options);
          }
          instructions = data.mapped_instructions;
          return ember['default'].RSVP.hash(data.promises).then(function(results) {
            var instruction, key;
            for (key in results) {
              instruction = instructions.findBy('to', key);
              if (_this._tw_is_debug()) {
                console.log("[_tw_sync_promise] Instruction of: ", instruction);
              }
              instruction.set('resolved_promise', results[key]);
            }
            instructions.forEach(function(instruction) {
              var id, is_promise, on_obj, polymorphic, prop, prop_obj, prop_objs, resolved_promise, to, type;
              if (_this._tw_is_debug()) {
                console.log("[_tw_sync_promise] Parsing instruction of: ", instruction);
              }
              is_promise = instruction.get('is_promise');
              resolved_promise = instruction.get('resolved_promise');
              on_obj = instruction.get('on_obj');
              to = instruction.get('to');
              polymorphic = instruction.get('polymorphic');
              prop = instruction.get('prop');
              prop_obj = instruction.get('prop_obj');
              prop_objs = ember['default'].makeArray(prop_obj);
              if (_this._tw_is_debug()) {
                console.log("[_tw_sync_promise] To value to be set [" + to + "] on: ", on_obj);
              }
              if (ember['default'].isPresent(to)) {
                if (is_promise && ember['default'].isArray(resolved_promise)) {
                  resolved_promise.clear();
                  return prop_objs.forEach(function(obj) {
                    return _this._tw_push_unless_contains(resolved_promise, obj);
                  });
                } else {
                  if (polymorphic) {
                    if (!ember['default'].isPresent(prop_obj)) {
                      console.error("[_tw_sync_promise] Cannot set a polymorphic that doesn't exist: [" + prop_obj + "] for instruction: ", instruction);
                    }
                    id = to + "_id";
                    type = to + "_type";
                    if (_this._tw_is_debug()) {
                      console.log("[_tw_sync_promise] Setting polymorphic of: ", to);
                    }
                    on_obj.set(id, prop_obj.get('id'));
                    return on_obj.set(type, _this.totem_scope.get_record_path(prop_obj));
                  } else {
                    return on_obj.set(to, prop_obj);
                  }
                }
              }
            });
            return ember['default'].RSVP.all(_this._tw_map_promise_definitions(data.promise_all)).then(function() {
              return resolve();
            });
          });
        };
      })(this));
    },
    _tw_edit_promise: function(data, options) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var instructions, promises;
          if (_this._tw_is_debug()) {
            console.log("[_tw_edit_promise] data, options: ", data, options);
          }
          instructions = data.mapped_instructions;
          promises = data.promises;
          return ember['default'].RSVP.hash(promises).then(function(results) {
            var instruction, key;
            for (key in results) {
              instruction = instructions.findBy('to', key);
              if (_this._tw_is_debug()) {
                console.log("[_tw_edit_promise] Instruction for [" + key + "]: ", instruction);
              }
              instruction.set('resolved_promise', results[key]);
            }
            instructions.forEach(function(instruction) {
              var on_prop, prop, value;
              prop = instruction.get('prop');
              on_prop = instruction.get('on');
              if (_this._tw_is_debug()) {
                console.log("[_tw_edit_promise] Parsing prop: [" + prop + "]", instruction);
              }
              if (ember['default'].isPresent(prop) && ember['default'].isPresent(on_prop)) {
                value = _this._tw_get_instruction_prop_value(instruction);
                if (_this._tw_is_debug()) {
                  console.log("[_tw_edit_promise] Value found as for prop [" + prop + "]: ", value);
                }
                return _this.set(prop, value);
              }
            });
            return resolve();
          });
        };
      })(this));
    },
    _tw_get_instruction_prop_value: function(instruction) {
      var generate, model_type, new_obj, prop, single, to, value, values, values_clone;
      prop = instruction.get('prop');
      if (!ember['default'].isPresent(prop)) {
        return null;
      }
      single = instruction.get('single');
      generate = instruction.get('generate');
      to = instruction.get('to');
      if (instruction.get('is_promise')) {
        value = instruction.get('resolved_promise');
        if (this._tw_is_debug()) {
          console.log("[_tw_get_instruction_prop_value] Setting on prop, value PROMISE: ", prop, value);
        }
      } else {
        value = instruction.get("on_obj." + to);
        if (this._tw_is_debug()) {
          console.log("[_tw_get_instruction_prop_value] Setting on prop, value: ", prop, value);
        }
      }
      if (ember['default'].isArray(value)) {
        values = ember['default'].makeArray(value);
        values_clone = [];
        values.forEach((function(_this) {
          return function(value) {
            return values_clone.pushObject(value);
          };
        })(this));
        if (single) {
          value = values_clone.get('firstObject');
        } else {
          value = values_clone;
        }
      }
      if (generate && !ember['default'].isPresent(value)) {
        model_type = ember['default'].Inflector.inflector.singularize(to);
        new_obj = this.store.createRecord(model_type);
        value = new_obj;
        if (this._tw_is_debug()) {
          console.log("[_tw_get_instruction_prop_value] Generating record of and type: ", new_obj, model_type);
        }
      }
      if (this._tw_is_debug()) {
        console.log("[_tw_get_instruction_prop_value] VALUE found as for [" + prop + "]: ", value);
      }
      return value;
    },
    _tw_generate_record: function(instruction, value) {
      var generate, model_type, new_obj;
      generate = instruction.get('generate');
      if (generate && !ember['default'].isPresent(prop_obj)) {
        model_type = ember['default'].Inflector.inflector.singularize(to);
        new_obj = this.store.createRecord(model_type);
        if (this._tw_is_debug()) {
          console.log("[_tw_instruction_map] Generating record of and type: ", new_obj, model_type);
        }
        return instruction.set('prop_obj', new_obj);
      }
    },
    _tw_get_nested_promise: function(map_fn) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var data, tasks;
          data = _this._tw_parse_instructions();
          tasks = data.map(function() {
            return map_fn;
          });
          return resolve(tasks.reduce(function(cur, next, i) {
            return cur.then(function() {
              return next.call(_this, data[i]);
            });
          }, ember['default'].RSVP.resolve()));
        };
      })(this));
    },
    _tw_map_promise_definitions: function(definitions) {
      return definitions.map((function(_this) {
        return function(definition) {
          var fn;
          fn = definition.get('fn');
          return fn.call(_this, definition);
        };
      })(this));
    },
    _tw_before_save_promises: function(definition) {
      var fn_names, fns, instruction, options, record;
      record = definition.get('record');
      instruction = definition.get('instruction');
      options = this._tw_get_options();
      fn_names = ember['default'].makeArray(instruction.before_save);
      fns = fn_names.map((function(_this) {
        return function(name) {
          var fn;
          fn = options.functions[name];
          if (!ember['default'].isPresent(fn)) {
            console.error("[_tw_before_save_promises] Cannot call before_save on undefined function of [" + name + "]");
          }
          return fn.call(_this, record);
        };
      })(this));
      if (this._tw_is_debug()) {
        console.log("[_tw_before_save_promises] Returning promises of: ", fns);
      }
      return fns;
    },
    _tw_after_save_promises: function(definition) {
      var fn_names, fns, instruction, options, record;
      record = definition.get('record');
      instruction = definition.get('instruction');
      options = this._tw_get_options();
      fn_names = ember['default'].makeArray(instruction.after_save);
      fns = fn_names.map((function(_this) {
        return function(name) {
          var fn;
          fn = options.functions[name];
          if (!ember['default'].isPresent(fn)) {
            console.error("[_tw_after_save_promises] Cannot call after_save on undefined function of [" + name + "]");
          }
          return fn.call(_this, record);
        };
      })(this));
      if (this._tw_is_debug()) {
        console.log("[_tw_after_save_promises] Returning promises of: ", fns);
      }
      return fns;
    },
    _tw_save: function(definition) {
      var instruction, options, record;
      record = definition.get('record');
      instruction = definition.get('instruction');
      options = this._tw_get_options();
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return ember['default'].RSVP.all(_this._tw_before_save_promises(definition)).then(function() {
            return record.save().then(function(saved_record) {
              var data, global_data;
              if (_this._tw_is_debug()) {
                console.log("[_tw_save] tw saved record: ", saved_record);
              }
              global_data = options.data;
              data = instruction.get('data');
              if (ember['default'].isPresent(data) && ember['default'].isPresent(data.set)) {
                if (ember['default'].isArray(global_data)) {
                  if (_this._tw_is_debug()) {
                    console.log("[_tw_save] Adding object to data (pushObject) of key [" + data.set + "]");
                  }
                  global_data.pushObject(saved_record);
                } else {
                  if (_this._tw_is_debug()) {
                    console.log("[_tw_save] Adding object to data (single) of key [" + data.set + "]");
                  }
                  global_data[data.set] = saved_record;
                }
              }
              return ember['default'].RSVP.all(_this._tw_after_save_promises(definition)).then(function() {
                return resolve();
              });
            });
          });
        };
      })(this));
    },
    _tw_prop_fn: function(definition) {
      var instruction, options, promises, prop_fn_names, record;
      record = definition.get('record');
      instruction = definition.get('instruction');
      options = this._tw_get_options();
      prop_fn_names = instruction.get('prop_fns');
      promises = [];
      if (!ember['default'].isPresent(prop_fn_names)) {
        return;
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          prop_fn_names.forEach(function(name) {
            var fn;
            fn = options.functions[name];
            if (!ember['default'].isPresent(fn)) {
              console.error("[_tw_prop_fn] Cannot call prop_fn on undefined function of [" + name + "]");
            }
            return promises.pushObject(fn.call(_this, record));
          });
          return ember['default'].RSVP.all(promises).then(function() {
            return resolve();
          });
        };
      })(this));
    },
    _tw_instruction_map: function(instruction, group_data) {
      var definition, generate, on_obj, on_obj_is_promise, on_obj_promise, on_prop, promise_all, promises, prop, prop_fns, prop_fns_present, prop_obj, prop_objs, save, to, use_ns;
      if (this._tw_is_debug()) {
        console.log('[_tw_instruction_map] Instruction mapping, firing gettings: ', instruction);
      }
      promise_all = group_data.get('promise_all');
      on_prop = instruction.get('on');
      prop = instruction.get('prop');
      save = instruction.get('save') || false;
      use_ns = instruction.get('use_ns') || false;
      generate = instruction.get('generate') || false;
      to = instruction.get('to');
      prop_fns = instruction.get('prop_fns');
      prop_fns_present = ember['default'].isPresent(prop_fns);
      if (use_ns && !instruction.get('is_mapped')) {
        to = ns['default'].to_p(to);
      }
      if (ember['default'].isPresent(prop)) {
        prop_obj = this.get(prop);
      }
      if (ember['default'].isPresent(prop_obj)) {
        prop_objs = ember['default'].makeArray(prop_obj);
      }
      if (ember['default'].isPresent(on_prop)) {
        on_obj = this.get(on_prop);
      }
      if (this._tw_is_debug()) {
        console.log("[_tw_instruction_map] on_obj found as: ", on_obj);
      }
      if (ember['default'].isPresent(to) && ember['default'].isPresent(on_obj)) {
        on_obj_promise = on_obj.get(to);
      }
      if (this._tw_is_debug()) {
        console.log("[_tw_instruction_map] on_obj [" + to + "] found as: ", on_obj_promise);
      }
      on_obj_is_promise = !ember['default'].isNone(on_obj_promise) && (on_obj_promise.then != null);
      if (this._tw_is_debug()) {
        console.log("[_tw_instruction_map] on_obj_is_promise for [" + to + "] on: ", on_obj, on_obj_is_promise);
      }
      instruction.set('to', to);
      instruction.set('prop_obj', prop_obj);
      instruction.set('on_obj', on_obj);
      instruction.set('on_obj_promise', on_obj_promise);
      instruction.set('is_promise', on_obj_is_promise);
      instruction.set('is_mapped', true);
      instruction.set('save', save);
      instruction.set('use_ns', use_ns);
      instruction.set('generate', generate);
      if (on_obj_is_promise) {
        promises = group_data.get('promises');
        promises[to] = on_obj_promise;
      }
      if (ember['default'].isPresent(prop_obj) && save) {
        prop_objs.forEach((function(_this) {
          return function(obj) {
            var definition;
            if (obj.get('isDirty')) {
              console.log("[_tw_instruction_map] Saving value of: ", obj);
              definition = ember['default'].Object.create({
                record: obj,
                instruction: instruction,
                fn: _this._tw_save
              });
              return _this._tw_push_unless_contains(promise_all, definition);
            }
          };
        })(this));
      }
      if (ember['default'].isPresent(on_obj) && save && !ember['default'].isPresent(prop)) {
        definition = ember['default'].Object.create({
          record: on_obj,
          instruction: instruction,
          fn: this._tw_save
        });
        this._tw_push_unless_contains(promise_all, definition);
      }
      if (prop_fns_present && ember['default'].isPresent(prop_obj)) {
        prop_objs.forEach((function(_this) {
          return function(obj) {
            definition = ember['default'].Object.create({
              record: obj,
              instruction: instruction,
              fn: _this._tw_prop_fn
            });
            return _this._tw_push_unless_contains(promise_all, definition);
          };
        })(this));
      }
      return instruction;
    },
    _tw_initialize: function() {
      var options;
      if (this._tw_is_initialized()) {
        return;
      }
      options = this.get('tw_options');
      options.instructions.forEach((function(_this) {
        return function(group, index) {
          var instructions;
          instructions = [];
          group.forEach(function(raw_instruction) {
            var instruction, key;
            instruction = ember['default'].Object.create();
            for (key in raw_instruction) {
              instruction.set(key, raw_instruction[key]);
            }
            return instructions.pushObject(instruction);
          });
          return options.instructions[index] = instructions;
        };
      })(this));
      this._tw_set_is_initialized();
      this._tw_set_is_not_edit();
      return this.set('tw_options', options);
    },
    _tw_get_new_group_data: function() {
      return ember['default'].Object.create({
        mapped_instructions: [],
        promise_all: [],
        promises: {}
      });
    },
    _tw_parse_group_data: function(group) {
      var group_data, mapped_instructions;
      group_data = this._tw_get_new_group_data();
      mapped_instructions = group_data.get('mapped_instructions');
      group.forEach((function(_this) {
        return function(instruction) {
          var mapped_instruction;
          mapped_instruction = _this._tw_instruction_map(instruction, group_data);
          return mapped_instructions.pushObject(mapped_instruction);
        };
      })(this));
      return group_data;
    },
    _tw_parse_instructions: function() {
      var data, instructions, options;
      options = this._tw_get_options();
      instructions = this._tw_get_instructions();
      options.data = {};
      data = [];
      instructions.forEach((function(_this) {
        return function(group) {
          var group_data;
          group_data = _this._tw_parse_group_data(group);
          return data.pushObject(group_data);
        };
      })(this));
      return data;
    },
    _tw_push_unless_contains: function(array, values) {
      values = ember['default'].makeArray(values);
      if (ember['default'].isEmpty(values)) {
        return;
      }
      return values.forEach((function(_this) {
        return function(value) {
          if (!(array.contains(value) || ember['default'].isBlank(value))) {
            return array.pushObject(value);
          }
        };
      })(this));
    },
    _tw_is_initialized: function() {
      return this.get('tw_options.is_initialized');
    },
    _tw_set_is_initialized: function() {
      return this.set('tw_options.is_initialized', true);
    },
    _tw_is_edit: function() {
      return this.get('tw_options.is_edit');
    },
    _tw_set_is_edit: function() {
      return this.set('tw_options.is_edit', true);
    },
    _tw_set_is_not_edit: function() {
      return this.set('tw_options.is_edit', false);
    },
    _tw_get_options: function() {
      return this.get('tw_options');
    },
    _tw_get_global_data: function() {
      return this.get('tw_options.data');
    },
    _tw_get_instructions: function() {
      return this.get('tw_options.instructions');
    },
    _tw_is_debug: function() {
      return this.get('tw_options.debug');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});