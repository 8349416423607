define('totem/mixins/select_text', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    init: function() {
      this._super();
      if (this.get('select_text')) {
        return this.add_mouse_up_function();
      }
    },
    add_mouse_up_function: function() {
      var mouse_up;
      if ((mouse_up = this.mouseUp)) {
        logger.warn("SelectTextViewMixin: [" + this + "] already has a mouseUp event.  Pre-pending this function.");
        return this.mouseUp = (function(event) {
          this.mouse_up_function(event);
          return mouse_up(event);
        });
      } else {
        return this.mouseUp = this.mouse_up_function;
      }
    },
    mouse_up_function: function(event) {
      var controller, i, len, results, selObj, select_text_controllers, value;
      if (!this.get('select_text')) {
        return;
      }
      selObj = window.getSelection();
      value = selObj.toString();
      if (!value) {
        return;
      }
      select_text_controllers = this.get('controller.select_text_controllers' || []);
      results = [];
      for (i = 0, len = select_text_controllers.length; i < len; i++) {
        controller = select_text_controllers[i];
        results.push(controller.process_select_text(value));
      }
      return results;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});