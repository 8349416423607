define('thinkspace-indented-list/mixins/handle_events', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    handle_cancel: function(clone, el, container, source) {
      var $el, children, delta_x, item, pos_x;
      if (this.list_container !== container) {
        return;
      }
      $el = $(el);
      pos_x = this.get_element_pos_x($(clone));
      item = this.get_element_item($el);
      delta_x = pos_x - item.pos_x;
      if (delta_x === 0) {
        return;
      }
      children = this.get_item_change_position_children(item);
      this.change_item_pos_x(item, delta_x);
      this.change_item_pos_x(children, delta_x);
      this.reset_has_children(item);
      return this.save_response();
    },
    handle_drop: function(clone, el, target, source, sibling) {
      var index, pos_x;
      sibling = this.get_sibling(sibling);
      pos_x = this.get_element_pos_x($(clone));
      index = this.get_change_item_index_from_sibling_element(sibling);
      if (this.list_container === source) {
        return this.drop_move_item(pos_x, el, index);
      } else {
        return this.drop_new_item(pos_x, el, source, index);
      }
    },
    drop_move_item: function(pos_x, el, index) {
      var $el, item;
      $el = $(el);
      item = this.get_element_item($el);
      return this.change_item_position(item, index, {
        pos_x: pos_x
      }).then((function(_this) {
        return function() {
          $el.remove();
          _this.reset_has_children(item);
          return _this.save_response();
        };
      })(this));
    },
    drop_new_item: function(pos_x, el, source, index) {
      var $el;
      $el = $(el);
      return this.get_new_item($el, pos_x, source).then((function(_this) {
        return function(new_item) {
          $el.remove();
          _this.value_items.insertAt(index, new_item);
          _this.reset_has_children(new_item);
          _this.select_item(new_item);
          return _this.save_response();
        };
      })(this));
    },
    get_element_pos_x: function($el) {
      var cleft, eleft, left, pos_x;
      cleft = $(this.list_container).offset().left;
      eleft = $el.offset().left || 0;
      left = eleft - cleft;
      if (left && left > 0) {
        pos_x = Math.floor(left / this.indent_px);
      } else {
        pos_x = 0;
      }
      if (pos_x > this.max_indent) {
        pos_x = this.max_indent;
      }
      return pos_x;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});