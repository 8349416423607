define('thinkspace-indented-list/mixins/item/new', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    get_new_item: function($el, pos_x, source) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var new_item;
          new_item = {
            pos_y: 0,
            pos_x: pos_x,
            description: null
          };
          return _this.get_source_container_item_values($el, source, new_item).then(function(item_values) {
            if (_this.is_hash(item_values)) {
              ember['default'].merge(new_item, item_values);
            }
            return resolve(new_item);
          });
        };
      })(this));
    },
    get_source_container_item_values: function($el, source, new_item) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var hash;
          hash = _this.new_source_containers.get(source) || {};
          if (ember['default'].isBlank(hash.callback_fn)) {
            return resolve(hash.item_values);
          }
          return _this.call_source_container_callback_function($el, hash, new_item).then(function(item_values) {
            return resolve(item_values);
          });
        };
      })(this));
    },
    call_source_container_callback_function: function($el, hash, new_item) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var comp, fn, response;
          fn = hash.callback_fn;
          comp = hash.component;
          if (!(ember['default'].isPresent(comp) && _this.is_function(comp[fn]))) {
            return resolve(hash.item_values);
          }
          response = comp[fn]($el, new_item);
          if (_this.is_object(response) && _this.is_function(response.then)) {
            return response.then(function(item_values) {
              return resolve(item_values);
            });
          } else {
            return resolve(response);
          }
        };
      })(this));
    },
    get_source_element_html_model_attributes: function($el) {
      var id, path, type, values;
      values = {};
      id = $el.attr('model_id');
      if (id) {
        id = parseInt(id);
      }
      type = $el.attr('model_type');
      path = $el.attr('model_value_path');
      if (id) {
        values.itemable_id = id;
      }
      if (type) {
        values.itemable_type = type;
      }
      if (path) {
        values.itemable_value_path = path;
      }
      return values;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});