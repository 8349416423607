define('thinkspace-readiness-assurance/response_manager', ['exports', 'ember', 'thinkspace-readiness-assurance/mixins/rm/chat', 'thinkspace-readiness-assurance/mixins/rm/helpers', 'thinkspace-readiness-assurance/mixins/rm/initialize', 'thinkspace-readiness-assurance/mixins/rm/response', 'thinkspace-readiness-assurance/mixins/rm/rooms', 'thinkspace-readiness-assurance/mixins/rm/server_events', 'thinkspace-readiness-assurance/mixins/rm/status'], function (exports, ember, m_chat, m_helpers, m_initialize, m_response, m_rooms, m_server_events, m_status) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Object.extend(m_initialize['default'], m_response['default'], m_status['default'], m_chat['default'], m_rooms['default'], m_server_events['default'], m_helpers['default']);

	exports['default'] = ___DefaultExportObject___;;

});