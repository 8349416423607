define('thinkspace-readiness-assurance/mixins/cm/initialize', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    readonly: ember['default'].computed.reads('rm.readonly'),
    init: function() {
      this._super();
      this.init_manager_properties();
      this.init_question_hash_values();
      this.init_response_path_values();
      return this.init_values();
    },
    init_manager_properties: function() {},
    init_question_hash_values: function() {
      return this.qid = this.get('question_hash.id');
    },
    init_response_path_values: function() {
      return this.chat_path = "chat.messages." + this.qid;
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});