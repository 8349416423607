define('thinkspace-readiness-assurance/mixins/rm/status', ['exports', 'ember', 'totem/ajax', 'totem-messages/messages'], function (exports, ember, ajax, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    join_status_received_event: function() {
      return this.join_room_event(this, 'status');
    },
    handle_status: function(data) {
      var qid, qm, status, value;
      console.info('received status--->', data);
      value = data.value || {};
      status = value.status;
      qid = value.question_id;
      if (ember['default'].isBlank(status)) {
        this.error("Received status 'status' is blank.");
      }
      if (ember['default'].isBlank(qid)) {
        this.error("Received chat 'question_id' is blank.");
      }
      qm = this.question_manager_map.get(qid);
      if (ember['default'].isBlank(qm)) {
        this.error("Received status 'question manager' for question_id '" + qid + "' not found.");
      }
      return qm.handle_status(status);
    },
    save_status: function(question_id, action) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var data, id, model, verb;
          if (_this.readonly) {
            return resolve();
          }
          if (!_this.save_to_server) {
            _this.save_off_message(_this.status);
            return resolve();
          }
          model = _this.status;
          id = _this.status.get('id');
          verb = 'post';
          data = {
            question_id: question_id
          };
          return ajax['default'].object({
            verb: verb,
            model: model,
            id: id,
            action: action,
            data: data
          }).then(function() {
            return resolve();
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});