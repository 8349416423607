define('totem/i18n', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___, i18n;

  i18n = (function() {
    function i18n() {
      this.locale = null;
      this.container = null;
    }

    i18n.prototype.message = function(options) {
      var template;
      if (options == null) {
        options = {};
      }
      this.set_locale();
      template = this.template(options);
      return this.format_message(template, options);
    };

    i18n.prototype.format_message = function(template, options) {
      var args, message;
      args = ember['default'].makeArray(options._i18n_args || []);
      message = template.fmt.apply(template, args);
      if (options.humanize !== false) {
        message = this.humanize(message);
      }
      return message;
    };

    i18n.prototype.template = function(options) {
      var default_path, path, template;
      if (options == null) {
        options = {};
      }
      path = options.path;
      if (path) {
        template = ember['default'].get(this.locale, path);
      }
      if (template) {
        options._i18n_args = options.args;
      }
      if (!template) {
        default_path = options.default_path;
        if (default_path) {
          template = ember['default'].get(this.locale, default_path);
        }
        if (template) {
          options._i18n_args = options.default_args;
        }
      }
      if (!template) {
        template = 'Missing i18n template';
      }
      return template;
    };

    i18n.prototype.humanize = function(str) {
      return ("" + str).replace(/_/g, ' ').replace(/^\w/g, function(s) {
        return s.toUpperCase();
      });
    };

    i18n.prototype.set_locale = function() {
      var country_code;
      if (this.locale != null) {
        return;
      }
      country_code = this.container.lookup('application:main').defaultLocale;
      return this.locale = this.container.lookupFactory('locale:' + country_code);
    };

    i18n.prototype.set_container = function(container) {
      return this.container = container;
    };

    return i18n;

  })();

  ___DefaultExportObject___ = new i18n;

  exports['default'] = ___DefaultExportObject___;;

});