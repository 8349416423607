define('thinkspace-casespace/mixins/services/server_events/base', ['exports', 'ember', 'thinkspace-casespace/mixins/services/server_events/events', 'thinkspace-casespace/mixins/services/server_events/helpers', 'thinkspace-casespace/mixins/services/server_events/initialize', 'thinkspace-casespace/mixins/services/server_events/rooms'], function (exports, ember, m_events, m_helpers, m_init, m_rooms) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = ember['default'].Mixin.create(m_init['default'], m_rooms['default'], m_events['default'], m_helpers['default']);

	exports['default'] = ___DefaultExportObject___;;

});