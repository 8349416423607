define('thinkspace-readiness-assurance/mixins/rm/helpers', ['exports', 'ember', 'totem/error'], function (exports, ember, totem_error) {

  'use strict';

  var ___DefaultExportObject___,
    slice = [].slice;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    current_user_full_name: function() {
      return this.current_user.get('full_name');
    },
    is_function: function(fn) {
      return typeof fn === 'function';
    },
    is_object: function(obj) {
      return obj && typeof obj === 'object';
    },
    is_hash: function(obj) {
      return this.is_object(obj) && !ember['default'].isArray(obj);
    },
    is_true_or_false: function(val) {
      return val === true || val === false;
    },
    stringify: function(obj) {
      return JSON.stringify(obj);
    },
    save_off_message: function(model) {
      return console.info("Saving to the server is turned off (options.save_response == false).", model);
    },
    error: function() {
      var args, message;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      message = args.shift() || '';
      if (ember['default'].isPresent(args)) {
        console.error(message, args);
      }
      return totem_error['default']["throw"](this, message);
    },
    toString: function() {
      return 'ReadinessAssuranceResponseManager:' + ember['default'].guidFor(this);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});