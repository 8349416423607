define('thinkspace-builder/steps/logistics', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-builder/step'], function (exports, ember, ns, ta, step) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = step['default'].extend({
    title: 'Logistics',
    id: 'logistics',
    component_path: ns['default'].to_p('builder', 'steps', 'logistics'),
    route_path: ns['default'].to_r('builder', 'cases', 'logistics'),
    is_completed: ember['default'].computed('builder.model', 'builder.step', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('builder').get_assignment().then(function(assignment) {
            var due_at, instructions, release_at;
            due_at = assignment.get('due_at');
            release_at = assignment.get('release_at');
            instructions = assignment.get('instructions');
            return resolve(ember['default'].isPresent(due_at) && ember['default'].isPresent(release_at) && ember['default'].isPresent(instructions));
          });
        };
      })(this));
      return ta['default'].PromiseObject.create({
        promise: promise
      });
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});