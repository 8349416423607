define('thinkspace-indented-list/mixins/register', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    register_component: function(component) {
      var el_id, item;
      item = this.get_component_item(component);
      el_id = this.get_component_element_id(component);
      this.el_id_to_component.set(el_id, component);
      return this.item_to_component.set(item, component);
    },
    register_list_container: function(component, list_container) {
      if (ember['default'].isBlank(list_container)) {
        return;
      }
      this.list_container_component = component;
      return this.set_list_container(list_container);
    },
    register_source_container: function(component, source_container, options) {
      var container;
      if (options == null) {
        options = {};
      }
      if (ember['default'].isBlank(source_container)) {
        return;
      }
      this.add_source_container(source_container);
      container = $(source_container)[0];
      return this.new_source_containers.set(container, {
        component: component,
        item_values: options.item_values || null,
        callback_fn: options.callback || null
      });
    },
    get_component_item: function(component) {
      return component.get('item');
    },
    get_component_element_id: function(component) {
      return component.get('elementId');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});