define('ember-validations/validators/base', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var get = Ember['default'].get;
  var set = Ember['default'].set;

  exports['default'] = Ember['default'].Object.extend({
    init: function() {
      set(this, 'errors', Ember['default'].A());
      this.dependentValidationKeys = Ember['default'].A();
      this.conditionals = {
        'if': get(this, 'options.if'),
        unless: get(this, 'options.unless')
      };
      this.model.addObserver(this.property, this, this._validate);
    },
    addObserversForDependentValidationKeys: Ember['default'].on('init', function() {
      this.dependentValidationKeys.forEach(function(key) {
        this.model.addObserver(key, this, this._validate);
      }, this);
    }),
    pushConditionalDependentValidationKeys: Ember['default'].on('init', function() {
      var _this = this;
      Ember['default'].A(['if', 'unless']).forEach(function(conditionalKind) {
        var conditional = _this.conditionals[conditionalKind];
        if (typeof(conditional) === 'string' && typeof(_this.model[conditional]) !== 'function') {
          _this.dependentValidationKeys.pushObject(conditional);
        }
      });
    }),
    pushDependentValidationKeyToModel: Ember['default'].on('init', function() {
      var model = get(this, 'model');
      if (model.dependentValidationKeys[this.property] === undefined) {
        model.dependentValidationKeys[this.property] = Ember['default'].A();
      }
      model.dependentValidationKeys[this.property].addObjects(this.dependentValidationKeys);
    }),
    call: function () {
      throw 'Not implemented!';
    },
    unknownProperty: function(key) {
      var model = get(this, 'model');
      if (model) {
        return get(model, key);
      }
    },
    isValid: Ember['default'].computed.empty('errors.[]'),
    isInvalid: Ember['default'].computed.not('isValid'),
    validate: function() {
      var self = this;
      return this._validate().then(function(success) {
        // Convert validation failures to rejects.
        var errors = get(self, 'model.errors');
        if (success) {
          return errors;
        } else {
          return Ember['default'].RSVP.reject(errors);
        }
      });
    },
    _validate: Ember['default'].on('init', function() {
      this.errors.clear();
      if (this.canValidate()) {
        this.call();
      }
      if (get(this, 'isValid')) {
        return Ember['default'].RSVP.resolve(true);
      } else {
        return Ember['default'].RSVP.resolve(false);
      }
    }),
    canValidate: function() {
      if (typeof(this.conditionals) === 'object') {
        if (this.conditionals['if']) {
          if (typeof(this.conditionals['if']) === 'function') {
            return this.conditionals['if'](this.model, this.property);
          } else if (typeof(this.conditionals['if']) === 'string') {
            if (typeof(this.model[this.conditionals['if']]) === 'function') {
              return this.model[this.conditionals['if']]();
            } else {
              return get(this.model, this.conditionals['if']);
            }
          }
        } else if (this.conditionals.unless) {
          if (typeof(this.conditionals.unless) === 'function') {
            return !this.conditionals.unless(this.model, this.property);
          } else if (typeof(this.conditionals.unless) === 'string') {
            if (typeof(this.model[this.conditionals.unless]) === 'function') {
              return !this.model[this.conditionals.unless]();
            } else {
              return !get(this.model, this.conditionals.unless);
            }
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    compare: function (a, b, operator) {
      switch (operator) {
        case '==':  return a == b; // jshint ignore:line
        case '===': return a === b;
        case '>=':  return a >= b;
        case '<=':  return a <= b;
        case '>':   return a > b;
        case '<':   return a < b;
        default:    return false;
      }
    }
  });

});