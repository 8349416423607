define('thinkspace-casespace-case-manager/controllers/team', ['exports', 'ember', 'ember-data', 'totem/ns'], function (exports, ember, ds, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].ObjectController.extend({
    needs: [ns['default'].to_p('case_manager')],
    case_manager_controller: ember['default'].computed.reads("controllers." + (ns['default'].to_p('case_manager'))),
    current_space: ember['default'].computed.reads('case_manager_controller.current_space'),
    current_assignment: ember['default'].computed.reads('case_manager_controller.current_assignment'),
    current_phase: ember['default'].computed.reads('case_manager_controller.current_phase'),
    send_case_manager_request: function(model, options) {
      return this.get('case_manager_controller').send_case_manager_request(model, options);
    },
    ajax_object: function(options) {
      if (options == null) {
        options = {};
      }
      return this.get('case_manager_controller').ajax_object(options);
    },
    team_route: {
      index: ns['default'].to_p('case_manager', 'teams.index'),
      "new": ns['default'].to_p('case_manager', 'teams.new'),
      edit: ns['default'].to_p('case_manager', 'teams.edit'),
      destroy: ns['default'].to_p('case_manager', 'teams.destroy')
    },
    show_loading_outlet: function() {
      return this.totem_messages.show_loading_outlet();
    },
    hide_loading_outlet: function() {
      return this.totem_messages.hide_loading_outlet();
    },
    previous_space: null,
    all_teams: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var space;
          space = _this.get('current_space');
          if (!space) {
            return resolve([]);
          }
          if (_this.get('previous_space') !== space) {
            _this.set('previous_space', space);
          }
          return _this.get('all_space_teams').then(function() {
            return resolve(_this.store.filter(ns['default'].to_p('team'), function(team) {
              return _this.totem_scope.record_authable_match_authable(team, space);
            }));
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    all_space_teams: ember['default'].computed('previous_space', function() {
      var promise, space;
      space = this.get('current_space');
      if (!space) {
        this.totem_error["throw"](this, 'A space is required to get space teams.');
      }
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var options;
          _this.show_loading_outlet();
          options = {
            action: 'teams',
            data: {
              space_id: space.get('id')
            }
          };
          return _this.send_case_manager_request(space, options).then(function() {
            _this.hide_loading_outlet();
            return resolve();
          }, function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: space
            });
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    all_team_users: ember['default'].computed('current_space', function() {
      var promise, space;
      space = this.get('current_space');
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var options;
          if (!space) {
            return resolve([]);
          }
          options = {
            action: 'team_users',
            data: {
              space_id: space.get('id')
            }
          };
          return _this.ajax_object(options).then(function(payload) {
            var user_ids, users;
            _this.totem_messages.api_success({
              source: _this,
              model: space
            });
            users = payload[ns['default'].to_p('users')];
            user_ids = users.mapBy('id');
            _this.store.pushPayload(payload);
            users = _this.store.all(ns['default'].to_p('user')).filter(function(user) {
              return user_ids.contains(parseInt(user.get('id')));
            });
            return resolve(users.sortBy('sort_name'));
          }, function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: space
            });
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    }),
    team_categories: ember['default'].computed(function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.store.find(ns['default'].to_p('category')).then(function(categories) {
            _this.totem_messages.api_success({
              source: _this,
              model: ns['default'].to_p('category')
            });
            return resolve(categories);
          }, function(error) {
            return _this.totem_messages.api_failure(error, {
              source: _this,
              model: ns['default'].to_p('category')
            });
          });
        };
      })(this));
      return ds['default'].PromiseArray.create({
        promise: promise
      });
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});