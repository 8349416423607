define('thinkspace-observation-list/mixins/sort_order', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'totem/scope', 'totem-messages/messages'], function (exports, ember, ns, ajax, totem_scope, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    handle_dragula_drop: function(el, target, source, sibling) {
      var $el, $sibling, changes;
      $el = $(el);
      $sibling = $(sibling);
      changes = this.get_observation_increment_and_decrement_changes($el, $sibling);
      if (ember['default'].isPresent(changes)) {
        return this.save_observation_order(changes);
      }
    },
    get_observation_increment_and_decrement_changes: function($el, $sibling) {
      var changes, move_to_position, move_up, obs, obs_pos, observations, sibling_obs;
      obs = this.get_element_observation($el);
      $el.remove();
      sibling_obs = this.get_element_observation($sibling);
      observations = this.get_ownerable_observations_in_position_order();
      move_to_position = this.get_dragged_observation_move_to_position(observations, obs, sibling_obs);
      move_up = this.is_dragged_observation_moved_up(obs, sibling_obs);
      if (move_to_position === obs.get('position')) {
        return null;
      }
      changes = [];
      obs_pos = obs.get('position') + 0;
      obs.set('position', move_to_position);
      changes.push({
        id: obs.get('id'),
        position: move_to_position
      });
      observations.forEach((function(_this) {
        return function(observation, index) {
          var i, id, pos;
          if (observation !== obs) {
            i = index + 1;
            pos = observation.get('position');
            if (i <= move_to_position) {
              if (pos > obs_pos) {
                i -= 1;
              }
            } else {
              if (pos <= obs_pos) {
                i += 1;
              }
            }
            if (move_up && pos === move_to_position) {
              i += 1;
            }
            if (pos !== i) {
              id = observation.get('id');
              changes.push({
                id: id,
                position: i
              });
              return observation.set('position', i);
            }
          }
        };
      })(this));
      return changes;
    },
    is_dragged_observation_moved_up: function(obs, sibling_obs) {
      if (!sibling_obs) {
        return false;
      }
      return obs.get('position') > sibling_obs.get('position');
    },
    get_ownerable_observations_in_position_order: function() {
      return this.get('model').get('observations').copy();
    },
    get_dragged_observation_move_to_position: function(observations, obs, sibling_obs) {
      var pos, spos;
      if (sibling_obs) {
        pos = obs.get('position');
        spos = sibling_obs.get('position');
        if (pos > spos) {
          return spos;
        } else {
          return spos - 1;
        }
      } else {
        return observations.get('lastObject.position') || observations.length;
      }
    },
    get_element_observation: function($el) {
      var model_id, type;
      if (ember['default'].isBlank($el)) {
        return null;
      }
      type = ns['default'].to_p('observation');
      model_id = $el.attr('model_id');
      return this.get('model.store').getById(type, model_id);
    },
    save_observation_order: function(changes) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var list, query;
          list = _this.get('model');
          query = {
            verb: 'put',
            action: 'observation_order',
            model: list,
            id: list.get('id'),
            data: {
              order: changes
            }
          };
          totem_scope['default'].add_ownerable_to_query(query.data);
          totem_scope['default'].add_authable_to_query(query.data);
          return ajax['default'].object(query).then(function() {
            totem_messages['default'].api_success({
              source: _this,
              model: list,
              action: 'observation_order'
            });
            return resolve();
          }, function(error) {
            return totem_messages['default'].api_failure(error, {
              source: _this,
              model: list
            });
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});