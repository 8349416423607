define('totem/mixins/data/queue', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___, queue;

  queue = ember['default'].Object.extend();

  queue.reopenClass({
    base_name: 'totem_data',
    requests_queue: ember['default'].Map.create(),
    get_requests_queue: function() {
      return this.requests_queue;
    },
    queue_request: function(source, name, id) {
      var req_id, req_queue;
      queue = this.get_requests_queue();
      req_id = this.get_request_id(name, id);
      req_queue = queue.get(req_id);
      if (req_queue && ember['default'].isArray(req_queue)) {
        req_queue.push(source);
        return true;
      } else {
        queue.set(req_id, []);
        return false;
      }
    },
    delete_queue_request: function(name, id) {
      var i, len, req_id, req_queue, source;
      queue = this.get_requests_queue();
      req_id = this.get_request_id(name, id);
      req_queue = queue.get(req_id);
      if (ember['default'].isPresent(req_queue) && ember['default'].isArray(req_queue)) {
        for (i = 0, len = req_queue.length; i < len; i++) {
          source = req_queue[i];
          if (source.get(this.base_name + "_include." + name) === true) {
            source[this.base_name + "_" + name].refresh();
          }
        }
      }
      return queue["delete"](req_id);
    },
    get_request_id: function(name, id) {
      return name + '--' + id;
    },
    get_totem_data_properties: function(source) {
      return source.get_totem_data_properties() || [];
    },
    debug_queue: function(title) {
      if (title == null) {
        title = '';
      }
      if (title) {
        console.log(title);
      }
      return this.get_requests_queue().forEach(function(value, key) {
        console.info("  key   = ", key);
        console.info("  value = ", value);
        return console.info(' ');
      });
    },
    toString: function() {
      return (this.base_name.camelize()) + "Queue";
    }
  });

  ___DefaultExportObject___ = queue;

  exports['default'] = ___DefaultExportObject___;;

});