define('totem-messages/api', ['exports', 'ember', 'totem/logger', 'totem/util', 'totem/error', 'totem-messages/api_status_codes', 'totem-messages/model_validation', 'totem/i18n', 'totem/config'], function (exports, ember, logger, util, totem_error, status_codes, model_val, i18n, config) {

  'use strict';

  var ApiMessages, ___DefaultExportObject___,
    hasProp = {}.hasOwnProperty;

  ApiMessages = (function() {
    function ApiMessages() {}

    ApiMessages.prototype.success = function(options) {
      var base_options;
      if (options == null) {
        options = {};
      }
      base_options = status_codes['default'].definition('success');
      options = this.merge_options(base_options, options);
      if (!options.i18n_path) {
        return this.return_value(options);
      }
      this.queue_message(options);
      this.process_handler(options);
      return this.return_value(options);
    };

    ApiMessages.prototype.failure = function(error, options) {
      var base_options;
      if (options == null) {
        options = {};
      }
      options.error = error;
      options.error_code = this.status_code_from_error(error);
      base_options = status_codes['default'].definition(options.error_code) || status_codes['default'].definition('failure');
      options = this.merge_options(base_options, options);
      console.warn('failure', error, options);
      return this.process_callback(options).then((function(_this) {
        return function() {
          return _this.process_failure(error, options);
        };
      })(this), (function(_this) {
        return function(e) {
          if (!(e && e.cancel === true)) {
            return _this.process_failure(error, options);
          }
        };
      })(this));
    };

    ApiMessages.prototype.process_failure = function(error, options) {
      this.queue_message(options);
      this.process_handler(options);
      this.process_model_rollback(options);
      if (options.hide_loading) {
        this.app_msgs.hide_loading_outlet();
      }
      if (options.fatal) {
        return totem_error['default']["throw"](this, this.get_error_message(options), {
          api: true
        });
      } else {
        this.log_error(options);
        return this.return_value(options);
      }
    };

    ApiMessages.prototype.return_value = function(options) {
      return options["return"] || options.model || null;
    };

    ApiMessages.prototype.process_callback = function(options) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var callback, error_code, handler, handler_callback;
          if (!options.allow_callback) {
            return resolve();
          }
          callback = options.callback;
          if (!callback) {
            return resolve();
          }
          switch (typeof callback) {
            case 'function':
              return _this.call_callback(callback, options).then(function() {
                return resolve();
              }, function(error) {
                return reject(error);
              });
            case 'object':
              if ((handler = options.handler)) {
                handler_callback = callback[handler];
                if (!handler_callback) {
                  if ((error_code = options.error_code)) {
                    handler_callback = callback[error_code];
                  }
                }
                if (handler_callback) {
                  return _this.call_callback(handler_callback, options).then(function() {
                    return resolve();
                  }, function(error) {
                    return reject(error);
                  });
                } else {
                  return resolve();
                }
              }
              break;
            default:
              return resolve();
          }
        };
      })(this));
    };

    ApiMessages.prototype.call_callback = function(callback, options) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var response, source;
          if ((source = options.source)) {
            response = callback.call(source, options);
          } else {
            response = callback(options);
          }
          if (response.then != null) {
            return response.then(function() {
              return resolve();
            }, function(error) {
              return reject(error);
            });
          } else {
            return resolve();
          }
        };
      })(this));
    };

    ApiMessages.prototype.process_handler = function(options) {
      var handler, mod;
      handler = options.handler;
      switch (handler) {
        case 'model_validation':
          mod = model_val['default'];
          break;
        default:
          return;
      }
      if (!mod) {
        this.throw_error("Handler module [" + handler + "] is missing.");
      }
      if (typeof mod.handle !== 'function') {
        this.throw_error("Handler module [" + handler + "] does not have a [handle] function.");
      }
      return mod.handle(this, options);
    };

    ApiMessages.prototype.process_model_rollback = function(options) {
      if (!options.model_rollback) {
        return;
      }
      if (options.model && typeof options.model.rollback === 'function') {
        return options.model.rollback();
      }
    };

    ApiMessages.prototype.queue_message = function(options) {
      var message, queue;
      message = this.get_options_message(options);
      queue = options.queue;
      if (!queue) {
        return;
      }
      if (typeof this.app_msgs[queue] !== 'function') {
        this.throw_error("Invalid status code message queue [" + queue + "].");
      }
      return this.app_msgs[queue](message, options.sticky);
    };

    ApiMessages.prototype.get_options_message = function(options) {
      var message;
      if (options.allow_user_message) {
        message = this.get_options_user_message(options);
        if (message) {
          return message;
        }
      }
      return i18n['default'].message({
        path: this.get_i18n_message_path(options),
        args: this.get_i18n_args(options),
        default_path: 'totem.api.status_codes.default',
        default_args: options.handler
      });
    };

    ApiMessages.prototype.get_options_user_message = function(options) {
      var response_json;
      if (options.user_message) {
        return options.user_message;
      }
      response_json = options.error && options.error.responseJSON;
      return response_json && response_json.errors && response_json.errors.user_message;
    };

    ApiMessages.prototype.get_i18n_message_path = function(options) {
      var key, matches, message, path, prefix, regex, value;
      prefix = config['default'].messages.i18n_path_prefix;
      path = options.i18n_path;
      if (ember['default'].isPresent(path) && ember['default'].isPresent(prefix)) {
        path = prefix + path;
      }
      if (path) {
        return path;
      }
      if ((matches = options.match_response_text) && (message = options.error.responseText)) {
        for (key in matches) {
          if (!hasProp.call(matches, key)) continue;
          value = matches[key];
          regex = new RegExp(key, 'i');
          if (message.match(regex)) {
            path = "totem.api.status_codes." + value;
            break;
          }
        }
      }
      return path || ("totem.api.status_codes." + options.handler);
    };

    ApiMessages.prototype.get_i18n_args = function(options) {
      var args, attr, i, i18n_attrs, len;
      if (!options.i18n) {
        return [];
      }
      i18n_attrs = ember['default'].makeArray(options.i18n);
      args = [];
      for (i = 0, len = i18n_attrs.length; i < len; i++) {
        attr = i18n_attrs[i];
        switch (attr) {
          case 'resource':
            args.push(this.get_model_name(options));
            break;
          case 'action':
            args.push(this.get_action(options));
        }
      }
      return args;
    };

    ApiMessages.prototype.get_action = function(options) {
      return options.action || 'find';
    };

    ApiMessages.prototype.get_model_name = function(options) {
      var model, type;
      model = options.model || 'unknown';
      if (typeof model === 'string') {
        type = model;
      } else {
        if (ember['default'].isArray(model)) {
          type = model.get('type').typeKey;
          type = type.pluralize();
        } else {
          type = model.constructor.typeKey;
        }
      }
      return type.split('/').pop();
    };

    ApiMessages.prototype.get_error_message = function(options) {
      var error, error_message, errors, handler, message, source, status_code;
      message = '';
      if (typeof options.fatal === 'string' && options.fatal !== '') {
        this.app_msgs.fatal(options.fatal);
        message += "[fatal: " + options.fatal + "] ";
      }
      source = options.source && options.source.toString && options.source.toString();
      status_code = options.error_code || 'unknown';
      handler = options.handler || 'unknown';
      error = options.error;
      if (error) {
        error_message = options.error_message || error.message || error.responseText || error.statusText;
        errors = error.errors && this.stringify(error.errors);
      }
      if (source) {
        message += "[source: " + source + "] ";
      }
      message += "[status-code: " + status_code + "] [handler-name: " + handler + "] ";
      if (errors) {
        message += "[errors: " + errors + "] ";
      }
      if (error_message) {
        message += "[error-message: " + error_message + "] ";
      }
      return message;
    };

    ApiMessages.prototype.set_app_msgs = function(app_msgs) {
      return this.app_msgs = app_msgs;
    };

    ApiMessages.prototype.merge_options = function(base_options, options) {
      var merged;
      merged = {};
      ember['default'].merge(merged, base_options);
      ember['default'].merge(merged, options);
      if (merged.message_array == null) {
        merged.message_array = [];
      }
      return merged;
    };

    ApiMessages.prototype.status_code_from_error = function(error) {
      if (error && (!error.status) && error.stack && util['default'].starts_with(error.stack, 'InvalidError')) {
        error.status = 422;
      }
      return error && error.status;
    };

    ApiMessages.prototype.throw_error = function(message, options) {
      if (options == null) {
        options = null;
      }
      this.app_msgs.error(message);
      if (options) {
        this.log_error(options);
      }
      return totem_error['default']["throw"](this, message, {
        api: true
      });
    };

    ApiMessages.prototype.log_error = function(options) {
      return logger['default'].error(this.get_error_message(options));
    };

    ApiMessages.prototype.stringify = function(object) {
      return JSON.stringify(object);
    };

    ApiMessages.prototype.toString = function() {
      return 'totem_messages:api';
    };

    return ApiMessages;

  })();

  ___DefaultExportObject___ = new ApiMessages;

  exports['default'] = ___DefaultExportObject___;;

});