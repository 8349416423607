define('thinkspace-peer-assessment/managers/evaluation/balance', ['exports', 'ember', 'totem/scope', 'totem/cache', 'totem/ds/associations', 'totem-messages/messages'], function (exports, ember, totem_scope, tc, ta, tm) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    model_points_different: ember['default'].computed.reads('model.points_different'),
    is_balance: ember['default'].computed.reads('model.is_balance'),
    is_balance_and_points_different: ember['default'].computed.and('is_balance', 'model_points_different'),
    points_total: ember['default'].computed('model.points', 'reviewables', function() {
      var points_per_member, reviewables;
      if (!this.get('is_balance')) {
        return;
      }
      points_per_member = this.get('model.points_per_member');
      reviewables = this.get('reviewables.length' || 0);
      return points_per_member * reviewables;
    }),
    points_remaining: ember['default'].computed('points_total', 'points_expended', function() {
      var points_expended, points_total;
      if (!this.get('is_balance')) {
        return;
      }
      points_total = this.get('points_total');
      points_expended = this.get('points_expended');
      return points_total - points_expended;
    }),
    points_expended: ember['default'].computed('reviews.@each.value', function() {
      var points, reviews;
      if (!this.get('is_balance')) {
        return;
      }
      reviews = this.get('reviews');
      if (!ember['default'].isPresent(reviews)) {
        return 0;
      }
      points = 0;
      reviews.forEach((function(_this) {
        return function(review) {
          return points += review.get_expended_points();
        };
      })(this));
      return points;
    }),
    points_different: ember['default'].computed('points_expended', function() {
      var points, reviews;
      if (!this.get('is_balance')) {
        return;
      }
      reviews = this.get('reviews');
      if (!ember['default'].isPresent(reviews)) {
        return 0;
      }
      if (reviews.get('length') === 1) {
        return 0;
      }
      points = [];
      reviews.forEach((function(_this) {
        return function(review) {
          return points.pushObject(review.get_expended_points());
        };
      })(this));
      return points.uniq().get('length');
    }),
    has_negative_points_remaining: ember['default'].computed('points_remaining', function() {
      var points;
      points = this.get('points_remaining');
      return points < 0;
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});