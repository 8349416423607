define('thinkspace-casespace-case-manager/components/wizards/assessment/mixins/descriptive_values', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    points_descriptive_enabled: ember['default'].computed.reads('points.descriptive.enabled'),
    points_descriptive_values: {
      not_at_all: ['Not at all', 'Somewhat', 'Completely'],
      rarely: ['Rarely', 'Sometimes', 'Always'],
      never: ['Never', 'Sometimes', 'Always'],
      strongly_disagree: ['Strongly disagree', 'Neutral', 'Strongly agree']
    },
    is_points_descriptive_not_at_all: false,
    is_points_descriptive_rarely: false,
    is_points_descriptive_never: false,
    is_points_descriptive_strongly_disagree: false,
    set_points_descriptive_enabled: 'set_points_descriptive_enabled',
    set_points_descriptive_values: 'set_points_descriptive_values',
    t_points_descriptive: ns['default'].to_t('case_manager', 'assignment', 'wizards', 'assessment', 'steps', 'settings', 'quantitative', 'shared', 'points_descriptive'),
    set_points_descriptive_values_radios: function(id) {
      var domain_values, key, property;
      domain_values = this.get('points_descriptive_values');
      for (key in domain_values) {
        this.set("is_points_descriptive_" + key, false);
      }
      property = "is_points_descriptive_" + id;
      return this.set(property, true);
    },
    actions: {
      toggle_set_points_descriptive_enabled: function() {
        return this.sendAction('set_points_descriptive_enabled', !this.get('points_descriptive_enabled'));
      },
      set_points_descriptive_values: function(id) {
        var domain_values, values;
        domain_values = this.get('points_descriptive_values');
        values = domain_values[id];
        if (!ember['default'].isPresent(values)) {
          return;
        }
        this.set_points_descriptive_values_radios(id);
        return this.sendAction('set_points_descriptive_values', values);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});