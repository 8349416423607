define('totem/config/locales', ['exports', 'ember', 'totem/config', 'totem/config/require_modules'], function (exports, ember, config, reqm) {

  'use strict';

  var TotemLocales, ___DefaultExportObject___;

  TotemLocales = (function() {
    function TotemLocales() {}

    TotemLocales.prototype.process = function(container) {
      var base, code, hash, i, len, locales, mod, mods, regex, results;
      locales = config['default'].locales;
      if (ember['default'].isBlank(locales)) {
        this.error("No ember application locals defined in environment 'totem.locals'.");
      }
      results = [];
      for (i = 0, len = locales.length; i < len; i++) {
        code = locales[i];
        base = this.base_local_for_code(code);
        if (ember['default'].isBlank(base)) {
          this.error("No base local exists for code '" + code + "'.");
        }
        regex = reqm['default'].config_regex("locales/" + code);
        mods = reqm['default'].filter_by(regex);
        results.push((function() {
          var j, len1, results1;
          results1 = [];
          for (j = 0, len1 = mods.length; j < len1; j++) {
            mod = mods[j];
            hash = reqm['default'].require_module(mod);
            if (!reqm['default'].is_hash(hash)) {
              this.error("Module '" + mod + "' is not a hash.");
            }
            results1.push($.extend(true, base, hash));
          }
          return results1;
        }).call(this));
      }
      return results;
    };

    TotemLocales.prototype.base_local_for_code = function(code) {
      return reqm['default'].require_module(reqm['default'].app_path("locales/" + code));
    };

    TotemLocales.prototype.error = function(message) {
      if (message == null) {
        message = '';
      }
      return reqm['default'].error(this, message);
    };

    TotemLocales.prototype.toString = function() {
      return 'TotemLocales';
    };

    return TotemLocales;

  })();

  ___DefaultExportObject___ = new TotemLocales;

  exports['default'] = ___DefaultExportObject___;;

});