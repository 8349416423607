define('thinkspace-indented-list/mixins/save_response', ['exports', 'ember', 'totem/ns', 'totem-messages/messages'], function (exports, ember, ns, totem_messages) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    save_response: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (_this.readonly) {
            return resolve();
          }
          return _this.process_save_response_queue().then(function() {
            return resolve();
          }, function(error) {
            console.error("Error saving response", error);
            _this.set('save_error', true);
            _this.queued_saves.clear();
            return reject(error);
          });
        };
      })(this));
    },
    process_save_response_queue: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return ember['default'].run.schedule('afterRender', _this, function() {
            if (ember['default'].isPresent(_this.queued_saves)) {
              _this.queued_saves.push(true);
              return resolve();
            }
            _this.queued_saves.push(true);
            return _this.save_response_record().then(function() {
              _this.queued_saves.pop();
              if (ember['default'].isBlank(_this.queued_saves)) {
                return resolve();
              }
              _this.queued_saves.clear();
              _this.process_save_response_queue();
              return resolve();
            }, function(error) {
              return reject(error);
            });
          });
        };
      })(this));
    },
    save_response_record: function() {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var items;
          items = _this.value_items.copy();
          items = items.map(function(item) {
            delete item.test_id;
            return item;
          });
          _this.set_items_pos_y(items);
          _this.response.set('value.items', items);
          if (!_this.send_response_to_server) {
            console.info('Saving the response to the server is turned off (options.save_response == false).');
            return resolve();
          }
          return _this.response.save().then(function() {
            totem_messages['default'].api_success({
              source: _this,
              model: _this.response,
              action: 'save',
              i18n_path: ns['default'].to_o('indented:response', 'save')
            });
            return resolve();
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});