define('totem-template-manager/tvo/section', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___,
    hasProp = {}.hasOwnProperty;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    register: function(section, options) {
      return this.tvo.set_path_value(this._get_path(section), options);
    },
    ready: function(section, value) {
      if (value == null) {
        value = true;
      }
      return this._ready(section, value);
    },
    ready_properties: function(value) {
      return this._ready_properties(value);
    },
    lookup: function(section) {
      return this.tvo.get(this._get_path(section));
    },
    actions: function(section) {
      return this._actions(section);
    },
    component: function(section) {
      return this._component(section);
    },
    has_action: function(section, action) {
      return this._has_action(section, action);
    },
    send_action: function(section, action, value) {
      return this._send_action(section, action, value);
    },
    call_action: function(section, action, value) {
      if (value == null) {
        value = null;
      }
      return this._call_action(section, action, value);
    },
    _ready: function(section, value) {
      this._setup_section(section);
      return this._set_value(section + ".ready", value);
    },
    _ready_properties: function(value) {
      if (!value) {
        return [];
      }
      return this.tvo.attribute_value_array(value).map(function(prop) {
        return "tvo.section." + prop + ".ready";
      });
    },
    _set_value: function(key, value) {
      var path;
      path = this._get_path(key);
      this.tvo.set(path, value);
      return path;
    },
    _get_path: function(key) {
      return this.tvo_property + "." + key;
    },
    _component: function(section) {
      var component;
      component = (this.lookup(section) || {}).component;
      return this.tvo.is_object_valid(component) && component;
    },
    _actions: function(section, action) {
      return (this.lookup(section) || {}).actions;
    },
    _has_action: function(section, action) {
      return (this.actions(section) || {})[action];
    },
    _send_action: function(section, action, value) {
      var actions, component, k, send_action, v;
      component = this.component(section);
      if (!component) {
        console.error("Section send action [" + action + "] component not registered.");
      }
      actions = this.actions(section) || {};
      send_action = null;
      for (k in actions) {
        if (!hasProp.call(actions, k)) continue;
        v = actions[k];
        if (k === action) {
          send_action = v;
        }
      }
      if (!send_action) {
        console.error("Section send action [" + action + "] not found.");
      }
      if (component && send_action) {
        return component.send(send_action, value);
      }
    },
    _call_action: function(section, action, value) {
      var actions, call_action, component, k, v;
      component = this.component(section);
      if (!component) {
        console.error("Section send action [" + action + "] component not registered.");
      }
      actions = this.actions(section) || {};
      call_action = null;
      for (k in actions) {
        if (!hasProp.call(actions, k)) continue;
        v = actions[k];
        if (k === action) {
          call_action = v;
        }
      }
      if (!call_action) {
        console.error("Section get action [" + action + "] not found.");
      }
      if (!(component[call_action] && typeof component[call_action] === 'function')) {
        console.error("Component does not have function [" + call_action + "].");
      }
      return component[call_action](value);
    },
    _setup_section: function(section) {
      var path;
      path = this._get_path(section);
      if (this.tvo.get(path)) {
        return;
      }
      return this._set_value(section, {});
    },
    toString: function() {
      return 'TvoSection';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});