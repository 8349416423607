define('totem-messages/mixins/services/messages/helpers', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    date_to_hh_mm: function(date) {
      return this.ttz.format(date, {
        format: 'h:mm a'
      });
    },
    date_from_now: function(date) {
      var zdate;
      zdate = this.ttz.format(date, {});
      return moment(zdate).fromNow();
    },
    minutes_from_now: function(date) {
      var r;
      r = Math.floor(((+date) - (+new Date())) / 60000);
      return r + ' minute' + (r === 1 ? '' : 's');
    },
    is_string: function(obj) {
      return obj && typeof obj === 'string';
    },
    is_function: function(obj) {
      return obj && typeof obj === 'function';
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});