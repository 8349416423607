define('thinkspace-builder/step', ['exports', 'ember', 'totem/ns', 'totem/util'], function (exports, ember, ns, util) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Object.extend({
    title: null,
    builder: ember['default'].inject.service()
  });

  exports['default'] = ___DefaultExportObject___;;

});