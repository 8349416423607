define('thinkspace-builder/steps/templates', ['exports', 'ember', 'totem/ns', 'totem/ds/associations', 'thinkspace-builder/step'], function (exports, ember, ns, ta, step) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = step['default'].extend({
    title: 'Templates',
    id: 'templates',
    component_path: ns['default'].to_p('builder', 'steps', 'templates'),
    route_path: ns['default'].to_r('builder', 'cases', 'templates'),
    is_completed: ember['default'].computed('builder.step', 'builder.model', function() {
      var promise;
      promise = new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          return _this.get('builder').get_assignment().then(function(assignment) {
            return assignment.totem_data.metadata.refresh().then(function() {
              return resolve(assignment.get('metadata.count') > 0);
            });
          });
        };
      })(this));
      return ta['default'].PromiseObject.create({
        promise: promise
      });
    })
  });

  exports['default'] = ___DefaultExportObject___;;

});