define('thinkspace-readiness-assurance/mixins/cm/values', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    message: null,
    messages: null,
    init_values: function() {
      this.set('message', null);
      return this.set('messages', this.format_messages());
    },
    add_message: function() {
      var message;
      message = this.get('message');
      return this.rm.save_chat(this.qid, message).then((function(_this) {
        return function() {};
      })(this));
    },
    format_messages: function() {
      var hash, i, len, messages, results;
      messages = this.get(this.chat_path) || [];
      results = [];
      for (i = 0, len = messages.length; i < len; i++) {
        hash = messages[i];
        results.push(this.format_message(hash));
      }
      return results;
    },
    format_message: function(hash) {
      var message, name, stime, time;
      message = hash.message;
      name = hash.first_name + " " + hash.last_name;
      stime = hash.time;
      time = this.rm.ttz.format(stime, {
        format: 'MMM Do, h:mm a'
      });
      return {
        time: time,
        name: name,
        message: message
      };
    },
    handle_chat: function(message) {
      var messages;
      messages = this.get(this.chat_path);
      if (messages) {
        messages.push(message);
      } else {
        this.set(this.chat_path, [message]);
      }
      this.set('message', null);
      return this.get('messages').pushObject(this.format_message(message));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});