define('thinkspace-casespace-case-manager/controllers/base', ['exports', 'ember', 'totem/ns'], function (exports, ember, ns) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Controller.extend({
    wizard_manager: ember['default'].inject.service(),
    case_manager: ember['default'].inject.service(),
    get_wizard_manager: function() {
      return this.get('wizard_manager');
    },
    get_case_manager: function() {
      return this.get('case_manager');
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});