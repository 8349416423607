define('totem/config/query_params', ['exports', 'ember', 'totem/ns', 'totem/config/require_modules'], function (exports, ember, ns, reqm) {

  'use strict';

  var TotemQueryParams, ___DefaultExportObject___;

  TotemQueryParams = (function() {
    function TotemQueryParams() {
      this.qp_map = {};
    }

    TotemQueryParams.prototype.process = function(container) {
      var hash, i, len, mod, model_path, mods, qp, regex;
      regex = reqm['default'].config_regex('query_params');
      mods = reqm['default'].filter_by(regex);
      if (ember['default'].isBlank(mods)) {
        return;
      }
      for (i = 0, len = mods.length; i < len; i++) {
        mod = mods[i];
        hash = reqm['default'].require_module(mod);
        if (!reqm['default'].is_hash(hash)) {
          this.error("Module '" + mod + "' is not a hash.");
        }
        for (model_path in hash) {
          qp = hash[model_path];
          if (!reqm['default'].is_string(model_path)) {
            this.error("Module '" + mod + "' model path is not a string.");
          }
          if (!reqm['default'].is_hash(qp)) {
            this.error("Module '" + mod + "' query params is not a hash.");
          }
          if (ember['default'].isPresent(this.qp_map[model_path])) {
            this.error("Module '" + mod + "' model path '" + model_path + "' is a duplicate.");
          }
          this.qp_map[model_path] = qp;
        }
      }
      return this.add_query_params_properties_to_model_classes(container);
    };

    TotemQueryParams.prototype.add_query_params_properties_to_model_classes = function(container) {
      var model_class, model_path, path, qp, ref, results;
      ref = this.qp_map;
      results = [];
      for (model_path in ref) {
        qp = ref[model_path];
        path = ns['default'].to_p(model_path);
        if (ember['default'].isBlank(path)) {
          this.error("Model path '" + model_path + "' does not exist.");
        }
        model_class = reqm['default'].factory(container, 'model', path);
        if (ember['default'].isBlank(model_class)) {
          this.error("Model class '" + model_path + "' for path '" + path + "' does not exist.");
        }
        results.push(model_class.reopenClass({
          include_authable_in_query: qp.authable || false,
          include_ownerable_in_query: qp.ownerable || false
        }));
      }
      return results;
    };

    TotemQueryParams.prototype.error = function(message) {
      if (message == null) {
        message = '';
      }
      return reqm['default'].error(this, message);
    };

    TotemQueryParams.prototype.toString = function() {
      return 'TotemQueryParams';
    };

    return TotemQueryParams;

  })();

  ___DefaultExportObject___ = new TotemQueryParams;

  exports['default'] = ___DefaultExportObject___;;

});