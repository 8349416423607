define('totem-simple-auth/authorizer', ['exports', 'ember', 'simple-auth-devise/authorizers/devise'], function (exports, ember, base) {

	'use strict';

	var ___DefaultExportObject___;

	___DefaultExportObject___ = base['default'].extend();

	exports['default'] = ___DefaultExportObject___;;

});