define('thinkspace-readiness-assurance/mixins/am/messages', ['exports', 'ember', 'totem/ns', 'totem/ajax'], function (exports, ember, ns, ajax) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    send_message_to_users: function(data) {
      return this.send_message('to_users', data);
    },
    send_message: function(action, data) {
      if (data == null) {
        data = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = _this.get_auth_query(_this.get_messages_url(action), data);
          return ajax['default'].object(query).then(function() {
            return resolve();
          });
        };
      })(this));
    },
    get_messages_url: function(action) {
      return ns['default'].to_p('readiness_assurance', 'messages', action);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});