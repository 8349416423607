define('totem-simple-auth/authenticator_switch_user', ['exports', 'ember', 'totem/ns', 'totem/config', 'totem/ajax', 'totem/scope', 'totem-simple-auth/authenticator'], function (exports, ember, ns, config, ajax, totem_scope, base) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base['default'].extend({
    restore: function(data) {
      console.warn('switch user authenticator restore', data, ajax['default'].adapter_host());
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          if (data.user_id === data.original_user_id) {
            data.authenticator = 'authenticator:totem';
          } else {
            if (!_this.is_valid_url()) {
              return reject('Invalid url');
            }
          }
          return _this._super(data).then(function(data) {
            return resolve(data);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    get_switch_user_whitelist_regexps: function() {
      var whitelist;
      whitelist = (config['default'].simple_auth && config['default'].simple_auth.switch_user_whitelist_regexps) || [];
      return ember['default'].makeArray(whitelist);
    },
    is_valid_url: function() {
      var target, valid_url;
      target = (window.location.pathname || '').trim();
      if (ember['default'].isBlank(target)) {
        return false;
      }
      valid_url = this.get_switch_user_whitelist_regexps().find((function(_this) {
        return function(regex) {
          return target.match(RegExp(regex, 'i'));
        };
      })(this));
      return ember['default'].isPresent(valid_url);
    },
    authenticate: function(session, data) {
      if (data == null) {
        data = {};
      }
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var current_user, original_user_id, query;
          current_user = session.get('user');
          if (ember['default'].isBlank(current_user)) {
            return reject('No session user.');
          }
          original_user_id = session.get('secure.original_user_id');
          query = {
            model: current_user,
            id: current_user.get('id'),
            verb: 'post',
            action: 'switch',
            data: data,
            skip_message: true
          };
          return ajax['default'].object(query).then(function(payload) {
            var store, type, user, user_id;
            store = _this.get_store();
            type = ns['default'].to_p('user');
            user = store.push(type, store.normalize(type, payload[type]));
            user_id = user.get('id');
            totem_scope['default'].ownerable(user);
            totem_scope['default'].set_current_user(user);
            data = {
              token: payload.token,
              email: user.get('email'),
              user_id: user_id,
              switch_user: true,
              original_user_id: original_user_id,
              original_user: original_user_id === user_id
            };
            return resolve(data);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});