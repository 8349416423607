define('thinkspace-team/views/form', ['exports', 'ember'], function (exports, ember) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].View.extend({
    title: ember['default'].computed.reads('controller.model.title'),
    team_category: ember['default'].computed.reads('controller.model.category_id'),
    save_disabled: ember['default'].computed.or('controller.validation_message', 'controller.model_validation_message'),
    team_category_change: ember['default'].observer('team_category', function() {
      return this.valid();
    }),
    focus_input: function() {
      return this.$('input').first().focus();
    },
    didInsertElement: function() {
      return this.focus_input();
    },
    validations: {
      title: {
        presence: true
      },
      team_category: {
        presence: true
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});