define('totem-messages/api_status_codes', ['exports'], function (exports) {

  'use strict';

  var ApiStatusCodes, ___DefaultExportObject___,
    indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

  ApiStatusCodes = (function() {
    function ApiStatusCodes() {}

    ApiStatusCodes.status_code_definitions = {
      not_found: {
        codes: [404],
        queue: 'error',
        i18n: ['resource'],
        fatal: true
      },
      model_validation: {
        codes: [422],
        queue: 'warn',
        i18n: ['resource'],
        fatal: false
      },
      unauthorized_access: {
        codes: [423],
        queue: 'error',
        fatal: false,
        hide_loading: true,
        model_rollback: true,
        allow_user_message: true,
        allow_callback: true
      },
      internal_server_error: {
        codes: [500],
        queue: 'error',
        error_message: 'Internal server error',
        fatal: true
      },
      session_error: {
        codes: [511],
        queue: 'error',
        fatal: false,
        match_response_text: {
          timeout: 'session_timeout',
          expired: 'session_expired'
        }
      },
      sign_in_error: {
        queue: 'error',
        fatal: false
      },
      success: {
        allow_user_message: false,
        queue: 'info',
        i18n: ['resource', 'action'],
        fatal: false
      },
      failure: {
        queue: 'error',
        fatal: true
      }
    };

    ApiStatusCodes.definition = function(code) {
      var code_def, codes, handler, ref, values;
      if (!code) {
        return null;
      }
      code_def = null;
      ref = this.status_code_definitions;
      for (handler in ref) {
        values = ref[handler];
        if (code === handler) {
          code_def = values;
          code_def.handler = handler;
        } else {
          codes = values.codes || [];
          if ((indexOf.call(codes, code) >= 0)) {
            code_def = values;
            code_def.handler = handler;
          }
        }
        if (code_def) {
          break;
        }
      }
      return code_def;
    };

    return ApiStatusCodes;

  })();

  ___DefaultExportObject___ = ApiStatusCodes;

  exports['default'] = ___DefaultExportObject___;;

});