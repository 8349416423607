define('thinkspace-readiness-assurance/mixins/am/timers', ['exports', 'ember', 'totem/ns', 'totem/ajax'], function (exports, ember, ns, ajax) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = ember['default'].Mixin.create({
    send_timer_cancel: function(data) {
      if (ember['default'].isBlank(data)) {
        return;
      }
      return this.send_timer_request(data, 'cancel');
    },
    send_timer_request: function(data, action) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var query;
          query = _this.get_auth_query(_this.get_timer_url(action), data);
          return ajax['default'].object(query).then(function() {
            return resolve();
          });
        };
      })(this));
    },
    get_timer_url: function(action) {
      return ns['default'].to_p('readiness_assurance', 'timers', action);
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});