define('totem/logger', ['exports', 'totem/config'], function (exports, config) {

  'use strict';

  var Logger, ___DefaultExportObject___, logger;

  logger = config['default'].logger || {};

  Logger = (function() {
    function Logger() {}

    Logger.env_log_level = null;

    Logger.log_values = {
      none: 0,
      info: 1,
      warn: 2,
      error: 3,
      debug: 4,
      verbose: 5,
      all: 6
    };

    Logger.log_value = null;

    Logger.is_info = null;

    Logger.is_warn = null;

    Logger.is_error = null;

    Logger.is_debug = null;

    Logger.is_verbose = null;

    Logger.is_all = null;

    Logger.is_trace = null;

    Logger.is_rest = null;

    Logger.set_log_trace = function(value) {
      if (value == null) {
        value = logger.log_trace;
      }
      return this.is_trace = value;
    };

    Logger.set_log_rest = function(value) {
      if (value == null) {
        value = logger.log_rest;
      }
      return this.is_rest = value;
    };

    Logger.set_log_level = function(env_log_level) {
      var ref;
      this.env_log_level = env_log_level != null ? env_log_level : logger.log_level;
      this.log_value = (ref = this.log_values[this.env_log_level]) != null ? ref : 0;
      this.is_info = this.log_values.info <= this.log_value || this.is_development();
      this.is_warn = (this.log_values.warn <= this.log_value) || this.is_development();
      this.is_error = (this.log_values.error <= this.log_value) || this.is_development();
      this.is_debug = this.log_values.debug <= this.log_value;
      this.is_verbose = this.log_values.verbose <= this.log_value;
      this.is_all = this.log_values.all <= this.log_value;
      this.set_log_trace();
      this.set_log_rest();
      if (this.is_verbose) {
        return this.log_test();
      }
    };

    Logger.info = function() {
      if (this.is_info) {
        return this._log_args('info', arguments);
      }
    };

    Logger.warn = function() {
      if (this.is_warn) {
        return this._log_args('warn', arguments);
      }
    };

    Logger.error = function() {
      if (this.is_error) {
        return this._log_args('error', arguments);
      }
    };

    Logger.debug = function() {
      if (this.is_debug) {
        return this._log_args('debug', arguments);
      }
    };

    Logger.verbose = function() {
      if (this.is_verbose) {
        return this._log_args('verbose', arguments);
      }
    };

    Logger.log = function() {
      return this._log_args('log', arguments);
    };

    Logger.rest = function() {
      return this._log_args('rest', arguments);
    };

    Logger.trace = function() {
      var args, name, object;
      object = arguments[0];
      name = null;
      if (object && object.toString) {
        name = object.toString();
        args = [].splice.call(arguments, 0);
        args.shift();
        args.unshift(name);
        args.push(object);
      }
      if (this.log_trace_entry(name)) {
        return this._log_args('trace', args);
      }
    };

    Logger.log_trace_entry = function(name) {
      if (this.is_trace === true) {
        return true;
      }
      return name && name.match(new RegExp(this.is_trace, 'i'));
    };

    Logger.is_development = function() {
      return config.env.environment === 'development';
    };

    Logger.log_test = function() {
      this._log_args('test', ">Log Test: [" + this.env_log_level + "] (log level " + this.log_value + ") -------------------");
      this.info("INFO");
      this.warn("WARN");
      this.error("ERROR");
      this.debug("DEBUG");
      this.verbose("VERBOSE");
      this._log_args('test', "<Log Test: [" + this.env_log_level + "] (log level " + this.log_value + ") -------------------");
      return this._log_args('test', "TRACE=" + this.is_trace);
    };

    Logger._log_args = function(level, args) {
      var log_args, message;
      message = args[0];
      if (typeof message === 'string' && args.length === 1) {
        switch (level) {
          case 'error':
            console.error("[" + level + "]", message);
            break;
          case 'warn':
            console.warn("[" + level + "]", message);
            break;
          case 'info':
            console.info("[" + level + "]", message);
            break;
          case 'trace':
            console.info("[" + level + "]", message);
            break;
          default:
            console.log("[" + level + "]", message);
        }
        return;
      }
      if (typeof message === 'object') {
        log_args = [].splice.call(message, 0);
        message = log_args.shift();
        if (typeof message === 'string') {
          switch (level) {
            case 'error':
              console.error("[" + level + "]", message, log_args);
              break;
            case 'warn':
              console.warn("[" + level + "]", message, log_args);
              break;
            case 'info':
              console.info("[" + level + "]", message, log_args);
              break;
            case 'trace':
              console.info("[" + level + "]", message, log_args);
              break;
            default:
              console.log("[" + level + "]", message, log_args);
          }
          return;
        }
      }
      switch (level) {
        case 'error':
          return console.error("[" + level + "]", args);
        case 'warn':
          return console.warn("[" + level + "]", args);
        case 'info':
          return console.info("[" + level + "]", args);
        case 'trace':
          return console.info("[" + level + "]", args);
        default:
          return console.log("[" + level + "]", args);
      }
    };

    return Logger;

  })();

  Logger.set_log_level();

  ___DefaultExportObject___ = Logger;

  exports['default'] = ___DefaultExportObject___;;

});