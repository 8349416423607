define('thinkspace-casespace-case-manager/components/wizards/steps/base', ['exports', 'ember', 'totem/ns', 'totem/ajax', 'thinkspace-base/components/base'], function (exports, ember, ns, ajax, base_component) {

  'use strict';

  var ___DefaultExportObject___;

  ___DefaultExportObject___ = base_component['default'].extend({
    step: '',
    default_step: ember['default'].computed(function() {
      return this.get('steps.firstObject');
    }),
    is_editing: ember['default'].computed.not('model.isNew'),
    thinkspace: ember['default'].inject.service(),
    wizard_manager: ember['default'].inject.service(),
    c_validated_input: ns['default'].to_p('common', 'shared', 'validated_input'),
    c_radio: ns['default'].to_p('common', 'shared', 'radio'),
    c_checkbox: ns['default'].to_p('common', 'shared', 'checkbox'),
    init: function() {
      var page_title;
      this._super();
      page_title = this.get('page_title');
      if (ember['default'].isPresent(page_title)) {
        return this.get('wizard_manager').set_page_title(page_title);
      }
    },
    wizard_ajax: function(query, model) {
      return new ember['default'].RSVP.Promise((function(_this) {
        return function(resolve, reject) {
          var store;
          store = _this.get_store();
          return ajax['default'].object(query).then(function(payload) {
            var normalized, payload_type, records, type;
            payload_type = ns['default'].to_p(ember['default'].Inflector.inflector.pluralize(model));
            type = ns['default'].to_p(model);
            records = payload[payload_type];
            if (ember['default'].isBlank(records)) {
              return resolve([]);
            }
            normalized = records.map(function(record) {
              return store.normalize(type, record);
            });
            records = store.pushMany(type, normalized);
            return resolve(records);
          }, function(error) {
            return reject(error);
          });
        };
      })(this));
    },
    get_store: function() {
      return this.container.lookup('store:main');
    },
    actions: {
      complete: function() {
        return this.get('wizard_manager').send_action('complete_step', this.get('step'));
      },
      back: function() {
        return this.get('wizard_manager').send_action('back', this.get('step'));
      },
      go_to_step: function(step) {
        return this.get('wizard_manager').send_action('go_to_step', step);
      }
    }
  });

  exports['default'] = ___DefaultExportObject___;;

});